import styled, { css, keyframes, FlattenSimpleInterpolation } from 'styled-components';
import { Box } from '@material-ui/core';

const load8 = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

export const CircleSpinner = styled(Box)`
  ${({ theme }): FlattenSimpleInterpolation => css`
    margin: 0;
    position: absolute;
    text-indent: -9999em;
    border-top: 0.7em solid rgba(100, 100, 100, 0.2);
    border-right: 0.7em solid rgba(100, 100, 100, 0.2);
    border-bottom: 0.7em solid rgba(100, 100, 100, 0.2);
    border-left: 0.7em solid ${theme.palette.secondary.main};
    transform: translateZ(0);
    animation: ${load8} 1.1s infinite linear;

    &,
    &:after {
      border-radius: 50%;
      width: 10em;
      height: 10em;
    }
  `}
`;
