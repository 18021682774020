import 'whatwg-fetch';
import { config } from '../../config';
import { standardOptions } from '../standardOptions';
import { Data, HttpRequestHandler, DataWithBody } from './types';
import { isDataFormType, isDataWithBodyType } from './typeguards';

const httpRequestHandler = (endpoint: string): HttpRequestHandler => {
  // init vars
  let csrfToken = '';

  //   declare functions
  const fetchEndpoint = (
    method: string,
    url: string,
    data: Data | DataWithBody,
    isFormData = false,
  ): Promise<Response> => {
    const headers = {
      ...(data.headers ? data.headers : {}),
      ...(method !== 'GET' ? { 'csrf-token': csrfToken } : {}),
    };

    const query =
      (data &&
        data.query &&
        Object.keys(data.query).length > 0 &&
        Object.keys(data.query)
          .filter((k) => data && data.query && data.query[k])
          .map(
            (k) =>
              data &&
              data.query &&
              data.query[k] &&
              `${k}=${encodeURIComponent(data.query[k] as string | number | boolean)}`,
          )
          .join('&')) ||
      '';

    const body =
      isDataWithBodyType(data) && data.body
        ? isFormData && isDataFormType(data.body)
          ? data.body
          : JSON.stringify(data.body)
        : undefined;

    return fetch(endpoint + url + (query ? `?${query}` : ''), {
      method,
      ...standardOptions(headers, isFormData),
      body,
    });
  };

  return {
    setCsrfToken: (token: string): void => {
      csrfToken = token;
    },
    post: (url: string, data: DataWithBody | undefined = {}): Promise<Response> => fetchEndpoint('POST', url, data),

    postFormData: (url: string, data: DataWithBody | undefined = {}): Promise<Response> =>
      fetchEndpoint('POST', url, data, true),
    putFormData: (url: string, data: DataWithBody | undefined = {}): Promise<Response> =>
      fetchEndpoint('PUT', url, data, true),

    get: (url: string, data: Data | undefined = {}): Promise<Response> => fetchEndpoint('GET', url, data),

    patch: (url: string, data: DataWithBody | undefined = {}): Promise<Response> => fetchEndpoint('PATCH', url, data),

    put: (url: string, data: DataWithBody | undefined = {}): Promise<Response> => fetchEndpoint('PUT', url, data),

    delete: (url: string, data: Data | undefined = {}): Promise<Response> => fetchEndpoint('DELETE', url, data),
  };
};

export const httpRequest = httpRequestHandler(config.endpoint);
export const httpMockRequest = httpRequestHandler(config.mockEndpoint);
