import React, { ReactElement, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Box } from '@material-ui/core';

import { RootState } from '../../../../lib/store';

import { attemptChangeStartPassword } from '../../../../lib/store/account';
import { BaseDialog } from '../../../molecules/window/BaseDialog';
import { successAccount } from '../../../../lib/store/account/actions';
import { ChangeStartPasswordFormValueType } from '../../form/ChangeStartPasswordForm/types';
import { ChangeStartPasswordForm } from '../../form/ChangeStartPasswordForm';

export const ChangeStartPasswordDialog = (): ReactElement => {
  const { t } = useTranslation(['common']);
  const { shouldChangePassword } = useSelector((state: RootState) => state.account);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(successAccount(''));
  }, []);

  const handleSubmit = (formState: ChangeStartPasswordFormValueType): void => {
    dispatch(attemptChangeStartPassword(formState));
  };

  return (
    <BaseDialog open={shouldChangePassword} title={t('common:startPasswordDialog.popupHeading')}>
      <Box mb={2}>
        <Typography>{t('common:startPasswordDialog.subheading')}</Typography>
      </Box>
      <ChangeStartPasswordForm handleSubmit={handleSubmit} />
    </BaseDialog>
  );
};
