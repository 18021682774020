import { Dispatch } from 'redux';
import { thunk } from './thunks';
import {
  setLoading,
  successInstruction,
  setInstruction,
  setInstructionList,
  setInstructionAssignment,
  setInstructionStats,
  setEmployeeStats,
} from './actions';
import {
  MetaFormValueType,
  InstructionSlideCreate,
  InstructionSlideUpdate,
  AssignInstructionType,
  PutInstructionTranslation,
  InstructionAsset,
  CreateInstructionType,
} from './types';
import { GetStateType } from '../../../../lib/store/types';
import {
  apiCreateInstruction,
  apiDeleteInstruction,
  apiUpdateInstruction,
  apiGetInstruction,
  apiGetInstructions,
  apiAssignInstruction,
  apiGetInstructionAssignment,
  apiGetInstructionStats,
  apiGetEmployeeStats,
  apiDeleteTranslation,
  apiUpdateTranslation,
} from './services';
import { enqueueSnackbar } from '../../../../lib/store/notifier/actions';
import { QueryObject } from '../../../../lib/httpClient';
import { enrichInstructionStats } from './utils';

export const attemptCreateInstruction = (
  metaValues: MetaFormValueType,
  slides: InstructionSlideCreate[],
  assets?: InstructionAsset[],
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    if (slides.length === 0) {
      dispatch(
        enqueueSnackbar({
          translationString: 'module-umo:pages.instructionsCreate.actions.addMissingSlide',
          messageTranslationKeys: { instructionName: metaValues.instructionName },
          options: {
            variant: 'error',
          },
        }),
      );
      return;
    }

    dispatch(setLoading('instructionCreate'));
    const instruction = await apiCreateInstruction({ ...metaValues, slides, assets });

    dispatch(successInstruction('instructionCreate'));
    dispatch(
      enqueueSnackbar({
        translationString: 'module-umo:pages.instructionsCreate.actions.addSuccess',
        messageTranslationKeys: { instructionName: instruction.instructionName },
        options: {
          variant: 'success',
        },
      }),
    );
  });

export const attemptCreateInstructionByWizard = (
  initialInstruction: CreateInstructionType,
  assignment: AssignInstructionType,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    if (initialInstruction.slides.length === 0) {
      dispatch(
        enqueueSnackbar({
          translationString: 'module-umo:pages.instructionsCreate.actions.addMissingSlide',
          messageTranslationKeys: { instructionName: initialInstruction.instructionName },
          options: {
            variant: 'error',
          },
        }),
      );
      return;
    }

    dispatch(setLoading('instructionCreate'));
    const instruction = await apiCreateInstruction(initialInstruction);
    await apiAssignInstruction(instruction._id, assignment);

    dispatch(successInstruction('instructionCreate'));
    dispatch(setInstruction(instruction));
    dispatch(setLoading());
  });

export const attemptUpdateInstruction = (
  _id: string,
  metaValues: MetaFormValueType,
  slides: InstructionSlideUpdate[],
  assets?: InstructionAsset[],
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    if (slides.length === 0) {
      dispatch(
        enqueueSnackbar({
          translationString: 'module-umo:pages.instructionsEdit.actions.editMissingSlide',
          messageTranslationKeys: { instructionName: metaValues.instructionName },
          options: {
            variant: 'error',
          },
        }),
      );
      return;
    }

    dispatch(setLoading('instructionUpdate'));
    const instruction = await apiUpdateInstruction(_id, {
      ...metaValues,
      slides,
      assets,
    });

    dispatch(
      enqueueSnackbar({
        translationString: 'module-umo:pages.instructionsEdit.actions.updateSuccess',
        messageTranslationKeys: { instructionName: instruction.instructionName },
        options: {
          variant: 'success',
        },
      }),
    );
  });

export const attemptDeleteInstruction = (
  _id: string,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('instructionDelete'));
    await apiDeleteInstruction(_id);
    dispatch(successInstruction('instructionDelete'));

    dispatch(
      enqueueSnackbar({
        translationString: 'module-umo:pages.instructionsEdit.actions.deleteSuccess',
        messageTranslationKeys: {},
        options: {
          variant: 'success',
        },
      }),
    );
  });

export const attemptDeleteTranslation = (
  _id: string,
  instructionId: string,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('instructionDelete'));
    await apiDeleteTranslation(_id, instructionId);
    dispatch(successInstruction('instructionDelete'));

    dispatch(
      enqueueSnackbar({
        translationString: 'module-umo:pages.instructionsTranslate.actions.deleteSuccess',
        messageTranslationKeys: {},
        options: {
          variant: 'success',
        },
      }),
    );
  });

export const attemptUpdateTranslation = (
  instructionId: string,
  body: { data: PutInstructionTranslation; assets: InstructionAsset[] },
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('instructionUpdate'));
    const instruction = await apiUpdateTranslation(instructionId, body);

    if (instruction) {
      dispatch(setInstruction(instruction));
    }

    dispatch(
      enqueueSnackbar({
        translationString: 'module-umo:pages.instructionsEdit.actions.updateSuccess',
        messageTranslationKeys: {},
        options: {
          variant: 'success',
        },
      }),
    );
  });

export const attemptGetInstruction = (
  _id: string,
  metaOnly?: boolean,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    const instruction = await apiGetInstruction(_id, metaOnly);
    dispatch(setInstruction(instruction));
  });

export const attemptGetInstructions = (
  filter?: QueryObject,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('instructionListGet'));
    const { instructions, totalInstructions } = await apiGetInstructions(filter);
    dispatch(setInstructionList({ instructions, totalInstructions }));
  });

export const attemptGetInstructionStats = (
  filter?: QueryObject,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    const data = (await apiGetInstructionStats(filter)) || [];
    const { instructionStats } = enrichInstructionStats(data);

    dispatch(setInstructionStats(instructionStats));
  });

export const attemptGetEmployeeStats = (
  filter?: QueryObject,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    const employeeStats = await apiGetEmployeeStats(filter);
    dispatch(setEmployeeStats(employeeStats));
  });

export const attemptGetInstructionAssignment = (
  _id: string,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    const instructionAssignment = await apiGetInstructionAssignment(_id);
    dispatch(setInstructionAssignment(instructionAssignment));
  });

export const attemptAssignInstruction = (
  _id: string,
  body: AssignInstructionType,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('instructionAssignmentSave'));
    await apiAssignInstruction(_id, body);
    dispatch(
      enqueueSnackbar({
        translationString: 'module-umo:pages.instructionsAssign.actions.addSuccess',
        messageTranslationKeys: {},
        options: {
          variant: 'success',
        },
      }),
    );
    dispatch(successInstruction('instructionAssignmentSave'));
  });
