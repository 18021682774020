import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { UserAccountType } from '../store/account/types';
import { attemptCheckAccountToken } from '../store/account/effects';

/**
 * check whether token is still valid or not by
 * it does so when there is an account, the user is not reauthenticating already, and the window is active
 */
export const useCheckAccountToken = (account: UserAccountType | null, reauthenticate: boolean): void => {
  const reduxDispatch = useDispatch();
  let interval: NodeJS.Timeout | null = null;
  const [isWindowActive, setIsWindowActive] = useState(true);

  const onWindowFocus = () => setIsWindowActive(true);
  const onWindowBlur = () => setIsWindowActive(false);

  // check whether token is still valid or not
  useEffect(() => {
    if (account && !reauthenticate && !interval && isWindowActive) {
      // check immediately
      reduxDispatch(attemptCheckAccountToken());

      // and check by interval
      interval = setInterval(() => {
        reduxDispatch(attemptCheckAccountToken());
      }, 300000); // 5m
    }

    // clear interval if window not active or already reauthenticating
    if (reauthenticate || !isWindowActive) {
      clearInterval(Number(interval));
    }

    return () => clearInterval(Number(interval));
  }, [account, reauthenticate, isWindowActive]);

  // setup listeners to check whether the app is active or not
  useEffect(() => {
    window.addEventListener('focus', onWindowFocus);
    window.addEventListener('blur', onWindowBlur);

    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener('focus', onWindowFocus);
      window.removeEventListener('blur', onWindowBlur);
    };
  });
};
