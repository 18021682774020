import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Box, Typography } from '@material-ui/core';
import { FormikProps } from 'formik';
import { EmployeeTypeField } from '../../fields/EmployeeTypeField';
import { RoleField } from '../../fields/RoleField';
import { LocationFields } from '../../fields/LocationFields';
import { ErrorBoxOnInvalidField } from '../../actions/ErrorBoxOnInvalidField';
import { GroupField } from '../../fields/GroupField';
import { UserFormValues } from '../../../../../lib/context/user/types';

// eslint-disable-next-line react/require-default-props
export const CompanyFields = (props: FormikProps<UserFormValues> & { disableAllFields?: boolean }): ReactElement => {
  const { disableAllFields } = props;
  const { t } = useTranslation(['module-core']);
  // eslint-disable-next-line react/destructuring-assignment
  return (
    <Box>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">{t('module-core:pages.userManagement.forms.headings.types')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <EmployeeTypeField disabled={disableAllFields} />
          </Grid>
          <Grid item xs={12}>
            <RoleField required disabled={disableAllFields} />
          </Grid>
          <Grid item xs={12}>
            <GroupField multiple disabled={disableAllFields} />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box mb={1}>
              <Typography variant="h6">
                {t('module-core:pages.userManagement.forms.headings.structureAssignment')}
              </Typography>
            </Box>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <LocationFields disableAllFields={disableAllFields} {...props} />
          </Grid>
        </Grid>
      </Box>
      <ErrorBoxOnInvalidField
        message={t('module-core:pages.userManagement.personalTabHasErrors')}
        fieldNames={['email', 'password']}
      />
    </Box>
  );
};
