import { instructionConstants } from './constants';
import {
  InstructionLoading,
  ActionType,
  Instruction,
  AssignInstructionType,
  EmployeeStat,
  InstructionMetaResponse,
  InstructionStats,
} from './types';
import { ProblemType } from '../../../../lib/store';

/**
 * Actions
 * Beschreibt was wir tun wollen
 */

export const setLoading = (payload: InstructionLoading = ''): ActionType => ({
  type: instructionConstants.INSTRUCTIONS_LOADING,
  payload,
});

export const erroredInstruction = (payload: ProblemType | null = null): ActionType => ({
  type: instructionConstants.INSTRUCTIONS_ERRORED,
  payload,
});

export const successInstruction = (payload: InstructionLoading = ''): ActionType => ({
  type: instructionConstants.INSTRUCTIONS_SUCCESS,
  payload,
});

export const setInstruction = (payload: Instruction | null = null): ActionType => ({
  type: instructionConstants.INSTRUCTIONS_SET,
  payload,
});

export const setInstructionAssignment = (payload: AssignInstructionType | null = null): ActionType => ({
  type: instructionConstants.INSTRUCTIONS_SET_ASSIGNMENT,
  payload,
});

export const setInstructionList = (payload: InstructionMetaResponse): ActionType => ({
  type: instructionConstants.INSTRUCTIONS_SET_ALL,
  payload,
});

export const setInstructionStats = (payload: InstructionStats[]): ActionType => ({
  type: instructionConstants.INSTRUCTIONS_SET_ALL_STATS,
  payload,
});

export const setEmployeeStats = (payload: EmployeeStat[]): ActionType => ({
  type: instructionConstants.INSTRUCTIONS_SET_ALL_EMPLOYEE_STATS,
  payload,
});

export const initInstruction = (): ActionType => ({
  type: instructionConstants.INSTRUCTIONS_INIT,
});
