import dayjs from 'dayjs';
import { FormikErrors } from 'formik';
import { TFunction } from 'i18next';
import { DATE_FORMAT } from '../../../../lib/constants/dateFormat';
import { UserFormValues, UserManagementMode } from '../../../../lib/context/user/types';

export const validateForm = (
  values: UserFormValues,
  t: TFunction,
  mode: UserManagementMode,
): FormikErrors<UserFormValues> => {
  const errors: FormikErrors<UserFormValues> = {};

  if (!values.role) {
    errors.role = t('init:shared.forms.errors.required');
  }

  if (mode !== 'edit' && !values.password) {
    errors.password = t('init:shared.forms.errors.required');
  } else if (values.password && !/^(?=.{6,50}$)(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*\W).*/.test(values.password)) {
    errors.password = t('init:shared.forms.errors.passwordFormat');
  }

  if (!values.email && !values.personnelNumber) {
    errors.email = t('module-core:pages.userManagement.forms.errors.identificationRequired');
    errors.personnelNumber = t('module-core:pages.userManagement.forms.errors.identificationRequired');
  }
  if (!values.firstName) {
    errors.firstName = t('init:shared.forms.errors.required');
  }

  if (!values.lastName) {
    errors.lastName = t('init:shared.forms.errors.required');
  }
  if (values.dateOfBirth && !dayjs(values.dateOfBirth, DATE_FORMAT).isValid()) {
    errors.dateOfBirth = t('common:forms.elements.dateField.invalid');
  }

  return errors;
};
