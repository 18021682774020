import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useFileCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      position: 'relative',
    },
    wrap: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    cardMediaWrap: {
      height: '136px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: theme.palette.grey[100],
    },
    media: {
      maxHeight: '100%',
      maxWidth: '100%',
      objectFit: 'unset',
      textIndent: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      fontSize: 0,
    },
    ellipsis: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    successBar: {
      background: theme.palette.success.main,
      width: '100%',
      height: '3px',
    },
    infoButton: {
      zIndex: 10,
    },
    infoPanel: {
      height: ({ panel }: { panel?: '' | 'info' }) => (panel === 'info' ? '100%' : 0),
      position: 'absolute',
      overflow: 'hidden',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      transition: 'height 200ms ease-in-out',
      background: theme.palette.background.paper,
      zIndex: 1,
    },
  }),
);
