import React from 'react';
import clsx from 'clsx';

import { Image } from '../Image';
import WaveImageSource from '../../../../lib/assets/images/wave.png';
import WaveRightImageSource from '../../../../lib/assets/images/wave-right.png';
import { WaveImageProps } from './types';
import { useWaveImageStyles } from './styles';

export const WaveImage = (props: WaveImageProps): React.ReactElement => {
  const { orientation, maxWidth } = props;
  const classes = useWaveImageStyles({ maxWidth });
  return (
    <Image
      className={clsx(
        classes.root,
        orientation === 'right' && classes.orientationRight,
        orientation === 'top' && classes.orientationTop,
        orientation === 'bottom' && classes.orientationBottom,
      )}
      alt="shows a wave"
      src={orientation === 'right' ? WaveRightImageSource : WaveImageSource}
    />
  );
};
