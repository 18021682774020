import React, { useState, useRef, ReactElement } from 'react';
import { Box, MenuList } from '@material-ui/core';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TopMenuItemProps } from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popper: {
      zIndex: 1,
      '&[x-placement*="bottom"] $arrow': {
        top: 0,
        left: 0,
        marginTop: '-0.9em',
        width: '3em',
        height: '1em',
        '&::before': {
          borderWidth: '0 1em 1em 1em',
          borderColor: `transparent transparent ${theme.palette.primary.main} transparent`,
        },
      },
      '&[x-placement*="top"] $arrow': {
        bottom: 0,
        left: 0,
        marginBottom: '-0.9em',
        width: '3em',
        height: '1em',
        '&::before': {
          borderWidth: '1em 1em 0 1em',
          borderColor: `${theme.palette.primary.main} transparent transparent transparent`,
        },
      },
      '&[x-placement*="right"] $arrow': {
        left: 0,
        marginLeft: '-0.9em',
        height: '3em',
        width: '1em',
        '&::before': {
          borderWidth: '1em 1em 1em 0',
          borderColor: `transparent ${theme.palette.primary.main} transparent transparent`,
        },
      },
      '&[x-placement*="left"] $arrow': {
        right: 0,
        marginRight: '-0.9em',
        height: '3em',
        width: '1em',
        '&::before': {
          borderWidth: '1em 0 1em 1em',
          borderColor: `transparent transparent transparent ${theme.palette.primary.main}`,
        },
      },
    },
    arrow: {
      position: 'absolute',
      fontSize: 7,
      width: '3em',
      height: '3em',
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: 0,
        height: 0,
        borderStyle: 'solid',
      },
    },
    paper: {
      maxWidth: 400,
      overflow: 'auto',
    },
  }),
);

export const TopMenuItem: React.FC<TopMenuItemProps> = ({
  mainComponent,
  dropdownComponent,
}: TopMenuItemProps): ReactElement | null => {
  const classes = useStyles();
  const [open, setOpen] = useState(false); // openState when clicked or navigated with keyboard
  const [arrowRef, setArrowRef] = React.useState(null);
  const anchorRef = useRef<HTMLButtonElement>(null); // ref to main menu item

  // default toggle handler when clicking / keyboard
  const handleToggle = (value: boolean): void => {
    setOpen(() => value);
  };

  // close handler -> only when main menu item not clicked. Close states for default click and hover
  const handleClose = (event: React.MouseEvent<EventTarget>): void => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  // pressing tab closes dropdown
  const handleListKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === 'Tab' || (event.shiftKey && event.key === 'Tab')) {
      event.preventDefault();
      setOpen(false);
    }
  };
  const handleButtonKeyDown = (event: React.KeyboardEvent): void => {
    if (event.shiftKey && event.key === 'Tab') {
      setOpen(false);
    }
  };

  const dropdownComponents = dropdownComponent(handleClose);

  return dropdownComponents.length > 0 ? (
    <Box
      mr={3}
      onMouseEnter={(): void => handleToggle(true)}
      onFocus={(): void => handleToggle(true)}
      onClick={(): void => handleToggle(!open)}
      onMouseLeave={(): void => handleToggle(false)}
      onKeyDown={handleButtonKeyDown}
    >
      {mainComponent(anchorRef, open)}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        className={classes.popper}
        disablePortal
        modifiers={{
          arrow: {
            enabled: true,
            element: arrowRef,
          },
        }}
      >
        <>
          <span
            className={classes.arrow}
            // cannot use useRef as Popper does not center arrow
            ref={(setArrowRef as unknown) as null}
          />
          <Paper className={classes.paper}>
            <MenuList id="menu-list-grow" onKeyDown={handleListKeyDown}>
              {dropdownComponents}
            </MenuList>
          </Paper>
        </>
      </Popper>
    </Box>
  ) : null;
};
TopMenuItem.propTypes = {
  mainComponent: PropTypes.func.isRequired,
  dropdownComponent: PropTypes.func.isRequired,
};
TopMenuItem.defaultProps = {};
