import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import posthog from 'posthog-js';
import { useLocation } from 'react-router-dom';

export const RouteTracker = (): null => {
  const location = useLocation();

  useEffect(() => {
    const title = location.pathname
      .split('/')
      .filter(Boolean)
      .map((el) => el[0].toUpperCase() + el.substring(1))
      .join(' ');

    ReactGA.set({ page: location.pathname, title });
    ReactGA.pageview(location.pathname);
    posthog?.capture('$pageview');
  }, [location]);

  return null;
};
