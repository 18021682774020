import React, { ReactElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../../lib/store';
import { Joyride } from './components/Joyride';
import { on, off } from '../../../../lib/events';
import { useCheckMounted } from './useCheckMounted';
import { attemptUpdateTutorial } from '../../../../lib/store/account/effects';
import { mergeTutorials } from './utils';
import { hasAccountRequiredPermission } from '../../../../lib/utils';
import { TutorialProps } from './types';
import { ConfirmDialog } from '../ConfirmDialog';
import { useCheckHistory } from '../../../../lib/hooks/useCheckHistory';

// @TODO: Pause and resume the tutorial
export const Tutorial = (props: TutorialProps): ReactElement | null => {
  const {
    steps: stepsUnFiltered,
    tutorialName,
    joyrideProps,
    getNextSteps,
    onSkipTutorial,
    onCompleteTutorial,
  } = props;
  // used to manually trigger the tutorial
  const [forced, setForced] = React.useState(false);
  const forceTutorial = React.useCallback(
    ({ detail }) => {
      const { tutorialName: name } = detail;
      if (name === tutorialName) {
        setForced(true);
      }
    },
    [tutorialName],
  );

  const forceTutorialOff = React.useCallback(
    ({ detail }) => {
      const { tutorialName: name } = detail;
      if (name === tutorialName) {
        setForced(false);
      }
    },
    [tutorialName],
  );

  const {
    account: { current },
  } = useSelector((state: RootState) => state);

  const history = useCheckHistory();
  const { t } = useTranslation(['common']);

  const nextSteps = getNextSteps ? getNextSteps({ t, history }) : undefined;

  const [isNextStepsDialogOpen, setIsNextStepsDialogOpen] = React.useState(false);

  const reduxDispatch = useDispatch();

  // if triggered manually, show tutorial
  React.useEffect(() => {
    on('ds_open:tutorial', forceTutorial);

    return () => {
      off('ds_open:tutorial', forceTutorial);
    };
  }, [forceTutorial, tutorialName]);

  React.useEffect(() => {
    on('ds_close:tutorial', forceTutorialOff);

    return () => {
      off('ds_close:tutorial', forceTutorialOff);
    };
  }, [forceTutorialOff, tutorialName]);

  // filter steps by permissions
  const steps = stepsUnFiltered.filter((step) => {
    if (step.requiredPermissions) {
      return hasAccountRequiredPermission(current?.role.permissions || [], step.requiredPermissions);
    }
    return true;
  });

  // used to check if all step targets are already rendered, and to retry if they are not
  const mounted = useCheckMounted(steps);

  // abort if no current user
  if (!current || current.isStartPassword) return null;

  const { tutorials = [], tutorialsMode } = current;

  // if tutorial or next steps dialog is not enabled, return null
  if (tutorialsMode === 'none' && !forced && !isNextStepsDialogOpen) return null;

  const userTutorial = tutorials.find((tutorial) => tutorial.name === tutorialName);

  // if user has already seen this tutorial, return null
  if (
    userTutorial &&
    (userTutorial.status === 'completed' || userTutorial.status === 'incomplete') &&
    !forced &&
    !isNextStepsDialogOpen
  ) {
    return null;
  }

  const handleCompleteTutorial = () => {
    reduxDispatch(
      attemptUpdateTutorial({ tutorials: mergeTutorials(tutorials, userTutorial, tutorialName, 'completed') }),
    );
    setForced(false);

    if (onCompleteTutorial) {
      onCompleteTutorial(history);
    }
  };

  const handleSkipTutorial = () => {
    reduxDispatch(
      attemptUpdateTutorial({ tutorials: mergeTutorials(tutorials, userTutorial, tutorialName, 'incomplete') }),
    );

    if (nextSteps?.renderIf.includes('skipped')) {
      setIsNextStepsDialogOpen(true);
    }

    setForced(false);

    if (onSkipTutorial) {
      onSkipTutorial(history);
    }
  };

  const handleClickCancel = () => {
    nextSteps?.onClickCancel();
    setIsNextStepsDialogOpen(false);
  };

  const handleClickConfirm = () => {
    nextSteps?.onClickConfirm();
    setIsNextStepsDialogOpen(false);
  };

  return (
    <>
      {mounted ? (
        <Joyride
          joyrideProps={joyrideProps}
          handleCompleteTutorial={handleCompleteTutorial}
          handleSkipTutorial={handleSkipTutorial}
          steps={steps}
          name={tutorialName}
        />
      ) : null}

      {isNextStepsDialogOpen ? (
        <ConfirmDialog
          title={nextSteps?.title || ''}
          textReason={nextSteps?.textReason || ''}
          textCancel={nextSteps?.textCancel || ''}
          textConfirm={nextSteps?.textConfirm || ''}
          handleClickConfirm={handleClickConfirm}
          handleClickCancel={handleClickCancel}
          open={isNextStepsDialogOpen}
        />
      ) : null}
    </>
  );
};
