import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useTabBarStyles = makeStyles(() =>
  createStyles({
    secondaryTab: {
      minWidth: '110px',
    },
    secondaryPaper: {
      width: (props: { variant: string }): string => (props.variant === 'fullWidth' ? '100%' : 'initial'),
    },
  }),
);
