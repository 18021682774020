import { QuestionnaireDataType, InstructionSlide } from '../../store/instruction/types';
import { QuestionnaireResult, ResultPayload } from './types';
import { InitialQuestionnaireValues } from '../../../features/InstructionViewer/types';

export const mapToInitialValues = (
  activeSlideQuestionnaire: QuestionnaireDataType[] | undefined,
): InitialQuestionnaireValues | null => {
  if (activeSlideQuestionnaire) {
    return {
      results: activeSlideQuestionnaire.map(() => {
        return { givenAnswers: [] };
      }),
    };
  }

  return null;
};

export const mapToInitialValuesFromResult = (
  slideId: string,
  results: QuestionnaireResult[],
): InitialQuestionnaireValues | null => {
  const result = results.find((res) => res._id === slideId);
  if (!result) return null;
  return {
    results: result.sets.map((set) => ({
      givenAnswers: set.givenAnswers,
    })),
  };
};

export const renderInitialResults = (slides: InstructionSlide[]): QuestionnaireResult[] => {
  const results: QuestionnaireResult[] = [];

  slides.forEach((slide) => {
    if (slide.type === 'questionnaire') {
      results.push({
        _id: slide._id,
        sets: slide.questionnaire.map((set, setIndex) => {
          const correctAnswers: string[] = [];
          set.answers.forEach((answer, answerIndex) => {
            if (answer.isCorrect) correctAnswers.push(`${answerIndex}`);
          });
          return {
            setIndex,
            givenAnswers: [],
            correctAnswers,
          };
        }),
      });
    }
  });
  return results;
};

export const replaceInResult = (results: QuestionnaireResult[], payload: ResultPayload): QuestionnaireResult[] => {
  return results.map((result) => {
    if (result._id === payload.slideId) {
      return {
        ...result,
        sets: result.sets.map((set) => {
          const foundSet = payload.sets.find((s) => s.setIndex === set.setIndex);
          return {
            ...set,
            givenAnswers: (foundSet && foundSet.givenAnswers) || set.givenAnswers,
          };
        }),
      };
    }
    return {
      ...result,
    };
  });
};
