import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { attemptDeleteUser } from '../../../../../lib/store/user/effects';
import { useUserStateContext } from '../../../../../lib/context/user';
import { PrimaryButton, RemoveButton } from '../../../../molecules/button';
import { ConfirmDeleteDialog } from '../../../window/ConfirmDeleteDialog';

// eslint-disable-next-line react/require-default-props
type SubmitbarProps = { hasDeletePermissions?: boolean; hasUpdatePermissions?: boolean };

export const Submitbar = (props: SubmitbarProps): ReactElement => {
  const { hasDeletePermissions, hasUpdatePermissions } = props;
  const { t } = useTranslation(['module-core', 'init']);
  const reduxDispatch = useDispatch();

  const { currentUser, loadingUser, viewMode } = useUserStateContext();

  const handleDelete = (): void => {
    if (viewMode === 'edit' && currentUser && currentUser._id) {
      reduxDispatch(attemptDeleteUser(currentUser._id));
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // handleDelete

  return viewMode === 'edit' ? (
    <Box pt={2} display="flex" justifyContent="space-between">
      {hasDeletePermissions ? (
        <RemoveButton
          onClick={handleClickOpen}
          disabled={!!(!!(currentUser && currentUser.isRootUser) || loadingUser)}
          loading={loadingUser === 'userDelete'}
        >
          {t('module-core:pages.userManagement.forms.submit.deleteUser')}
        </RemoveButton>
      ) : null}
      {hasUpdatePermissions ? (
        <PrimaryButton type="submit" loading={loadingUser === 'userEdit'}>
          {t('init:shared.forms.submit.save')}
        </PrimaryButton>
      ) : null}
      {handleDelete && (
        <ConfirmDeleteDialog open={open} handleClickConfirm={handleDelete} handleClickCancel={handleClose} />
      )}
    </Box>
  ) : (
    <Box pt={2} display="flex" justifyContent="flex-end">
      <PrimaryButton type="submit" loading={loadingUser === 'userAdd'}>
        {t('init:shared.forms.submit.create')}
      </PrimaryButton>
    </Box>
  );
};
