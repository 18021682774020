import React, { ReactElement } from 'react';
import { FormikProps, Field, FieldProps } from 'formik';
import { Typography, Box, Grid, FormControlLabel, FormGroup, Checkbox } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { HelpOutlineRounded } from '@material-ui/icons';
import { QuestionnaireDataType } from '../../../../lib/store/instruction/types';
import { InitialQuestionnaireValues } from '../../types';
import { useQuestionnaireStyles } from './styles';

export const Questionnaire = (
  props: { questionnaire: QuestionnaireDataType[] } & FormikProps<InitialQuestionnaireValues>,
): ReactElement => {
  const { t } = useTranslation();
  const classes = useQuestionnaireStyles();
  const { questionnaire, setFieldValue } = props;
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box p={3}>
          <Typography variant="h4">{t('shared.instructionViewer.question.question1')}</Typography>
          <Typography variant="body1">{t('shared.instructionViewer.question.question2')}</Typography>
        </Box>
        <Box p={3} pt={0}>
          {questionnaire.map((item, index) => {
            return (
              <Box key={item.question} mb={2} display="flex">
                <Box flex="0" mr={1.5} color="#989898">
                  <HelpOutlineRounded color="inherit" />
                </Box>

                <Box flex="1">
                  <Typography variant="body1" style={{ fontWeight: 600 }}>
                    {`${index + 1}. `}
                    {item.question}
                  </Typography>
                  <Box display="inline-flex">
                    <FormGroup>
                      {item.answers.map((answerObject, answerIndex) => {
                        return (
                          <Field key={answerObject.answer} name={`results.${index}.givenAnswers`}>
                            {({ field }: FieldProps): ReactElement => {
                              return (
                                <FormControlLabel
                                  className={classes.answer}
                                  control={
                                    // eslint-disable-next-line react/jsx-wrap-multilines
                                    <Checkbox
                                      checked={field.value.includes(`${answerIndex}`)}
                                      onChange={(): void => {
                                        if (field.value.includes(`${answerIndex}`)) {
                                          const nextValue = field.value.filter(
                                            (value: string) => value !== `${answerIndex}`,
                                          );
                                          setFieldValue(`results.${index}.givenAnswers`, nextValue);
                                        } else {
                                          const nextValue = field.value.concat(`${answerIndex}`);
                                          setFieldValue(`results.${index}.givenAnswers`, nextValue);
                                        }
                                      }}
                                      id={`results.${index}.givenAnswers`}
                                      name={`results.${index}.givenAnswers`}
                                    />
                                  }
                                  label={answerObject.answer}
                                />
                              );
                            }}
                          </Field>
                        );
                      })}
                    </FormGroup>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Grid>
    </Grid>
  );
};
