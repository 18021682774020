import { Dispatch } from 'redux';
import { thunk } from './thunks';
import { apiCreateUser, apiGetUsers, apiUpdateUser, apiDeleteUser } from './services';
import { setLoading, addUser, successUser, setUserList, replaceUser, deleteUser } from './actions';
import { GetStateType } from '..';
import { CreateUserType } from './types';

import { QueryObject } from '../../httpClient';
import { enqueueSnackbar } from '../notifier/actions';

export const attemptGetUsers = (
  filter?: QueryObject,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    const { users, totalUsers } = await apiGetUsers(filter);
    dispatch(setUserList({ users, totalUsers }));
  });

export const attemptCreateUser = (
  body: CreateUserType,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('userAdd'));
    const user = await apiCreateUser(body);
    dispatch(addUser(user));
    dispatch(successUser('userAdd'));
    dispatch(
      enqueueSnackbar({
        translationString: 'module-core:pages.userManagement.actions.userAddSuccess',
        messageTranslationKeys: {},
        options: {
          variant: 'success',
        },
      }),
    );
  });

export const attemptUpdateUser = (
  _id: string,
  body: Partial<CreateUserType>,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('userEdit'));
    if (Object.keys(body).length > 0) {
      const user = await apiUpdateUser(_id, body);
      dispatch(replaceUser(user));

      dispatch(successUser('userEdit'));

      dispatch(
        enqueueSnackbar({
          translationString: 'module-core:pages.userManagement.actions.userUpdateSuccess',
          messageTranslationKeys: {},
          options: {
            variant: 'success',
          },
        }),
      );
    } else {
      dispatch(
        enqueueSnackbar({
          translationString: 'module-core:pages.userManagement.actions.userUpdateNoChange',
          messageTranslationKeys: {},
          options: {
            variant: 'warning',
          },
        }),
      );
    }
  });

export const attemptDeleteUser = (_id: string): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('userDelete'));
    await apiDeleteUser(_id);
    dispatch(deleteUser(_id));
    dispatch(successUser('userDelete'));

    dispatch(
      enqueueSnackbar({
        translationString: 'module-core:pages.userManagement.actions.userDeleteSuccess',
        messageTranslationKeys: {},
        options: {
          variant: 'success',
        },
      }),
    );
  });
