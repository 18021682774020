export enum roleConstants {
  ROLE_SET_ALL = 'dSimple/role/SET_ALL',
  ROLE_SET_ALL_NAMES = 'dSimple/role/SET_ALL_NAMES',
  ROLE_LOADING = 'dSimple/role/LOADING',
  ROLE_ERRORED = 'dSimple/role/ERRORED',
  ROLE_SUCCESS = 'dSimple/role/SUCCESS',
  ROLE_ADD = 'dSimple/role/ADD',
  ROLE_REPLACE = 'dSimple/role/REPLACE',
  ROLE_DELETE = 'dSimple/role/DELETE',
  ROLE_INIT = 'dSimple/role/INIT',
}
