import { Grid } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { BaseTemplate } from '../foundation/BaseTemplate';

export const CenteredContentTemplate = ({ children }: { children: ReactElement }): ReactElement => {
  return (
    <BaseTemplate stretch waveOrientation={{ bottom: true }} withPixel={{ bottom: true }}>
      <Grid style={{ height: '100%' }} container justify="center" alignItems="center">
        {children}
      </Grid>
    </BaseTemplate>
  );
};
