import React, { ReactElement, useEffect, useRef, useLayoutEffect } from 'react';
import { Drawer, Typography, Stepper, Step, StepLabel, IconButton } from '@material-ui/core';

import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { FormikProps } from 'formik';
import dayjs from 'dayjs';

import { CloseRounded } from '@material-ui/icons';
import { useCheckHistory } from '../../../lib/hooks/useCheckHistory';
import { useInstructionsWizardStyles } from './styles';
import { queryParams } from '../../../lib/utils/queryParams';
import { TextWithIconButton, PrimaryButton } from '../../../components/molecules/button';
import StepTemplates from './components/StepTemplates';
import { StepMeta } from './components/StepMeta';
import { StepAssignments } from './components/StepAssignments';
import { AlertBoxWithButton } from '../../../components/molecules/card/AlertCardWithButton';
import { hasAccountRequiredPermission } from '../../../lib/utils';
import { RootState } from '../../../lib/store';
import { AvailablePermissions } from '../../../lib/constants/availablePermissions';
import { attemptGetInstructions } from '../../lib/store/instruction/effects';
import {
  setLoading,
  erroredInstruction,
  successInstruction,
  setInstruction as setGlobalInstruction,
} from '../../lib/store/instruction/actions';
import { CreateInstructionType, AssignInstructionType } from '../../lib/store/instruction/types';
import { Template } from '../../lib/store/template/types';
import { UpgradeDialog } from '../../../components/organisms/window/UpgradeDialog';
import { useCheckPlan } from '../../../lib/hooks/useCheckPlan';
import { availablePlans } from '../../../lib/constants/availablePlans';
import { LogoSpinner } from '../../../components/molecules/spinner/LogoSpinner';
import { getStepErrorMessage, submitWizard } from './utils';
import { WizardMetaFormValues } from './types';
import { DATE_FORMAT } from '../../../lib/constants/dateFormat';
import { UmoAuthRoutes } from '../../lib/constants/pagePaths';
import { StepSuccess } from './components/StepSuccess';

const STEPS = [
  'module-umo:shared.wizard.step-1.title',
  'module-umo:shared.wizard.step-2.title',
  'module-umo:shared.wizard.step-3.title',
];

export const InstructionsWizard = (): ReactElement | null => {
  const { t } = useTranslation();

  const { current: account, homeUrl } = useSelector((state: RootState) => state.account);
  const { totalInstructions, loading, instruction: currentInstruction, success } = useSelector(
    (state: RootState) => state.instruction,
  );
  const { hasPlan, _inclusiveInstructions, isBetaPlan } = useCheckPlan(availablePlans);

  const currentInstructionId = currentInstruction?._id || '';

  const isUpgradeDialogOpen =
    (!hasPlan || isBetaPlan) &&
    typeof _inclusiveInstructions === 'number' &&
    totalInstructions >= _inclusiveInstructions;

  const reduxDispatch = useDispatch();

  const history = useCheckHistory();
  const location = useLocation();
  const { drawer } = queryString.parse(location.search);
  const isOpen = drawer === 'wizard';

  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedTemplateId, setSelectedTemplateId] = React.useState<string>('');
  const [selectedCategory, setSelectedCategory] = React.useState<string>('multi-step-instruction');
  const [instruction, setInstruction] = React.useState<Partial<CreateInstructionType>>({
    createdBy: account?._id || '',
  });
  const [assignment, setAssignment] = React.useState<Partial<AssignInstructionType>>({});
  const [isErrorMessageShown, setIsErrorMessageShown] = React.useState<boolean>(false);

  const metaFormRef = useRef<FormikProps<WizardMetaFormValues>>(null);

  const errorMessage = getStepErrorMessage({
    step: activeStep + 1,
    instruction,
    assignment,
    t,
    metaFormErrors: metaFormRef?.current?.errors,
  });

  const permissions = (account && account.role.permissions) || [];

  const classes = useInstructionsWizardStyles({ overflowVisible: activeStep === 0 });

  const hasPermissions = hasAccountRequiredPermission(
    permissions,
    [
      AvailablePermissions.CoreUseradministrationCreate,
      AvailablePermissions.CoreUseradministrationUpdate,
      AvailablePermissions.CoreRoleFind,
      AvailablePermissions.UmoInstructionadministrationCreate,
      AvailablePermissions.UmoInstructionadministrationUpdate,
      AvailablePermissions.UmoInstructionassignmentUser,
    ],
    true,
  );

  const handleClose = () => {
    queryParams.remove(history, 'drawer');
  };

  const handleUpdateInstruction = (data: Partial<CreateInstructionType>) => {
    setInstruction((prev) => ({ ...prev, ...data }));
  };

  const handleUpdateAssignment = (data: Partial<AssignInstructionType>) => {
    setAssignment((prev) => ({ ...prev, ...data }));
  };

  const handleNext = () => {
    if (errorMessage) {
      setIsErrorMessageShown(true);
      return;
    }

    setIsErrorMessageShown(false);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    // setInstruction + setAssignment with meta form values when going back
    if (activeStep === 2 && metaFormRef?.current) {
      const { values: metaValues } = metaFormRef.current;

      handleUpdateInstruction({
        instructionName: metaValues.instructionName,
        instructionType: metaValues.instructionType,
        instructionTypeCategory: metaValues.instructionTypeCategory,
      });
      handleUpdateAssignment({
        intervalInMilliSeconds: metaValues.intervalInMilliSeconds ?? undefined,
        ...(metaValues.startDate && { startDate: dayjs(metaValues.startDate, DATE_FORMAT).toDate() }),
      });
    }
    // then navigate back
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = () => {
    if (errorMessage) {
      setIsErrorMessageShown(true);
      return;
    }

    setIsErrorMessageShown(false);
    submitWizard({
      metaFormRef,
      instruction,
      assignment,
      reduxDispatch,
      setInstruction,
      setAssignment,
    });
  };

  useLayoutEffect(() => {
    if (hasPermissions && isOpen) {
      reduxDispatch(setLoading('instructionListGet'));
      reduxDispatch(attemptGetInstructions());
    }
  }, [reduxDispatch, hasPermissions, isOpen]);

  useEffect(() => {
    if (success === 'instructionCreate') {
      handleNext();
    }
  }, [success]);

  useEffect(() => {
    if (!isOpen) {
      setActiveStep(0);
      setSelectedTemplateId('');
      setSelectedCategory('multi-step-instruction');
      setInstruction({
        createdBy: account?._id || '',
      });
      setAssignment({});
      setIsErrorMessageShown(false);
      reduxDispatch(setLoading());
      reduxDispatch(erroredInstruction());
      reduxDispatch(successInstruction());
      reduxDispatch(setGlobalInstruction(null));
    }
  }, [isOpen]);

  return (
    <Drawer PaperProps={{ className: classes.paperRoot }} anchor="right" open={isOpen} onClose={handleClose}>
      {loading === 'instructionListGet' ? (
        <div className={classes.loadingRoot}>
          <LogoSpinner />
        </div>
      ) : hasPermissions ? (
        <div className={classes.innerRoot}>
          <div className={classes.contentRoot}>
            <div className={classes.topBarWrap}>
              <Typography variant="h1">{t('module-umo:shared.wizard.heading')}</Typography>
              <IconButton onClick={handleClose}>
                <CloseRounded />
              </IconButton>
            </div>

            {activeStep !== STEPS.length && (
              <Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel>
                {STEPS.map((label) => (
                  <Step key={label}>
                    <StepLabel>{t(label)}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            )}
            <div className={classes.contentWrap}>
              {activeStep !== STEPS.length && (
                <Typography>{t(`module-umo:shared.wizard.step-${activeStep + 1}.description`)}</Typography>
              )}
              <div className={classes.contentInner}>
                {activeStep === 0 ? (
                  <StepTemplates
                    handleSelectTemplate={(id: string, slides: Template['slides'], language: Template['language']) => {
                      handleUpdateInstruction({ slides, language });
                      setSelectedTemplateId(id);
                    }}
                    selectedTemplateId={selectedTemplateId}
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                    initialLanguage="de-DE"
                  />
                ) : activeStep === 1 ? (
                  <StepAssignments currentAssignment={assignment} handleUpdateAssignment={handleUpdateAssignment} />
                ) : activeStep === 2 ? (
                  <StepMeta
                    metaFormRef={metaFormRef}
                    initialValues={{
                      instructionName: instruction.instructionName || '',
                      instructionType: instruction.instructionType || '',
                      instructionTypeCategory: instruction.instructionTypeCategory || '',
                      startDate: assignment.startDate
                        ? dayjs(assignment.startDate || undefined)
                            .format(DATE_FORMAT)
                            .toString()
                        : '',
                      intervalInMilliSeconds: assignment.intervalInMilliSeconds || null,
                    }}
                  />
                ) : (
                  <StepSuccess
                    isExecutingPossible={!!assignment.users?.some((userId) => userId === account?._id)}
                    currentInstructionId={currentInstructionId}
                    handleClickPreview={() => {
                      queryParams.remove(history, 'drawer');
                      history.push(UmoAuthRoutes.instructionsPreview + currentInstructionId);
                    }}
                    handleClickExecute={() => {
                      queryParams.remove(history, 'drawer');
                      history.push(UmoAuthRoutes.myInstructionsView + currentInstructionId);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          {activeStep !== STEPS.length && (
            <div className={classes.navigation}>
              <TextWithIconButton disabled={activeStep === 0} onClick={handleBack}>
                {t('module-umo:shared.wizard.navigation.back')}
              </TextWithIconButton>

              <div className={classes.nextButtonWrap}>
                {isErrorMessageShown && (
                  <Typography variant="subtitle2" className={classes.errorMessage}>
                    {errorMessage}
                  </Typography>
                )}
                <PrimaryButton
                  variant="contained"
                  color="primary"
                  onClick={activeStep === STEPS.length - 1 ? handleSubmit : handleNext}
                  loading={loading === 'instructionCreate'}
                >
                  {activeStep === STEPS.length - 1
                    ? t('module-umo:shared.wizard.navigation.finish')
                    : t('module-umo:shared.wizard.navigation.next')}
                </PrimaryButton>
              </div>
            </div>
          )}
        </div>
      ) : (
        <AlertBoxWithButton buttonLink={homeUrl || '/'} buttonText={t('init:shared.forms.submit.backPage')}>
          <Typography variant="h6" color="textSecondary" component="p">
            {t('init:pages.unauthorized.content')}
          </Typography>
        </AlertBoxWithButton>
      )}
      <UpgradeDialog handleClose={() => handleClose()} open={isUpgradeDialogOpen} />
    </Drawer>
  );
};
