import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useProfileDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    alignHorizontal: {
      flexDirection: 'row',
      '& > *': {
        marginRight: theme.spacing(2),
      },
    },
  }),
);
