import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

type SingleFileFieldStyles = { isDragActive: boolean; isDragAccept: boolean; isDragReject: boolean };

const getColor = (props: SingleFileFieldStyles, theme: Theme) => {
  if (props.isDragAccept) {
    return theme.palette.success.main;
  }
  if (props.isDragReject) {
    return theme.palette.error.main;
  }
  if (props.isDragActive) {
    return theme.palette.primary.main;
  }
  return theme.palette.grey[400];
};

export const useSingleFileFieldStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderWidth: '2px',
      borderRadius: '2px',
      borderColor: (props: SingleFileFieldStyles) => getColor(props, theme),
      borderStyle: 'dashed',
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.grey[600],
      transition: 'border .24s ease-in-out',
      '& .dropzone': {
        outline: 'none',
        display: 'flex',
        padding: '20px',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      },
    },
    previewImage: {
      maxWidth: '100%',
      maxHeight: '100%',
    },
    scaleSmall: {
      maxHeight: '100px',
    },
  }),
);
