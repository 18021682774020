import React, { ReactElement } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { RemoveButton } from '../../../molecules/button';
import { ConfirmDeleteDialogProps } from './types';

export const ConfirmDeleteDialog = (props: ConfirmDeleteDialogProps): ReactElement => {
  const { open, handleClickCancel, handleClickConfirm } = props;
  const { t } = useTranslation(['init', 'module-umo']);
  return (
    <Dialog
      open={open}
      // onClose={handleClickCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t(`common:confirmDialog.popupHeadingInstruction`)}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography>{t(`common:confirmDialog.popupDialogInstruction`)}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClickCancel} color="primary" autoFocus>
          {t(`common:confirmDialog.buttons.cancel`)}
        </Button>
        <RemoveButton onClick={handleClickConfirm} color="primary">
          {t(`common:confirmDialog.buttons.delete`)}
        </RemoveButton>
      </DialogActions>
    </Dialog>
  );
};
