/* eslint-disable @typescript-eslint/no-explicit-any */
import { BillingCustomerFormValues } from '../../../../components/organisms/form/BillingCustomerForm';
import { httpRequest, handleResponse } from '../../../httpClient';
import { OrganisationBilling, OrganisationRequestType, OrganisationType, OrganisationBillingReports } from '../types';
import { PaymentMethod } from '../../../../components/organisms/form/PaymentForm/types';

export const apiSendAdminRequest = (type: OrganisationRequestType): Promise<unknown> =>
  httpRequest.get('/core/organisations/request', { query: { type } }).then(handleResponse);

export const apiCheckOrganisation = (organisationSlug: string): Promise<OrganisationType> =>
  httpRequest
    .get('/core/organisations/check', { query: { organisationSlug } })
    .then((res) => handleResponse<OrganisationType>(res))
    .then((data) => data);

export const apiGetOrganisationBilling = (): Promise<{
  billing: OrganisationBilling;
  reports: OrganisationBillingReports;
}> =>
  httpRequest
    .get('/core/organisations/billing')
    .then((res) => handleResponse<{ billing: OrganisationBilling; reports: OrganisationBillingReports }>(res))
    .then((data) => data);

export const apiOrganisationSetupIntent = (paymentMethod: 'sepa_debit'): Promise<{ clientSecret: string }> =>
  httpRequest
    .post('/core/organisations/billing/setup-intent', { body: { paymentMethod } })
    .then((res) => handleResponse<{ clientSecret: string }>(res))
    .then((data) => data);

export const apiUpdateOrganisation = (body: Partial<OrganisationType>): Promise<OrganisationType> =>
  httpRequest
    .patch('/core/organisations', { body })
    .then((res) => handleResponse<OrganisationType>(res))
    .then((data) => data);

export const apiBillingCreateCustomer = (body: BillingCustomerFormValues): Promise<OrganisationBilling> =>
  httpRequest
    .post('/core/organisations/billing/customer', { body })
    .then((res) => handleResponse<OrganisationBilling>(res))
    .then((data) => data);

export const apiBillingCreateSubscription = (body: {
  customerId: string;
  paymentMethod: PaymentMethod;
  paymentMethodId: string;
}): Promise<{ billing: OrganisationBilling; subscription: any; reports: OrganisationBillingReports }> =>
  httpRequest
    .post('/core/organisations/billing/subscription', { body })
    .then((res) =>
      handleResponse<{ billing: OrganisationBilling; subscription: any; reports: OrganisationBillingReports }>(res),
    )
    .then((data) => data);

export const apiBillingRetryInvoice = (body: {
  customerId: string;
  paymentMethod: PaymentMethod;
  paymentMethodId: string;
}): Promise<{ billing: OrganisationBilling; subscription: any; reports: OrganisationBillingReports }> =>
  httpRequest
    .post('/core/organisations/billing/retry-invoice', { body })
    .then((res) =>
      handleResponse<{ billing: OrganisationBilling; subscription: any; reports: OrganisationBillingReports }>(res),
    )
    .then((data) => data);

export const apiBillingProvisionAccess = (body: {
  subscriptionId: string;
  paymentMethod: PaymentMethod;
  paymentMethodId: string;
}): Promise<{ billing: OrganisationBilling; subscription: any; reports: OrganisationBillingReports }> =>
  httpRequest
    .post('/core/organisations/billing/provision-access', { body })
    .then((res) =>
      handleResponse<{ billing: OrganisationBilling; subscription: any; reports: OrganisationBillingReports }>(res),
    )
    .then((data) => data);

export const apiBillingCancelSubscription = (): Promise<{ billing: OrganisationBilling; subscription: any }> =>
  httpRequest
    .delete('/core/organisations/billing/subscription')
    .then((res) => handleResponse<{ billing: OrganisationBilling; subscription: any }>(res))
    .then((data) => data);

export const apiBillingUpdateCustomer = (body: BillingCustomerFormValues): Promise<OrganisationBilling> =>
  httpRequest
    .patch('/core/organisations/billing/customer', { body })
    .then((res) => handleResponse<OrganisationBilling>(res))
    .then((data) => data);
