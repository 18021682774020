import { Dispatch } from 'redux';
import { GetStateType, WorkType, StartType, EndType, ErrorType } from '../../store/types';
import { exchangeToken } from '../exchangeToken';

import { getRefreshToken } from '../../utils';
import { reauthenticateAccount } from '../../store/account/actions';
import { APIError } from '../../types';

export const thunkify = ({ start, end, error }: { start?: StartType; end?: EndType; error: ErrorType }) => <
  T extends unknown
>(
  work: WorkType<T>,
) => async (dispatch: Dispatch, getState: GetStateType): Promise<T | undefined> => {
  let result;
  if (start) {
    start(dispatch);
  }
  try {
    result = await work(dispatch, getState);
  } catch (e) {
    if (e && e instanceof APIError && e.tokenExpired) {
      try {
        const refreshToken = getRefreshToken();
        if (refreshToken) {
          await exchangeToken(refreshToken);
          result = await work(dispatch, getState);
        } else {
          // eslint-disable-next-line no-console
          console.error({
            code: 'CORE_HTTPC_THUNK_NO_TOKEN',
            type: 'error',
            message: 'Caught by not having a refreshToken',
            stack: JSON.stringify(e),
          });
        }
      } catch (err) {
        if (err && err instanceof APIError) {
          // eslint-disable-next-line no-console
          console.warn((err && err.message) || err);
          if (err && err.tokenExpired) {
            dispatch(reauthenticateAccount());
          } else {
            if (error) {
              error(err, dispatch);
            }

            // eslint-disable-next-line no-console
            console.error({
              code: 'CORE_HTTPC_THUNK_UNKNOWN_ERR',
              type: 'error',
              message: 'Caught while exchanging token',
              stack: JSON.stringify(err),
            });
          }
        } else {
          // eslint-disable-next-line no-console
          console.warn(e);
          if (error) {
            error(new Error('Unknown Error. Error not of type APIError'), dispatch);
          }
        }
      }
    } else if (e && e instanceof APIError) {
      // eslint-disable-next-line no-console
      console.warn((e && e.message) || e);
      if (error) {
        error(e, dispatch);
      }
    } else {
      // eslint-disable-next-line no-console
      console.warn(e);
      if (error) {
        error(new Error('Unknown Error. Error not of type APIError'), dispatch);
      }
    }
  }
  if (end) {
    end(dispatch);
  }
  return result;
};
