import React, { ReactElement } from 'react';
import { BaseTemplate } from '../foundation/BaseTemplate';
import { PublicTemplateProps } from './types';

export const PublicTemplate = (props: PublicTemplateProps): ReactElement => {
  const { children, mode } = props;
  return (
    <BaseTemplate
      mode={mode}
      waveOrientation={mode === 'embed' ? {} : { right: true }}
      stretch
      sliderMode
      withPixel={mode === 'embed' ? {} : { right: false }}
    >
      {children}
    </BaseTemplate>
  );
};
