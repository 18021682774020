import { ActionType, InstructionTypeLoading, InstructionType } from './types';
import { ProblemType } from '../../../../lib/store';
import { instructionTypeConstants } from './constants';

export const setAllInstructionType = (payload: InstructionType[] = []): ActionType => ({
  type: instructionTypeConstants.INSTRUCTIONTYPE_SET_ALL,
  payload,
});

export const setLoading = (payload: InstructionTypeLoading = ''): ActionType => ({
  type: instructionTypeConstants.INSTRUCTIONTYPE_LOADING,
  payload,
});

export const erroredInstructionType = (payload: ProblemType | null = null): ActionType => ({
  type: instructionTypeConstants.INSTRUCTIONTYPE_ERRORED,
  payload,
});

export const successInstructionType = (payload: InstructionTypeLoading = ''): ActionType => ({
  type: instructionTypeConstants.INSTRUCTIONTYPE_SUCCESS,
  payload,
});

export const addInstructionType = (payload: InstructionType): ActionType => ({
  type: instructionTypeConstants.INSTRUCTIONTYPE_ADD,
  payload,
});

export const replaceInstructionType = (payload: InstructionType): ActionType => ({
  type: instructionTypeConstants.INSTRUCTIONTYPE_REPLACE,
  payload,
});

export const deleteInstructionType = (payload: string): ActionType => ({
  type: instructionTypeConstants.INSTRUCTIONTYPE_DELETE,
  payload,
});
