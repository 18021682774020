import { httpRequest, handleResponse } from '../../../httpClient';
import { EmployeeType, CreateEmployeeType, UpdateEmployeeType } from '../types';
import { isObject } from '../../../utils/helpers';

/**
 * EmployeeTypes
 */
export const apiGetEmployeeTypes = (): Promise<EmployeeType[]> =>
  httpRequest
    .get(`/core/employee-types`)
    .then((res) => handleResponse<EmployeeType[]>(res))
    .then((data) => (isObject(data) ? [] : data))
    .catch();

export const apiCreateEmployeeType = (body: CreateEmployeeType): Promise<EmployeeType> =>
  httpRequest
    .post('/core/employee-types', { body })
    .then((res) => handleResponse<EmployeeType>(res))
    .then((data) => data)
    .catch();

export const apiUpdateEmployeeType = (body: UpdateEmployeeType): Promise<EmployeeType> =>
  httpRequest
    .patch('/core/employee-types', { body })
    .then((res) => handleResponse<EmployeeType>(res))
    .then((data) => data);

export const apiDeleteEmployeeType = (_id: string): Promise<unknown> =>
  httpRequest.delete(`/core/employee-types/${_id}`).then(handleResponse);
