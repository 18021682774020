import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, ListItemText } from '@material-ui/core';
import { Form, Formik, FormikErrors } from 'formik';
import clsx from 'clsx';
import { InlineEditFieldFormProps, InlineEditFieldValueType } from './types';
import { SaveIconButton, CancelIconButton, DeleteIconButton } from '../../../molecules/button';
import { InputField } from '../../../molecules/form/elements';
import { useStyles } from './styles';

export const InlineEditFieldForm = (props: InlineEditFieldFormProps): ReactElement => {
  const { fieldName, fieldLabel, initialFieldValue, loading, handleSubmit, handleCancel, handleDelete, dense } = props;
  const { t } = useTranslation(['init', 'module-core']);
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        [fieldName]: initialFieldValue,
      }}
      onSubmit={(values): void => {
        if (handleSubmit) {
          handleSubmit(values[fieldName]);
        }
      }}
      validate={(values: InlineEditFieldValueType): FormikErrors<InlineEditFieldValueType> => {
        const errors: FormikErrors<InlineEditFieldValueType> = {};

        if (!values[fieldName]) {
          errors[fieldName] = t('init:shared.forms.errors.required');
        }
        return errors;
      }}
    >
      {(): ReactElement => (
        <Form>
          <Box display="flex">
            <Box>
              {handleDelete && (
                <DeleteIconButton
                  disabled={!!loading}
                  loading={loading === 'delete'}
                  edge="start"
                  aria-label="delete"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete();
                  }}
                />
              )}
            </Box>
            <Box display="flex" alignItems="center" flex="1">
              {handleSubmit ? (
                <InputField required denseError name={fieldName} label={fieldLabel} variant="standard" />
              ) : (
                <ListItemText>{initialFieldValue}</ListItemText>
              )}
            </Box>
            <Box className={clsx(dense && classes.boxDense)}>
              <CancelIconButton
                disabled={!!loading}
                edge="end"
                aria-label="cancel"
                onClick={(e) => {
                  e.stopPropagation();
                  handleCancel();
                }}
              />
              {handleSubmit && (
                <SaveIconButton
                  disabled={!!loading}
                  loading={loading === 'save'}
                  type="submit"
                  edge="end"
                  aria-label={`save ${initialFieldValue}`}
                />
              )}
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

InlineEditFieldForm.propTypes = {
  fieldLabel: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  loading: PropTypes.oneOf(['save', 'delete', '']).isRequired,
  initialFieldValue: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};
