import React, { ReactElement, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, CircularProgress } from '@material-ui/core';
import { Field, FieldProps, useFormikContext } from 'formik';
import { Autocomplete } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { AutoCompleteFieldProps } from './types';
import { isString } from '../../../../../lib/utils/helpers';

import { useInputFieldStyles } from '../InputField/styles';

const useStyles = makeStyles(() =>
  createStyles({
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    spinner: (props: { multiple: boolean }) =>
      props.multiple
        ? {
            position: 'absolute',
            right: '65px',
          }
        : {},
  }),
);

// eslint-disable-next-line @typescript-eslint/ban-types
export const AutoCompleteField = <T extends {}>({
  name,
  label,
  loading,
  disabled = false,
  multiple = false,
  selectValueBy,
  options,
  handleFetchData = (): void => undefined,
  handleResetData = (): void => undefined,
  handleSelectHook = (): void => undefined,
  renderOption = undefined,
  getOptionDisabled = undefined,
  getOptionLabel = undefined,
  required,
  onBlur,
  onFocus,
}: AutoCompleteFieldProps<T>): ReactElement => {
  const classes = useStyles({ multiple });
  const inputFieldClasses = useInputFieldStyles();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (!open) {
      handleResetData();
    } else {
      handleFetchData();
    }
  }, [open, handleResetData, handleFetchData]);

  const handleSelectValue = (value: T | T[] | null): void => {
    if (!Array.isArray(value)) handleSelectHook(value);
    setFieldValue(name, value);
  };

  const handleOptionLabel = (option: T): string => {
    const val = selectValueBy ? option[selectValueBy] : option;

    if (isString(val, false)) {
      return val;
    }
    // eslint-disable-next-line no-console
    console.error({
      type: 'warning',
      code: 'SHAREDCOM_FORM_ELEMENTS_AUTOC_NO_FIELD_VALUE',
      message: 'autocomplete has been given an empty value',
      stack: JSON.stringify(val),
    });
    return '';
  };

  return (
    <Field name={name}>
      {({ meta, field }: FieldProps): ReactElement => {
        return (
          <Autocomplete
            id={name}
            open={open}
            loading={open && loading}
            disabled={disabled}
            multiple={multiple}
            options={options}
            value={field.value}
            fullWidth
            openOnFocus
            noOptionsText={t('forms.elements.autoCompleteField.noOptionsText')}
            loadingText={t('forms.elements.autoCompleteField.loadingText')}
            getOptionSelected={(option, value): boolean => {
              return selectValueBy ? option[selectValueBy] === value[selectValueBy] : option === value;
            }}
            getOptionDisabled={getOptionDisabled}
            getOptionLabel={getOptionLabel || handleOptionLabel}
            onOpen={(): void => {
              setOpen(true);
            }}
            onClose={(): void => {
              setOpen(false);
            }}
            renderOption={renderOption}
            onChange={(_, value): void => handleSelectValue(value)}
            renderInput={(params): ReactElement => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                label={label}
                className={inputFieldClasses.root}
                error={!!(meta.touched && meta.error)}
                helperText={(meta.touched && meta.error) || ''}
                required={required}
                onBlur={(e) => {
                  if (onBlur) {
                    onBlur(e);
                  }

                  field.onBlur(e);
                }}
                onFocus={onFocus || undefined}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {open && loading ? (
                        <CircularProgress className={classes.spinner} color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        );
      }}
    </Field>
  );
};

AutoCompleteField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
      }).isRequired,
    ),
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  selectValueBy: PropTypes.string,
  handleFetchData: PropTypes.func,
  handleResetData: PropTypes.func,
  handleSelectHook: PropTypes.func,
};

AutoCompleteField.defaultProps = {
  handleFetchData: (): void => undefined,
  handleResetData: (): void => undefined,
  handleSelectHook: (): void => undefined,
  disabled: false,
  multiple: false,
  required: false,
  selectValueBy: undefined,
};
