import { Dispatch } from 'redux';
import { thunk } from './thunks';
import { apiCreateRole, apiDeleteRole, apiGetRoleNames, apiGetRoles, apiUpdateRole } from './services';
import { addRole, deleteRole, replaceRole, setLoading, setRoleNames, setRoles, successRole } from './actions';
import { GetStateType } from '..';
import { enqueueSnackbar } from '../notifier/actions';
import { RoleType } from './types';
import { replaceRoleInCurrent } from '../account/actions';

export const attemptGetRoles = (): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('rolesGet'));
    const roles = await apiGetRoles();
    dispatch(setRoles(roles));
  });

export const attemptGetRoleNames = (): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('roleNameListGet'));
    const roleNames = await apiGetRoleNames();
    dispatch(setRoleNames(roleNames));
  });

export const attemptCreateRole = (
  body: Omit<RoleType, '_id'>,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('roleAdd'));
    const role = await apiCreateRole(body);
    dispatch(addRole(role));
    dispatch(successRole('roleAdd'));
    dispatch(
      enqueueSnackbar({
        translationString: 'module-core:pages.roleManagement.actions.roleAddSuccess',
        messageTranslationKeys: {},
        options: {
          variant: 'success',
        },
      }),
    );
  });

export const attemptUpdateRole = (
  _id: string,
  body: Partial<RoleType>,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch, getState: GetStateType) => {
    dispatch(setLoading(`roleEdit-${_id}`));

    const role = await apiUpdateRole(_id, body);
    dispatch(replaceRole(role));
    // replace role in current logged in account if same
    const { current } = getState().account;
    if (current && current.role && current.role._id === role._id) {
      dispatch(replaceRoleInCurrent(role));
    }

    dispatch(
      enqueueSnackbar({
        translationString: 'module-core:pages.roleManagement.actions.roleUpdateSuccess',
        messageTranslationKeys: {},
        options: {
          variant: 'success',
        },
      }),
    );
  });

export const attemptDeleteRole = (_id: string): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading(`roleDelete-${_id}`));
    await apiDeleteRole(_id);
    dispatch(deleteRole(_id));

    dispatch(
      enqueueSnackbar({
        translationString: 'module-core:pages.roleManagement.actions.roleDeleteSuccess',
        messageTranslationKeys: {},
        options: {
          variant: 'success',
        },
      }),
    );
  });
