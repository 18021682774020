import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Box } from '@material-ui/core';
import clsx from 'clsx';
import { BaseTemplate } from '../foundation/BaseTemplate';
import { AccessTemplateProps } from './types';
import { useAccessTemplateStyles } from './styles';

export const AccessTemplate = ({ header, infoBar, footer, toc, children }: AccessTemplateProps): ReactElement => {
  const classes = useAccessTemplateStyles();
  return (
    <BaseTemplate waveOrientation={{ bottom: true }} withPixel={{ bottom: true }}>
      <Grid className={clsx(classes.fluentHeight, classes.withDisclaimer)} container direction="column">
        <Grid xs={12} container direction="column" item>
          {infoBar && (
            <Grid container>
              <Grid xs={12} item>
                {infoBar}
              </Grid>
            </Grid>
          )}

          <Grid className={classes.stretch} justify="center" alignContent="flex-start" container>
            <Grid container direction="column" xs={12} sm={8} md={6} item spacing={2}>
              {header && <Grid item>{header}</Grid>}
              <Grid item>
                <Paper className={classes.paper} elevation={8}>
                  <Box p="2.5rem">{children}</Box>
                </Paper>
              </Grid>
              <Grid item>{footer && footer()}</Grid>
            </Grid>
          </Grid>
        </Grid>
        {toc ? toc() : null}
      </Grid>
    </BaseTemplate>
  );
};

AccessTemplate.propTypes = {
  header: PropTypes.element,
  infoBar: PropTypes.element,
  footer: PropTypes.func,
  toc: PropTypes.func,
};

AccessTemplate.defaultProps = {
  header: null,
  infoBar: null,
  footer: (): void => undefined,
  toc: null,
};
