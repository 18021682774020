import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    boxDense: {
      '& .MuiButtonBase-root:first-child': {
        marginRight: 0,
      },
      '& .MuiButtonBase-root:last-child': {
        marginRight: '-16px',
      },
    },
  }),
);
