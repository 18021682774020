/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { AlertTitle } from '@material-ui/lab';
import { removeSnackbar } from '../../lib/store/notifier/actions';
import { RootState } from '../../lib/store';
import { LanguageNameSpaces } from '../../lib/constants/languageNameSpaces';
import { NotificationType } from '../../lib/store/notifier/types';

let displayed = [] as any;

export const Notifier = (): null => {
  const reduxDispatch = useDispatch();
  const { t } = useTranslation(LanguageNameSpaces);
  const notifications = useSelector((state: RootState) => state.notification.notifications || []);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: number | string | undefined): void => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: number): void => {
    displayed = [...displayed.filter((key: number) => id !== key)];
  };

  React.useEffect(() => {
    notifications.forEach(
      ({
        message = undefined, // direct message translated via backend
        details = undefined,
        errors = undefined,
        translationString = '', // string for translation
        messageTranslationKeys = undefined,
        options = {},
        dismissed = false,
      }: NotificationType) => {
        if (dismissed) {
          // dismiss snackbar using notistack
          closeSnackbar(options.key);
          return;
        }

        // do nothing if snackbar is already displayed
        if (displayed.includes(options.key)) return;

        // display snackbar using notistack
        const snackbarMessage = errors ? (
          <Box>
            <AlertTitle>{t('init:shared.errors.errorBoxTitle')}</AlertTitle>
            {errors.map((error) => (
              <Typography key={error.message}>{error.message}</Typography>
            ))}
          </Box>
        ) : details ? (
          <Box>
            <AlertTitle>{t('init:shared.errors.errorBoxTitle')}</AlertTitle>
            {details.map((detail) => (
              <Typography key={detail.msg}>{detail.msg}</Typography>
            ))}
          </Box>
        ) : translationString ? (
          t(translationString, messageTranslationKeys || {})
        ) : (
          message
        );

        if (snackbarMessage) {
          enqueueSnackbar(snackbarMessage, {
            // persist: true,
            ...options,
            onClose: (event, reason, myKey) => {
              if (options.onClose) {
                options.onClose(event, reason, myKey);
              }
            },
            onExited: (_event: any, myKey: any) => {
              // remove this snackbar from redux store
              reduxDispatch(removeSnackbar(myKey));
              removeDisplayed(myKey);
            },
          });
          // keep track of snackbars that we've displayed
          storeDisplayed(options.key);
        } else {
          // eslint-disable-next-line no-console
          console.error({ type: 'error', code: 'SHCON_NOTIFIER_NO_MESSAGE', message: 'no snackbarMessage determined' });
        }
      },
    );
  }, [notifications, closeSnackbar, enqueueSnackbar, reduxDispatch, t]);

  return null;
};
