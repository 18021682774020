// Imported descending from bold -> light
import MontserratBlack from '../assets/fonts/montserrat-black-webfont.woff2';
import MontserratExtraBold from '../assets/fonts/montserrat-extrabold-webfont.woff2';
import MontserratBold from '../assets/fonts/montserrat-bold-webfont.woff2';
import MontserratSemiBold from '../assets/fonts/montserrat-semibold-webfont.woff2';
import MontserratMedium from '../assets/fonts/montserrat-medium-webfont.woff2';
import MontserratRegular from '../assets/fonts/montserrat-regular-webfont.woff2';
import MontserratLight from '../assets/fonts/montserrat-light-webfont.woff2';
import MontserratExtraLight from '../assets/fonts/montserrat-extralight-webfont.woff2';
import MontserratThin from '../assets/fonts/montserrat-thin-webfont.woff2';
// italic
import MontserratLightItalic from '../assets/fonts/montserrat-lightitalic-webfont.woff2';
import MontserratMediumItalic from '../assets/fonts/montserrat-mediumitalic-webfont.woff2';

export const montserratBlack = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontDisplay: 'swap' as const,
  fontWeight: 900,
  src: `
    local('Montserrat'),
    local('Montserrat-Black'),
    url(${MontserratBlack}) format('woff2')
  `,
};

export const montserratExtraBold = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontDisplay: 'swap' as const,
  fontWeight: 800,
  src: `
      local('Montserrat'),
      local('Montserrat-Extra-Bold'),
      url(${MontserratExtraBold}) format('woff2')
    `,
};

export const montserratBold = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontDisplay: 'swap' as const,
  fontWeight: 700,
  src: `
      local('Montserrat'),
      local('Montserrat-Bold'),
      url(${MontserratBold}) format('woff2')
    `,
};

export const montserratSemiBold = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontDisplay: 'swap' as const,
  fontWeight: 600,
  src: `
      local('Montserrat'),
      local('Montserrat-Semi-Bold'),
      url(${MontserratSemiBold}) format('woff2')
    `,
};

export const montserratMedium = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontDisplay: 'swap' as const,
  fontWeight: 500,
  src: `
      local('Montserrat'),
      local('Montserrat-Medium'),
      url(${MontserratMedium}) format('woff2')
    `,
};

export const montserratRegular = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontDisplay: 'swap' as const,
  fontWeight: 400,
  src: `
      local('Montserrat'),
      local('Montserrat-Regular'),
      url(${MontserratRegular}) format('woff2')
    `,
};

export const montserratLight = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontDisplay: 'swap' as const,
  fontWeight: 300,
  src: `
      local('Montserrat'),
      local('Montserrat-Light'),
      url(${MontserratLight}) format('woff2')
    `,
};

export const montserratExtraLight = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontDisplay: 'swap' as const,
  fontWeight: 200,
  src: `
      local('Montserrat'),
      local('Montserrat-Extra-Light'),
      url(${MontserratExtraLight}) format('woff2')
    `,
};

export const montserratThin = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontDisplay: 'swap' as const,
  fontWeight: 100,
  src: `
      local('Montserrat'),
      local('Montserrat-Thin'),
      url(${MontserratThin}) format('woff2')
    `,
};

export const montserratLightItalic = {
  fontFamily: 'Montserrat',
  fontStyle: 'italic',
  fontDisplay: 'swap' as const,
  fontWeight: 300,
  src: `
          local('Montserrat'),
          local('Montserrat-Light-Italic'),
          url(${MontserratLightItalic}) format('woff2')
        `,
};

export const montserratMediumItalic = {
  fontFamily: 'Montserrat',
  fontStyle: 'italic',
  fontDisplay: 'swap' as const,
  fontWeight: 300,
  src: `
        local('Montserrat'),
        local('Montserrat-Medium-Italic'),
        url(${MontserratMediumItalic}) format('woff2')
      `,
};
