import React, { Component, ErrorInfo } from 'react';
import ErrorReport from '../../pages/ErrorReport';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type MyProps = { children: any };
type MyState = { info: null | ErrorInfo; error: null | Error };

const isChunkLoadError = (error: Error | null, info: ErrorInfo) => {
  return !!(info && error && error.toString().indexOf('ChunkLoadError') > -1);
};

class ErrorBoundary extends Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = { info: null, error: null };
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    this.setState({ info, error });
    // log the error to our server with loglevel
    if (!isChunkLoadError(error, info)) {
      throw error;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render(): any {
    const { info, error } = this.state;
    // eslint-disable-next-line no-console
    // console.error('error: ', error);
    const { children } = this.props;

    if (info) {
      if (isChunkLoadError(error, info)) {
        return <ErrorReport showUpdate />;
      }
      // You can render any custom fallback UI
      return <ErrorReport />;
    }

    return children;
  }
}

export default ErrorBoundary;
