import { Dispatch } from 'redux';
import { thunk } from './thunks';
import {
  apiCreateLocation,
  apiUpdateLocation,
  apiDeleteLocation,
  apiGetLocations,
  apiCreateDepartment,
  apiUpdateDepartment,
  apiDeleteDepartment,
  apiCreateArea,
  apiUpdateArea,
  apiDeleteArea,
} from './services';
import {
  setAllLocations,
  replaceLocation,
  deleteLocation,
  addLocation,
  setLoading,
  addDepartment,
  replaceDepartment,
  deleteDepartment,
  addArea,
  replaceArea,
  deleteArea,
} from './actions';
import { GetStateType } from '../types';

import { UpdateLocationDataType } from './types';
import { enqueueSnackbar } from '../notifier/actions';

/**
 * Locations
 */
export const attemptGetLocations = (): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    const locations = await apiGetLocations();
    dispatch(setAllLocations(locations));
  });

export const attemptCreateLocation = (
  locationName: string,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('locationAdd'));
    const location = await apiCreateLocation({ locationName });

    dispatch(addLocation(location));
    dispatch(
      enqueueSnackbar({
        translationString: 'module-core:shared.locations.locationItem.actions.locationAddSuccess',
        messageTranslationKeys: { locationName: location.locationName },
        options: {
          variant: 'success',
        },
      }),
    );
  });

export const attemptUpdateLocation = (
  body: UpdateLocationDataType,
  locationId: string,
  oldLocationName: string,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('locationEdit'));
    const updatedLocation = await apiUpdateLocation(body, locationId);
    dispatch(replaceLocation(updatedLocation));
    dispatch(
      enqueueSnackbar({
        translationString: 'module-core:shared.locations.locationItem.actions.locationUpdateSuccess',
        messageTranslationKeys: { oldLocationName, locationName: updatedLocation.locationName },
        options: {
          variant: 'success',
        },
      }),
    );
  });

export const attemptDeleteLocation = (
  _id: string,
  locationName: string,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('locationDelete'));
    await apiDeleteLocation(_id);
    dispatch(deleteLocation(_id));
    dispatch(
      enqueueSnackbar({
        translationString: 'module-core:shared.locations.locationItem.actions.locationDeleteSuccess',
        messageTranslationKeys: { locationName },
        options: {
          variant: 'success',
        },
      }),
    );
  });

/**
 * Departments
 */
export const attemptCreateDepartment = (
  departmentName: string,
  locationId: string,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('departmentAdd'));
    const department = await apiCreateDepartment({ departmentName }, locationId);

    dispatch(addDepartment({ data: department, locationId }));
    dispatch(
      enqueueSnackbar({
        translationString: 'module-core:shared.locations.departmentItem.actions.departmentAddSuccess',
        messageTranslationKeys: { departmentName: department.departmentName },
        options: {
          variant: 'success',
        },
      }),
    );
  });

export const attemptUpdateDepartment = (
  body: { departmentName: string; _id: string },
  locationId: string,
  oldDepartmentName: string,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('departmentEdit'));
    const department = await apiUpdateDepartment(body, body._id);
    dispatch(replaceDepartment({ data: department, locationId }));
    dispatch(
      enqueueSnackbar({
        translationString: 'module-core:shared.locations.departmentItem.actions.departmentUpdateSuccess',
        messageTranslationKeys: { oldDepartmentName, departmentName: body.departmentName },
        options: {
          variant: 'success',
        },
      }),
    );
  });

export const attemptDeleteDepartment = (
  _id: string,
  locationId: string,
  departmentName: string,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('departmentDelete'));
    await apiDeleteDepartment(_id, locationId);
    dispatch(deleteDepartment(_id));
    dispatch(
      enqueueSnackbar({
        translationString: 'module-core:shared.locations.departmentItem.actions.departmentDeleteSuccess',
        messageTranslationKeys: { departmentName },
        options: {
          variant: 'success',
        },
      }),
    );
  });

/**
 * Areas
 */
export const attemptCreateArea = (
  areaName: string,
  departmentId: string,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('areaAdd'));
    const area = await apiCreateArea({ areaName }, departmentId);

    dispatch(addArea({ data: area, departmentId }));
    dispatch(
      enqueueSnackbar({
        translationString: 'module-core:shared.locations.areaItem.actions.areaAddSuccess',
        messageTranslationKeys: { areaName: area.areaName },
        options: {
          variant: 'success',
        },
      }),
    );
  });

export const attemptUpdateArea = (
  body: { areaName: string; _id: string },
  areaId: string,
  departmentId: string,
  previousAreaName: string,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('areaEdit'));
    const area = await apiUpdateArea(body, areaId);
    dispatch(replaceArea({ data: area, departmentId }));
    dispatch(
      enqueueSnackbar({
        translationString: 'module-core:shared.locations.areaItem.actions.areaUpdateSuccess',
        messageTranslationKeys: { previousAreaName, areaName: body.areaName },
        options: {
          variant: 'success',
        },
      }),
    );
  });

export const attemptDeleteArea = (
  _id: string,
  departmentId: string,
  areaName: string,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('areaDelete'));
    await apiDeleteArea(_id, departmentId);
    dispatch(deleteArea(_id));
    dispatch(
      enqueueSnackbar({
        translationString: 'module-core:shared.locations.areaItem.actions.areaDeleteSuccess',
        messageTranslationKeys: { areaName },
        options: {
          variant: 'success',
        },
      }),
    );
  });
