/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dispatch } from 'redux';
import { loadStripe } from '@stripe/stripe-js';

import { thunk, thunkInline } from './thunks';
import {
  apiCheckOrganisation,
  apiSendAdminRequest,
  apiUpdateOrganisation,
  apiBillingCreateCustomer,
  apiBillingUpdateCustomer,
  apiBillingCreateSubscription,
  apiBillingRetryInvoice,
  apiBillingCancelSubscription,
  apiGetOrganisationBilling,
} from './services';
import { GetStateType } from '..';
import {
  successOrganisation,
  erroredOrganisation,
  setOrganisationSlug,
  checkOrganisation,
  currentOrganisation,
  loadingOrganisation,
  setBillingReports,
} from './actions';
import { apiUploadFile, apiReplaceFile, apiDeleteFile } from '../file/services';
import { OrganisationRequestType, OrganisationType } from './types';
import { enqueueSnackbar } from '../notifier/actions';
import { isFileType } from '../../utils/typeguards';
import { BillingCustomerFormValues } from '../../../components/organisms/form/BillingCustomerForm';
import { PaymentMethod } from '../../../components/organisms/form/PaymentForm/types';
import { config } from '../../config';
import { handleSubscriptionResponse } from './utils';

export const attemptSendAdminRequest = (
  type: OrganisationRequestType,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) => {
  return thunk(async (dispatch: Dispatch) => {
    dispatch(loadingOrganisation('organisationAdminRequestGet'));
    await apiSendAdminRequest(type);
    dispatch(loadingOrganisation(''));
    dispatch(successOrganisation('organisationAdminRequestGet'));
  });
};

export const attemptCheckOrganisation = (
  workspace: string | null,
): ((dispatch: Dispatch, getState: GetStateType) => void) => {
  return thunk(async (dispatch: Dispatch) => {
    dispatch(loadingOrganisation('organisationCheck'));
    if (workspace) {
      const res = await apiCheckOrganisation(workspace);

      if (res.organisationSlug) {
        dispatch(successOrganisation('organisationCheck'));
        dispatch(setOrganisationSlug(res.organisationSlug));
        dispatch(currentOrganisation(res));
      } else {
        dispatch(erroredOrganisation({ translationString: 'init:pages.organisation.actions.slugNotFound' }));
      }
    }
    dispatch(checkOrganisation(true));
    dispatch(loadingOrganisation(''));
  });
};

export const attemptGetOrganisationBilling = (): ((dispatch: Dispatch, getState: GetStateType) => void) => {
  return thunk(async (dispatch: Dispatch, getState: GetStateType) => {
    const {
      organisation: { current },
    } = getState();
    if (current) {
      dispatch(loadingOrganisation('organisationBillingGet'));

      const { billing, reports } = await apiGetOrganisationBilling();

      dispatch(currentOrganisation({ ...current, billing }));
      dispatch(setBillingReports(reports));

      dispatch(loadingOrganisation(''));
    }
  });
};

export const attemptBillingCreateCustomer = (
  body: BillingCustomerFormValues,
): ((dispatch: Dispatch, getState: GetStateType) => void) => {
  return thunk(async (dispatch: Dispatch, getState: GetStateType) => {
    const {
      organisation: { current },
    } = getState();
    dispatch(loadingOrganisation('organisationBilling'));
    const res = await apiBillingCreateCustomer(body);

    if (res && current) {
      dispatch(currentOrganisation({ ...current, billing: res }));
      dispatch(successOrganisation('organisationBilling'));
    }

    dispatch(loadingOrganisation(''));
  });
};

export const attemptBillingCreateSubscription = (
  customerId: string,
  paymentMethod: PaymentMethod,
  paymentMethodId: string,
): ((dispatch: Dispatch, getState: GetStateType) => void) => {
  return thunkInline(async (dispatch: Dispatch, getState: GetStateType) => {
    const {
      organisation: { current },
    } = getState();
    dispatch(loadingOrganisation('organisationBilling'));

    if (current) {
      // initial attempt to create subscription
      const { billing, subscription, reports } = await apiBillingCreateSubscription({
        customerId,
        paymentMethod,
        paymentMethodId,
      });

      await handleSubscriptionResponse(
        subscription,
        billing,
        reports,
        paymentMethodId,
        paymentMethod,
        current,
        dispatch,
      );
    } else {
      dispatch(loadingOrganisation(''));
    }
  });
};

export const attemptBillingRetryInvoice = (
  customerId: string,
  paymentMethod: PaymentMethod,
  paymentMethodId: string,
): ((dispatch: Dispatch, getState: GetStateType) => void) => {
  return thunkInline(async (dispatch: Dispatch, getState: GetStateType) => {
    const {
      organisation: { current },
    } = getState();
    dispatch(loadingOrganisation('organisationBilling'));

    if (current) {
      // initial attempt to create subscription
      const { billing, subscription, reports } = await apiBillingRetryInvoice({
        customerId,
        paymentMethod,
        paymentMethodId,
      });

      await handleSubscriptionResponse(
        subscription,
        billing,
        reports,
        paymentMethodId,
        paymentMethod,
        current,
        dispatch,
      );
    } else {
      dispatch(loadingOrganisation(''));
    }
  });
};

export const attemptBillingUpdateCustomer = (
  body: BillingCustomerFormValues,
): ((dispatch: Dispatch, getState: GetStateType) => void) => {
  return thunk(async (dispatch: Dispatch, getState: GetStateType) => {
    const {
      organisation: { current },
    } = getState();
    dispatch(loadingOrganisation('organisationBilling'));
    const res = await apiBillingUpdateCustomer(body);

    if (res && current) {
      dispatch(currentOrganisation({ ...current, billing: res }));
      dispatch(successOrganisation('organisationBilling'));
    }

    dispatch(loadingOrganisation(''));
  });
};

export const attemptBillingCancelSubscription = (): ((dispatch: Dispatch, getState: GetStateType) => void) => {
  return thunk(async (dispatch: Dispatch, getState: GetStateType) => {
    const {
      organisation: { current, billingReports },
    } = getState();
    dispatch(loadingOrganisation('cancelSubscription'));
    const res = await apiBillingCancelSubscription();

    if (res && res.billing && current) {
      dispatch(currentOrganisation({ ...current, billing: res.billing }));
      dispatch(setBillingReports({ invoiceHistory: billingReports.invoiceHistory }));
      dispatch(successOrganisation('cancelSubscription'));
    }

    dispatch(loadingOrganisation(''));
  });
};

export const attemptUpdateOrganisation = (
  body: Partial<OrganisationType>,
  logo?: File | string,
): ((dispatch: Dispatch, getState: GetStateType) => void) => {
  return thunk(async (dispatch: Dispatch, getState: GetStateType) => {
    dispatch(loadingOrganisation('organisationUpdate'));
    const { current } = getState().organisation;
    let data = { ...body };

    // if current logo -> replace, delete or ignore
    if (current && isFileType(current.logo)) {
      const { _id: logoId } = current.logo;

      // if new logo -> replace
      if (logo instanceof Blob) {
        const { _id } = await apiReplaceFile(logoId, logo, { category: 'logo', fileName: logo.name });
        data = Object.assign(data, { logo: _id });
        // if logo is '' -> delete
      } else if (logo === '') {
        await apiDeleteFile(logoId);
        data = Object.assign(data, { logo: undefined });
      } else {
        data = Object.assign(data, { logo: logoId });
      }
      // else -> logo contains location to file -> ignore
    } else if (logo instanceof Blob) {
      // if logo is File and no current logo set => new logo
      const { _id } = await apiUploadFile(logo, { category: 'logo', fileName: logo.name });
      data = Object.assign(data, { logo: _id });
    }

    const organisation = await apiUpdateOrganisation(data);

    dispatch(currentOrganisation(organisation));
    dispatch(loadingOrganisation(''));
    dispatch(
      enqueueSnackbar({
        translationString: 'module-core:pages.systemSettings.actions.organisationUpdateSuccess',
        messageTranslationKeys: undefined,
        options: {
          variant: 'success',
        },
      }),
    );
  });
};
