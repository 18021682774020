export enum actionTypes {
  SET_ALL = 'SET_ALL',
  TOGGLE_MULTISELECT = 'TOGGLE_MULTISELECT',
  TOGGLE_AREA_SELECTABLE = 'TOGGLE_AREA_SELECTABLE',
  SELECT = 'SELECT',
  EDIT = 'EDIT',
  SELECT_DEPARTMENT = 'SELECT_DEPARTMENT',
  EDIT_DEPARTMENT = 'EDIT_DEPARTMENT',
  SELECT_AREA = 'SELECT_AREA',
  EDIT_AREA = 'EDIT_AREA',
  SET_INITIAL_SELECTED = 'SET_INITIAL_SELECTED',
}
