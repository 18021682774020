import React, { Suspense, ReactElement, useEffect } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga4';

import { createTheme } from './lib/theme';
import { store } from './lib/store';
import { Routes } from './_umo/routes';
import ErrorBoundary from './features/ErrorBoundry';
import { useAlertBoxStyles } from './lib/theme/themeParts/overrides/muiAlert';
import { getCurrentLanguage } from './lib/utils';
import i18n from './lib/i18n';
import ErrorReport from './pages/ErrorReport';

import 'iframe-resizer/js/iframeResizer.contentWindow.min';

// eslint-disable-next-line react/require-default-props
type Props = { mode?: 'embed' | string | null; environment: 'development' | 'sandbox' | 'production' | 'test' };

export const App = (props: Props): ReactElement => {
  const { mode, environment } = props;
  const classes = useAlertBoxStyles();
  const [lang, setLang] = React.useState(getCurrentLanguage());

  i18n.on('languageChanged', () => {
    const currentLanguage = getCurrentLanguage();
    document.documentElement.setAttribute('lang', currentLanguage);
    if (currentLanguage !== lang) setLang(currentLanguage);
  });

  const theme = createTheme(lang === 'de' ? 'deDE' : 'enUS');

  useEffect(() => {
    if (environment !== 'sandbox' && mode !== 'embed') {
      // TODO: receive cookie consent from website when embedded
      ReactGA.initialize([{ trackingId: 'G-7LVEZS1675' }]);
    }
  }, [environment, mode]);

  return (
    <React.StrictMode>
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider
              maxSnack={3}
              classes={{
                variantSuccess: classes.standardSuccess,
                variantError: classes.standardError,
                variantWarning: classes.standardWarning,
                variantInfo: classes.standardInfo,
              }}
            >
              <MuiPickersUtilsProvider utils={DayjsUtils}>
                <CssBaseline />
                <Sentry.ErrorBoundary fallback={<ErrorReport />}>
                  <ErrorBoundary>
                    <Suspense fallback={<div />}>
                      <Routes />
                    </Suspense>
                  </ErrorBoundary>
                </Sentry.ErrorBoundary>
              </MuiPickersUtilsProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </Provider>
    </React.StrictMode>
  );
};

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}
