import React, { ReactElement } from 'react';
import dayjs from 'dayjs';
import { Box, IconButton, Typography, Card, CardContent, CardActions, Chip, Tooltip } from '@material-ui/core';
import { InfoRounded, CloseRounded } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FileType, FileRenderStatus } from '../../../../lib/store/file/types';
import { DATE_FORMAT } from '../../../../lib/constants/dateFormat';
import { useFileCardStyles } from '../../styles';
import { DeleteIconButton, PrimaryButton } from '../../../../components/molecules/button';
import { attemptDeleteFile } from '../../../../lib/store/file/effects';

export type FileCardProps = {
  fileName: FileType['fileName'];
  originalName: FileType['fileName'];
  createdBy: FileType['createdBy'];
  createdAt: FileType['createdAt'];
  usedBy?: FileType['usedBy'];
  usedByHeader?: FileType['usedByHeader'];
  status?: FileRenderStatus;
  src: string;
  _id: string;
  handleSelect?: () => void;
  hasFileDeletePermission: boolean;
  currentGroup?: FileType['group'];
};

export const FileCard = (props: FileCardProps): ReactElement => {
  const {
    originalName,
    createdBy,
    createdAt,
    _id,
    src,
    status,
    handleSelect,
    usedBy,
    usedByHeader,
    hasFileDeletePermission,
    currentGroup,
  } = props;
  const [panel, setPanel] = React.useState<'' | 'info'>('');
  const classes = useFileCardStyles({ panel });
  const reduxDispatch = useDispatch();
  const { t } = useTranslation();

  const showOrderedList = usedByHeader && usedByHeader.length > 0 && usedBy && usedBy.length > 0;

  const handleDeleteFile = () => {
    reduxDispatch(attemptDeleteFile(_id));
  };

  return (
    <Card className={classes.card}>
      <div className={classes.cardMediaWrap}>
        <img
          className={classes.media}
          alt={`shows with name: ${originalName}`}
          src={`${src}?size=small`}
          title={originalName}
        />
      </div>

      <Box className={classes.infoPanel}>
        <Box p={1.5} pt={0} display="flex" flexDirection="column">
          <Box pt={2}>
            <Typography variant="h5">{t('module-core:shared.common.usedBy')}</Typography>
          </Box>
          {usedByHeader && usedByHeader.length > 0 && (
            <Box>
              <Box pb={1}>
                <Typography variant="subtitle1">
                  {showOrderedList ? '1. ' : ''}
                  {t('module-core:shared.common.header')}
                </Typography>
              </Box>
            </Box>
          )}
          {usedBy && usedBy.length > 0 && (
            <Box>
              <Box pb={1}>
                <Typography variant="subtitle1">
                  {showOrderedList ? '2. ' : ''}
                  {t('module-core:shared.common.instructions')}:
                </Typography>
              </Box>
              {usedBy.map((item) => {
                return (
                  <Box component="span" key={item._id} mr={1}>
                    <Chip label={item.name} variant="outlined" />
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>
      </Box>

      <CardContent>
        <Box>
          <Typography className={classes.ellipsis} title={originalName} variant="h5">
            {originalName}
          </Typography>
          <Box display="flex" flexWrap="nowrap" style={{ gap: '8px' }}>
            <Box display="flex" flexDirection="column" flex="1" maxWidth={currentGroup ? '50%' : '100%'}>
              <Box>
                <Typography variant="subtitle1">{t('module-core:shared.common.createdAt')} </Typography>
                <Typography
                  title={dayjs(createdAt).format(DATE_FORMAT)}
                  className={classes.ellipsis}
                  variant="subtitle2"
                >
                  {dayjs(createdAt).format(DATE_FORMAT)}
                </Typography>
              </Box>
              {createdBy?.firstName && createdBy?.lastName && (
                <Box mt={1}>
                  <Typography variant="subtitle1">{t('module-core:shared.common.createdBy')}</Typography>
                  <Typography
                    title={`${createdBy?.firstName} ${createdBy?.lastName}`}
                    className={classes.ellipsis}
                    variant="subtitle2"
                  >
                    {`${createdBy?.firstName} ${createdBy?.lastName}`}
                  </Typography>
                </Box>
              )}
            </Box>

            {currentGroup && (
              <Box flex="1" maxWidth="50%">
                <Typography variant="subtitle1">{t('module-core:shared.common.category')}</Typography>
                <Typography title={currentGroup} className={classes.ellipsis} variant="subtitle2">
                  {currentGroup}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </CardContent>

      <CardActions>
        {((usedBy && usedBy.length > 0) || (usedByHeader && usedByHeader.length > 0)) && (
          <IconButton
            className={classes.infoButton}
            size="small"
            color="primary"
            onClick={() => setPanel((prevPanel) => (prevPanel ? '' : 'info'))}
          >
            {!panel ? <InfoRounded /> : <CloseRounded />}
          </IconButton>
        )}

        {(hasFileDeletePermission && usedBy && usedBy.length > 0) || (usedByHeader && usedByHeader.length > 0) ? (
          <Tooltip title={t('this file cannot be deleted yet as its used by other items') || ''}>
            <div>
              <DeleteIconButton size="small" disabled loading={false} edge="end" onClick={handleDeleteFile} />
            </div>
          </Tooltip>
        ) : hasFileDeletePermission ? (
          <DeleteIconButton size="small" loading={false} edge="end" onClick={handleDeleteFile} />
        ) : (
          <div />
        )}
        {handleSelect && (
          <Box flex="1" display="flex" justifyContent="flex-end">
            <PrimaryButton variant="text" size="small" endIcon={null} onClick={handleSelect}>
              {t('module-core:shared.common.select')}
            </PrimaryButton>
          </Box>
        )}
      </CardActions>
      {status === 'injected' && <Box className={classes.successBar} />}
    </Card>
  );
};
