/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { ActionType } from './types';

/**
 * dispatch context
 */
export const InstructionViewerDispatchContext = React.createContext<React.Dispatch<ActionType>>(undefined as any);
export const InstructionViewerDispatchProvider = InstructionViewerDispatchContext.Provider;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useViewerDispatchContext = () => {
  const instructionViewerDispatch = React.useContext(InstructionViewerDispatchContext);
  if (!instructionViewerDispatch)
    // eslint-disable-next-line no-console
    console.error('Missuse of useInstructionViewerDispatchContext. No Context Provider detected');
  return instructionViewerDispatch;
};
