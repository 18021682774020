import { AvailablePermissions } from '../../../lib/constants/availablePermissions';
import { PermissionType } from '../../../lib/store/role/types';
import { hasAccountRequiredPermission } from '../../../lib/utils';

export const hasDashboardPermissions = (permissions: PermissionType[]): boolean => {
  return hasAccountRequiredPermission(permissions || [], [AvailablePermissions.UmoDashboardStatisticsFind]);
};

export const hasMyInstructionsPermissions = (permissions: PermissionType[]): boolean => {
  return hasAccountRequiredPermission(permissions || [], [
    AvailablePermissions.UmoInstructionexecutionListFind,
    AvailablePermissions.UmoInstructionexecutionExecute,
    AvailablePermissions.UmoInstructionexecutionFind,
  ]);
};

export const hasArchivePermissions = (permissions: PermissionType[]): boolean => {
  return hasAccountRequiredPermission(permissions || [], [
    AvailablePermissions.UmoInstructionarchiveListFind,
    AvailablePermissions.UmoInstructionarchiveExport,
    AvailablePermissions.UmoInstructionarchiveDelete,
  ]);
};

export const hasInstructionsOverviewPermissions = (permissions: PermissionType[]): boolean => {
  return hasAccountRequiredPermission(permissions || [], [AvailablePermissions.UmoInstructionadministrationListFind]);
};

export const hasInstructionsPreviewPermissions = (permissions: PermissionType[]): boolean => {
  return hasAccountRequiredPermission(permissions || [], [AvailablePermissions.UmoInstructionadministrationPreview]);
};

export const hasMyInstructionsViewPermissions = (permissions: PermissionType[]): boolean => {
  return hasAccountRequiredPermission(permissions || [], [AvailablePermissions.UmoInstructionexecutionExecute]);
};

export const hasInstructionsEditPermissions = (permissions: PermissionType[]): boolean => {
  return hasAccountRequiredPermission(permissions || [], [AvailablePermissions.UmoInstructionadministrationUpdate]);
};

export const hasInstructionsTranslatePermissions = (permissions: PermissionType[]): boolean => {
  return hasAccountRequiredPermission(permissions || [], [AvailablePermissions.UmoInstructionadministrationTranslate]);
};
export const hasInstructionsAssignPermissions = (permissions: PermissionType[]): boolean => {
  return hasAccountRequiredPermission(permissions || [], [
    AvailablePermissions.UmoInstructionassignmentCompanystructure,
    AvailablePermissions.UmoInstructionassignmentGroup,
    AvailablePermissions.UmoInstructionassignmentEmployeeType,
    AvailablePermissions.UmoInstructionassignmentUser,
    AvailablePermissions.UmoInstructionassignmentPublic,
  ]);
};

export const hasInstructionsCreatePermissions = (permissions: PermissionType[]): boolean => {
  return hasAccountRequiredPermission(permissions || [], [AvailablePermissions.UmoInstructionadministrationCreate]);
};

export const hasTermControlPermissions = (permissions: PermissionType[]): boolean => {
  return hasAccountRequiredPermission(permissions || [], [
    AvailablePermissions.UmoInstructioncontrolInstructionviewFind,
    AvailablePermissions.UmoInstructioncontrolEmployeeviewFind,
  ]);
};

export const hasSettingsPermissions = (permissions: PermissionType[]): boolean => {
  return hasAccountRequiredPermission(permissions || [], [
    AvailablePermissions.UmoInstructiontypesFind,
    AvailablePermissions.UmoInstructiontypesCreate,
    AvailablePermissions.UmoInstructiontypesUpdate,
    AvailablePermissions.UmoInstructiontypesDelete,
    AvailablePermissions.CoreFilesFind,
    AvailablePermissions.UmoInstructionadministrationCreate,
    AvailablePermissions.UmoInstructionadministrationUpdate,
  ]);
};
