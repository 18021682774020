/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { OrganisationType } from '../organisation/types';
import { ProblemType } from '../types';
import { fileConstants } from './constants';

export type FileCategory = 'logo' | 'images' | 'documents';

export interface FileType {
  _id: string;
  organisation: string | OrganisationType;
  fileName: string;
  originalName: string;
  proxyUrl: string;
  location: string;
  mimeType: string;
  category: FileCategory;
  size: number;
  createdBy: { _id: string; firstName?: string; lastName?: string };
  createdAt?: Date;
  group?: string;
  lastEditedBy?: string;
  lastEditedAt?: Date;
  usedBy?: {
    name: string;
    type: 'instruction';
    _id: string;
  }[];
  usedByHeader?: {
    type: 'header';
    _id: string;
  }[];
  thumbnail?: {
    location: string;
    proxyUrl: string;
    fileName: string;
    size: number;
  };
}

export type FileStatic = {
  fileName: string;
  location: string;
};

export type FileLoading = '' | 'fileGet' | 'fileDelete';
export type FileRenderStatus = 'injected' | 'load' | 'initial';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isFileLoadingState = (obj: any): obj is { status: 'load'; tempId: string; file: File } =>
  obj.status === 'load';

export type FileState = {
  fileList: (
    | { status: 'injected' | 'initial'; tempId?: never; file: FileType | FileStatic }
    | { status: 'load'; tempId: string; file: File }
  )[];
  problem: ProblemType | null;
  loading: FileLoading;
  success: FileLoading;
};

export type ActionType =
  | { type: fileConstants.FILE_LOADING; payload: FileLoading }
  | { type: fileConstants.FILE_ERRORED; payload: ProblemType | null }
  | { type: fileConstants.FILE_SUCCESS; payload: FileLoading }
  | { type: fileConstants.FILE_SET_ALL; payload: FileType[] | FileStatic[] }
  | { type: fileConstants.FILE_REMOVE; payload: { _id: string } }
  | { type: fileConstants.FILE_SET_ALL_UPLOAD; payload: { file: File; tempId: string }[] }
  | { type: fileConstants.FILE_ADD; payload: { file: FileType; position: 'asc' | 'desc'; tempId?: string } }
  | { type: fileConstants.FILE_INIT }
  | { type: 'dSimple/RESET_APP' };
