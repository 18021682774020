import { Dispatch } from 'redux';
import { thunk } from './thunks';
import { apiCreateGroup, apiDeleteGroup, apiGetGroups, apiUpdateGroup } from './services';
import { setLoading, addGroup, deleteGroup, replaceGroup, setAllGroup } from './actions';

import { UpdateGroup } from './types';
import { enqueueSnackbar } from '../notifier/actions';
import { GetStateType } from '../types';

export const attemptGetGroups = (): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    const group = await apiGetGroups();
    dispatch(setAllGroup(group));
  });

export const attemptCreateGroup = (
  groupName: string,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('groupAdd'));
    const group = await apiCreateGroup({ groupName });

    dispatch(addGroup(group));
    dispatch(
      enqueueSnackbar({
        translationString: 'module-core:shared.groups.actions.addSuccess',
        messageTranslationKeys: { groupName: group.groupName },
        options: {
          variant: 'success',
        },
      }),
    );
  });

export const attemptUpdateGroup = (
  body: UpdateGroup,
  oldGroupName: string,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('groupEdit'));
    const updatedGroup = await apiUpdateGroup(body);
    dispatch(replaceGroup(updatedGroup));
    dispatch(
      enqueueSnackbar({
        translationString: 'module-core:shared.groups.actions.updateSuccess',
        messageTranslationKeys: { oldGroupName, groupName: updatedGroup.groupName },
        options: {
          variant: 'success',
        },
      }),
    );
  });

export const attemptDeleteGroup = (
  _id: string,
  groupName: string,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('groupDelete'));
    await apiDeleteGroup(_id);
    dispatch(deleteGroup(_id));
    dispatch(
      enqueueSnackbar({
        translationString: 'module-core:shared.groups.actions.deleteSuccess',
        messageTranslationKeys: { groupName },
        options: {
          variant: 'success',
        },
      }),
    );
  });
