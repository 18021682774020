import React, { ReactElement, ReactNode } from 'react';

import { Box, Typography, List, ListItem, Divider, ListItemSecondaryAction } from '@material-ui/core';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@material-ui/lab';
import { CategoryPickerProps } from './types';
import { useCategoryPickerStyles } from './styles';

import { SingleFieldForm } from '../../form/SingleFieldForm';
import { EditIconButton } from '../../../molecules/button';
import { InlineEditFieldForm } from '../../form/InlineEditFieldForm';

export const CategoryPicker = (props: CategoryPickerProps): ReactElement => {
  const {
    onNodeSelect,
    selected,
    categories,
    handleCreateCategory,
    handleUpdateCategory,
    handleDeleteCategory,
    isLoading,
    setEditMode,
  } = props;
  const { t } = useTranslation();

  const classes = useCategoryPickerStyles();

  const items: ReactNode[] = [];
  let currentItems: ReactNode[] = [];
  let currentTitle = categories[0].title;

  categories.forEach((category, index) => {
    const { _id, name, title, value, divider, isCustomCategory, isInEditMode, isSkeleton } = category;
    const isActive = selected?.value === value;
    const end = index === categories.length - 1;

    // Check if the category title has changed, or if we're at the end of the loop
    if (title !== currentTitle) {
      // Push the accumulated items with the current title to the main items list
      items.push(
        <List
          subheader={
            currentTitle ? (
              <Typography variant="h5" className={classes.ellipsis}>
                {currentTitle}
              </Typography>
            ) : undefined
          }
          key={`${currentTitle}-root`}
        >
          {currentItems}
        </List>,
      );
      // Update current title and reset currentItems for the new group
      currentTitle = title;
      currentItems = [];
    }

    // Add the current item to currentItems
    currentItems.push(
      <>
        {divider && (
          <Box mt={2} mb={2}>
            <Divider />
          </Box>
        )}
        <div
          className={clsx(
            classes.container,
            isCustomCategory && classes.containerDisabledActiveButton,
            isInEditMode && classes.containerEditMode,
          )}
        >
          <ListItem
            button
            dense
            disableGutters
            className={clsx(classes.category, isActive && !isInEditMode && classes.activeListItem)}
            key={value}
            onClick={() => onNodeSelect(value, isCustomCategory || false)}
          >
            {isSkeleton ? (
              <Skeleton width={`${Math.floor(Math.random() * (100 - 25 + 1)) + 25}%`} />
            ) : isInEditMode ? (
              <InlineEditFieldForm
                dense
                fieldLabel=""
                fieldName="name"
                loading={isLoading === 'isEditing' ? 'save' : isLoading === 'isDeleting' ? 'delete' : ''}
                initialFieldValue={value}
                handleSubmit={
                  handleUpdateCategory
                    ? (v): void => {
                        handleUpdateCategory(_id || '', v, value);
                      }
                    : undefined
                }
                handleCancel={(): void => {
                  if (setEditMode) {
                    setEditMode('');
                  }
                }}
                handleDelete={
                  handleDeleteCategory
                    ? (): void => {
                        handleDeleteCategory(_id || '', name);
                      }
                    : undefined
                }
              />
            ) : (
              <>
                <Typography
                  variant="body2"
                  className={`${classes.listItemText} ${isActive ? classes.activeListItemText : ''} ${
                    classes.ellipsis
                  }`}
                >
                  {name}
                </Typography>
                {isCustomCategory && setEditMode && (
                  <ListItemSecondaryAction className={classes.action}>
                    <EditIconButton
                      className={classes.editIconButton}
                      edge="end"
                      size="small"
                      aria-label={`edit ${name}`}
                      onClick={(e): void => {
                        e.stopPropagation();
                        setEditMode(value);
                      }}
                    />
                  </ListItemSecondaryAction>
                )}
              </>
            )}
          </ListItem>
        </div>
      </>,
    );

    // If we're at the end, push the last group of items
    if (end) {
      items.push(
        <List
          subheader={
            title ? (
              <Typography className={classes.ellipsis} variant="h5">
                {title}
              </Typography>
            ) : undefined
          }
          key={`${title}-root`}
        >
          {currentItems}
        </List>,
      );
    }
  });

  return (
    <Box>
      {items}
      {handleCreateCategory && (
        <>
          <Box mt={2} mb={2}>
            <Divider />
          </Box>
          <SingleFieldForm
            disabled={false}
            loading={isLoading === 'isCreating'}
            fieldLabel={t('module-core:shared.common.newCategory')}
            fieldName="name"
            handleSubmit={handleCreateCategory}
            validateOnBlur={false}
            normalize={(value) => {
              const sanitizedValue = value.replace(/[^a-zA-Z0-9-_ äöüÄÖÜß]/g, '');

              return sanitizedValue;
            }}
          />
        </>
      )}
    </Box>
  );
};
