import { FileCategoryState, ActionType } from './types';
import { fileCategoryConstants } from './constants';

// INITIAL STATE
const initialState: FileCategoryState = {
  fileCategories: [],
  problem: null,
  loading: '',
  success: '',
};

/**
 * Reducer
 * Updated den STORE je nach ACTION type - Mitarbeiter der Auftrag ausfuehrt
 */
export const fileCategoryReducer = (state = initialState, action: ActionType): FileCategoryState => {
  switch (action.type) {
    case fileCategoryConstants.FILE_CATEGORY_SET_ALL:
      return {
        ...state,
        fileCategories: action.payload,
      };

    case fileCategoryConstants.FILE_CATEGORY_LOADING:
      return {
        ...state,
        loading: action.payload,
        problem: action.payload ? null : state.problem,
        success: action.payload ? '' : state.success,
      };

    case fileCategoryConstants.FILE_CATEGORY_ERRORED:
      return {
        ...state,
        problem: action.payload,
      };

    case fileCategoryConstants.FILE_CATEGORY_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };

    case fileCategoryConstants.FILE_CATEGORY_ADD:
      return {
        ...state,
        fileCategories: [...state.fileCategories, action.payload],
      };

    case fileCategoryConstants.FILE_CATEGORY_REPLACE:
      return {
        ...state,
        fileCategories: state.fileCategories.map((fileCategory) =>
          fileCategory._id === action.payload._id ? action.payload : fileCategory,
        ),
      };

    case fileCategoryConstants.FILE_CATEGORY_DELETE:
      return {
        ...state,
        fileCategories: state.fileCategories.filter((fileCategory) => fileCategory._id !== action.payload),
      };

    case fileCategoryConstants.FILE_CATEGORY_INIT:
      return {
        ...initialState,
      };

    case 'dSimple/RESET_APP':
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
