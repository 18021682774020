import { ActionType, OrganisationLoading, OrganisationType, OrganisationBillingReports } from './types';
import { ProblemType } from '..';
/**
 * Actions
 * Beschreibt was wir tun wollen - Auftrag oder Befehl
 */
export const loadingOrganisation = (payload: OrganisationLoading = ''): ActionType => ({
  type: 'dSimple/organisation/LOADING',
  payload,
});

export const erroredOrganisation = (payload: ProblemType | null = null): ActionType => ({
  type: 'dSimple/organisation/ERRORED',
  payload,
});

export const setOrganisationSlug = (payload: string | null = null): ActionType => ({
  type: 'dSimple/organisation/SET_SLUG',
  payload,
});

export const successOrganisation = (payload: OrganisationLoading = ''): ActionType => ({
  type: 'dSimple/organisation/SUCCESS',
  payload,
});

export const checkOrganisation = (payload = false): ActionType => ({
  type: 'dSimple/organisation/CHECK',
  payload,
});

export const currentOrganisation = (payload: OrganisationType | null = null): ActionType => ({
  type: 'dSimple/organisation/CURRENT',
  payload,
});

export const setBillingReports = (payload: OrganisationBillingReports = {}): ActionType => ({
  type: 'dSimple/organisation/SET_BILLING_REPORTS',
  payload,
});
