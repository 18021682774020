import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ImageStyleProps } from './types';

export const useImageStyles = makeStyles(() =>
  createStyles({
    root: {
      width: ({ width }: ImageStyleProps): string => width || 'initial',
      height: ({ height }: ImageStyleProps): string => height || 'initial',
      maxWidth: ({ maxWidth }: ImageStyleProps): string => maxWidth || 'initial',
      maxHeight: ({ maxHeight }: ImageStyleProps): string => maxHeight || 'initial',
    },
  }),
);
