import React, { ReactElement } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { MainLogoSpinner } from '../../components/molecules/spinner/MainLogoSpinner';
import { CenteredContentTemplate } from '../../components/templates';

const useStyles = makeStyles(() =>
  createStyles({
    weight500: {
      fontWeight: 500,
      visibility: 'hidden',
      fontSize: 0,
    },
    weight600: {
      fontWeight: 600,
      visibility: 'hidden',
      fontSize: 0,
    },
  }),
);

export const Splash = (): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <CenteredContentTemplate>
      <>
        <Grid item>
          <MainLogoSpinner loadingText={t('init:pages.splash.loadingWorkspace')} />
        </Grid>
        <Typography className={classes.weight500}>FontLoader</Typography>
        <Typography className={classes.weight600}>FontLoader</Typography>
      </>
    </CenteredContentTemplate>
  );
};

export default Splash;
