import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box } from '@material-ui/core';
import { WaveProps } from './types';
import { useWaveStyles } from './styles';
import { WaveImage } from '../../../atoms/images/WaveImage';
import { PixelImage } from '../../../atoms/images/PixelImage';

export const Wave = ({ orientation, withPixel }: WaveProps): ReactElement => {
  const classes = useWaveStyles();
  return (
    <Box
      className={clsx(
        classes.waveWrap,
        orientation === 'right' && classes.orientationRight,
        orientation === 'bottom' && classes.orientationBottom,
        orientation === 'top' && classes.orientationTop,
      )}
    >
      <WaveImage orientation={orientation} />
      {withPixel && <PixelImage />}
    </Box>
  );
};

Wave.propTypes = {
  orientation: PropTypes.string,
  withPixel: PropTypes.bool.isRequired,
};

Wave.defaultProps = {
  orientation: 'right',
};
