import React from 'react';
import { Box, Paper } from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { MainLogo } from '../../../atoms/images/MainLogo';
import { PrimaryButton } from '../../button';
import { AlertBoxWithButtonProps } from './types';
import { RouterLink } from '../../link/RouterLink';

export const AlertBoxWithButton = (props: AlertBoxWithButtonProps): React.ReactElement => {
  const { children, buttonText, buttonLink, handleButtonClick } = props;
  return (
    <Paper elevation={8}>
      <Box p="1.5rem">
        <MainLogo />
        <hr />
        <Box mt={5}>
          <Alert severity="error">{children}</Alert>
        </Box>
        <Box mt={6} display="flex" justifyContent="flex-end">
          {handleButtonClick ? (
            <PrimaryButton endIcon={<KeyboardArrowRight />} onClick={handleButtonClick}>
              {buttonText}
            </PrimaryButton>
          ) : (
            <PrimaryButton endIcon={<KeyboardArrowRight />} to={buttonLink || '/'} component={RouterLink}>
              {buttonText}
            </PrimaryButton>
          )}
        </Box>
      </Box>
    </Paper>
  );
};
