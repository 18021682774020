import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { useImageStyles } from './styles';
import { ImageProps } from './types';

export const Image = (props: ImageProps): ReactElement => {
  const { src, alt, className, ...styleProps } = props;
  const classes = useImageStyles(styleProps);

  return <img src={src} alt={alt} className={clsx(classes.root, className)} />;
};
