import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useUpgradeTextStyles } from './styles';

type UpgradeTextProps = {
  handleClick: (e: React.KeyboardEvent | React.MouseEvent) => void;
};

export const UpgradeText = ({ handleClick }: UpgradeTextProps): ReactElement => {
  const classes = useUpgradeTextStyles();
  const { t } = useTranslation();

  return (
    <>
      <span tabIndex={0} onKeyDown={handleClick} onClick={handleClick} role="button" className={clsx(classes.text)}>
        {t('common:upgradeDialog.Upgrade')}
      </span>
    </>
  );
};
