import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../constants/dateFormat';
import { CreateUserType, UserType } from '../../store/user/types';
import { UserFormValues } from './types';

export const mapToInitialValues = (values: UserType): UserFormValues => {
  return {
    firstName: values.firstName || '',
    lastName: values.lastName || '',
    gender: values.gender || '',
    email: values.email || '',
    password: '',
    personnelNumber: values.personnelNumber || '',
    rfidNumber: values.rfidNumber || '',
    role: values.role || null,
    dateOfBirth: values.dateOfBirth ? dayjs(values.dateOfBirth).utc().format(DATE_FORMAT).toString() : null,
    groups: values.groups || [],
    employeeTypeName: values.employeeTypeName || '',
    location: values.location || null,
    department: values.department || null,
    area: values.area || null,
  };
};

export const transformToUpdateUserType = (values: UserFormValues): Partial<CreateUserType> => {
  return {
    firstName: values.firstName,
    lastName: values.lastName,
    gender: values.gender,
    email: values.email,
    ...(values.password && { password: values.password, isStartPassword: true }), // is only being set new
    personnelNumber: values.personnelNumber,
    rfidNumber: values.rfidNumber,
    ...(values.role && { role: values.role._id }),
    dateOfBirth: values.dateOfBirth ? dayjs.utc(values.dateOfBirth, DATE_FORMAT).toDate() : undefined,
    groups: values.groups,
    employeeTypeName: values.employeeTypeName,
    location: (values && values.location && values.location._id) || undefined,
    department: (values && values.department && values.department._id) || undefined,
    area: (values && values.area && values.area._id) || undefined,
  };
};

export const transformToCreateUserType = (values: UserFormValues): CreateUserType => {
  return {
    ...(values.firstName && { firstName: values.firstName }),
    ...(values.lastName && { lastName: values.lastName }),
    ...(values.gender && { gender: values.gender }),
    ...{ email: values.email || '' },
    ...(values.password && { password: values.password, isStartPassword: true }),
    ...(values.personnelNumber && { personnelNumber: values.personnelNumber }),
    ...(values.rfidNumber && { rfidNumber: values.rfidNumber }),
    ...(values.role ? { role: values.role._id } : { role: '' }),
    ...(values.dateOfBirth && {
      dateOfBirth: dayjs.utc(values.dateOfBirth, DATE_FORMAT).toDate(),
    }),
    ...(values.groups && { groups: values.groups }),
    ...(values.employeeTypeName && { employeeTypeName: values.employeeTypeName }),
    ...(values.location && { location: values.location._id }),
    ...(values.department && { department: values.department._id }),
    ...(values.area && { area: values.area._id }),
  };
};
