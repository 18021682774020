import React, { ReactElement, useEffect, useState } from 'react';

import { Box, Card, CardContent, LinearProgress, Typography, CardActions, IconButton } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Skeleton } from '@material-ui/lab';
import { useFileCardStyles } from '../../styles';
import { processFile } from '../../../../lib/utils/processFile';
import { attemptUploadFile } from '../../../../lib/store/file/effects';
import { PrimaryButton } from '../../../../components/molecules/button';

export type FileUploadCardProps = {
  fileUpload: File;
  tempId: string;
  currentGroup?: string;
};

export const FileUploadCard = (props: FileUploadCardProps): ReactElement => {
  const { fileUpload, tempId, currentGroup } = props;
  const [preview, setPreview] = useState('');
  const reduxDispatch = useDispatch();
  const classes = useFileCardStyles({});

  useEffect(() => {
    let mounted = true;

    (async () => {
      const { file, dataUrl } = await processFile(fileUpload, fileUpload.type !== 'image/gif');
      if (file && dataUrl && mounted) {
        setPreview(dataUrl);
        reduxDispatch(attemptUploadFile(file, 'desc', tempId, currentGroup));
      }
    })();

    // eslint-disable-next-line no-return-assign
    return () => {
      mounted = false;
    };
  }, [fileUpload, tempId]);

  return (
    <Card>
      <Box className={classes.cardMediaWrap}>
        {preview && <img className={classes.media} alt={`shows with name: ${fileUpload.name}`} src={preview} />}
      </Box>
      <CardContent>
        <Box>
          <Typography className={classes.ellipsis} variant="h5">
            <Skeleton />
          </Typography>
          <Box>
            <Typography variant="subtitle1">
              <Skeleton width="60%" />
            </Typography>
            <Typography variant="subtitle2">
              <Skeleton width="60%" />
            </Typography>
          </Box>
          <Box mt={1}>
            <Typography variant="subtitle1">
              <Skeleton width="60%" />
            </Typography>
            <Typography variant="subtitle2">
              <Skeleton width="60%" />
            </Typography>
          </Box>
        </Box>
      </CardContent>

      <CardActions>
        <IconButton size="small" color="primary">
          <Skeleton width="50px" />
        </IconButton>
        <IconButton size="small" color="primary">
          <Skeleton width="50px" />
        </IconButton>
        <Box flex="1" display="flex" justifyContent="flex-end">
          <PrimaryButton variant="text" size="small" endIcon={null}>
            <Skeleton width="100px" />
          </PrimaryButton>
        </Box>
      </CardActions>

      <Box>
        <LinearProgress />
      </Box>
    </Card>
  );
};
