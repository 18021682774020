import { Dispatch } from 'redux';
import { thunk } from './thunks';
import { setFileList, setLoading, addFile, removeFile } from './actions';
import { GetStateType } from '../types';
import { apiFindFiles, apiUploadFile, apiDeleteFile } from './services';
import { QueryObject } from '../../httpClient';

export const attemptGetFiles = (query?: QueryObject): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('fileGet'));

    const data = await apiFindFiles(query);
    dispatch(setFileList(data));
    dispatch(setLoading(''));
  });

export const attemptUploadFile = (
  file: File,
  position: 'asc' | 'desc',
  tempId?: string,
  currentGroup?: string,
): ((dispatch: Dispatch, getState: GetStateType) => void) => {
  return thunk(async (dispatch: Dispatch) => {
    const data = await apiUploadFile(file, { category: 'images', fileName: file.name, group: currentGroup });
    dispatch(addFile({ file: data, position, tempId }));
  });
};

export const attemptDeleteFile = (_id: string): ((dispatch: Dispatch, getState: GetStateType) => void) => {
  return thunk(async (dispatch: Dispatch) => {
    dispatch(removeFile({ _id }));
    await apiDeleteFile(_id);
  });
};
