import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { SearchRounded } from '@material-ui/icons';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { PlaceholderBoxProps } from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      backgroundColor: theme.palette.grey[100],
      flex: '1',
      height: '100%',
      width: '100%',
      justifyContent: 'center',
    },
    icon: {
      fontSize: '5.5rem',
      color: theme.palette.primary.main,
    },
    content: {
      maxWidth: '75%',
      margin: '0 auto',
    },
  }),
);

export const PlaceholderBox = ({ helpText, icon }: PlaceholderBoxProps): ReactElement => {
  const classes = useStyles();
  return (
    <Box p={5} display="flex" flexDirection="column" className={classes.wrapper}>
      <Box className={classes.content} textAlign="center">
        {icon ? React.cloneElement(icon, { className: classes.icon }) : <SearchRounded className={classes.icon} />}
      </Box>
      <Box className={classes.content} textAlign="center">
        {helpText}
      </Box>
    </Box>
  );
};

PlaceholderBox.propTypes = {
  helpText: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.elementType, PropTypes.string]).isRequired,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.elementType]),
};

PlaceholderBox.defaultProps = {
  icon: null,
};
