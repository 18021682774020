export const productionEnvironment = {
  environment: 'production',
  spaUrl: 'https://app.d-simple.com',
  spaRootUrl: 'https://d-simple.com',
  endpoint: 'https://api.d-simple.com/api',
  mockEndpoint: '',
  STRIPE_PUBLISH_KEY:
    'pk_live_51JWnU7In4pb3jcxGUegQQhN9SuChGyDrs8gxeJKb6f6uP5i9SqJbUQKUyJr105vHVEzV070KxBdZ1Tbcjy0JYuMU00cqSDXT9Z',
  posthog: 'phc_tMAYaRKZ1hU1rTAMbSyDuPFMnweGcymD9ARzC4xkUio',
};
