import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .ql-snow .ql-picker.ql-header': {
        width: '110px',
      },
      '& .ql-snow .ql-stroke': {
        color: theme.palette.text.secondary,
        stroke: theme.palette.text.secondary,
      },
      '& .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, & .ql-active, & .ql-active .ql-stroke, &  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, & .ql-snow.ql-toolbar button:hover .ql-stroke, & .ql-snow.ql-toolbar button.ql-active .ql-stroke': {
        color: theme.palette.text.primary,
        stroke: theme.palette.text.primary,
      },
    },
  }),
);
