import React from 'react';
import clsx from 'clsx';

import PropTypes from 'prop-types';
import { Image } from '../Image';
import PixelImageSource from '../../../../lib/assets/images/pixel.png';
import { PixelImageProps } from './types';
import { usePixelImageStyles } from './styles';

export const PixelImage = (props: PixelImageProps): React.ReactElement => {
  const { small, flip } = props;
  const classes = usePixelImageStyles();
  return (
    <Image
      className={clsx(classes.root, small && classes.small, flip && classes.flip)}
      alt="shows several pixel for branding"
      src={PixelImageSource}
    />
  );
};

PixelImage.propTypes = {
  small: PropTypes.bool,
  flip: PropTypes.bool,
};

PixelImage.defaultProps = {
  small: false,
  flip: false,
};
