import { SECONDARY_MAIN_COLOR, PRIMARY_TEXT_COLOR } from '../../../constants';

export const muiMenuItem = {
  root: {
    fontSize: '1.1rem',
    fontWeight: 600,
    padding: 0,
    '& > *': {
      paddingTop: '18px',
      paddingBottom: '18px',
      paddingLeft: '35px',
      paddingRight: '35px',
    },
    '&:hover:before, &.Mui-focusVisible:before': {
      content: '""',
      top: '0',
      width: '5px',
      background: SECONDARY_MAIN_COLOR,
      position: 'absolute',
      bottom: '0',
      left: '0',
    },
    '& a': {
      color: PRIMARY_TEXT_COLOR,
      textDecoration: 'none',
    },
    '&.active:after': {
      content: '""',
      width: '10px',
      height: '10px',
      background: SECONDARY_MAIN_COLOR,
      position: 'absolute',
      borderRadius: '100%',
      top: '50%',
      transform: 'translateY(-50%)',
      right: '10px',
    },
  },
};
