import React, { ReactElement } from 'react';
import { AppBar, Box, Toolbar, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';

import { useSelector } from 'react-redux';
import { NavLogo } from '../../components/atoms/images/NavLogo';
import { CompanyMenuItem } from '../../components/organisms/menu/CompanyMenuItem';
import { ProfileMenuItem } from '../../components/organisms/menu/ProfileMenuItem';
// import { ModulesMenuItem } from '../../components/organisms/menu/ModulesMenuItem';
import { NavBarProps } from './types';
import { RootState } from '../../lib/store';
import { useCheckHistory } from '../../lib/hooks/useCheckHistory';
import {
  hasCompanyStructurePermissions,
  hasUserManagementPermissions,
  hasRoleManagementPermissions,
  hasSystemSettingsPermissions,
  hasBillingPermissions,
} from '../../lib/utils/permissionChecker';
import { MobileMenu } from '../MobileMenu';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    toolbar: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
      },
    },
    itemWrap: {
      '& .MuiBox-root:last-child': {
        marginRight: '0px',
      },
      '& .MuiButton-textPrimary': {
        fontWeight: 800,
        fontSize: '1.1rem',
      },
    },
    logoWrap: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  }),
);

export const NavBar = ({ renderTopBar }: NavBarProps): ReactElement => {
  const classes = useStyles();
  const { current: account, homeUrl } = useSelector((state: RootState) => state.account);
  const permissions = (account && account.role.permissions) || [];
  const history = useCheckHistory();
  const theme = useTheme();
  const isLowViewPort = useMediaQuery(theme.breakpoints.down('xs'));

  const shouldRenderCompanyMenuItem =
    hasCompanyStructurePermissions(permissions) ||
    hasUserManagementPermissions(permissions) ||
    hasRoleManagementPermissions(permissions) ||
    hasSystemSettingsPermissions(permissions) ||
    hasBillingPermissions(permissions);

  const handleClickLogo = () => {
    history.push(homeUrl);
  };

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        {typeof renderTopBar === 'function' && renderTopBar()}
        <Toolbar className={classes.toolbar}>
          <Box
            className={classes.logoWrap}
            onClick={handleClickLogo}
            alignItems="center"
            justifyContent="center"
            display="flex"
            flex="0"
          >
            <NavLogo />
          </Box>
          <Box
            className={classes.itemWrap}
            display="inline-flex"
            alignSelf="flex-end"
            alignItems="flex-end"
            justifyContent="flex-end"
            flex="1"
          >
            {isLowViewPort && shouldRenderCompanyMenuItem ? (
              <>
                {/* Only render mobile mode if companymenu is rendered as well. Otherwise there is enough space to display profile */}
                <MobileMenu permissions={permissions} />
              </>
            ) : (
              <>
                <CompanyMenuItem permissions={permissions} />
                {/* <ModulesMenuItem permissions={(account && account.role.permissions) || []} /> */}
                <ProfileMenuItem />
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};
