import React, { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { AutoCompleteField } from '../../../../molecules/form/elements';
import { setLoading as setLoadingGroup } from '../../../../../lib/store/group/actions';
import { attemptGetGroups } from '../../../../../lib/store/group/effects';
import { RootState } from '../../../../../lib/store';
import { GroupFieldProps } from './types';

export const GroupField = (props: GroupFieldProps): ReactElement => {
  const { name, preventFetching, showLoadingOn, multiple = false, disabled } = props;
  const { t } = useTranslation(['module-core']);
  const reduxDispatch = useDispatch();
  const { loading, groups } = useSelector((state: RootState) => state.group);

  const handleFetchGroups = useCallback(() => {
    reduxDispatch(setLoadingGroup(showLoadingOn || 'groupAutoCompleteGet'));
    reduxDispatch(attemptGetGroups());
  }, [reduxDispatch, showLoadingOn]);

  const handleResetGroups = useCallback(() => {
    reduxDispatch(setLoadingGroup());
  }, [reduxDispatch]);

  return (
    <AutoCompleteField<string>
      multiple={multiple}
      disabled={disabled}
      name={name || 'groups'}
      label={t('module-core:pages.userManagement.forms.labels.group')}
      loading={loading === (showLoadingOn || 'groupAutoCompleteGet')}
      options={groups.map((group) => group.groupName)}
      handleFetchData={preventFetching ? undefined : handleFetchGroups}
      handleResetData={preventFetching ? undefined : handleResetGroups}
    />
  );
};

GroupField.propTypes = {
  name: PropTypes.string,
  preventFetching: PropTypes.bool,
  showLoadingOn: PropTypes.string,
  multiple: PropTypes.bool,
};
GroupField.defaultProps = {
  name: '',
  preventFetching: false,
  showLoadingOn: '',
  multiple: false,
};
