import { fileConstants } from './constants';
import { FileState, ActionType, isFileLoadingState } from './types';
import { isFileType } from '../../utils/typeguards';

// INITIAL STATE
const initialState: FileState = {
  fileList: [],
  problem: null,
  loading: '',
  success: '',
};

/**
 * Reducer
 * Updated den STORE je nach ACTION type - Mitarbeiter der Auftrag ausfuehrt
 */
export const fileReducer = (state = initialState, action: ActionType): FileState => {
  switch (action.type) {
    case fileConstants.FILE_LOADING:
      return {
        ...state,
        loading: action.payload,
        problem: action.payload ? null : state.problem,
        success: action.payload ? '' : state.success,
      };

    case fileConstants.FILE_ERRORED:
      return {
        ...state,
        problem: action.payload,
      };

    case fileConstants.FILE_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };

    case fileConstants.FILE_SET_ALL:
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        fileList: (action.payload as any[]).map((file) => ({ file, status: 'initial' })),
      };

    case fileConstants.FILE_SET_ALL_UPLOAD:
      return {
        ...state,
        fileList: [...action.payload.map((data) => ({ ...data, status: 'load' as const })), ...state.fileList],
      };
    case fileConstants.FILE_REMOVE:
      return {
        ...state,
        fileList: state.fileList.filter(
          (data) =>
            !isFileLoadingState(data) && isFileType(data.file) && data.file._id && data.file._id !== action.payload._id,
        ),
      };

    case fileConstants.FILE_ADD:
      return {
        ...state,
        fileList: state.fileList.map((data) =>
          isFileLoadingState(data) && data.tempId === action.payload.tempId
            ? { file: action.payload.file, tempId: undefined, status: 'injected' as const }
            : data,
        ),
      };
    case fileConstants.FILE_INIT:
      return {
        ...initialState,
      };

    case 'dSimple/RESET_APP':
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
