import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useInstructionViewerStyles = makeStyles((theme: Theme) =>
  createStyles({
    embedded: {
      padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    },
    simpleNav: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'space-between',
      },
    },
    overflowHidden: {
      overflow: 'hidden',
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        overflow: 'visible',
      },
    },
    fHeight: {
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        height: 'auto',
      },
    },
    scroll: {
      height: 'calc(100% - 110px)', // subtract info box
      overflowY: 'auto',
    },
    contentWrap: {
      flex: '1',
      maxWidth: '100%',
      overflowX: 'auto',
    },
    infoBoxWrap: {
      width: '100%',
      marginTop: theme.spacing(3),
      maxWidth: '100%',
    },
    infoBox: {
      borderTop: `4px solid ${theme.palette.primary.main}`,
    },
    captureContainer: {
      fontSize: '16px',
      backgroundColor: '#fff',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
    },
    infoBoxInner: {
      flexDirection: 'column',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    contentInformation: {
      [theme.breakpoints.up('xs')]: {
        '& > div': {
          flex: '1 0 100%',
        },
      },
    },
    contentChildren: {
      marginTop: ({ isWizardMode }: { isWizardMode?: boolean }) => (isWizardMode ? '0px' : '25px'),
      marginLeft: '0',
      '& > div': {
        width: '100%',
      },
      [theme.breakpoints.up('sm')]: {
        marginLeft: '25px',
      },
    },
  }),
);
