/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import { httpRequest } from '../../../lib/httpClient';
import { config } from '../../../lib/config';

function isImageHidden(imageElement: HTMLImageElement) {
  const style = getComputedStyle(imageElement);

  // Check if width is greater than 0
  const isWidthZero = parseInt(style.width, 10) === 0;

  // Check if height is greater than 0
  const isHeightZero = parseInt(style.height, 10) === 0;

  // Check if display property is "none"
  const isDisplayNone = style.display === 'none';

  // Combine the conditions
  const isHidden = isWidthZero || isHeightZero || isDisplayNone;

  return isHidden;
}

/**
 * Tracks whether all images with selector have been fully loaded
 * Returns array of outstanding ids
 */
export const useImageCallIds = (
  condition: unknown,
  selector: string,
  token?: string,
): { imageCallIds: (string | undefined)[]; autoRelease: boolean } => {
  const [imageCallIds, setImageCallIds] = useState<(string | undefined)[]>([]);
  const [autoRelease, setAutoRelease] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let timer: any = null;

  // per loaded image, remove id from array
  const onImageLoaded = (event: (Event & { target: (EventTarget & { id?: string }) | null }) | string) => {
    const id = typeof event === 'string' ? event : event?.target?.id;
    setImageCallIds((prevIds) => {
      return prevIds.filter((prevId) => prevId !== id);
    });
  };

  // per failed image, attempt to retry with auth token
  const handleInterceptCalls = (elements: NodeListOf<HTMLImageElement>, viewToken: string | undefined) => {
    elements.forEach((element) => {
      const originalSource = element.src;

      const isValidEndpoint = originalSource.includes(config.endpoint);

      if (isValidEndpoint) {
        // overwrite src to not show the broken image icon
        // eslint-disable-next-line no-param-reassign
        element.src = '';

        const endpoint = originalSource.replace(config.endpoint, '');

        httpRequest
          // replace default endpoint from src url as it's defined in httpRequest
          .get(endpoint, {
            headers: viewToken ? { authorization: `Bearer ${viewToken}` } : {},
          })
          .then((response) => response.blob())
          .then((blob) => {
            const url = URL.createObjectURL(blob);
            // eslint-disable-next-line no-param-reassign
            element.src = url; // Attach the new image URL to the img element
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('Error refetching the image:', error);
          });
      }
    });
  };

  // setup event listener per specified selector and cleanup if component unmounts
  useEffect(() => {
    const images = document.querySelectorAll<HTMLImageElement>(selector);

    const ids: (string | undefined)[] = [];

    images?.forEach((element) => {
      if (!element.complete && !isImageHidden(element)) {
        ids.push(element?.id);
        element.addEventListener('load', onImageLoaded);
      }
    });

    if (images) {
      handleInterceptCalls(images, token);
    }

    setImageCallIds(ids);

    return () => {
      images?.forEach((element) => {
        if (!element.complete) {
          element.removeEventListener('load', onImageLoaded);
        }
      });
    };
  }, [condition, token]);

  // when condition changes, reset autoRelease and set timer to set to true after x amount
  useEffect(() => {
    setAutoRelease(false);
    if (timer) {
      timer = null;
    }

    timer = setTimeout(() => {
      setAutoRelease(true);
    }, 60000);

    return () => {
      timer = null;
    };
  }, [condition, timer]);

  return { imageCallIds, autoRelease };
};
