import React, { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import queryString from 'query-string';
import { Typography, Link } from '@material-ui/core';
import { ResetForm } from '../../components/organisms/form/ResetForm';
import { AccessTemplate } from '../../components/templates';
import { config } from '../../lib/config';
import { isString } from '../../lib/utils/helpers';

import { NonAuthRoutes } from '../../lib/constants/pagePaths';
import { attemptResetPassword } from '../../lib/store/account/effects';
import { LogoHeadingBox } from '../../components/molecules/box/LogoHeadingBox';
import { InfoBar } from '../../components/molecules/box/InfoBar';
import { RouterLink } from '../../components/molecules/link/RouterLink';
import { successAccount } from '../../lib/store/account/actions';

const Reset = (): ReactElement => {
  const { t } = useTranslation(['init']);
  const location = useLocation();
  const { email, vtoken } = queryString.parse(location.search);
  const reduxDispatch = useDispatch();

  useEffect(() => {
    reduxDispatch(successAccount(''));
  }, []);

  const handleSubmit = (password: string): void => {
    if (isString(vtoken)) {
      reduxDispatch(attemptResetPassword(vtoken, password));
    } else {
      // eslint-disable-next-line no-console
      console.error({ code: 'MAIN_RESET_MISSING_PARAM', type: 'error', message: 'Missing Parameter vtoken' });
    }
  };

  return (
    <AccessTemplate
      infoBar={<InfoBar infoLink={config.wikiUrl || ''} />}
      header={<LogoHeadingBox title={t('init:pages.reset.header.title')} />}
      footer={(): ReactElement => {
        return (
          <Typography align="center">
            <Trans
              i18nKey="init:shared.pages.footer.loginText"
              components={{
                login: <Link component={RouterLink} color="secondary" to={NonAuthRoutes.login} />,
              }}
            />
          </Typography>
        );
      }}
    >
      <ResetForm email={isString(email) ? email : ''} accessible={!!(email && vtoken)} handleSubmit={handleSubmit} />
    </AccessTemplate>
  );
};
export default Reset;
