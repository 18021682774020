import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { Props, StyleProps } from './types';
import { CircleSpinner } from '../../../atoms/spinners/CircleSpinner';
import { CircleLogo } from '../../../atoms/images/CircleLogo';

const useStyles = makeStyles({
  boxAdjustments: {
    fontSize: (props: StyleProps): string => props.size || '10px',
    padding: '10px',
  },
});

export const LogoSpinner = ({ size }: Props): ReactElement => {
  const classes = useStyles({ size });
  return (
    <Box className={classes.boxAdjustments} display="flex" justifyContent="center" alignItems="center">
      <CircleSpinner />
      <CircleLogo />
    </Box>
  );
};

LogoSpinner.propTypes = {
  size: PropTypes.string,
};

LogoSpinner.defaultProps = {
  size: '10px',
};
