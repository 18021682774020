import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useAccessTemplateStyles = makeStyles((theme: Theme) =>
  createStyles({
    fluentHeight: {
      height: '100%',
    },
    withDisclaimer: {
      minHeight: `calc(100vh - ${theme.spacing(4)}px)`,
      marginBottom: -theme.spacing(4),
      flexWrap: 'nowrap',
      '& > div': {
        flex: 1,
        marginBottom: theme.spacing(4),
      },
    },
    stretch: {
      flex: '1',
    },
    paper: {
      borderRadius: '10px',
    },
  }),
);
