export enum fileConstants {
  FILE_LOADING = 'dSimple/file/LOADING',
  FILE_ERRORED = 'dSimple/file/ERRORED',
  FILE_SUCCESS = 'dSimple/file/SUCCESS',
  FILE_SET_ALL = 'dSimple/file/SET_ALL',
  FILE_SET_ALL_UPLOAD = 'dSimple/file/SET_ALL_UPLOAD',
  FILE_ADD = 'dSimple/file/ADD',
  FILE_REMOVE = 'dSimple/file/REMOVE',
  FILE_INIT = 'dSimple/file/INIT',
}
