import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { COLOR_BLUE_LIGHT } from '../../../lib/theme/constants';

const USER_CARD_FLEX = '1 1 calc(33.33% - 16px)';
const USER_CARD_MAX = 'calc(33.33% - 16px)';

export const useInstructionsWizardStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingRoot: {
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    topBarWrap: {
      display: 'flex',
      alignItems: 'center',
      '& .MuiTypography-root': {
        marginBottom: '0',
        flex: 1,
      },
    },
    stepper: {
      [theme.breakpoints.down('sm')]: {
        '& .MuiTypography-root': {
          fontSize: '0.7rem',
        },
      },
      [theme.breakpoints.down('xs')]: {
        '& .MuiStepLabel-labelContainer': {
          display: 'none',
        },
      },
    },
    paperRoot: {
      width: '90%',
      maxWidth: 1680,
      display: 'flex',
      flexDirection: 'column',
    },
    innerRoot: {
      position: 'relative',
      height: '100%',
    },
    contentRoot: {
      padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      overflow: 'hidden',
      paddingBottom: '100px',
      height: '100%',
    },
    contentWrap: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      [theme.breakpoints.down('sm')]: {
        overflowX: 'hidden',
        overflowY: ({ overflowVisible }: { overflowVisible?: boolean }) => (overflowVisible ? 'auto' : 'hidden'),
      },
    },
    contentInner: {
      overflow: 'hidden',
      flex: 1,
      marginTop: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        overflow: ({ overflowVisible }: { overflowVisible?: boolean }) => (overflowVisible ? 'visible' : 'hidden'),
      },
    },
    navigation: {
      flex: 0,
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      background: theme.palette.grey[100],
      position: 'absolute',
      left: 0,
      bottom: 0,
      right: 0,
    },
    nextButtonWrap: {
      display: 'flex',
      alignItems: 'center',
    },
    errorMessage: {
      color: theme.palette.error.main,
      marginRight: theme.spacing(2),
    },
  }),
);

export const useStepSuccessStyles = makeStyles((theme: Theme) =>
  createStyles({
    successBoxWrap: {
      display: 'flex',
      justifyContent: 'center',
    },
    successBox: {
      maxWidth: '600px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    checkIcon: {
      fontSize: '225px',
      color: theme.palette.success.main,
    },
    divider: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      width: '125px',
    },
    whatNextText: {
      textAlign: 'center',
      maxWidth: '450px',
      marginTop: theme.spacing(2),
    },
    whatNextButtons: {
      display: 'flex',
      justifyContent: 'center',
      gap: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    whatNextBox: {},
    successMessage: {
      marginTop: theme.spacing(2),
    },
  }),
);

export const useStepTemplatesStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        maxWidth: `calc(100% + ${theme.spacing(4)}px)`,
      },
    },
    gridChild: {
      height: '100%',
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
        overflow: 'visible',
        height: 'auto',
      },
    },
    paper: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      paddingBottom: 0,
    },
    chip: {
      margin: theme.spacing(0.5),
    },
    gridItem1: {
      maxHeight: '100%',
    },
    templateListWrap: {
      height: '100%',
      marginLeft: -theme.spacing(2),
      marginRight: -theme.spacing(2),
      paddingTop: 0,
      paddingBottom: 0,
      overflowY: 'auto',
    },
  }),
);

export const useUserCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      display: 'flex',
      flex: USER_CARD_FLEX,
      maxWidth: USER_CARD_MAX,
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        flex: '1',
        maxWidth: '100%',
      },
    },
    root: {
      flex: '1',
      display: 'flex',
      maxWidth: '100%',
      '&:hover': {
        background: theme.palette.grey[100],
      },
    },
    activeItem: {
      border: '2px solid rgba(0, 0, 0, 0.12)',
    },
    userCardSelected: {
      flex: '0',
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.success.main,
      background: 'rgba(129, 199, 132, 0.4)',
      '& .MuiSvgIcon-root': {
        margin: theme.spacing(2),
      },
    },
    userCardContent: {
      flex: 1,
      padding: theme.spacing(2),
      maxWidth: '100%',
    },
    userCardContentShrink: {
      maxWidth: 'calc(100% - 57px)',
    },
    verticalDivider: {
      borderRight: `1px solid ${theme.palette.divider}`,
      height: '100%',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    headerInfo: {
      marginLeft: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      overflow: 'hidden',
    },
    editAction: {
      marginLeft: theme.spacing(1),
      flex: '0 0 50px',
    },
    headerInfoContent: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    content: {},
    contentRow: {},
  }),
);

export const useStepAssignmentsStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridRoot: {
      height: '100%',
    },
    userCards: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    userCardSkeleton: {
      flex: USER_CARD_FLEX,
      maxWidth: USER_CARD_MAX,
      [theme.breakpoints.down('sm')]: {
        flex: '1',
        maxWidth: '100%',
      },
    },
    gridItem: {
      height: '100%',
      overflowY: 'auto',
    },
  }),
);

export const useUserFormStyles = makeStyles(() =>
  createStyles({
    paperRoot: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    noBorder: {
      border: 'none',
    },
  }),
);

export const useAddUserCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: USER_CARD_FLEX,
      maxWidth: USER_CARD_MAX,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      border: ({ open }: { open?: boolean }): string =>
        open ? `2px dashed ${theme.palette.secondary.main}` : `2px dashed ${theme.palette.grey[500]}`,
      background: ({ open }: { open?: boolean }): string => (open ? theme.palette.grey[100] : `initial`),
      borderRadius: theme.shape.borderRadius,
      cursor: 'pointer',
      position: 'relative',
      minHeight: '100px',
      '& .MuiSvgIcon-root': {
        color: theme.palette.success.main,
        fontSize: '2.5rem',
      },
      '&:hover': {
        borderColor: theme.palette.secondary.main,
        background: theme.palette.grey[100],
      },
      [theme.breakpoints.down('sm')]: {
        flex: '1',
        maxWidth: '100%',
      },
    },
    text: {
      marginTop: theme.spacing(1),
    },
  }),
);

export const useStepMetaStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      height: '100%',
    },
    paperRoot: {
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    infoWrap: {
      padding: theme.spacing(2),
      backgroundColor: COLOR_BLUE_LIGHT,
      borderRadius: theme.shape.borderRadius,
    },
    infoGrid: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  }),
);
