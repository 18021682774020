/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch } from 'redux';
import { loadStripe } from '@stripe/stripe-js';
import { OrganisationBilling, OrganisationType, OrganisationBillingReports } from './types';
import {
  erroredOrganisation,
  loadingOrganisation,
  currentOrganisation,
  successOrganisation,
  setBillingReports,
} from './actions';
import { config } from '../../config';
import { apiBillingProvisionAccess } from './services';
import { PaymentMethod } from '../../../components/organisms/form/PaymentForm/types';
import i18n from '../../i18n';

export const handleSubscriptionResponse = async (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  subscription: any,
  billing: OrganisationBilling,
  reports: OrganisationBillingReports,
  paymentMethodId: string,
  paymentMethod: PaymentMethod,
  current: OrganisationType,
  dispatch: Dispatch,
): Promise<void> => {
  // if card is declined for whatever reason, show error and abort
  if (!subscription) {
    dispatch(erroredOrganisation({ message: i18n.t('Unkown Error when validating payment response') }));
    return;
  }
  if (subscription.error) {
    dispatch(erroredOrganisation({ translationString: i18n.t('This payment option was declined') }));
    return;
  }

  const setupIntent = subscription.pending_setup_intent;

  // if subscription is pending and requires action -> show modal for authentication (handle by stripe)
  if (setupIntent && setupIntent.status === 'requires_action') {
    const stripe = await loadStripe(config.STRIPE_PUBLISH_KEY);
    if (!stripe) {
      dispatch(erroredOrganisation({ message: i18n.t('Unkown Error when validating payment method') }));
      return;
    }

    stripe
      .confirmCardSetup(setupIntent.client_secret, {
        payment_method: paymentMethodId,
      })
      .then(async (result) => {
        if (result.error) {
          // start code flow to handle updating the payment details
          // Display error message in your UI.
          // The card was declined (i.e. insufficient funds, card has expired, etc)
          dispatch(
            erroredOrganisation({
              message: i18n.t(
                'We are unable to authenticate your payment method. Please choose a different payment method and try again',
              ),
            }),
          );
          dispatch(loadingOrganisation(''));
        } else if (result.setupIntent.status === 'succeeded') {
          // authentication completed -> provision access to the app
          const {
            billing: billingProvisioned,
            subscription: subscriptionProvisioned,
            reports: reportsProvisioned,
          } = await apiBillingProvisionAccess({
            subscriptionId: subscription.id,
            paymentMethod,
            paymentMethodId,
          });

          // check if completed now -> if not, let user update payment method
          if (
            subscriptionProvisioned.pending_setup_intent &&
            subscriptionProvisioned.pending_setup_intent.status === 'requires_action'
          ) {
            dispatch(
              erroredOrganisation({
                message: i18n.t(
                  'We are unable to authenticate your payment method. Please choose a different payment method and try again',
                ),
              }),
            );
            dispatch(loadingOrganisation(''));
          } else {
            dispatch(currentOrganisation({ ...current, billing: billingProvisioned }));
            dispatch(setBillingReports(reportsProvisioned));
            dispatch(successOrganisation('organisationBilling'));
            dispatch(loadingOrganisation(''));
          }
        }
      });
    // No customer action needed
  } else if (billing) {
    dispatch(currentOrganisation({ ...current, billing }));
    dispatch(setBillingReports(reports));
    dispatch(successOrganisation('organisationBilling'));
    dispatch(loadingOrganisation(''));
  }
};
