import { DataWithBody } from './types';
/**
 * Takes an object and confirms that the type is "DataWithBody"
 * @param obj
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isDataWithBodyType = (obj: DataWithBody | any): obj is DataWithBody => {
  return obj && obj.body;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isDataFormType = (obj: DataWithBody | any): obj is FormData => {
  return obj && obj instanceof FormData;
};
