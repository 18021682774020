import { fileConstants } from './constants';
import { FileLoading, ActionType, FileType, FileStatic } from './types';
import { ProblemType } from '..';

/**
 * Actions
 * Beschreibt was wir tun wollen
 */

export const setLoading = (payload: FileLoading = ''): ActionType => ({
  type: fileConstants.FILE_LOADING,
  payload,
});

export const erroredFile = (payload: ProblemType | null = null): ActionType => ({
  type: fileConstants.FILE_ERRORED,
  payload,
});

export const successFile = (payload: FileLoading = ''): ActionType => ({
  type: fileConstants.FILE_SUCCESS,
  payload,
});

export const setFileList = (payload: FileType[] | FileStatic[]): ActionType => ({
  type: fileConstants.FILE_SET_ALL,
  payload,
});

export const setFileUploadList = (payload: { file: File; tempId: string }[]): ActionType => ({
  type: fileConstants.FILE_SET_ALL_UPLOAD,
  payload,
});

export const addFile = (payload: { file: FileType; position: 'asc' | 'desc'; tempId?: string }): ActionType => ({
  type: fileConstants.FILE_ADD,
  payload,
});

export const removeFile = (payload: { _id: string }): ActionType => ({
  type: fileConstants.FILE_REMOVE,
  payload,
});

export const initFile = (): ActionType => ({
  type: fileConstants.FILE_INIT,
});
