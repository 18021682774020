import { ActionType, GroupLoading, Group } from './types';
import { ProblemType } from '..';
import { groupConstants } from './constants';

/**
 * Actions
 * Beschreibt was wir tun wollen
 */

export const setAllGroup = (payload: Group[] = []): ActionType => ({
  type: groupConstants.GROUP_SET_ALL,
  payload,
});

export const setLoading = (payload: GroupLoading = ''): ActionType => ({
  type: groupConstants.GROUP_LOADING,
  payload,
});

export const erroredGroup = (payload: ProblemType | null = null): ActionType => ({
  type: groupConstants.GROUP_ERRORED,
  payload,
});

export const successGroup = (payload: GroupLoading = ''): ActionType => ({
  type: groupConstants.GROUP_SUCCESS,
  payload,
});

export const addGroup = (payload: Group): ActionType => ({
  type: groupConstants.GROUP_ADD,
  payload,
});

export const replaceGroup = (payload: Group): ActionType => ({
  type: groupConstants.GROUP_REPLACE,
  payload,
});

export const deleteGroup = (payload: string): ActionType => ({
  type: groupConstants.GROUP_DELETE,
  payload,
});
