import { Dispatch } from 'redux';
import { thunkify } from '../../httpClient/thunkify';
import { setLoading } from './actions';
import { ProblemType } from '../types';
import { enqueueSnackbar } from '../notifier/actions';

/**
 * Thunks
 *
 * The return value of the inner function should be a promise. The dispatch function
 * returns the value of the function from within it. This allows us to chain dispatch functions.
 */
export const thunk = thunkify({
  // start: (dispatch: Dispatch) => dispatch(loadingLocation()),
  end: (dispatch: Dispatch) => dispatch(setLoading('')),
  error: (e: ProblemType, dispatch: Dispatch) => {
    return dispatch(
      enqueueSnackbar({
        message: e.message,
        details: e.details,
        options: {
          variant: 'error',
        },
      }),
    );
  },
});
