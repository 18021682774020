import { dashboardConstants } from './constants';
import { ActionType, DashboardState } from './types';

// INITIAL STATE
const initialState: DashboardState = {
  locations: null,
  departments: null,
  areas: null,
  users: null,
  instructions: null,
  instructionStatus: null,
  archive: null,
  archiveUsers: null,
  archivePublic: null,
  problem: null,
  loading: null,
  success: null,
};

/**
 * Reducer
 * Updated den STORE je nach ACTION type - Mitarbeiter der Auftrag ausfuehrt
 */
export const dashboardReducer = (state = initialState, action: ActionType): DashboardState => {
  switch (action.type) {
    case dashboardConstants.DASHBOARD_LOADING:
      return {
        ...state,
        loading: { ...state.loading, [action.payload.type]: action.payload.data },
        problem: {
          ...state.problem,
          [action.payload.type]: action.payload.data ? null : state.problem ? state.problem[action.payload.type] : null,
        },
        success: {
          ...state.success,
          [action.payload.type]: action.payload.data
            ? false
            : state.success
            ? state.success[action.payload.type]
            : false,
        },
      };

    case dashboardConstants.DASHBOARD_ERRORED:
      return {
        ...state,
        problem: {
          ...state.problem,
          [action.payload.type]: action.payload.data,
        },
      };

    case dashboardConstants.DASHBOARD_SUCCESS:
      return {
        ...state,
        success: {
          ...state.success,
          [action.payload.type]: action.payload.data,
        },
      };

    case dashboardConstants.DASHBOARD_SET:
      return {
        ...state,
        [action.payload.type]: action.payload.data,
      };

    case dashboardConstants.DASHBOARD_INIT:
      return {
        ...initialState,
      };

    case 'dSimple/RESET_APP':
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
