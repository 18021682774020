/* eslint-disable consistent-return */
import React, { ReactElement } from 'react';
import { NavLink as RouterNavLink, useHistory } from 'react-router-dom';
import { ExtendedNavLinkProps } from './types';

export const NavLink: React.FC<ExtendedNavLinkProps> = (props: ExtendedNavLinkProps): ReactElement => {
  const { to, className, ...other } = props;
  const history = useHistory();

  return (
    <RouterNavLink
      to={to}
      className={className}
      onClick={(e) => {
        if (e.metaKey || e.ctrlKey) return;
        e.preventDefault();
        // eslint-disable-next-line no-return-assign
        if (window.swUpdate) return (window.location = to);
        return history.push(to);
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    />
  );
};
