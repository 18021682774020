import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useTextareaFieldStyles = makeStyles(() =>
  createStyles({
    limitWrap: {
      position: 'absolute',
      right: '10px',
      top: '5px',
      fontSize: '10px',
    },
  }),
);
