import React, { ReactElement, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, FormikErrors } from 'formik';
import { Box, Paper, Typography, IconButton } from '@material-ui/core';

import { CloseRounded, SaveRounded } from '@material-ui/icons';
import clsx from 'clsx';
import { attemptUpdateUser, attemptCreateUser } from '../../../../lib/store/user/effects';
import { transformToCreateUserType, transformToUpdateUserType } from '../../../../lib/context/user/utils';

import { useUserStateContext } from '../../../../lib/context/user';
import { UserFormValues } from '../../../../lib/context/user/types';
import { successUser } from '../../../../lib/store/user/actions';
import {
  PersonalFields,
  ResetFormHelper,
  initialValues,
  validateForm,
} from '../../../../components/organisms/form/UserForm';
import { useUserFormStyles } from '../styles';
import { PrimaryButton } from '../../../../components/molecules/button';
import { RootState } from '../../../../lib/store';
import { ListSkeleton } from '../../../../components/molecules/skeleton/ListSkeleton';

/**
 * UserForm component renders a form for creating or editing a user.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} [props.title] - The title of the form.
 * @param {Function} props.handleClose - The function to handle closing the form.
 * @param {boolean} [props.isDialogMode] - Indicates whether the form is used in a dialog mode.
 * @returns {ReactElement} The rendered UserForm component.
 */
export const UserForm = (props: { title?: string; handleClose: () => void; isDialogMode?: boolean }): ReactElement => {
  const { handleClose, isDialogMode, title } = props;
  const classes = useUserFormStyles();
  const { t } = useTranslation(['init', 'module-core']);
  const { currentUser, loadingUser, successUser: success, viewMode, initialUserFormValues } = useUserStateContext();
  const { loading: isLoadingRoles, roles } = useSelector((state: RootState) => state.role);

  const employeeRole = roles.find((role) => role?.slug === 'employee');

  const isLoading = isLoadingRoles || !employeeRole;

  const reduxDispatch = useDispatch();

  const handleSubmit = (values: UserFormValues): void => {
    if (viewMode === 'edit' && currentUser && currentUser._id) {
      reduxDispatch(attemptUpdateUser(currentUser._id, transformToUpdateUserType(values)));
    } else {
      reduxDispatch(attemptCreateUser(transformToCreateUserType(values)));
    }
  };

  const handleAdditionalAction = useCallback((): void => {
    reduxDispatch(successUser());
  }, [reduxDispatch]);

  useEffect(() => {
    if (isDialogMode && (success === 'userEdit' || success === 'userAdd')) {
      handleClose();
      reduxDispatch(successUser());
    }
  }, [isDialogMode, success, handleClose, reduxDispatch]);

  return (
    <Paper variant="outlined" className={clsx(classes.paperRoot, isDialogMode && classes.noBorder)}>
      <Box display="flex" flexDirection="column" flex="0">
        {(title || (handleClose && !isDialogMode)) && (
          <Box py={2} px={2} pb={2} display="flex" alignItems="center">
            <Box flex="1">{title && <Typography variant="h4">{title}</Typography>}</Box>
            {handleClose && !isDialogMode && (
              <Box>
                <IconButton color="primary" onClick={handleClose} edge="end">
                  <CloseRounded />
                </IconButton>
              </Box>
            )}
          </Box>
        )}
      </Box>
      <Box py={2} px={2} pt={0} flex="1" overflow="hidden auto">
        {!isLoading && employeeRole ? (
          <Formik
            initialValues={
              initialUserFormValues || {
                ...initialValues,
                role: { _id: employeeRole._id, roleName: employeeRole?.roleName },
              }
            }
            onSubmit={(values): void => {
              handleSubmit(values);
            }}
            validate={(values): FormikErrors<UserFormValues> => validateForm(values, t, viewMode)}
          >
            {(): ReactElement => {
              return (
                <Form>
                  <ResetFormHelper reset={success === 'userAdd'} additionalAction={handleAdditionalAction} />
                  <PersonalFields disableAllFields={false} showRequiredOnly />
                  <Box display="flex" justifyContent="flex-end" mt={2}>
                    <PrimaryButton
                      variant="text"
                      type="submit"
                      endIcon={<SaveRounded />}
                      loading={loadingUser === 'userAdd' || loadingUser === 'userEdit'}
                    >
                      {t('init:shared.forms.submit.save')}
                    </PrimaryButton>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        ) : (
          <ListSkeleton amountItems={4} />
        )}
      </Box>
    </Paper>
  );
};
