import React, { ReactElement } from 'react';

import PropTypes from 'prop-types';
import clsx from 'clsx';

import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { Typography, Box } from '@material-ui/core';

import { useBaseSideBarStyles } from './styles';
import { BaseSideBarProps } from './types';

import { NavLink } from '../../../molecules/link/NavLink';

export const BaseSideBar = (props: BaseSideBarProps): ReactElement => {
  const { sideBarItems, moduleTitle, toolbarHeight = '64px' } = props;

  const classes = useBaseSideBarStyles({ toolbarHeight });
  const isLowViewport = window.innerWidth < 600;
  const [open, setOpen] = React.useState(!isLowViewport);

  const handleToggleDrawer = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.gap} />
        <Box id="dashboard-navbar">
          <Box>
            <Typography variant="h1" className={clsx(classes.module, classes.moduleLink)}>
              {moduleTitle.toUpperCase()}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box id="side-navbar">
          <MenuList className={classes.list}>
            {sideBarItems.map((item) => (
              <MenuItem
                disableGutters
                className={classes.button}
                key={item.text}
                id={`sidebar-item${item.to.split('/').join('-')}`}
              >
                <NavLink id="side-ownInstructions" activeClassName="sideBarItemActive" to={item.to}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText className={classes.text} primary={item.text} />
                </NavLink>
              </MenuItem>
            ))}
          </MenuList>
        </Box>
        <Box className={classes.openerBox}>
          <IconButton id="open-navbar" onClick={handleToggleDrawer}>
            {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </Box>
      </Drawer>
    </>
  );
};

BaseSideBar.propTypes = {
  sideBarItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      icon: PropTypes.element.isRequired,
    }).isRequired,
  ).isRequired,
  moduleTitle: PropTypes.string.isRequired,
  toolbarHeight: PropTypes.string,
};

BaseSideBar.defaultProps = {
  toolbarHeight: '64px',
};
