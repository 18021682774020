import { httpRequest, handleResponse } from '../../../httpClient';
import { FileCategory, CreateFileCategory, UpdateFileCategory } from '../types';
import { isObject } from '../../../utils/helpers';

/**
 * FileCategories
 */
export const apiGetFileCategories = (): Promise<FileCategory[]> =>
  httpRequest
    .get(`/core/file-categories`)
    .then((res) => handleResponse<FileCategory[]>(res))
    .then((data) => {
      return isObject(data) ? [] : data;
    })
    .catch();

export const apiCreateFileCategory = (body: CreateFileCategory): Promise<FileCategory> =>
  httpRequest
    .post('/core/file-categories', { body })
    .then((res) => handleResponse<FileCategory>(res))
    .then((data) => data)
    .catch();

export const apiUpdateFileCategory = (body: UpdateFileCategory): Promise<FileCategory> =>
  httpRequest
    .patch('/core/file-categories', { body })
    .then((res) => handleResponse<FileCategory>(res))
    .then((data) => data);

export const apiDeleteFileCategory = (_id: string): Promise<unknown> =>
  httpRequest.delete(`/core/file-categories/${_id}`).then(handleResponse);
