import React, { ReactElement, Suspense } from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps, Redirect, Route, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../lib/store';
import { NonAuthRoutes } from '../../lib/constants/pagePaths';
import { AuthRouteProps } from './types';
import { getSubdomain, isValidSubdomain } from '../../lib/utils';
import { MainLogoSpinner } from '../../components/molecules/spinner/MainLogoSpinner';
import { MainTemplate } from '../../components/templates';
import ErrorBoundary from '../../features/ErrorBoundry';

export const AuthRoute = (props: AuthRouteProps): ReactElement => {
  const {
    Component,
    SuspenseComponent,
    path,
    exact = false,
    isAuthenticated,
    stretch = false,
    sliderMode = false,
    sideBarItems,
    moduleTitle,
  } = props;
  const subdomain = getSubdomain(window.location.hostname);
  const { t } = useTranslation();

  const organisationSlug = useSelector((state: RootState) => state.organisation.slug);

  const location = useLocation();

  if (isAuthenticated && Component) {
    return (
      <Route
        exact={exact}
        path={path}
        render={(routeProps: RouteComponentProps): JSX.Element => {
          return (
            <>
              <MainTemplate
                sideBarItems={sideBarItems}
                moduleTitle={moduleTitle}
                stretch={stretch}
                sliderMode={sliderMode}
              >
                <Suspense
                  fallback={
                    SuspenseComponent || (
                      <Box
                        justifyContent="center"
                        style={{ height: 'calc(100vh - 64px - 64px - 64px)' }}
                        display="flex"
                        flexDirection="column"
                      >
                        <MainLogoSpinner loadingText={t('init:shared.pages.loadingPage')} />
                      </Box>
                    )
                  }
                >
                  <ErrorBoundary>
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <Component {...routeProps} />
                  </ErrorBoundary>
                </Suspense>
              </MainTemplate>
            </>
          );
        }}
      />
    );
  }

  return (
    <Redirect
      to={{
        pathname: !isAuthenticated
          ? !organisationSlug || !isValidSubdomain(subdomain)
            ? NonAuthRoutes.workspace
            : NonAuthRoutes.login
          : NonAuthRoutes.unauthorized, // show unauthorized route when no Component given
        search: location.pathname ? `?dest=${encodeURIComponent(location.pathname)}` : '',
      }}
    />
  );
};

AuthRoute.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.elementType]),
  SuspenseComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.elementType]),
  path: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  exact: PropTypes.bool,
  isAuthenticated: PropTypes.bool.isRequired,
  sideBarItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      icon: PropTypes.element.isRequired,
    }).isRequired,
  ).isRequired,
  moduleTitle: PropTypes.string.isRequired,
};
AuthRoute.defaultProps = {
  Component: null,
  exact: false,
  SuspenseComponent: null,
};
