import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ListItemEditableStyleProps } from './types';

export const useListItemEditableStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderLeft: ({ editMode }: ListItemEditableStyleProps): string =>
        editMode ? `4px solid ${theme.palette.primary.main}` : '4px solid transparent',
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      ...({ editMode }: ListItemEditableStyleProps) => editMode && { paddingTop: '0', paddingBottom: '0' },
      '&:hover': {
        borderLeft: `4px solid ${theme.palette.primary.main}`,
      },
      '&.Mui-selected': {
        borderLeft: `4px solid ${theme.palette.primary.main}`,
      },
      '& form': {
        width: '100%',
      },
    },
  }),
);
