// eslint-disable-next-line import/no-unresolved
import { ComponentsProps } from '@material-ui/core/styles/props';

export const props: ComponentsProps = {
  MuiButton: {
    variant: 'contained',
    color: 'primary',
  },
  MuiLink: {
    color: 'secondary',
  },
  MuiTextField: {
    variant: 'outlined',
  },
};
