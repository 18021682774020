import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useBasePopupStyles = makeStyles((theme: Theme) =>
  createStyles({
    popper: {
      zIndex: 1450,
      padding: ({ fullWidth }: { fullWidth?: boolean }) => (fullWidth ? 0 : theme.spacing(1)),
      width: ({ fullWidth }: { fullWidth?: boolean }) => (fullWidth ? '100%' : 'initial'),
    },
  }),
);
