export const sandboxEnvironment = {
  environment: 'sandbox',
  spaUrl: 'https://app.d-simple-sandbox.com',
  spaRootUrl: 'https://d-simple-sandbox.com',
  endpoint: 'https://api.d-simple-sandbox.com/api',
  mockEndpoint: '',
  STRIPE_PUBLISH_KEY:
    'pk_test_51JWnU7In4pb3jcxGRE3d5hg9pjc0slQCYbqVLhhU1gdbqFd36In7e6sH4tztZowdD3N9UmMecmy8RXQ5sN7FHmqF00AVHsPWho',
  posthog: '',
};
