import { ActionType, RoleManagementLoading, RoleNameType, RoleType } from './types';
import { roleConstants } from './constants';
import { ProblemType } from '..';

/**
 * Actions
 * Beschreibt was wir tun wollen
 */

export const setRoles = (payload: RoleType[] = []): ActionType => ({
  type: roleConstants.ROLE_SET_ALL,
  payload,
});

export const setRoleNames = (payload: RoleNameType[] = []): ActionType => ({
  type: roleConstants.ROLE_SET_ALL_NAMES,
  payload,
});

export const setLoading = (payload: RoleManagementLoading = ''): ActionType => ({
  type: roleConstants.ROLE_LOADING,
  payload,
});

export const erroredRole = (payload: ProblemType | null = null): ActionType => ({
  type: roleConstants.ROLE_ERRORED,
  payload,
});

export const successRole = (payload: RoleManagementLoading = ''): ActionType => ({
  type: roleConstants.ROLE_SUCCESS,
  payload,
});

export const addRole = (payload: RoleType): ActionType => ({
  type: roleConstants.ROLE_ADD,
  payload,
});

export const replaceRole = (payload: RoleType): ActionType => ({
  type: roleConstants.ROLE_REPLACE,
  payload,
});

export const deleteRole = (payload: string): ActionType => ({
  type: roleConstants.ROLE_DELETE,
  payload,
});

export const initRoles = (): ActionType => ({
  type: roleConstants.ROLE_INIT,
});
