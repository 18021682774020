import { TFunction } from 'i18next';
import { FormikErrors, FormikProps } from 'formik';
import { Dispatch } from 'redux';
import dayjs from 'dayjs';
import {
  CreateInstructionType,
  AssignInstructionType,
  isCreateInstructionType,
  isAssignInstructionType,
} from '../../lib/store/instruction/types';
import { WizardMetaFormValues } from './types';
import { DATE_FORMAT } from '../../../lib/constants/dateFormat';
import { attemptCreateInstructionByWizard } from '../../lib/store/instruction/effects';

export const getStepErrorMessage = (params: {
  step: number;
  instruction: Partial<CreateInstructionType>;
  assignment: Partial<AssignInstructionType>;
  t: TFunction;
  metaFormErrors: FormikErrors<WizardMetaFormValues> | undefined;
}): string | undefined => {
  const { step, instruction, assignment, t, metaFormErrors } = params;
  switch (step) {
    case 1: {
      if (!instruction?.slides || instruction.slides.length === 0) {
        return t('module-umo:shared.wizard.step-1.error-message');
      }

      return undefined;
    }

    case 2: {
      if (!assignment?.users || assignment.users.length === 0) {
        return t('module-umo:shared.wizard.step-2.error-message');
      }

      return undefined;
    }

    case 3: {
      if (metaFormErrors && Object.keys(metaFormErrors).length > 0) {
        return t('module-umo:shared.wizard.step-3.error-message');
      }

      return undefined;
    }

    default:
      return undefined;
  }
};

/**
 * Attempts to submit the wizard. If the meta form is valid, it will dispatch the create instruction action.
 */
export const submitWizard = (params: {
  metaFormRef: React.RefObject<FormikProps<WizardMetaFormValues>>;
  instruction: Partial<CreateInstructionType>;
  assignment: Partial<AssignInstructionType>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reduxDispatch: Dispatch<any>;
  setInstruction: (instruction: Partial<CreateInstructionType>) => void;
  setAssignment: (assignment: Partial<AssignInstructionType>) => void;
}): void => {
  const { metaFormRef, instruction, assignment, reduxDispatch, setInstruction, setAssignment } = params;

  if (!metaFormRef || !metaFormRef.current) {
    return;
  }

  const { submitForm, validateForm, values: metaValues } = metaFormRef.current;
  submitForm(); // noop but touch fields

  // validate meta form
  validateForm(metaValues).then((errors) => {
    if (Object.keys(errors).length === 0) {
      // update values with meta form values
      const instructionValues = {
        ...instruction,
        shortDescription: '',
        instructionName: metaValues.instructionName,
        instructionType: metaValues.instructionType,
        instructionTypeCategory: metaValues.instructionTypeCategory,
      };
      const assignmentValues = {
        ...assignment,
        intervalInMilliSeconds: metaValues.intervalInMilliSeconds,
        startDate: dayjs(metaValues.startDate, DATE_FORMAT).toDate(),
      };

      if (isCreateInstructionType(instructionValues) && isAssignInstructionType(assignmentValues)) {
        // once we have valid instruction and assignment, we can dispatch the create instruction action
        setInstruction(instructionValues);
        setAssignment(assignmentValues);
        reduxDispatch(attemptCreateInstructionByWizard(instructionValues, assignmentValues));
      } else {
        console.error('Invalid instruction or assignment', { instructionValues, assignmentValues });
      }
    }
  });
};
