// INITIAL STATE
import { AccountStateType, ActionType, ActionUmoType } from './types';
import { accountConstants, accountUmoConstants } from './constants';

const initialState: AccountStateType = {
  current: null,
  checked: false,
  problem: null,
  loading: '',
  success: '',
  homeUrl: '',
  isAuthenticated: false,
  reauthenticate: false,
  shouldChangePassword: false,
  csrfToken: '',
};

/**
 * Reducer
 * Updated den STORE je nach ACTION type
 */

export const accountReducer = (state = initialState, action: ActionType | ActionUmoType): AccountStateType => {
  switch (action.type) {
    case accountConstants.ACCOUNT_LOADING:
      return {
        ...state,
        loading: action.payload,
        problem: action.payload ? null : state.problem,
        success: action.payload ? '' : state.success,
      };

    case accountConstants.ACCOUNT_ERRORED:
      return {
        ...state,
        problem: action.payload,
      };

    case accountConstants.ACCOUNT_CURRENT:
      return {
        ...state,
        current: action.payload,
      };

    case accountConstants.ACCOUNT_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };

    case accountConstants.ACCOUNT_CHECK:
      return {
        ...state,
        checked: action.payload,
      };

    case accountConstants.ACCOUNT_SET_CSRF:
      return {
        ...state,
        csrfToken: action.payload,
      };

    case accountConstants.ACCOUNT_SET_HOME_URL:
      return {
        ...state,
        homeUrl: action.payload,
      };

    case accountConstants.SET_SHOULD_CHANGE_PASSWORD:
      return {
        ...state,
        shouldChangePassword: action.payload,
      };

    case accountConstants.ACCOUNT_PROFILE_AUTH:
      return {
        ...state,
        isAuthenticated: action.payload,
        reauthenticate: action.payload ? false : state.reauthenticate,
      };

    case accountConstants.ACCOUNT_REAUTHENTICATE:
      return {
        ...state,
        reauthenticate: true,
        success: '',
        problem: null,
      };

    case accountConstants.ACCOUNT_CURRENT_ROLE_REPLACE:
      if (state.current) {
        return {
          ...state,
          current: { ...state.current, role: action.payload },
        };
      }
      return state;

    case accountUmoConstants.ACCOUNT_UMO_ABSOLVED_REPLACE:
      if (state.current) {
        return {
          ...state,
          current: {
            ...state.current,
            absolvedInstructions:
              state.current.absolvedInstructions && state.current.absolvedInstructions.length
                ? state.current.absolvedInstructions.map((absolvedInstruction) =>
                    absolvedInstruction._id === action.payload._id ? action.payload : absolvedInstruction,
                  )
                : [action.payload],
          },
        };
      }
      return {
        ...state,
      };

    case 'dSimple/RESET_APP':
      return {
        ...initialState,
        checked: true,
      };

    default:
      return state;
  }
};
