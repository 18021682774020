import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useWaveStyles = makeStyles((theme: Theme) =>
  createStyles({
    waveWrap: {
      position: 'fixed',
      zIndex: -1,
      display: 'none',
    },
    orientationRight: {
      display: 'block',
      top: '0',
      right: '0',
      height: '100%',
    },
    orientationTop: {
      display: 'block',
      bottom: 'initial',
      right: 'initial',
      top: '0',
      left: theme.breakpoints.up('sm') ? '-280px' : '0',
      width: '100%',
      transform: 'rotate(180deg)',
    },
    orientationBottom: {
      display: 'block',
      width: '100%',
      bottom: '0',
      right: '0',
      maxWidth: '1980px',
    },
  }),
);
