import { ActionType, NotificationType, NotifierStateType } from './types';
import { notifierConstants } from './constants';

const initialState: NotifierStateType = {
  notifications: [],
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const notificationReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case notifierConstants.ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...action.payload,
          },
        ],
      };

    case notifierConstants.CLOSE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.map((notification: NotificationType) =>
          action.payload.dismissAll || notification.options.key === action.payload.key
            ? { ...notification, dismissed: true }
            : { ...notification },
        ),
      };

    case notifierConstants.REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification: NotificationType) => notification.options.key !== action.payload,
        ),
      };

    case 'dSimple/RESET_APP':
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
