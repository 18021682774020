import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import { FONT_FAMILY, PRIMARY_MAIN_COLOR, SECONDARY_MAIN_COLOR } from '../../constants';

export const typography: TypographyOptions = {
  fontFamily: FONT_FAMILY,
  h1: {
    color: PRIMARY_MAIN_COLOR,
    fontWeight: 700,
    textTransform: 'uppercase',
    fontSize: '2rem',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '25px',
    position: 'relative',
    '&::before': {
      content: '""',
      height: '1.4em',
      width: '4px',
      background: SECONDARY_MAIN_COLOR,
      position: 'relative',
      marginRight: '8px',
    },
    '@media (max-width: 600px)': {
      fontSize: '14px',
    },
  },
  h2: {
    fontWeight: 600,
    fontSize: '2rem',
    textTransform: 'capitalize',
    position: 'relative',
  },
  h3: {
    fontWeight: 700,
    fontSize: '1.8rem',
    lineHeight: '1.167',
    position: 'relative',
  },
  h4: {
    fontWeight: 600,
    fontSize: '1.5rem',
    lineHeight: '1.167',
    position: 'relative',
  },
  h5: {
    fontWeight: 700,
    fontSize: '1.3rem',
    lineHeight: '1.334',
    position: 'relative',
    marginBottom: '10px',
  },
  h6: {
    fontWeight: 600,
    fontSize: '1.2rem',
    lineHeight: '1.334',
    position: 'relative',
    marginBottom: '10px',
  },
  subtitle1: {
    fontWeight: 700,
    lineHeight: 1,
    fontSize: '0.8rem',
    position: 'relative',
  },
  subtitle2: {
    fontWeight: 400,
    lineHeight: 1,
    fontSize: '0.8rem',
    position: 'relative',
  },
};
