import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';

import { Button, MenuItem, Box } from '@material-ui/core';
import { SettingsApplicationsRounded } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { TopMenuItem } from '../../molecules/menu/TopMenuItem';
import { AuthRoutes } from '../../../lib/constants/pagePaths';
import { MenuItemProps } from './types';
import {
  hasCompanyStructurePermissions,
  hasUserManagementPermissions,
  hasRoleManagementPermissions,
  hasSystemSettingsPermissions,
  hasBillingPermissions,
} from '../../../lib/utils/permissionChecker';
import { NavLink } from '../../molecules/link/NavLink';

export const CompanyMenuItem = ({ permissions }: MenuItemProps): ReactElement => {
  const { t } = useTranslation(['menu']);
  return (
    <TopMenuItem
      mainComponent={(anchorRef, open): ReactElement => {
        return (
          <Button
            id="navbar-settings-button"
            ref={anchorRef}
            startIcon={<SettingsApplicationsRounded color="secondary" />}
            variant="text"
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
          >
            {t('menu:navbar.settings.text')}
          </Button>
        );
      }}
      dropdownComponent={(handleClose): ReactElement[] => {
        const elements = [];
        if (hasCompanyStructurePermissions(permissions)) {
          elements.push(
            <MenuItem key="companystructure" component={NavLink} to={AuthRoutes.companyStructure} onClick={handleClose}>
              <Box>{t('menu:navbar.settings.dropdown.companystructure')}</Box>
            </MenuItem>,
          );
        }

        if (hasUserManagementPermissions(permissions)) {
          elements.push(
            <MenuItem component={NavLink} to={AuthRoutes.userManagement} key="usermanagement" onClick={handleClose}>
              <Box>{t('menu:navbar.settings.dropdown.usermanagement')}</Box>
            </MenuItem>,
          );
        }
        if (hasRoleManagementPermissions(permissions)) {
          elements.push(
            <MenuItem component={NavLink} to={AuthRoutes.roleManagement} key="rolemanagement" onClick={handleClose}>
              <Box>{t('menu:navbar.settings.dropdown.rolemanagement')}</Box>
            </MenuItem>,
          );
        }

        if (hasSystemSettingsPermissions(permissions)) {
          elements.push(
            <MenuItem component={NavLink} to={AuthRoutes.systemSettings} key="systemsettings" onClick={handleClose}>
              <Box>{t('menu:navbar.settings.dropdown.systemsettings')}</Box>
            </MenuItem>,
          );
        }

        if (hasBillingPermissions(permissions)) {
          elements.push(
            <MenuItem component={NavLink} to={AuthRoutes.billing} key="billing" onClick={handleClose}>
              <Box>{t('menu:navbar.settings.dropdown.billing')}</Box>
            </MenuItem>,
          );
        }
        return elements;
      }}
    />
  );
};

CompanyMenuItem.propTypes = {
  permissions: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      permitted: PropTypes.bool.isRequired,
    }).isRequired,
  ).isRequired,
};
