import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useQuestionnaireStyles = makeStyles((theme: Theme) =>
  createStyles({
    answer: {
      marginTop: theme.spacing(1.5),
      alignItems: 'flex-start',
      '& .MuiFormControlLabel-label': {
        paddingTop: theme.spacing(1),
      },
    },
  }),
);
