import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const defaultInstance = i18n.createInstance();

defaultInstance
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ...(process.env.REACT_APP_NODE_ENV === 'test' && { lng: 'cimode' }),
    load: 'languageOnly',
    fallbackLng: process.env.REACT_APP_NODE_ENV === 'test' ? 'cimode' : 'en',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    ns: ['init'],
    defaultNS: 'common',
    debug: !!(process.env.NODE_ENV === 'production' || process.env.REACT_APP_NODE_ENV === 'test'),
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default defaultInstance;
