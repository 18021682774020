import {
  PRIMARY_TEXT_COLOR,
  SECONDARY_TEXT_COLOR,
  PRIMARY_MAIN_COLOR,
  SECONDARY_MAIN_COLOR,
  SECONDARY_MAIN_COLOR_LIGHT,
  SECONDARY_MAIN_COLOR_DARK,
  PRIMARY_MAIN_COLOR_LIGHT,
  PRIMARY_MAIN_COLOR_DARK,
} from '../../constants';

export const palette = {
  primary: {
    light: PRIMARY_MAIN_COLOR_LIGHT,
    main: PRIMARY_MAIN_COLOR,
    dark: PRIMARY_MAIN_COLOR_DARK,
  },
  secondary: {
    light: SECONDARY_MAIN_COLOR_LIGHT,
    main: SECONDARY_MAIN_COLOR,
    dark: SECONDARY_MAIN_COLOR_DARK,
  },
  text: {
    primary: PRIMARY_TEXT_COLOR,
    secondary: SECONDARY_TEXT_COLOR,
  },
};
