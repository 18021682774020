import { APIError } from '../../types';

// TODO:
// if API is unified to return only errors in format data.errors ->
// check if res.json() -> if not catch error and throw new API Error
// check if res.ok -> return data
// if not, check if data.errors -> if so, throw new API Error with data.errors
// if not, throw unknown error
/**
 * Function handles response from each response to the api via fetch (via res.json()).
 * When not res.ok, it throws an error. When res.ok, the function checks whether an error object is there,
 * which is only the case when tokenExpired is true. In that case the server does still send 200 status so no error code is shown in console.
 * @param res Api Response object
 * @returns either the response body, or error object
 */
export const handleResponse = async <T extends unknown>(res: Response): Promise<T> => {
  if (res.status === 204) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return {} as any;
  }

  const data = await res.json().catch((e) => {
    return { caughtError: e };
  });

  if (res.ok) {
    if (data && data.error) {
      throw new APIError(data.error);
    }
    // data is empty object when status 204
    return data;
  }

  if (!data.errors && !data.error && !(data instanceof APIError)) {
    // eslint-disable-next-line no-console
    console.error({
      code: data.caughtError ? 'LIB_HTTPC_HR_NO_JSON' : 'LIB_HTTPC_HR_UNKNOWN_RESPONSE',
      message: data.caughtError ? 'No body stream - No json()' : 'No error object defined in servers response',
      type: 'error',
      stack: `path: ${res.url}, stack: ${data.caughtError}`,
    });
  }

  if (data.error) throw new APIError(data.error);

  // validation error
  if (data.errors) {
    // log error for devs usage
    // eslint-disable-next-line no-console
    console.error(data);
    throw new APIError('An unknown Error has occured. This has been logged');
  }
  throw new APIError(data);

  // TODO: Test this?! What should happen to the view? Use object to define code, or leave keep throwing data.error which results in catch(e) = undefined
  // throw new Error('No error object defined in servers response');
};
