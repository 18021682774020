import {
  COLOR_GREEN,
  PRIMARY_MAIN_COLOR,
  PRIMARY_TEXT_COLOR,
  COLOR_RED,
  SECONDARY_MAIN_COLOR_LIGHT,
  SECONDARY_TEXT_COLOR_LIGHT,
} from '../constants';

export const commonAlertBox = {
  backgroundColor: '#fff',
  borderTop: '1px solid',
  borderRight: '1px solid',
  borderBottom: '1px solid',
  borderLeft: '7px solid',
  display: 'flex',
  alignItems: 'center',
  color: PRIMARY_TEXT_COLOR,
  borderRightColor: SECONDARY_TEXT_COLOR_LIGHT,
  borderTopColor: SECONDARY_TEXT_COLOR_LIGHT,
  borderBottomColor: SECONDARY_TEXT_COLOR_LIGHT,
  boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  //   '&.MuiPaper-elevation6': {

  //   },
};

export const alertBoxStylesStandard = {
  ...commonAlertBox,
  borderLeftColor: COLOR_RED,
  '& .MuiAlert-icon, & .MuiSvgIcon-root': {
    color: COLOR_RED,
    fill: COLOR_RED,
  },
};

export const alertBoxStylesSuccess = {
  ...commonAlertBox,
  borderLeftColor: COLOR_GREEN,
  '& .MuiAlert-icon, & .MuiSvgIcon-root': {
    color: COLOR_GREEN,
    fill: COLOR_GREEN,
  },
};

export const alertBoxStylesWarning = {
  ...commonAlertBox,
  borderLeftColor: SECONDARY_MAIN_COLOR_LIGHT,
  '& .MuiAlert-icon, & .MuiSvgIcon-root': {
    color: SECONDARY_MAIN_COLOR_LIGHT,
    fill: SECONDARY_MAIN_COLOR_LIGHT,
  },
};

export const alertBoxStylesInfo = {
  ...commonAlertBox,
  borderLeftColor: PRIMARY_MAIN_COLOR,
  '& .MuiAlert-icon, & .MuiSvgIcon-root': {
    color: PRIMARY_MAIN_COLOR,
    fill: PRIMARY_MAIN_COLOR,
  },
};
