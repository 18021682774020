import { InstructionState, ActionType } from './types';
import { instructionConstants } from './constants';

// INITIAL STATE
const initialState: InstructionState = {
  currentInstruction: null,
  problem: null,
  loading: '',
  success: '',
};

/**
 * Reducer
 * Updated den STORE je nach ACTION type
 */
export const openInstructionReducer = (state = initialState, action: ActionType): InstructionState => {
  switch (action.type) {
    case instructionConstants.INSTRUCTIONS_LOADING:
      return {
        ...state,
        loading: action.payload,
        problem: action.payload ? null : state.problem,
        success: action.payload ? '' : state.success,
      };

    case instructionConstants.INSTRUCTIONS_ERRORED:
      return {
        ...state,
        problem: action.payload,
      };

    case instructionConstants.INSTRUCTIONS_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };

    case instructionConstants.INSTRUCTIONS_SET:
      return {
        ...state,
        currentInstruction: action.payload,
      };

    case instructionConstants.INSTRUCTIONS_INIT:
      return {
        ...initialState,
      };

    case 'dSimple/RESET_APP':
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
