import React from 'react';
import { connect, getIn, FormikProps } from 'formik';
import { Alert } from '@material-ui/lab';
import { Box } from '@material-ui/core';

// This component renders an error message if a field has
// an error and it's already been touched.
const EnhancedErrorBoxOnInvalidField = <T extends unknown>(props: {
  fieldNames: string[];
  message: string;
  formik: FormikProps<T>;
}): React.ReactElement | null => {
  // All FormikProps available on props.formik via HOC wrapper
  const { fieldNames, formik, message } = props;
  const hasError = fieldNames.some((value) => !!getIn(props.formik.errors, value));
  return hasError && formik.submitCount > 0 ? (
    <Box pt={2}>
      <Alert severity="error">{message}</Alert>
    </Box>
  ) : null;
};

export const ErrorBoxOnInvalidField = connect<{ message: string; fieldNames: string[] }>(
  EnhancedErrorBoxOnInvalidField,
);
