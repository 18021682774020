import { createMuiTheme, responsiveFontSizes, Theme } from '@material-ui/core/styles';
import * as locales from '@material-ui/core/locale';
import { typography, palette, overrides, props } from './themeParts';

type SupportedLocales = keyof typeof locales;

export const createTheme = (locale: SupportedLocales): Theme => {
  let themeDefinition = createMuiTheme(
    {
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1440,
        },
      },
      typography,
      palette,
      overrides,
      props,
    },
    locales[locale],
  );
  themeDefinition = responsiveFontSizes(themeDefinition);
  return themeDefinition;
};
