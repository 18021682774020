import { useEffect, useLayoutEffect, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RoleStateType, ActionType, Reducer } from './types';
import { roleReducer } from './roleReducer';
import { actionTypes } from './constants';
import { RootState } from '../../store';
import { logReducer } from '../../utils/logReducer';
import { setLoading } from '../../store/role/actions';
import { attemptGetRoles } from '../../store/role/effects';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useRole = () => {
  /**
   * Redux state
   */
  const {
    role: { roles: initialRoles, loading: loadingRoles },
  } = useSelector((state: RootState) => state);

  /**
   * Fetch user Data initially and setLoading
   */

  const reduxDispatch = useDispatch();
  useLayoutEffect(() => {
    reduxDispatch(setLoading('roleGet'));
  }, [reduxDispatch]);

  useEffect(() => {
    reduxDispatch(attemptGetRoles());
  }, [reduxDispatch]);

  /**
   * userReducer
   */
  const [state, dispatch] = useReducer(
    process.env.NODE_ENV === 'production' ? roleReducer : logReducer<Reducer, RoleStateType, ActionType>(roleReducer),
    {
      roles: initialRoles,
    },
  );

  // update state if props change
  useEffect(() => {
    dispatch({
      type: actionTypes.SET_ALL,
      payload: initialRoles,
    });
  }, [initialRoles, dispatch]);

  /**
   * generate return context
   */
  return {
    // role reducer state
    ...state,

    // role dispatch function
    dispatch,

    // redux state
    loadingRoles,
  };
};
