import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { SECONDARY_MAIN_COLOR } from '../../../../lib/theme/constants';

export const useCategoryPickerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 216,
      flexGrow: 1,
      maxWidth: 400,
    },
    container: {
      '&:hover $action': {
        display: 'block',
      },
    },
    containerEditMode: {
      '& .MuiInputBase-root input': {
        height: '12px',
      },
      '& .MuiButtonBase-root.MuiIconButton-edgeEnd': {
        padding: 0,
      },
      '& .MuiButtonBase-root.MuiIconButton-edgeStart': {
        padding: 0,
        marginLeft: 0,
        marginRight: '4px',
      },
      '& .MuiIconButton-label svg': {
        fontSize: '16px',
      },
    },
    containerDisabledActiveButton: {
      '&:hover $activeListItem': {
        '&:after': {
          display: 'none',
        },
      },
    },
    action: {
      right: '2px',
      display: 'none',
    },
    category: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      cursor: 'pointer',
      paddingRight: '20px',
    },
    activeListItem: {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      position: 'relative',
      '&:after': {
        content: '""',
        width: '10px',
        height: '10px',
        background: SECONDARY_MAIN_COLOR,
        position: 'absolute',
        borderRadius: '100%',
        top: '50%',
        transform: 'translateY(-50%)',
        right: '0',
      },
    },
    activeListItemText: {
      fontWeight: 500,
    },
    listItemText: {
      paddingRight: theme.spacing(1.5),
    },
    ellipsis: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },

    editIconButton: {
      '& .MuiSvgIcon-root': {
        fontSize: '16px',
      },
    },
  }),
);
