import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { IconButton, Box, Theme, CircularProgress } from '@material-ui/core';
import { Form, Formik, FormikErrors } from 'formik';
import { AddCircleOutlineRounded } from '@material-ui/icons';
import { SingleLineFormProps, SingleLineFormValueType } from './types';
import { InputField } from '../../../molecules/form/elements';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    submitButton: {
      color: ({ color }: { color?: 'success' | 'primary' }) => theme.palette[color || 'success'].main,
    },
    input: {
      '& .MuiFormHelperText-contained': {
        position: 'absolute',
        bottom: '-20px',
      },
    },
  }),
);

export const SingleFieldForm = ({
  fieldName,
  fieldLabel,
  disabled,
  loading,
  icon,
  handleSubmit,
  resetOnSubmit = true,
  isRequired = true,
  iconColor,
  validateOnBlur,
  normalize,
}: SingleLineFormProps): ReactElement => {
  const classes = useStyles({ color: iconColor });
  const { t } = useTranslation(['init', 'module-core']);

  return (
    <Formik
      initialValues={{
        [fieldName]: '',
      }}
      onSubmit={(values, { resetForm }): void => {
        handleSubmit(values[fieldName]);
        if (resetOnSubmit) {
          resetForm();
        }
      }}
      validateOnBlur={validateOnBlur}
      validate={(values: SingleLineFormValueType): FormikErrors<SingleLineFormValueType> => {
        const errors: FormikErrors<SingleLineFormValueType> = {};

        if (isRequired && !values[fieldName]) {
          errors[fieldName] = t('init:shared.forms.errors.required');
        }
        return errors;
      }}
    >
      {(): ReactElement => (
        <Box display="flex" flex="1" alignItems="flex-end">
          <Form style={{ flex: 1 }}>
            <Box display="flex">
              <Box flex="1">
                <InputField normalize={normalize} required denseError name={fieldName} label={fieldLabel} />
              </Box>
              <Box flex="0">
                <IconButton
                  disabled={disabled || !!loading}
                  className={classes.submitButton}
                  aria-label={`adds a new ${fieldName}`}
                  type="submit"
                >
                  {loading ? <CircularProgress size="24px" /> : icon || <AddCircleOutlineRounded />}
                </IconButton>
              </Box>
            </Box>
          </Form>
        </Box>
      )}
    </Formik>
  );
};

SingleFieldForm.propTypes = {
  fieldLabel: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  icon: PropTypes.element,
};

SingleFieldForm.defaultProps = {
  disabled: false,
  icon: undefined,
};
