export enum employeeTypeConstants {
  EMPLOYEE_TYPE_SET_ALL = 'dSimple/employeeType/SET_ALL',
  EMPLOYEE_TYPE_LOADING = 'dSimple/employeeType/LOADING',
  EMPLOYEE_TYPE_ERRORED = 'dSimple/employeeType/ERRORED',
  EMPLOYEE_TYPE_SUCCESS = 'dSimple/employeeType/SUCCESS',
  EMPLOYEE_TYPE_ADD = 'dSimple/employeeType/ADD',
  EMPLOYEE_TYPE_REPLACE = 'dSimple/employeeType/REPLACE',
  EMPLOYEE_TYPE_DELETE = 'dSimple/employeeType/DELETE',
  EMPLOYEE_TYPE_INIT = 'dSimple/employeeType/INIT',
}
