import { LocationStateType, ActionType } from './types';
import { locationConstants } from './constants';
import {
  addDepartmentToLocationHelper,
  replaceDepartmentInLocationHelper,
  addAreaToLocationHelper,
  replaceAreaInLocationHelper,
} from './utils';

// INITIAL STATE
const initialState: LocationStateType = {
  locations: [],
  problem: null,
  loading: '',
  success: '',
};

/**
 * Reducer
 * Updated den STORE je nach ACTION type - Mitarbeiter der Auftrag ausfuehrt
 */
export const locationReducer = (state = initialState, action: ActionType): LocationStateType => {
  switch (action.type) {
    case locationConstants.LOCATION_SET_ALL:
      return {
        ...state,
        locations: action.payload,
      };

    case locationConstants.LOCATION_LOADING:
      return {
        ...state,
        loading: action.payload,
        problem: action.payload ? null : state.problem,
        success: action.payload ? '' : state.success,
      };

    case locationConstants.LOCATION_ERRORED:
      return {
        ...state,
        problem: action.payload,
      };

    case locationConstants.LOCATION_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };

    case locationConstants.LOCATION_ADD:
      return {
        ...state,
        locations: [...state.locations, { ...action.payload, departments: [] }],
      };

    case locationConstants.LOCATION_REPLACE:
      return {
        ...state,
        locations: state.locations.map((location) =>
          location._id === action.payload._id ? { ...action.payload, departments: location.departments } : location,
        ),
      };

    case locationConstants.LOCATION_DELETE:
      return {
        ...state,
        locations: state.locations.filter((location) => location._id !== action.payload),
      };

    case locationConstants.LOCATION_ADD_DEPARTMENT:
      return {
        ...state,
        locations: addDepartmentToLocationHelper(state.locations, action.payload.locationId, action.payload.data),
      };

    case locationConstants.LOCATION_REPLACE_DEPARTMENT:
      return {
        ...state,
        locations: replaceDepartmentInLocationHelper(state.locations, action.payload.locationId, action.payload.data),
      };

    case locationConstants.LOCATION_DELETE_DEPARTMENT:
      return {
        ...state,
        locations: state.locations.map((location) => ({
          ...location,
          departments: location.departments.filter((department) => department._id !== action.payload),
        })),
      };

    case locationConstants.LOCATION_ADD_AREA:
      return {
        ...state,
        locations: addAreaToLocationHelper(state.locations, action.payload.departmentId, action.payload.data),
      };

    case locationConstants.LOCATION_REPLACE_AREA:
      return {
        ...state,
        locations: replaceAreaInLocationHelper(state.locations, action.payload.departmentId, action.payload.data),
      };

    case locationConstants.LOCATION_DELETE_AREA:
      return {
        ...state,
        locations: state.locations.map((location) => ({
          ...location,
          departments: location.departments.map((department) => ({
            ...department,
            areas: department.areas.filter((area) => area._id !== action.payload),
          })),
        })),
      };

    case locationConstants.LOCATION_INIT:
      return {
        ...initialState,
      };

    case 'dSimple/RESET_APP':
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
