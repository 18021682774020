import { Dispatch } from 'redux';
import { thunkify } from '../../httpClient/thunkify';
import { loadingAccount, erroredAccount } from './actions';

/**
 * Thunks
 *
 * The return value of the inner function should be a promise. The dispatch function
 * returns the value of the function from within it. This allows us to chain dispatch functions.
 */
export const thunk = thunkify({
  end: (dispatch: Dispatch) => dispatch(loadingAccount('')),
  error: (e, dispatch: Dispatch) => dispatch(erroredAccount(e)),
});

export const thunkNoLoading = thunkify({
  error: (e, dispatch: Dispatch) => dispatch(erroredAccount(e)),
});
