import React, { ReactElement } from 'react';
import { PersonRounded, CheckCircleRounded } from '@material-ui/icons';
import { Typography, Paper, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useUserCardStyles } from '../styles';
import { UserCardProps } from '../types';
import { EditIconButton } from '../../../../components/molecules/button';

export const UserCard = (props: UserCardProps): ReactElement => {
  const { user, handleSelectUser, handleEditUser, isSelected, isEditing } = props;
  const classes = useUserCardStyles();
  const { t } = useTranslation(['module-core']);

  return (
    <Box className={classes.cardRoot}>
      <Paper
        onClick={() => handleSelectUser(user)}
        variant="outlined"
        className={clsx(classes.root, isEditing && classes.activeItem)}
        elevation={5}
      >
        {isSelected && (
          <div className={classes.userCardSelected}>
            <CheckCircleRounded color="inherit" />
            <div className={classes.verticalDivider} />
          </div>
        )}
        <div className={clsx(classes.userCardContent, isSelected && classes.userCardContentShrink)}>
          <div className={classes.header}>
            <PersonRounded fontSize="large" />
            <div className={classes.headerInfo}>
              <Typography variant="body2" className={classes.headerInfoContent}>
                {user.email}
              </Typography>
              <Typography variant="body2" className={classes.headerInfoContent}>
                {user.personnelNumber}
              </Typography>
            </div>
            <div className={classes.editAction}>
              <EditIconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleEditUser();
                }}
              />
            </div>
          </div>
          <div className={classes.content}>
            {(user.firstName || user.lastName) && (
              <div className={classes.contentRow}>
                <Typography variant="subtitle1">{t('module-core:pages.userManagement.tableHeadings.name')}</Typography>
                <Typography variant="body2">{`${user.firstName || ''} ${user.lastName || ''}`}</Typography>
              </div>
            )}
          </div>
        </div>
      </Paper>
    </Box>
  );
};
