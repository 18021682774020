import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import { Field, FieldProps } from 'formik';
import clsx from 'clsx';

import { Box } from '@material-ui/core';
import { RichInputFieldProps } from './types';
import { useRichInputFieldStyles } from './styles';

import { Toolbar } from './components/Toolbar';
import 'react-quill/dist/quill.snow.css';

export const RichInputField = (props: RichInputFieldProps): ReactElement => {
  const { name } = props;
  const classes = useRichInputFieldStyles();

  return (
    <Field name={name}>
      {({ field, form }: FieldProps): ReactElement => {
        return (
          <Box display="flex" flexDirection="column" flex="1" height="100%">
            <Toolbar />
            <ReactQuill
              id={name}
              className={clsx(classes.root)}
              theme="snow"
              value={field.value}
              onChange={(value) => {
                form.setFieldValue(name, value);
              }}
              modules={RichInputField.modules}
              formats={RichInputField.formats}
            />
          </Box>
        );
      }}
    </Field>
  );
};

RichInputField.modules = {
  toolbar: {
    container: '#toolbar',
  },
  clipboard: {
    matchVisual: false,
  },
};

RichInputField.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
];

RichInputField.propTypes = {
  name: PropTypes.string.isRequired,
};
