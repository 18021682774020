// Choose enum over object here to make sure we cannot overwrite the properties
// CORE
export enum NonAuthRoutes {
  unauthorized = '/unauthorized',
  login = '/login',
  forgot = '/forgot',
  reset = '/reset',
  register = '/register',
  workspace = '/workspace',
}

export enum AuthRoutes {
  dashboard = '/umo/dashboard',
  companyStructure = '/core/company-structure',
  userManagement = '/core/user-management',
  roleManagement = '/core/role-management',
  systemSettings = '/core/system-settings',
  billing = '/core/billing',
}
