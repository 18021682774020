import React, { ReactElement } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AddCircleOutlineRounded } from '@material-ui/icons';
import { useAddUserCardStyles } from '../styles';
import { AddUserCardProps } from '../types';

export const AddUserCard = (props: AddUserCardProps): ReactElement => {
  const { toggleForm, open } = props;
  const classes = useAddUserCardStyles({ open });
  const { t } = useTranslation(['module-umo']);

  return (
    <Box className={classes.root} onClick={toggleForm}>
      <AddCircleOutlineRounded />

      <Typography className={classes.text} variant="subtitle1">
        {t('module-umo:shared.wizard.addEmployee')}
      </Typography>
    </Box>
  );
};
