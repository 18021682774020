import React, { ReactElement } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { DeleteRounded } from '@material-ui/icons';
import { useRemoveButtonStyles } from './styles';
import { RemoveButtonProps } from './types';

export const RemoveButton = (props: RemoveButtonProps): ReactElement => {
  const classes = useRemoveButtonStyles();
  const { loading, disabled, endIcon, children, ...other } = props;

  return (
    <Button
      className={classes.buttonRoot}
      disabled={disabled || loading}
      endIcon={endIcon || (loading ? <CircularProgress size="24px" /> : <DeleteRounded />)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {children || ''}
    </Button>
  );
};
