import { makeStyles, createStyles } from '@material-ui/core/styles';

export const usePixelImageStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '120px',
      position: 'absolute',
      bottom: '0',
      right: '0',
    },
    small: {
      width: '40px',
    },
    flip: {
      left: 0,
      right: 'initial',
      transform: 'scaleX(-1)',
    },
  }),
);
