import { Dispatch } from 'redux';
import { thunk } from './thunks';
import {
  apiCreateInstructionType,
  apiDeleteInstructionType,
  apiGetInstructionType,
  apiUpdateInstructionType,
} from './services';

import {
  setLoading,
  addInstructionType,
  deleteInstructionType,
  replaceInstructionType,
  setAllInstructionType,
} from './actions';

import { UpdateInstructionType } from './types';
import { GetStateType } from '../../../../lib/store/types';
import { enqueueSnackbar } from '../../../../lib/store/notifier/actions';

export const attemptGetInstructionTypes = (
  instructionTypeName?: string,
  hideExternal?: boolean,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    let query: Record<string, Record<string, unknown>> | undefined = instructionTypeName
      ? { filter: { instructionTypeName: { $regex: `.*${instructionTypeName}.*` } } }
      : undefined;

    if (hideExternal && query) {
      Object.assign(query, {
        filter: { ...query.filter, $and: [{ category: { $ne: 'visitor' } }, { category: { $ne: 'external' } }] },
      });
    } else if (hideExternal) {
      query = { filter: { $and: [{ category: { $ne: 'visitor' } }, { category: { $ne: 'external' } }] } };
    }

    const instructionType = await apiGetInstructionType(query ? { filter: JSON.stringify(query.filter) } : undefined);
    dispatch(setAllInstructionType(instructionType));
  });

export const attemptCreateInstructionType = (
  instructionTypeName: string,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('instructionTypeAdd'));
    const instructionType = await apiCreateInstructionType({ instructionTypeName });

    dispatch(addInstructionType(instructionType));
    dispatch(
      enqueueSnackbar({
        translationString: 'module-umo:shared.instructionType.actions.addSuccess',
        messageTranslationKeys: { instructionTypeName: instructionType.instructionTypeName },
        options: {
          variant: 'success',
        },
      }),
    );
  });

export const attemptUpdateInstructionType = (
  body: UpdateInstructionType,
  oldInstructionTypeName: string,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('instructionTypeEdit'));
    const updatedInstructionType = await apiUpdateInstructionType(body);
    dispatch(replaceInstructionType(updatedInstructionType));
    dispatch(
      enqueueSnackbar({
        translationString: 'module-umo:shared.instructionType.actions.updateSuccess',
        messageTranslationKeys: {
          oldInstructionTypeName,
          instructionTypeName: updatedInstructionType.instructionTypeName,
        },
        options: {
          variant: 'success',
        },
      }),
    );
  });

export const attemptDeleteInstructionType = (
  _id: string,
  instructionTypeName: string,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('instructionTypeDelete'));
    await apiDeleteInstructionType(_id);
    dispatch(deleteInstructionType(_id));
    dispatch(
      enqueueSnackbar({
        translationString: 'module-umo:shared.instructionType.actions.deleteSuccess',
        messageTranslationKeys: { instructionTypeName },
        options: {
          variant: 'success',
        },
      }),
    );
  });
