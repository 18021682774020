import { GraphQLClient, gql } from 'graphql-request';
import { config } from '../../config';
import { WikiRequestType } from './types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const gqlClient = (query: string) => {
  const endpoint = `${config.wikiUrl}/graphql`;
  const graphQLClient = new GraphQLClient(endpoint, {
    headers: {
      authorization: `Bearer ${config.wikiAPIKey}`,
    },
  });

  const queryString = gql`
    ${query}
  `;
  return graphQLClient.request<WikiRequestType>(queryString);
};
