import { useSelector } from 'react-redux';
import { RootState } from '../store';

export const useCheckPlan = (
  planToCheck: string | string[],
): {
  _plan: string;
  hasPlan: boolean;
  isBetaPlan: boolean;
  _inclusiveUsers: number | undefined;
  _inclusiveInstructions: number | undefined;
} => {
  const { current: organisation } = useSelector((state: RootState) => state.organisation);
  const { billing } = organisation || {};
  const { _plan = '', _inclusiveUsers, _inclusiveInstructions } = billing || {};

  const isBetaPlan = _plan.split('-')[0] === 'beta';

  if (typeof planToCheck === 'string') {
    return { hasPlan: planToCheck === _plan, _plan, isBetaPlan, _inclusiveUsers, _inclusiveInstructions };
  }

  return { hasPlan: planToCheck.includes(_plan), _plan, isBetaPlan, _inclusiveUsers, _inclusiveInstructions };
};
