import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { ResetFormHelperProps } from './types';

export const ResetFormHelper: React.FC<ResetFormHelperProps> = (props: ResetFormHelperProps): null => {
  const { reset, additionalAction = (): void => undefined } = props;
  const { resetForm } = useFormikContext();

  useEffect(() => {
    if (reset) {
      additionalAction();
      resetForm();
    }
  }, [reset, additionalAction, resetForm]);
  return null;
};

ResetFormHelper.propTypes = {
  reset: PropTypes.bool.isRequired,
  additionalAction: PropTypes.func,
};

ResetFormHelper.defaultProps = {
  additionalAction: (): void => undefined,
};
