import { InstructionState, ActionType } from './types';
import { instructionConstants } from './constants';

// INITIAL STATE
const initialState: InstructionState = {
  currentInstruction: null,
  myInstructionList: [],
  problem: null,
  loading: '',
  success: '',
};

/**
 * Reducer
 * Updated den STORE je nach ACTION type - Mitarbeiter der Auftrag ausfuehrt
 */
export const myInstructionReducer = (state = initialState, action: ActionType): InstructionState => {
  switch (action.type) {
    case instructionConstants.INSTRUCTIONS_LOADING:
      return {
        ...state,
        loading: action.payload,
        problem: action.payload ? null : state.problem,
        success: action.payload ? '' : state.success,
      };

    case instructionConstants.INSTRUCTIONS_ERRORED:
      return {
        ...state,
        problem: action.payload,
      };

    case instructionConstants.INSTRUCTIONS_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };

    case instructionConstants.INSTRUCTIONS_REPLACE:
      return {
        ...state,
        myInstructionList: state.myInstructionList.map((myInstruction) => {
          return action.payload.instructionId === myInstruction._id
            ? { ...myInstruction, nextDue: action.payload.nextDue, lastPassed: action.payload.lastPassed }
            : myInstruction;
        }),
        currentInstruction: state.currentInstruction
          ? { ...state.currentInstruction, nextDue: action.payload.nextDue, lastPassed: action.payload.lastPassed }
          : state.currentInstruction,
      };

    case instructionConstants.INSTRUCTIONS_SET:
      return {
        ...state,
        currentInstruction: action.payload,
      };

    case instructionConstants.INSTRUCTIONS_SET_ALL:
      return {
        ...state,
        myInstructionList: action.payload,
      };

    case instructionConstants.INSTRUCTIONS_INIT:
      return {
        ...initialState,
      };

    case 'dSimple/RESET_APP':
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
