import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldProps } from 'formik';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { DateFieldProps } from './types';
import { DATE_FORMAT } from '../../../../../lib/constants/dateFormat';
import { useInputFieldStyles } from '../InputField/styles';
import { useDateFieldStyles } from './styles';

export const DateField = ({ name, label, disabled, required, onBlur, onFocus }: DateFieldProps): ReactElement => {
  const { t } = useTranslation();
  const inputFieldClasses = useInputFieldStyles();
  const classes = useDateFieldStyles();

  return (
    <Field name={name}>
      {({ field, meta, form }: FieldProps): ReactElement => {
        return (
          <KeyboardDatePicker
            id={name}
            name={name}
            disabled={disabled}
            className={clsx(classes.buttonAlignment, inputFieldClasses.root)}
            variant="dialog"
            error={!!(meta.touched && meta.error)}
            helperText={(meta.touched && meta.error) || ''}
            format={DATE_FORMAT}
            fullWidth
            label={label}
            value={field.value || null}
            onChange={(_date, val): void => {
              form.setFieldValue(field.name, val, true);
            }}
            inputValue={field.value}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            InputAdornmentProps={{}}
            invalidDateMessage={t('forms.elements.dateField.invalid')}
            maxDateMessage={t('forms.elements.dateField.maxDateInvalid')}
            minDateMessage={t('forms.elements.dateField.minDateInvalid')}
            cancelLabel={t('forms.elements.dateField.cancel')}
            required={required}
            onBlur={(e) => {
              if (onBlur) {
                onBlur(e);
              }

              field.onBlur(e);
            }}
            onFocus={onFocus || undefined}
          />
        );
      }}
    </Field>
  );
};

DateField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
