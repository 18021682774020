import { Dispatch } from 'redux';
import { thunk } from './thunks';
import { apiCreateFileCategory, apiDeleteFileCategory, apiGetFileCategories, apiUpdateFileCategory } from './services';
import { setLoading, addFileCategory, deleteFileCategory, replaceFileCategory, setAllFileCategories } from './actions';
import { GetStateType } from '..';
import { UpdateFileCategory } from './types';
import { enqueueSnackbar } from '../notifier/actions';

/**
 * FileCategories
 */
export const attemptGetFileCategories = (): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('fileCategoryGet'));
    const fileCategories = await apiGetFileCategories();

    dispatch(setAllFileCategories(fileCategories));
  });

export const attemptCreateFileCategory = (
  name: string,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('fileCategoryAdd'));
    const fileCategory = await apiCreateFileCategory({ name });

    dispatch(addFileCategory(fileCategory));
    dispatch(
      enqueueSnackbar({
        translationString: 'module-core:shared.fileCategories.actions.addSuccess',
        messageTranslationKeys: { name: fileCategory.name },
        options: {
          variant: 'success',
        },
      }),
    );
  });

export const attemptUpdateFileCategory = (
  body: UpdateFileCategory,
  oldName: string,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('fileCategoryEdit'));
    const updatedFileCategory = await apiUpdateFileCategory(body);
    dispatch(replaceFileCategory(updatedFileCategory));
    dispatch(
      enqueueSnackbar({
        translationString: 'module-core:shared.fileCategories.actions.updateSuccess',
        messageTranslationKeys: { oldName, name: updatedFileCategory.name },
        options: {
          variant: 'success',
        },
      }),
    );
  });

export const attemptDeleteFileCategory = (
  _id: string,
  name: string,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('fileCategoryDelete'));
    await apiDeleteFileCategory(_id);
    dispatch(deleteFileCategory(_id));
    dispatch(
      enqueueSnackbar({
        translationString: 'module-core:shared.fileCategories.actions.deleteSuccess',
        messageTranslationKeys: { name },
        options: {
          variant: 'success',
        },
      }),
    );
  });
