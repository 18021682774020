import React, { ReactElement } from 'react';
import { Box, Typography, Card, CardContent, CardActions } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { FileStatic } from '../../../../lib/store/file/types';

import { useFileCardStyles } from '../../styles';
import { PrimaryButton } from '../../../../components/molecules/button';

import { getStaticFileUrl } from '../../utils';

export type FileStaticCardProps = {
  fileName: FileStatic['fileName'];
  location: FileStatic['location'];
  handleSelect?: () => void;
};

export const FileStaticCard = (props: FileStaticCardProps): ReactElement => {
  const { fileName, location, handleSelect } = props;

  const classes = useFileCardStyles({});

  const { t } = useTranslation();

  const src = getStaticFileUrl({ fileName, location });

  return (
    <Card className={classes.card}>
      <Box className={classes.cardMediaWrap}>
        <img className={classes.media} alt={`shows with name: ${fileName}`} src={src} title={fileName} />
      </Box>

      <CardContent>
        <Box>
          <Typography className={classes.ellipsis} title={fileName} variant="h5">
            {fileName}
          </Typography>
        </Box>
      </CardContent>

      <CardActions>
        {handleSelect && (
          <Box flex="1" display="flex" justifyContent="flex-end">
            <PrimaryButton variant="text" size="small" endIcon={null} onClick={handleSelect}>
              {t('module-core:shared.common.select')}
            </PrimaryButton>
          </Box>
        )}
      </CardActions>
    </Card>
  );
};
