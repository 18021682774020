/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { ActionType } from './types';
import { useLocation } from './useLocation';

/**
 * state context
 */

export const LocationStateContext = React.createContext<Omit<ReturnType<typeof useLocation>, 'dispatch'>>(
  undefined as any,
);
export const LocationStateProvider = LocationStateContext.Provider;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useLocationStateContext = () => {
  const locationState = React.useContext(LocationStateContext);
  // eslint-disable-next-line no-console
  if (!locationState) console.error('Missuse of useLocationStateContext. No Context Provider detected');
  return locationState;
};

/**
 * dispatch context
 */
export const LocationDispatchContext = React.createContext<React.Dispatch<ActionType>>(undefined as any);
export const LocationDispatchProvider = LocationDispatchContext.Provider;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useLocationDispatchContext = () => {
  const locationDispatch = React.useContext(LocationDispatchContext);
  if (!locationDispatch)
    // eslint-disable-next-line no-console
    console.error('Missuse of useInstructionEditorDispatchContext. No Context Provider detected');
  return locationDispatch;
};
