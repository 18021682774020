import React, { ReactElement } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { InfoBoxProps } from './types';
import { useInstructionViewerStyles } from '../../styles';

export const InfoBox = (props: InfoBoxProps): ReactElement => {
  const { t } = useTranslation();
  const { instructionName, instructionType, createdBy, activeSlideIndex, totalSlides, children, isWizardMode } = props;
  const classes = useInstructionViewerStyles({ isWizardMode });
  return (
    <Box className={classes.infoBoxWrap}>
      <Box display="flex" justifyContent="flex-end" width="100%" my={0.5}>
        <Typography variant="subtitle2">
          {`${t('shared.instructionViewer.infoBox.page')} ${activeSlideIndex + 1} / ${totalSlides}`}
        </Typography>
      </Box>

      <Box py={isWizardMode ? 1 : 3} className={classes.infoBox}>
        <Box display="flex" className={classes.infoBoxInner}>
          <Box
            className={classes.contentInformation}
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="space-between"
            alignItems={isWizardMode ? 'center' : undefined}
            flex="1"
          >
            <Box mb={isWizardMode ? 0 : 2} flex="1 0 100%">
              <Typography variant="h4">{instructionName}</Typography>{' '}
            </Box>
            {instructionType && (
              <Box mb={1} display="flex">
                <Typography variant="subtitle1">
                  {t('shared.instructionViewer.infoBox.type')}{' '}
                  <Typography component="span" variant="subtitle2">
                    {instructionType}
                  </Typography>
                </Typography>
              </Box>
            )}
            {!!createdBy.trim() && (
              <Box mb={1} display="flex">
                <Typography variant="subtitle1">
                  {t('shared.instructionViewer.infoBox.createdBy')}{' '}
                  <Typography component="span" variant="subtitle2">
                    {createdBy}
                  </Typography>
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            className={classes.contentChildren}
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            alignItems="center"
          >
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
