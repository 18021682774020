export interface ErrorDetail {
  msg: string;
  param: string;
}

export interface ErrorValidation {
  path: string;
  message: string;
  errorCode?: string;
}

export class APIError extends Error {
  tokenExpired?: boolean;

  details?: ErrorDetail[];

  errors?: ErrorValidation[];

  constructor(
    payload: string | { message: string; details?: ErrorDetail[]; errors?: ErrorValidation[]; tokenExpired?: boolean },
  ) {
    super(typeof payload === 'string' ? payload : payload.message);
    if (typeof payload !== 'string') {
      this.details = payload.details;
      this.errors = payload.errors;
      if (payload.tokenExpired) this.tokenExpired = payload.tokenExpired;
    }
  }
}
