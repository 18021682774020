import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { Field, FieldProps } from 'formik';
import clsx from 'clsx';
import { InputFieldProps } from './types';
import { useInputFieldStyles } from './styles';

export const InputField = (props: InputFieldProps): ReactElement => {
  const {
    name,
    label,
    type,
    variant,
    disabled,
    autoComplete,
    autoFocus,
    normalize,
    denseError,
    InputProps,
    required,
    onBlur,
    onFocus,
    className,
  } = props;
  const classes = useInputFieldStyles();

  const checkStopPropagation = (event: React.MouseEvent): void => {
    // if (stopPropagation) event.stopPropagation();
    event.stopPropagation();
  };

  const handleChange = (
    value: string,
    setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void,
  ): void => {
    const normalizedValue = normalize ? normalize(value) : value;
    setFieldValue(name, normalizedValue, true);
  };

  return (
    <Field name={name}>
      {({ field, meta, form }: FieldProps): ReactElement => {
        return (
          <TextField
            className={clsx(classes.root, denseError && classes.denseError, className)}
            id={name}
            name={name}
            type={type}
            error={!!(meta.touched && meta.error)}
            helperText={(meta.touched && meta.error) || ''}
            onBlur={(e) => {
              if (onBlur) {
                onBlur(e);
              }

              field.onBlur(e);
            }}
            onFocus={onFocus || undefined}
            onChange={(event): void => handleChange(event.target.value, form.setFieldValue)}
            onClick={checkStopPropagation}
            value={field.value}
            fullWidth
            disabled={disabled}
            autoComplete={autoComplete}
            autoFocus={!!autoFocus}
            variant={variant}
            label={variant === 'standard' ? undefined : label}
            placeholder={variant === 'standard' ? label : undefined}
            size={variant === 'standard' ? 'small' : 'medium'}
            required={required}
            inputProps={{
              ...(variant === 'standard' && { 'aria-label': 'description' }),
              ...(autoComplete === 'off' && {
                autocomplete: 'new-password',
                form: {
                  autocomplete: 'off',
                },
              }),
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={InputProps}
          />
        );
      }}
    </Field>
  );
};

InputField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  autoComplete: PropTypes.string,
  type: PropTypes.oneOf(['text', 'email', 'password']),
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  denseError: PropTypes.bool,
  required: PropTypes.bool,
};

InputField.defaultProps = {
  type: 'text',
  disabled: false,
  autoFocus: false,
  autoComplete: undefined,
  variant: 'outlined',
  denseError: false,
  required: false,
};
