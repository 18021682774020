import { UserFormValues } from '../../../../lib/context/user/types';

export const initialValues: UserFormValues = {
  firstName: '',
  lastName: '',
  gender: '',
  dateOfBirth: null,
  email: '',
  password: '',
  personnelNumber: '',
  rfidNumber: '',
  groups: [],
  employeeTypeName: '',
  role: null,
  location: null,
  department: null,
  area: null,
};
