import {
  montserratBlack,
  montserratExtraBold,
  montserratBold,
  montserratSemiBold,
  montserratMedium,
  montserratRegular,
  montserratLight,
  montserratExtraLight,
  montserratThin,
  montserratLightItalic,
  montserratMediumItalic,
} from '../../../fonts';
import { COLOR_WHITE } from '../../../constants';

export const muiCssBaseline = {
  '@global': {
    '@font-face': [
      montserratBlack,
      montserratExtraBold,
      montserratBold,
      montserratSemiBold,
      montserratMedium,
      montserratRegular,
      montserratLight,
      montserratExtraLight,
      montserratThin,
      montserratLightItalic,
      montserratMediumItalic,
    ],
    html: {
      height: '100%',
    },
    body: {
      height: '100%',
      backgroundColor: COLOR_WHITE,
    },
    'body #app': {
      height: '100%',
    },
  },
};
