import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation, RouteComponentProps, Redirect, Route } from 'react-router-dom';
import queryString from 'query-string';
import { getSubdomain, isValidSubdomain } from '../../lib/utils';
import { NonAuthRouteProps } from './types';
import { NonAuthRoutes } from '../../lib/constants/pagePaths';
import { RootState } from '../../lib/store';
import { useCheckHistory } from '../../lib/hooks/useCheckHistory';

export const NonAuthRoute = ({
  Component,
  path,
  exact = false,
  shouldRedirect = true,
}: NonAuthRouteProps): ReactElement => {
  const subdomain = getSubdomain(window.location.hostname);
  const history = useCheckHistory();
  const location = useLocation();
  const { dest } = queryString.parse(location.search);

  const { isAuthenticated, homeUrl } = useSelector((state: RootState) => state.account);

  const organisationSlug = useSelector((state: RootState) => state.organisation.slug);

  return (
    <Route
      exact={exact}
      path={path}
      render={(props: RouteComponentProps): JSX.Element => {
        // If Authenticated, redirect to destination or dashboard
        if (isAuthenticated && shouldRedirect) {
          history.push(dest && typeof dest === 'string' ? dest : homeUrl);
        }

        // If path is register or workspace show it regardless of the subdomain
        // eslint-disable-next-line react/jsx-props-no-spreading
        if (path === NonAuthRoutes.register || path === NonAuthRoutes.workspace) return <Component {...props} />;

        // else, check if no subdomain provided or is app. -> redirect to workspace. If subdomain, render wished component
        return !organisationSlug || !isValidSubdomain(subdomain) ? (
          <Redirect
            to={{
              pathname: NonAuthRoutes.workspace, // show unauthorized route when no Component given
              search: path ? `?dest=${encodeURIComponent(path)}` : '',
            }}
          />
        ) : (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Component {...props} />
        );
      }}
    />
  );
};

NonAuthRoute.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.elementType]).isRequired,
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
};
NonAuthRoute.defaultProps = {
  exact: false,
};
