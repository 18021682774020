import { httpRequest, handleResponse } from '../../../httpClient';
import { RoleNameType, RoleType } from '../types';
import { isObject } from '../../../utils/helpers';

export const apiGetRoles = (): Promise<RoleType[]> =>
  httpRequest
    .get('/core/roles')
    .then((res) => handleResponse<RoleType[]>(res))
    .then((data) => (isObject(data) ? [] : data))
    .catch();

export const apiGetRoleNames = (): Promise<RoleNameType[]> =>
  httpRequest
    .get('/core/roles/meta')
    .then((res) => handleResponse<RoleNameType[]>(res))
    .then((data) => (isObject(data) ? [] : data))
    .catch();

export const apiCreateRole = (body: Omit<RoleType, '_id'>): Promise<RoleType> =>
  httpRequest
    .post('/core/roles', { body })
    .then((res) => handleResponse<RoleType>(res))
    .then((data) => data)
    .catch();

export const apiUpdateRole = (_id: string, body: Partial<RoleType>): Promise<RoleType> =>
  httpRequest
    .patch(`/core/roles/${_id}`, { body })
    .then((res) => handleResponse<RoleType>(res))
    .then((data) => data)
    .catch();

export const apiDeleteRole = (_id: string): Promise<unknown> =>
  httpRequest.delete(`/core/roles/${_id}`).then(handleResponse);
