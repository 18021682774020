import React, { ReactElement } from 'react';
import ReactJoyride, { CallBackProps, ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core';
import { JoyrideProps } from '../types';
import { on, off } from '../../../../../lib/events';

const localesDefault = {
  back: 'Back',
  close: 'Close',
  last: 'Complete',
  next: 'Next',
  open: 'Open the dialog',
  skip: 'Skip',
};

export const Joyride = (props: JoyrideProps): ReactElement => {
  const { steps, handleSkipTutorial, handleCompleteTutorial, joyrideProps = {}, name } = props;
  const { t } = useTranslation(['common']);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const helpersRef = React.useRef<any>(null);

  // call store methods from if event triggered
  const handleInteraction = React.useCallback(
    ({ detail }) => {
      const { tutorialName, action } = detail;
      if (name === tutorialName && typeof action === 'string') {
        if (helpersRef.current && typeof helpersRef.current[action] === 'function') {
          helpersRef.current[action]();
        }
      }
    },
    [name],
  );

  const theme = useTheme();

  // setup event listeners to interact with tutorial from outside
  React.useEffect(() => {
    on('ds_interact:tutorial', handleInteraction);

    return () => {
      off('ds_interact:tutorial', handleInteraction);
    };
  }, [handleInteraction, name]);

  const handleStateChange = (data: CallBackProps) => {
    const { action, type, status } = data;

    if (action === ACTIONS.SKIP && type === EVENTS.TOUR_END && handleSkipTutorial) {
      handleSkipTutorial();
    } else if (handleCompleteTutorial && type === EVENTS.TOUR_END && status === STATUS.FINISHED) {
      handleCompleteTutorial();
    }
  };

  return (
    <ReactJoyride
      debug
      steps={steps.map((step) => {
        const { locale = {} } = step;
        const localesTranslated = {
          back: t(typeof locale.back === 'string' ? locale.back : localesDefault.back),
          close: t(typeof locale.close === 'string' ? locale.close : localesDefault.close),
          last: t(typeof locale.last === 'string' ? locale.last : localesDefault.last),
          next: t(typeof locale.next === 'string' ? locale.next : localesDefault.next),
          open: t(typeof locale.open === 'string' ? locale.open : localesDefault.open),
          skip: t(typeof locale.skip === 'string' ? locale.skip : localesDefault.skip),
        };
        return {
          ...step,
          content: typeof step.content === 'string' ? t(step.content) : step.content,
          title: typeof step.title === 'string' ? t(step.title) : step.title,
          locale: localesTranslated,
        };
      })}
      callback={handleStateChange}
      continuous
      showSkipButton
      disableOverlayClose
      getHelpers={(helpers) => {
        helpersRef.current = helpers;
      }}
      styles={{ options: { primaryColor: theme.palette.secondary.main } }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...joyrideProps}
    />
  );
};
