import { httpRequest, handleResponse } from '../../../httpClient';
import { Group, CreateGroup, UpdateGroup } from '../types';
import { isObject } from '../../../utils/helpers';

/**
 * Locations
 */
export const apiGetGroups = (): Promise<Group[]> =>
  httpRequest
    .get('/core/groups')
    .then((res) => handleResponse<Group[]>(res))
    .then((data) => (isObject(data) ? [] : data))
    .catch();

export const apiCreateGroup = (body: CreateGroup): Promise<Group> =>
  httpRequest
    .post('/core/groups', { body })
    .then((res) => handleResponse<Group>(res))
    .then((data) => data)
    .catch();

export const apiUpdateGroup = (body: UpdateGroup): Promise<Group> =>
  httpRequest
    .patch('/core/groups', { body })
    .then((res) => handleResponse<Group>(res))
    .then((data) => data);

export const apiDeleteGroup = (_id: string): Promise<unknown> =>
  httpRequest.delete(`/core/groups/${_id}`).then(handleResponse);
