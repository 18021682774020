// eslint-disable-next-line import/no-unresolved
import { Overrides } from '@material-ui/core/styles/overrides';
import { muiTextField } from './muiTextField';
import { muiCssBaseline } from './muiCssBaseline';
import { muiAppBar } from './muiAppBar';
import { muiMenuItem } from './muiMenuItem';
import { SECONDARY_MAIN_COLOR_LIGHT } from '../../constants';
import { muiAlert } from './muiAlert';

export const overrides: Overrides = {
  MuiCssBaseline: muiCssBaseline,
  MuiPaper: {
    root: {
      '& .MuiAutocomplete-option[aria-disabled="true"]': {
        opacity: '1',
        backgroundColor: 'rgba(200,200,200,0.1)',
        color: 'rgba(0,0,0,0.3)',
      },
    },
    rounded: {
      borderRadius: '4px',
    },
  },
  MuiLink: {
    root: {
      fontWeight: 700,
      '&:hover': {
        color: SECONDARY_MAIN_COLOR_LIGHT,
      },
    },
  },
  MuiAutocomplete: {
    root: {
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
        padding: '13.5px 14px',
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
        padding: 0,
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        paddingLeft: '13.5px',
      },
    },
  },
  MuiTextField: muiTextField,
  MuiAppBar: muiAppBar,
  MuiButton: {
    textPrimary: {
      '& .MuiButton-iconSizeMedium > *:first-child': {
        fontSize: '1.5rem',
      },
    },
  },
  MuiMenuItem: muiMenuItem,
  MuiAlert: muiAlert,
};
