import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, Grid, Box, TextField, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { KeyboardArrowRight } from '@material-ui/icons';
import { Formik, Form, Field, FieldProps, FormikErrors } from 'formik';
import { ResetFormPropType } from '../../../../pages/Reset/types';
import { FormValuesType } from './types';
import { RootState } from '../../../../lib/store';
import { NonAuthRoutes } from '../../../../lib/constants/pagePaths';
import { ErrorBox } from '../../../molecules/form/errors/ErrorBox';
import { RouterLink } from '../../../molecules/link/RouterLink';
import { PrimaryButton } from '../../../molecules/button';

export const ResetForm: React.FC<ResetFormPropType> = ({ email, accessible, handleSubmit }): ReactElement => {
  const { t } = useTranslation(['init']);
  const { problem, success, loading } = useSelector((state: RootState) => state.account);

  return (
    <Formik
      initialValues={{
        password: '',
      }}
      onSubmit={(values): void => {
        handleSubmit(values.password);
      }}
      validate={(values: FormValuesType): FormikErrors<FormValuesType> => {
        const errors: FormikErrors<FormValuesType> = {};

        if (!values.password) {
          errors.password = t('init:shared.forms.errors.required');
        } else if (!/^(?=.{6,20}$)(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*\W).*/i.test(values.password)) {
          errors.password = t('init:shared.forms.errors.passwordFormat');
        }
        return errors;
      }}
    >
      {(): ReactElement => (
        <Form>
          <Grid container spacing={3}>
            {accessible && (
              <Grid item xs={12}>
                <Typography>
                  {t('init:pages.reset.content.resetForEmail')}
                  <strong>{email}</strong>
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Field name="password" type="password">
                {({ field, meta }: FieldProps): ReactElement => {
                  return (
                    <TextField
                      id="password"
                      name="password"
                      type="password"
                      error={!!(meta.touched && meta.error)}
                      helperText={(meta.touched && meta.error) || ''}
                      onBlur={field.onBlur}
                      label={t('init:pages.reset.forms.labels.newPassword')}
                      onChange={field.onChange}
                      value={field.value}
                      disabled={!accessible}
                      fullWidth
                      required
                    />
                  );
                }}
              </Field>
            </Grid>
            {!accessible && (
              <Grid item xs={12}>
                <Alert severity="error">
                  <Trans
                    i18nKey="init:pages.reset.content.incorrectParams"
                    components={{
                      forgot: <Link component={RouterLink} color="secondary" to={NonAuthRoutes.forgot} />,
                    }}
                  />
                </Alert>
              </Grid>
            )}
            {problem && (problem.message || problem.details) && (
              <Grid item xs={12}>
                <ErrorBox message={problem.message} details={problem.details} />
              </Grid>
            )}
            {success === 'reset' && (
              <Grid item xs={12}>
                <Alert severity="success">
                  <Trans
                    i18nKey="init:pages.reset.content.success"
                    components={{
                      login: <Link component={RouterLink} color="secondary" to={NonAuthRoutes.login} />,
                    }}
                  />
                </Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <PrimaryButton
                  type="submit"
                  loading={loading === 'reset'}
                  disabled={success === 'reset' || !accessible}
                  endIcon={<KeyboardArrowRight />}
                >
                  {t('init:pages.reset.forms.buttons.submit')}
                </PrimaryButton>
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

ResetForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  accessible: PropTypes.bool.isRequired,
};
