import React, { ReactElement } from 'react';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import i18n from '../../../../lib/i18n';
import { InfoBarProps } from './types';

const useStyles = makeStyles((theme) => ({
  isActive: {
    color: theme.palette.primary.main,
    '&:after': {
      content: '""',
      width: '100%',
      height: '4px',
      display: 'block',
      position: 'absolute',
      background: theme.palette.secondary.main,
      borderRadius: theme.shape.borderRadius,
    },
  },
  isNotActive: {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  spacing: {
    marginLeft: '2px',
    marginRight: '2px',
  },
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const InfoBar = (_props: InfoBarProps): ReactElement => {
  const classes = useStyles();

  const changeLanguage = (lng: string): void => {
    i18n.changeLanguage(lng);
  };

  return (
    <Box mt={4} mb={4} display="flex">
      <Box flex="1">
        {/* <Typography color="textSecondary" variant="h5">
          <Link color="secondary" target="_blank" href={infoLink || '#'}>
            INFO
          </Link>
        </Typography> */}
      </Box>
      <Box display="flex" justifyContent="flex-end" flex="1">
        <Typography
          className={i18n.language.includes('de') ? classes.isActive : classes.isNotActive}
          display="inline"
          color="textSecondary"
          align="right"
          variant="h5"
          onClick={(): void => changeLanguage('de')}
        >
          DE
        </Typography>
        <Typography className={classes.spacing} display="inline" color="textSecondary" align="right" variant="h5">
          /
        </Typography>
        <Typography
          className={i18n.language.includes('en') ? classes.isActive : classes.isNotActive}
          display="inline"
          color="textSecondary"
          align="right"
          variant="h5"
          onClick={(): void => changeLanguage('en')}
        >
          EN
        </Typography>
      </Box>
    </Box>
  );
};
