import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useInputFieldStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiOutlinedInput-root': {
        backgroundColor: theme.palette.grey[100],
      },
      '& .MuiOutlinedInput-root.Mui-focused, &.MuiTextField-root:hover .MuiOutlinedInput-root:not(.Mui-disabled)': {
        backgroundColor: theme.palette.common.white,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        backgroundColor: 'initial',
      },
    },
    denseError: {
      '& .MuiFormHelperText-contained': {
        position: 'absolute',
        bottom: '-20px',
      },
    },
    fullHeight: {
      height: '100%',
      '& .MuiInputBase-root': {
        height: '100%',
        '& .MuiInputBase-input': {
          height: '100% !important',
        },
      },
    },
  }),
);
