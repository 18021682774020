import { Template } from '../types';

import { httpRequest, QueryObject, handleResponse } from '../../../../../lib/httpClient';
import { isObject } from '../../../../../lib/utils/helpers';

export const apiFindTemplates = (filter?: QueryObject, viewToken?: string): Promise<Template[]> =>
  httpRequest
    .get(`/umo/templates`, { query: filter || {}, headers: viewToken ? { authorization: `Bearer ${viewToken}` } : {} })
    .then((res) => handleResponse<Template[]>(res))
    .then((data) => (isObject(data) ? [] : data))
    .catch();
