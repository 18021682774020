import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Grid, Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { KeyboardArrowRight } from '@material-ui/icons';
import { Formik, Form, FormikErrors } from 'formik';
import { ForgotFormPropType } from '../../../../pages/Forgot/types';
import { FormValuesType } from './types';
import { RootState } from '../../../../lib/store';
import { ErrorBox } from '../../../molecules/form/errors/ErrorBox';
import { InputField } from '../../../molecules/form/elements';
import { PrimaryButton } from '../../../molecules/button';

export const ForgotForm: React.FC<ForgotFormPropType> = ({ handleSubmit }): ReactElement => {
  const { t } = useTranslation(['init']);

  const { problem, success, loading } = useSelector((state: RootState) => state.account);

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      onSubmit={(values): void => {
        handleSubmit(values.email);
      }}
      validate={(values: FormValuesType): FormikErrors<FormValuesType> => {
        const errors: FormikErrors<FormValuesType> = {};

        if (!values.email) {
          errors.email = t('init:shared.forms.errors.required');
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
          errors.email = t('init:shared.forms.errors.invalidFormat');
        }
        return errors;
      }}
    >
      {(): ReactElement => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InputField required name="email" type="email" label={t('init:shared.forms.labels.email')} />
            </Grid>
            {problem && (problem.message || problem.details) && (
              <Grid item xs={12}>
                <ErrorBox message={problem.message} details={problem.details} />
              </Grid>
            )}
            {success === 'forgot' && (
              <Grid item xs={12}>
                <Alert severity="success">{t('init:pages.forgot.content.success')}</Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <PrimaryButton
                  type="submit"
                  loading={loading === 'forgot'}
                  disabled={success === 'forgot'}
                  endIcon={<KeyboardArrowRight />}
                >
                  {t('init:shared.forms.submit.request')}
                </PrimaryButton>
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

ForgotForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};
