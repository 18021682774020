import { InstructionTypeState, ActionType } from './types';
import { instructionTypeConstants } from './constants';

// INITIAL STATE
const initialState: InstructionTypeState = {
  instructionTypes: [],
  problem: null,
  loading: 'instructionTypeGet',
  success: '',
};

export const instructionTypeReducer = (state = initialState, action: ActionType): InstructionTypeState => {
  switch (action.type) {
    case instructionTypeConstants.INSTRUCTIONTYPE_SET_ALL:
      return {
        ...state,
        instructionTypes: action.payload,
      };

    case instructionTypeConstants.INSTRUCTIONTYPE_LOADING:
      return {
        ...state,
        loading: action.payload,
        problem: action.payload ? null : state.problem,
        success: action.payload ? '' : state.success,
      };

    case instructionTypeConstants.INSTRUCTIONTYPE_ERRORED:
      return {
        ...state,
        problem: action.payload,
      };

    case instructionTypeConstants.INSTRUCTIONTYPE_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };

    case instructionTypeConstants.INSTRUCTIONTYPE_ADD:
      return {
        ...state,
        instructionTypes: [...state.instructionTypes, action.payload],
      };

    case instructionTypeConstants.INSTRUCTIONTYPE_REPLACE:
      return {
        ...state,
        instructionTypes: state.instructionTypes.map((instructionType) =>
          instructionType._id === action.payload._id ? action.payload : instructionType,
        ),
      };

    case instructionTypeConstants.INSTRUCTIONTYPE_DELETE:
      return {
        ...state,
        instructionTypes: state.instructionTypes.filter((instructionType) => instructionType._id !== action.payload),
      };

    case 'dSimple/RESET_APP':
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
