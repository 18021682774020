import { useEffect, useLayoutEffect, useRef } from 'react';
import { InternalDepartment, InternalLocation } from '../../../../../lib/context/location/types';
import { UseLocationSelectorProps } from './types';

export const useLocationSelector = (
  props: UseLocationSelectorProps,
): { selectedLocation: InternalLocation | undefined; selectedDepartment: InternalDepartment | undefined } => {
  const { locations, departments, setFieldValue, values } = props;

  const selectedLocation = locations.find((location) => location.isSelected) || undefined;
  const selectedDepartment = departments.find((department) => department.isSelected) || undefined;

  const prevSelectedLocationNameRef = useRef<string>();
  const prevSelectedDepartmentNameRef = useRef<string>();

  useEffect(() => {
    prevSelectedLocationNameRef.current = (selectedLocation && selectedLocation._id) || undefined;
  });
  useEffect(() => {
    prevSelectedDepartmentNameRef.current = (selectedDepartment && selectedDepartment._id) || undefined;
  });

  useLayoutEffect(() => {
    const hasSelectedLocationChanged = prevSelectedLocationNameRef.current
      ? prevSelectedLocationNameRef.current !== (selectedLocation && selectedLocation._id)
      : false;

    if ((!selectedLocation || hasSelectedLocationChanged) && values.department && values.department.departmentName) {
      setFieldValue('department', null);
    }
  }, [selectedLocation, setFieldValue, values.department]);

  useLayoutEffect(() => {
    const hasSelectedDepartmentChanged = prevSelectedDepartmentNameRef.current
      ? prevSelectedDepartmentNameRef.current !== (selectedDepartment && selectedDepartment._id)
      : false;
    if ((!selectedDepartment || hasSelectedDepartmentChanged) && values.area && values.area.areaName) {
      setFieldValue('area', null);
    }
  }, [selectedDepartment, setFieldValue, values.area]);

  return {
    selectedLocation,
    selectedDepartment,
  };
};
