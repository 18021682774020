import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  alertBoxStylesInfo,
  alertBoxStylesStandard,
  alertBoxStylesSuccess,
  alertBoxStylesWarning,
} from '../../../styles/alertBox.styles';

export const useAlertBoxStyles = makeStyles(() =>
  createStyles({
    standardError: alertBoxStylesStandard,
    standardSuccess: alertBoxStylesSuccess,
    standardWarning: alertBoxStylesWarning,
    standardInfo: alertBoxStylesInfo,
  }),
);

export const muiAlert = {
  icon: {
    fontSize: '30px',
  },
  standardError: alertBoxStylesStandard,
  standardSuccess: alertBoxStylesSuccess,
  standardWarning: alertBoxStylesWarning,
  standardInfo: alertBoxStylesInfo,
};
