import React, { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { AutoCompleteField } from '../../../../molecules/form/elements';
import { setLoading as setLoadingEmployeeType } from '../../../../../lib/store/employeeType/actions';
import { attemptGetEmployeeTypes } from '../../../../../lib/store/employeeType/effects';
import { RootState } from '../../../../../lib/store';
import { EmployeeTypeFieldProps } from './types';

export const EmployeeTypeField = (props: EmployeeTypeFieldProps): ReactElement => {
  const { name, preventFetching, showLoadingOn, disabled } = props;
  const { t } = useTranslation(['module-core']);
  const reduxDispatch = useDispatch();

  const { loading, employeeTypes } = useSelector((state: RootState) => state.employeeType);

  const handleFetchEmployeeTypes = useCallback(() => {
    reduxDispatch(setLoadingEmployeeType(showLoadingOn || 'employeeTypeAutoCompleteGet'));
    reduxDispatch(attemptGetEmployeeTypes());
  }, [reduxDispatch, showLoadingOn]);

  const handleResetEmployeeTypes = useCallback(() => {
    reduxDispatch(setLoadingEmployeeType());
  }, [reduxDispatch]);

  return (
    <AutoCompleteField<string>
      disabled={disabled}
      name={name || 'employeeTypeName'}
      label={t('module-core:pages.userManagement.forms.labels.employeeType')}
      loading={loading === (showLoadingOn || 'employeeTypeAutoCompleteGet')}
      options={employeeTypes.map((emp) => emp.employeeTypeName)}
      handleFetchData={preventFetching ? undefined : handleFetchEmployeeTypes}
      handleResetData={preventFetching ? undefined : handleResetEmployeeTypes}
    />
  );
};

EmployeeTypeField.propTypes = {
  name: PropTypes.string,
  preventFetching: PropTypes.bool,
  showLoadingOn: PropTypes.string,
};
EmployeeTypeField.defaultProps = {
  name: '',
  preventFetching: false,
  showLoadingOn: '',
};
