// INITIAL STATE
import { OrganisationStateType, ActionType } from './types';

const initialState: OrganisationStateType = {
  current: null,
  billingReports: {},
  problem: null,
  loading: '',
  success: '',
  checked: false,
  slug: null,
};

/**
 * Reducer
 * Updated den STORE je nach ACTION type - Mitarbeiter der Auftrag ausfuehrt
 */

export const organisationReducer = (state = initialState, action: ActionType): OrganisationStateType => {
  switch (action.type) {
    case 'dSimple/organisation/LOADING':
      return {
        ...state,
        loading: action.payload,
        problem: action.payload ? null : state.problem,
        success: action.payload ? '' : state.success,
      };

    case 'dSimple/organisation/ERRORED':
      return {
        ...state,
        problem: action.payload,
      };

    case 'dSimple/organisation/SET_SLUG':
      return {
        ...state,
        slug: action.payload,
      };

    case 'dSimple/organisation/CURRENT':
      return {
        ...state,
        current: action.payload,
      };

    case 'dSimple/organisation/SET_BILLING_REPORTS':
      return {
        ...state,
        billingReports: action.payload,
      };

    case 'dSimple/organisation/SUCCESS':
      return {
        ...state,
        success: action.payload,
      };

    case 'dSimple/organisation/CHECK':
      return {
        ...state,
        checked: action.payload,
      };

    case 'dSimple/RESET_APP':
      return {
        ...initialState,
        checked: true,
        slug: state.slug,
      };

    default:
      return state;
  }
};
