import { httpRequest, handleResponse, QueryObject } from '../../../../../lib/httpClient';
import { InstructionType, CreateInstructionType, UpdateInstructionType } from '../types';
import { isObject } from '../../../../../lib/utils/helpers';

export const apiGetInstructionType = (query?: QueryObject): Promise<InstructionType[]> =>
  httpRequest
    .get('/umo/instruction-types', { query })
    .then((res) => handleResponse<InstructionType[]>(res))
    .then((data) => (isObject(data) ? [] : data))
    .catch();

export const apiCreateInstructionType = (body: CreateInstructionType): Promise<InstructionType> =>
  httpRequest
    .post('/umo/instruction-types', { body })
    .then((res) => handleResponse<InstructionType>(res))
    .then((data) => data)
    .catch();

export const apiUpdateInstructionType = (body: UpdateInstructionType): Promise<InstructionType> =>
  httpRequest
    .patch('/umo/instruction-types', { body })
    .then((res) => handleResponse<InstructionType>(res))
    .then((data) => data);

export const apiDeleteInstructionType = (_id: string): Promise<unknown> =>
  httpRequest.delete(`/umo/instruction-types/${_id}`).then(handleResponse);
