import i18n from 'i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import en from './instruction-locales/en/view.json';
import de from './instruction-locales/de/view.json';
import pl from './instruction-locales/pl/view.json';
import es from './instruction-locales/es/view.json';
import ru from './instruction-locales/ru/view.json';
import fr from './instruction-locales/fr/view.json';
import cz from './instruction-locales/cz/view.json';

const instructionInstance = i18n.createInstance().use(LanguageDetector);

instructionInstance.init({
  resources: {
    en: {
      translation: en,
    },
    de: {
      translation: de,
    },
    es: {
      translation: es,
    },
    fr: {
      translation: fr,
    },
    pl: {
      translation: pl,
    },
    ru: {
      translation: ru,
    },
    cz: {
      translation: cz,
    },
  },
  load: 'languageOnly',
  fallbackLng: process.env.REACT_APP_NODE_ENV === 'test' ? 'cimode' : 'en',
  lng: 'en',
  ...(process.env.REACT_APP_NODE_ENV === 'test' && { lng: 'cimode' }),
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  react: {
    wait: true,
  },
  debug: !(process.env.NODE_ENV === 'production' || process.env.REACT_APP_NODE_ENV === 'test'),
  detection: {
    lookupLocalStorage: 'instruction-lng',
  },
});

export { instructionInstance };
