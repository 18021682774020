import React, { ReactElement } from 'react';
import { Typography, TypographyProps } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    typographyRoot: {
      fontSize: '0.65rem',
    },
  }),
);

export const HelperText = (props: TypographyProps): ReactElement => {
  const classes = useStyles();
  const { children, ...other } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Typography variant="body2" className={classes.typographyRoot} {...other}>
      {children || ''}
    </Typography>
  );
};
