/* eslint-disable consistent-return */
import React, { ReactElement, Ref } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { RouterLinkProps } from './types';

export const RouterLink: React.FC<RouterLinkProps> = React.forwardRef(
  (props: RouterLinkProps, ref: Ref<HTMLAnchorElement> | undefined): ReactElement => {
    const { to, className, ...other } = props;
    const history = useHistory();

    return (
      <Link
        to={to}
        ref={ref}
        className={className}
        onClick={(e) => {
          if (e.metaKey || e.ctrlKey) return;
          e.preventDefault();
          // eslint-disable-next-line no-return-assign
          if (window.swUpdate) return (window.location = to);
          return history.push(to);
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...other}
      />
    );
  },
);

RouterLink.displayName = 'RouterLink';
