/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Divider, ListItem } from '@material-ui/core';
import { ListItemEditableProps } from './types';
import { useListItemEditableStyles } from './styles';

export const ListItemEditable = (props: ListItemEditableProps): ReactElement => {
  const { editMode, editModeContent, button, children, ...other } = props;
  const classes = useListItemEditableStyles({ editMode });
  return (
    // need cast to any because of https://github.com/mui-org/material-ui/issues/14971 // eslint-disable-next-line
    <>
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      <ListItem className={classes.root} button={button as any} {...other}>
        {editMode && editModeContent ? editModeContent : children}
      </ListItem>
      <Divider />
    </>
  );
};

ListItemEditable.propTypes = {
  editMode: PropTypes.bool,
  editModeContent: PropTypes.element,
};

ListItemEditable.defaultProps = {
  editMode: false,
  editModeContent: undefined,
};
