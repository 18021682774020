import React, { ReactElement } from 'react';
import { CircularProgress, IconButton } from '@material-ui/core';
import { SaveRounded } from '@material-ui/icons';
import { useSaveIconButtonStyles } from './styles';
import { SaveIconButtonProps } from './types';

export const SaveIconButton = (props: SaveIconButtonProps): ReactElement => {
  const classes = useSaveIconButtonStyles();
  const { loading, ...other } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <IconButton className={classes.buttonRoot} {...other}>
      {loading ? <CircularProgress size="24px" /> : <SaveRounded />}
    </IconButton>
  );
};
