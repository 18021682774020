import React, { ReactElement, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, FormControl, RadioGroup, Radio } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Formik, Form, FormikErrors } from 'formik';
import { KeyboardArrowRight } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { Alert } from '@material-ui/lab';
import i18n from '../../../../lib/i18n';

import { ProfileDialogProps } from './types';
import { getCurrentLanguage } from '../../../../lib/utils';
import { BaseDialog } from '../../../molecules/window/BaseDialog';
import { PrimaryButton } from '../../../molecules/button';
import { SelectField, InputField } from '../../../molecules/form/elements';
import { RootState } from '../../../../lib/store';
import { UserAccountUpdateBody } from '../../../../lib/store/account/types';
import { attemptUpdateAccount } from '../../../../lib/store/account/effects';
import { trigger } from '../../../../lib/events';
import { useProfileDialogStyles } from './styles';
import { ErrorBox } from '../../../molecules/form/errors/ErrorBox';
import { erroredAccount, successAccount } from '../../../../lib/store/account/actions';

export const ProfileDialog = (props: ProfileDialogProps): ReactElement | null => {
  const { t } = useTranslation(['common']);
  const { open, onClose } = props;
  const {
    account: { current, loading, success, problem },
  } = useSelector((state: RootState) => state);
  const reduxDispatch = useDispatch();

  const classes = useProfileDialogStyles();

  useLayoutEffect(() => {
    reduxDispatch(erroredAccount());
    reduxDispatch(successAccount(''));

    return () => {
      reduxDispatch(erroredAccount());
      reduxDispatch(successAccount(''));
    };
  }, [open]);

  if (!current) return null;

  const changeLanguage = (): void => {
    i18n.changeLanguage(getCurrentLanguage() === 'en' ? 'de' : 'en');
  };

  const handleSubmit = (values: UserAccountUpdateBody) => {
    reduxDispatch(attemptUpdateAccount(values));
  };

  const handleStartTutorial = () => {
    trigger('ds_open:tutorial', { tutorialName: 'core:basic' });
    onClose();
  };

  return (
    <BaseDialog
      DialogProps={{ maxWidth: 'sm', fullWidth: true }}
      open={open}
      onClose={onClose}
      title={t('common:profile.popupHeading')}
    >
      <Box mb={1}>
        <Typography variant="h6">{t('common:profile.labelLanguage')}</Typography>
      </Box>
      <Box mb={2} display="flex" alignItems="center">
        <FormControl component="fieldset">
          <RadioGroup
            className={classes.alignHorizontal}
            aria-label="Select Language"
            id="language-selector"
            name="language"
            value={getCurrentLanguage()}
            onChange={changeLanguage}
          >
            <FormControlLabel key="EN" value="en" control={<Radio />} label={t('common:profile.english')} />
            <FormControlLabel key="DE" value="de" control={<Radio />} label={t('common:profile.german')} />
          </RadioGroup>
        </FormControl>
      </Box>
      <Formik
        initialValues={{ tutorialsMode: current.tutorialsMode || 'not-started' }}
        onSubmit={handleSubmit}
        validate={(values) => {
          const errors: FormikErrors<{ password: string; currentPassword: string }> = {};

          if (values.password && !values.currentPassword) {
            errors.currentPassword = t('init:shared.forms.errors.required');
          }

          if (values.password && !/^(?=.{6,50}$)(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*\W).*/.test(values.password)) {
            errors.password = t('init:shared.forms.errors.passwordFormat');
          }
          return errors;
        }}
      >
        <Form>
          <Box>
            <Typography variant="h6">{t('common:profile.labeltext')}</Typography>
          </Box>
          <Box mt={2}>
            <InputField
              autoComplete="off"
              type="password"
              label={t('common:profile.passwordOld')}
              name="currentPassword"
            />
          </Box>
          <Box mt={2}>
            <InputField autoComplete="off" type="password" label={t('common:profile.passwordNew')} name="password" />
          </Box>

          <Box mt={2}>
            <Box>
              <Typography variant="h6">{t('common:profile.tutorialSettings')}</Typography>
            </Box>
            <Box display="flex" flexDirection="column" mt={2}>
              <Box flex="2">
                <SelectField
                  label={t('common:profile.tutorialsMode')}
                  name="tutorialsMode"
                  options={[
                    { label: t('common:profile.none'), value: 'none' },
                    { label: t('common:profile.notStarted'), value: 'not-started' },
                  ]}
                />
              </Box>
              <Box mt={1} flex="1">
                <PrimaryButton endIcon={null} size="small" variant="text" onClick={handleStartTutorial}>
                  {t('common:profile.startBasicTutorial')}
                </PrimaryButton>
              </Box>
            </Box>
          </Box>
          {problem && (problem.message || problem.details) && (
            <Box mt={2}>
              <ErrorBox message={problem.message} details={problem.details} />
            </Box>
          )}
          {success === 'profileEdit' && (
            <Box mt={2}>
              <Alert severity="success">{t('common:profile.saved')}</Alert>
            </Box>
          )}
          <Box mt={2} flex="1" textAlign="right">
            <PrimaryButton loading={loading === 'profileEdit'} type="submit" endIcon={<KeyboardArrowRight />}>
              {t('common:profile.save')}
            </PrimaryButton>
          </Box>
        </Form>
      </Formik>
    </BaseDialog>
  );
};
