/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
function on(eventType: string, listener: (event: Event) => void) {
  document.addEventListener(eventType, listener);
}

function off(eventType: string, listener: (event: Event) => void) {
  document.removeEventListener(eventType, listener);
}

function trigger(eventType: string, data: unknown) {
  const event = new CustomEvent(eventType, { detail: data });
  document.dispatchEvent(event);
}

export { on, off, trigger };
