import { Dispatch } from 'redux';
import { thunk } from './thunks';
import { apiCreateEmployeeType, apiDeleteEmployeeType, apiGetEmployeeTypes, apiUpdateEmployeeType } from './services';
import { setLoading, addEmployeeType, deleteEmployeeType, replaceEmployeeType, setAllEmployeeTypes } from './actions';
import { GetStateType } from '..';
import { UpdateEmployeeType } from './types';
import { enqueueSnackbar } from '../notifier/actions';

/**
 * EmployeeTypes
 */
export const attemptGetEmployeeTypes = (): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('employeeTypeGet'));
    const employeeTypes = await apiGetEmployeeTypes();
    dispatch(setAllEmployeeTypes(employeeTypes));
  });

export const attemptCreateEmployeeType = (
  employeeTypeName: string,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('employeeTypeAdd'));
    const employeeType = await apiCreateEmployeeType({ employeeTypeName });

    dispatch(addEmployeeType(employeeType));
    dispatch(
      enqueueSnackbar({
        translationString: 'module-core:shared.employeeTypes.actions.addSuccess',
        messageTranslationKeys: { employeeTypeName: employeeType.employeeTypeName },
        options: {
          variant: 'success',
        },
      }),
    );
  });

export const attemptUpdateEmployeeType = (
  body: UpdateEmployeeType,
  oldEmployeeTypeName: string,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('employeeTypeEdit'));
    const updatedEmployeeType = await apiUpdateEmployeeType(body);
    dispatch(replaceEmployeeType(updatedEmployeeType));
    dispatch(
      enqueueSnackbar({
        translationString: 'module-core:shared.employeeTypes.actions.updateSuccess',
        messageTranslationKeys: { oldEmployeeTypeName, employeeTypeName: updatedEmployeeType.employeeTypeName },
        options: {
          variant: 'success',
        },
      }),
    );
  });

export const attemptDeleteEmployeeType = (
  _id: string,
  employeeTypeName: string,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('employeeTypeDelete'));
    await apiDeleteEmployeeType(_id);
    dispatch(deleteEmployeeType(_id));
    dispatch(
      enqueueSnackbar({
        translationString: 'module-core:shared.employeeTypes.actions.deleteSuccess',
        messageTranslationKeys: { employeeTypeName },
        options: {
          variant: 'success',
        },
      }),
    );
  });
