import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Grid, Box, Button } from '@material-ui/core';

import { KeyboardArrowRight } from '@material-ui/icons';
import { Formik, Form, FormikErrors } from 'formik';
import { RootState } from '../../../../lib/store';
import { ChangeStartPasswordFormProps, ChangeStartPasswordFormValueType } from './types';

import { ErrorBox } from '../../../molecules/form/errors/ErrorBox';
import { InputField } from '../../../molecules/form/elements';

export const ChangeStartPasswordForm: React.FC<ChangeStartPasswordFormProps> = ({ handleSubmit }): ReactElement => {
  const { t } = useTranslation(['init']);
  const { problem, loading } = useSelector((state: RootState) => state.account);

  return (
    <Formik
      initialValues={{
        currentPassword: '',
        password: '',
      }}
      onSubmit={(values): void => {
        handleSubmit(values);
      }}
      validate={(values: ChangeStartPasswordFormValueType): FormikErrors<ChangeStartPasswordFormValueType> => {
        const errors: FormikErrors<ChangeStartPasswordFormValueType> = {};

        if (!values.currentPassword) {
          errors.currentPassword = t('init:shared.forms.errors.required');
        }

        if (!values.password) {
          errors.password = t('init:shared.forms.errors.required');
        } else if (!/^(?=.{6,50}$)(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*\W).*/.test(values.password)) {
          errors.password = t('init:shared.forms.errors.passwordFormat');
        }

        return errors;
      }}
    >
      {(): ReactElement => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InputField
                required
                name="currentPassword"
                type="password"
                label={t('init:shared.forms.labels.currentPassword')}
              />
            </Grid>
            <Grid item xs={12}>
              <InputField required name="password" type="password" label={t('init:shared.forms.labels.password')} />
            </Grid>

            {problem && (problem.message || problem.details) && (
              <Grid item xs={12}>
                <ErrorBox message={problem.message} details={problem.details} />
              </Grid>
            )}
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button type="submit" disabled={loading === 'changePassword'} endIcon={<KeyboardArrowRight />}>
                  {t('init:shared.forms.submit.save')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

ChangeStartPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};
