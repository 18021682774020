import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const usePrimaryButtonStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonRoot: {
      '&:hover': {
        background: theme.palette.primary.light,
      },
    },
  }),
);
