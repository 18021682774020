import React, { ReactElement } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import { UpgradeDialogProps } from './types';
import { AvailablePermissions } from '../../../../lib/constants/availablePermissions';
import { hasAccountRequiredPermission } from '../../../../lib/utils';
import { RootState } from '../../../../lib/store';
import { AuthRoutes } from '../../../../lib/constants/pagePaths';
import { attemptSendAdminRequest } from '../../../../lib/store/organisation/effects';
import { useCheckHistory } from '../../../../lib/hooks/useCheckHistory';

export const UpgradeDialog = (props: UpgradeDialogProps): ReactElement => {
  const { open, handleClose } = props;
  const { t } = useTranslation(['init', 'module-umo']);
  const history = useCheckHistory();
  const reduxDispatch = useDispatch();
  const { current: account } = useSelector((state: RootState) => state.account);
  const permissions = (account && account.role.permissions) || [];
  const hasBillingPermissions = hasAccountRequiredPermission(permissions, [
    AvailablePermissions.CoreCrosssystemSettingsBillingUpdate,
  ]);

  const handleClickConfirm = (): void => {
    if (hasBillingPermissions) {
      history.push(AuthRoutes.billing);
    } else {
      // send email
      reduxDispatch(attemptSendAdminRequest('planUpgrade'));
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t(`common:upgradeDialog.Upgrade required`)}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography>
            {t('common:upgradeDialog.The limit of the free plan has been reached')}{' '}
            {hasBillingPermissions
              ? t('common:upgradeDialog.Would you like to be redirected to the Billing Management?')
              : t('common:upgradeDialog.Would you like to send a request to the Account owner?')}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="default" variant="text" autoFocus>
          {t(`Abbrechen`)}
        </Button>
        <Button onClick={handleClickConfirm} variant="text" color="secondary">
          {t(`${hasBillingPermissions ? 'common:upgradeDialog.Next' : 'common:upgradeDialog.Send'}`)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
