import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { BaseTemplate } from '../foundation/BaseTemplate';
import { NavBar } from '../../../features/NavBar';
import { MainTemplateProps } from './types';

export const MainTemplate = (props: MainTemplateProps): ReactElement => {
  const { stretch = false, sliderMode = false, sideBarItems, moduleTitle, children } = props;
  return (
    <BaseTemplate
      waveOrientation={{ right: true }}
      stretch={stretch}
      sliderMode={sliderMode}
      withPixel={{ right: false }}
      header={(topBar): ReactElement => {
        return <NavBar renderTopBar={() => topBar} />;
      }}
      // inject sidebar here as it's based on the module
      sideBarItems={sideBarItems}
      moduleTitle={moduleTitle}
    >
      {children}
    </BaseTemplate>
  );
};

MainTemplate.propTypes = {
  stretch: PropTypes.bool,
  sliderMode: PropTypes.bool,
  sideBarItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      icon: PropTypes.element.isRequired,
    }).isRequired,
  ).isRequired,
  moduleTitle: PropTypes.string.isRequired,
};
MainTemplate.defaultProps = {
  stretch: false,
  sliderMode: false,
};
