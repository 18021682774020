export enum locationConstants {
  LOCATION_SET_ALL = 'dSimple/location/SET_ALL',
  LOCATION_LOADING = 'dSimple/location/LOADING',
  LOCATION_ERRORED = 'dSimple/location/ERRORED',
  LOCATION_SUCCESS = 'dSimple/location/SUCCESS',
  LOCATION_ADD = 'dSimple/location/ADD',
  LOCATION_REPLACE = 'dSimple/location/REPLACE',
  LOCATION_DELETE = 'dSimple/location/DELETE',
  LOCATION_ADD_DEPARTMENT = 'dSimple/location/ADD_DEPARTMENT',
  LOCATION_REPLACE_DEPARTMENT = 'dSimple/location/REPLACE_DEPARTMENT',
  LOCATION_DELETE_DEPARTMENT = 'dSimple/location/DELETE_DEPARTMENT',
  LOCATION_ADD_AREA = 'dSimple/location/ADD_AREA',
  LOCATION_REPLACE_AREA = 'dSimple/location/REPLACE_AREA',
  LOCATION_DELETE_AREA = 'dSimple/location/DELETE_AREA',
  LOCATION_INIT = 'dSimple/location/INIT',
}
