import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Grid, Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardArrowRight } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { Formik, Form, FormikErrors } from 'formik';
import { RootState } from '../../../../lib/store';
import { OrganisationFormPropType } from '../../../../pages/Organisation/types';
import { FormValuesType } from './types';
import { config } from '../../../../lib/config';
import { InputField } from '../../../molecules/form/elements';

const useStyles = makeStyles({
  floatingHeader: {
    position: 'absolute',
    top: '-20px',
  },
});

export const OrganisationForm: React.FC<OrganisationFormPropType> = ({ dest, handleSubmit }): ReactElement => {
  const { t } = useTranslation(['init']);
  const classes = useStyles();

  const { problem, success, loading, slug } = useSelector((state: RootState) => state.organisation);

  if (success && slug) {
    const url = new URL(config.spaRootUrl);
    const to = `${url.protocol}//${slug}.${url.host}${dest || ''}`;
    window.location.href = to;
  }

  return (
    <Formik
      initialValues={{
        organisationSlug: '',
      }}
      onSubmit={(values): void => {
        handleSubmit(values.organisationSlug);
      }}
      validate={(values: FormValuesType): FormikErrors<FormValuesType> => {
        const errors: FormikErrors<FormValuesType> = {};
        const regex = RegExp('^[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?$');
        if (!values.organisationSlug) {
          errors.organisationSlug = t('init:shared.forms.errors.required');
        } else if (!regex.test(values.organisationSlug)) {
          errors.organisationSlug = t('init:shared.forms.errors.invalidOrgFormat');
        }
        return errors;
      }}
    >
      {({ values }): ReactElement => (
        <Form style={{ position: 'relative' }}>
          {values.organisationSlug && (
            <Typography className={classes.floatingHeader}>
              {t('init:pages.organisation.content.info')}{' '}
              <Typography component="span" color="secondary">
                <strong>{values.organisationSlug}</strong>
              </Typography>
              <strong>{`.${new URL(config.spaRootUrl).host}`}</strong>
            </Typography>
          )}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InputField
                required
                name="organisationSlug"
                label={t('init:pages.organisation.forms.labels.workspace')}
                normalize={(value): string => value.toLowerCase()}
              />
            </Grid>
            {problem && problem.translationString && (
              <Grid item xs={12}>
                <Alert severity="error">{t(problem.translationString)}</Alert>
              </Grid>
            )}
            {problem && problem.message && (
              <Grid item xs={12}>
                <Alert severity="error">{t(problem.message)}</Alert>
              </Grid>
            )}
            {success && (
              <Grid item xs={12}>
                <Alert severity="success">{t('init:pages.organisation.content.success')}</Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button type="submit" disabled={loading === 'organisationCheck'} endIcon={<KeyboardArrowRight />}>
                  {t('init:shared.forms.submit.continue')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

OrganisationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  dest: PropTypes.string.isRequired,
};
