import { templateConstants } from './constants';
import { TemplateLoading, ActionType, Template } from './types';
import { ProblemType } from '../../../../lib/store';

/**
 * Actions
 * Beschreibt was wir tun wollen
 */

export const setLoading = (payload: TemplateLoading = ''): ActionType => ({
  type: templateConstants.TEMPLATE_LOADING,
  payload,
});

export const erroredTemplate = (payload: ProblemType | null = null): ActionType => ({
  type: templateConstants.TEMPLATE_ERRORED,
  payload,
});

export const successTemplate = (payload: TemplateLoading = ''): ActionType => ({
  type: templateConstants.TEMPLATE_SUCCESS,
  payload,
});

export const setTemplateList = (payload: Template[]): ActionType => ({
  type: templateConstants.TEMPLATE_SET_ALL,
  payload,
});

export const initTemplate = (): ActionType => ({
  type: templateConstants.TEMPLATE_INIT,
});
