import React, { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { attemptGetLocations } from '../../../../../lib/store/location/effects';
import { setLoading } from '../../../../../lib/store/location/actions';
import { actionTypes } from '../../../../../lib/context/location/constants';

import { AutoCompleteField } from '../../../../molecules/form/elements';
import { useLocationDispatchContext, useLocationStateContext } from '../../../../../lib/context/location';
import { useLocationSelector } from './useLocationSelector';
import { LocationFieldsProps } from './types';
import { InternalArea, InternalDepartment, InternalLocation } from '../../../../../lib/context/location/types';

export const LocationFields = (props: LocationFieldsProps): ReactElement => {
  const { t } = useTranslation(['module-core']);
  const reduxDispatch = useDispatch();
  const { spacing = 1, setFieldValue, values, disableAllFields } = props;

  const { locations, departments, areas, loading: loadingLocation } = useLocationStateContext();
  const dispatch = useLocationDispatchContext();
  const { selectedLocation, selectedDepartment } = useLocationSelector({
    locations,
    departments,
    setFieldValue,
    values,
  });

  const handleOpenLocations = useCallback(() => {
    if (!selectedLocation) {
      reduxDispatch(setLoading('locationGet'));
      reduxDispatch(attemptGetLocations());
    }
  }, [reduxDispatch, selectedLocation]);

  const handleCloseLocations = useCallback(() => {
    if (!selectedLocation) {
      reduxDispatch(setLoading());
    }
  }, [reduxDispatch, selectedLocation]);

  const selectLocationHook = (internalLocation: InternalLocation | null): void => {
    const location = locations.find((loc) => internalLocation && loc.locationName === internalLocation.locationName);
    if (location) {
      dispatch({
        type: actionTypes.SELECT,
        payload: { data: true, _id: location._id },
      });
    } else if (selectedLocation) {
      // if no value selected, e.g. clear button pressed, deselect location
      dispatch({
        type: actionTypes.SELECT,
        payload: { data: false, _id: selectedLocation._id },
      });
    } else {
      // eslint-disable-next-line no-console
      console.error({
        type: 'warning',
        code: 'PAGES_CORE_AUTHED_USERM_UFORM_CFIELDS_NO_VALUE_SELECTED',
        message: 'selectLocationHook reached else because no value selected, nor a selected value exists',
      });
    }
  };

  const selectDepartmentHook = (internalDepartment: InternalDepartment | null): void => {
    const department = departments.find(
      (dep) => internalDepartment && dep.departmentName === internalDepartment.departmentName,
    );
    if (department) {
      dispatch({
        type: actionTypes.SELECT_DEPARTMENT,
        payload: { data: true, _id: department._id },
      });
    } else if (selectedDepartment) {
      // if no value selected, e.g. clear button pressed, deselect department
      dispatch({
        type: actionTypes.SELECT_DEPARTMENT,
        payload: { data: false, _id: selectedDepartment._id },
      });
    } else {
      // eslint-disable-next-line no-console
      console.error({
        type: 'warning',
        code: 'PAGES_CORE_AUTHED_USERM_UFORM_CFIELDS_NO_VALUE_SELECTED',
        message: 'selectDepartmentHook reached else because no value selected, nor a selected value exists',
      });
    }
  };

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box flex="1 0 100%" mb={spacing} mt={spacing}>
        <AutoCompleteField<InternalLocation>
          name="location"
          label={t('module-core:pages.userManagement.forms.labels.location')}
          loading={loadingLocation === 'locationGet'}
          options={locations}
          selectValueBy="locationName"
          disabled={disableAllFields}
          handleFetchData={handleOpenLocations}
          handleResetData={handleCloseLocations}
          handleSelectHook={selectLocationHook}
        />
      </Box>
      <Box flex="1 0 100%" mb={spacing} mt={spacing}>
        <AutoCompleteField<InternalDepartment>
          name="department"
          label={t('module-core:pages.userManagement.forms.labels.department')}
          disabled={disableAllFields || !(selectedLocation && selectedLocation._id)}
          loading={false}
          options={departments.filter((department) => department.isVisible)}
          selectValueBy="departmentName"
          handleSelectHook={selectDepartmentHook}
        />
      </Box>
      <Box flex="1 0 100%" mb={spacing} mt={spacing}>
        <AutoCompleteField<InternalArea>
          name="area"
          label={t('module-core:pages.userManagement.forms.labels.area')}
          disabled={disableAllFields || !(selectedDepartment && selectedDepartment._id)}
          loading={false}
          selectValueBy="areaName"
          options={areas.filter((area) => area.isVisible)}
        />
      </Box>
    </Box>
  );
};
