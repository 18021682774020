import React, { ReactElement } from 'react';
import { Box, Paper, Tab, Tabs } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { StyledTabBarProps } from './types';
import { useTabBarStyles } from './styles';

export type { TabItem } from './types';

export const StyledTabBar = (props: StyledTabBarProps): ReactElement => {
  const { isSecondary, orientation = 'horizontal', tabItems, currentValue, variant, handleChange } = props;
  const classes = useTabBarStyles({ variant });
  return (
    <Box display="flex" mr={orientation === 'vertical' ? 4 : 0}>
      <Paper className={clsx(isSecondary && classes.secondaryPaper)}>
        <Tabs
          variant={variant}
          orientation={orientation}
          value={currentValue}
          onChange={(_event, newValue): void => handleChange(newValue)}
          aria-label="tabbar"
        >
          {tabItems.map((tabItem, index) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Tab
              className={clsx(isSecondary && classes.secondaryTab)}
              key={tabItem.label}
              label={tabItem.label}
              disabled={tabItem.disabled}
              id={`${isSecondary ? 'secondary' : 'nav'}-tab-${index}`}
              aria-controls={`${isSecondary ? 'secondary' : 'nav'}-tabpanel-${index}`}
            />
          ))}
        </Tabs>
      </Paper>
    </Box>
  );
};

StyledTabBar.propTypes = {
  isSecondary: PropTypes.bool,
  tabItems: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string })).isRequired,
  currentValue: PropTypes.number.isRequired,
  variant: PropTypes.oneOf(['fullWidth', 'scrollable', 'standard']).isRequired,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  handleChange: PropTypes.func.isRequired,
};

StyledTabBar.defaultProps = {
  isSecondary: false,
  orientation: 'horizontal',
};
