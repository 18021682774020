import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

import { LogoSpinner } from '../LogoSpinner';
import { MainLogoSpinnerProps } from './types';

export const MainLogoSpinner: React.FC<MainLogoSpinnerProps> = ({
  loadingText,
}: MainLogoSpinnerProps): ReactElement => {
  return (
    <Grid container spacing={1} justify="center" alignItems="center">
      <Grid item>
        <LogoSpinner size="8px" />
      </Grid>
      {loadingText && (
        <Grid xs={12} item>
          <Typography align="center">{loadingText}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

MainLogoSpinner.propTypes = {
  loadingText: PropTypes.string,
};

MainLogoSpinner.defaultProps = {
  loadingText: '',
};
