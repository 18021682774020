import { RoleManagementState, ActionType } from './types';
import { roleConstants } from './constants';

// INITIAL STATE
const initialState: RoleManagementState = {
  roles: [],
  roleNameList: [],
  problem: null,
  loading: '',
  success: '',
};

/**
 * Reducer
 * Updated den STORE je nach ACTION type - Mitarbeiter der Auftrag ausfuehrt
 */
export const roleReducer = (state = initialState, action: ActionType): RoleManagementState => {
  switch (action.type) {
    case roleConstants.ROLE_SET_ALL:
      return {
        ...state,
        roles: action.payload,
      };

    case roleConstants.ROLE_SET_ALL_NAMES:
      return {
        ...state,
        roleNameList: action.payload,
      };

    case roleConstants.ROLE_LOADING:
      return {
        ...state,
        loading: action.payload,
        problem: action.payload ? null : state.problem,
        success: action.payload ? '' : state.success,
      };

    case roleConstants.ROLE_ERRORED:
      return {
        ...state,
        problem: action.payload,
      };

    case roleConstants.ROLE_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };

    case roleConstants.ROLE_ADD:
      return {
        ...state,
        roles: [...state.roles, action.payload],
      };

    case roleConstants.ROLE_REPLACE:
      return {
        ...state,
        roles: state.roles.map((role) => (role._id === action.payload._id ? action.payload : role)),
      };

    case roleConstants.ROLE_DELETE:
      return {
        ...state,
        roles: state.roles.filter((role) => role._id !== action.payload),
      };

    case roleConstants.ROLE_INIT:
      return {
        ...initialState,
      };

    case 'dSimple/RESET_APP':
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
