import React, { ReactElement } from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CenteredContentTemplate } from '../../components/templates';
import { AlertBoxWithButton } from '../../components/molecules/card/AlertCardWithButton';
import { RootState } from '../../lib/store';

export const Unauthorized: React.FC = (): ReactElement => {
  const { t } = useTranslation(['init']);
  const { homeUrl } = useSelector((state: RootState) => state.account);
  return (
    <CenteredContentTemplate>
      <AlertBoxWithButton buttonLink={homeUrl || '/'} buttonText={t('init:shared.forms.submit.backPage')}>
        <Typography variant="h6" color="textSecondary" component="p">
          {t('init:pages.unauthorized.content')}
        </Typography>
      </AlertBoxWithButton>
    </CenteredContentTemplate>
  );
};

export default Unauthorized;
