import { ActionType, ActionUmoType, UserAccountType, AccountTypeLoading } from './types';
import { ProblemType } from '..';
import { accountConstants, accountUmoConstants } from './constants';
import { UserInstructionAbsolved } from '../user/types';
import { RoleType } from '../role/types';
/**
 * Actions
 * Beschreibt was wir tun wollen - Auftrag oder Befehl
 */
export const loadingAccount = (payload: AccountTypeLoading): ActionType => ({
  type: accountConstants.ACCOUNT_LOADING,
  payload,
});

export const erroredAccount = (payload: ProblemType | null = null): ActionType => ({
  type: accountConstants.ACCOUNT_ERRORED,
  payload,
});

export const successAccount = (payload: AccountTypeLoading): ActionType => ({
  type: accountConstants.ACCOUNT_SUCCESS,
  payload,
});

export const currentAccount = (payload: UserAccountType | null = null): ActionType => ({
  type: accountConstants.ACCOUNT_CURRENT,
  payload,
});

export const checkAccount = (payload = false): ActionType => ({
  type: accountConstants.ACCOUNT_CHECK,
  payload,
});

export const authAccount = (payload = false): ActionType => ({
  type: accountConstants.ACCOUNT_PROFILE_AUTH,
  payload,
});

export const reauthenticateAccount = (): ActionType => ({
  type: accountConstants.ACCOUNT_REAUTHENTICATE,
});

export const csrfAccount = (payload = ''): ActionType => ({
  type: accountConstants.ACCOUNT_SET_CSRF,
  payload,
});

export const setHomeUrl = (payload = ''): ActionType => ({
  type: accountConstants.ACCOUNT_SET_HOME_URL,
  payload,
});

export const setShouldChangePassword = (payload = false): ActionType => ({
  type: accountConstants.SET_SHOULD_CHANGE_PASSWORD,
  payload,
});

export const replaceRoleInCurrent = (payload: RoleType): ActionType => ({
  type: accountConstants.ACCOUNT_CURRENT_ROLE_REPLACE,
  payload,
});

export const replaceOrAddAbsolved = (payload: UserInstructionAbsolved): ActionUmoType => ({
  type: accountUmoConstants.ACCOUNT_UMO_ABSOLVED_REPLACE,
  payload,
});
