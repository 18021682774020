import imageCompression from 'browser-image-compression';

const imageOptions = {
  maxSizeMB: 5,
  maxWidthOrHeight: 1280,
  useWebWorker: true,
};

export const processFile = async (initialFile: File, compress = false): Promise<{ file?: File; dataUrl?: string }> => {
  const file: File = compress ? await imageCompression(initialFile, imageOptions) : initialFile;

  return new Promise((resolve) => {
    // Read file into data URL to preview
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onabort = (e) => {
      // eslint-disable-next-line no-console
      console.error({
        type: 'warning',
        code: 'FILE_ABORTED',
        message: 'file reading was aborted',
        stack: JSON.stringify(e),
      });
      return resolve({ file: undefined, dataUrl: undefined });
    };
    reader.onerror = (e) => {
      // eslint-disable-next-line no-console
      console.error({
        type: 'error',
        code: 'FILE_ERROR',
        message: 'file reading has failed',
        stack: JSON.stringify(e),
      });
      return resolve({ file: undefined, dataUrl: undefined });
    };

    reader.onload = () => {
      const results = reader.result;

      if (typeof results === 'string') {
        return resolve({ file, dataUrl: results });
      }
      // eslint-disable-next-line no-console
      console.error({
        type: 'error',
        code: 'FILE_ERROR',
        message: 'file loaded, but result is not data URL',
      });
      return resolve({ file: undefined, dataUrl: undefined });
    };
  });
};
