import React, { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { InstructionTypeFieldProps } from './types';
import { RootState } from '../../../../../../lib/store';
import { setLoading } from '../../../../../lib/store/instructionType/actions';
import { attemptGetInstructionTypes } from '../../../../../lib/store/instructionType/effects';
import { AutoCompleteField } from '../../../../../../components/molecules/form/elements';
import { useCheckPlan } from '../../../../../../lib/hooks/useCheckPlan';
import { availablePlans } from '../../../../../../lib/constants/availablePlans';
import { UpgradeText } from './UpgradeText';
import { UpgradeDialog } from '../../../../../../components/organisms/window/UpgradeDialog';

export const InstructionTypeField = (props: InstructionTypeFieldProps): ReactElement => {
  const { name, preventFetching, showLoadingOn, hideExternal, required, onBlur, onFocus } = props;
  const { t } = useTranslation(['module-umo']);
  const { setFieldValue } = useFormikContext();
  const reduxDispatch = useDispatch();
  const { hasPlan } = useCheckPlan(availablePlans);

  const { loading, instructionTypes } = useSelector((state: RootState) => state.instructionType);

  const handleFetchInstructionTypes = useCallback(() => {
    reduxDispatch(setLoading(showLoadingOn || 'instructionTypeAutoCompleteGet'));
    reduxDispatch(attemptGetInstructionTypes(undefined, hideExternal));
  }, [reduxDispatch, showLoadingOn]);

  const handleResetInstructionTypes = useCallback(() => {
    reduxDispatch(setLoading());
  }, [reduxDispatch]);

  const selectHook = (value: string | null) => {
    const instructionType = instructionTypes.find((el) => el.instructionTypeName === value);
    setFieldValue('instructionTypeCategory', instructionType && value ? instructionType.category || '' : '');
  };

  const [open, setOpen] = React.useState(false);

  const handleUpgradeClick = () => {
    setOpen(true);
  };

  return (
    <>
      <AutoCompleteField<string>
        onBlur={onBlur}
        onFocus={onFocus}
        name={name || 'instructionTypeName'}
        label={t('module-umo:shared.instructionType.forms.labels.instructionTypeName')}
        loading={loading === (showLoadingOn || 'instructionTypeAutoCompleteGet')}
        options={instructionTypes.map((ins) => ins.instructionTypeName)}
        required={required}
        getOptionDisabled={(option) => {
          const { category } = instructionTypes.find((ins) => ins.instructionTypeName === option) || {};
          return !hasPlan && (category === 'external' || category === 'visitor');
        }}
        renderOption={(option) => {
          const { category } = instructionTypes.find((ins) => ins.instructionTypeName === option) || {};

          return !hasPlan && (category === 'external' || category === 'visitor') ? (
            <div style={{ display: 'flex', width: '100%' }}>
              {option}
              <UpgradeText handleClick={handleUpgradeClick} />
            </div>
          ) : (
            option
          );
        }}
        handleFetchData={preventFetching ? undefined : handleFetchInstructionTypes}
        handleResetData={preventFetching ? undefined : handleResetInstructionTypes}
        handleSelectHook={selectHook}
      />
      <UpgradeDialog handleClose={() => setOpen(false)} open={open} />
    </>
  );
};

InstructionTypeField.propTypes = {
  name: PropTypes.string,
  preventFetching: PropTypes.bool,
  showLoadingOn: PropTypes.string,
};
InstructionTypeField.defaultProps = {
  name: '',
  preventFetching: false,
  showLoadingOn: '',
};
