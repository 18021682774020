import { History } from 'history';

export const queryParams = {
  add: (history: History, key: string, value: string): void => {
    const currentSearch = new URLSearchParams(history.location.search);
    currentSearch.set(key, value);
    history.push({ search: currentSearch.toString() });
  },
  remove: (history: History, key: string): void => {
    const currentSearch = new URLSearchParams(history.location.search);
    currentSearch.delete(key);
    history.push({ search: currentSearch.toString() });
  },
};
