import { httpRequest, handleResponse, QueryObject } from '../../../httpClient';
import { UserType, CreateUserType, UserMetaResponse } from '../types';

export const apiGetUsers = (filter?: QueryObject): Promise<UserMetaResponse> =>
  httpRequest
    .get('/core/users', { query: filter || {} })
    .then((res) => handleResponse<UserMetaResponse>(res))
    .then((data) => data)
    .catch();

export const apiCreateUser = (body: CreateUserType): Promise<UserType> =>
  httpRequest
    .post('/core/users', { body })
    .then((res) => handleResponse<UserType>(res))
    .then((data) => data)
    .catch();

export const apiUpdateUser = (_id: string, body: Partial<CreateUserType>): Promise<UserType> =>
  httpRequest
    .patch(`/core/users/${_id}`, { body })
    .then((res) => handleResponse<UserType>(res))
    .then((data) => data)
    .catch();

export const apiDeleteUser = (_id: string): Promise<unknown> =>
  httpRequest.delete(`/core/users/${_id}`).then(handleResponse);
