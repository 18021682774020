import { UserTutorial } from '../../../../lib/store/user/types';

/**
 * Updates the user's tutorial if given, or adds it to the array
 */
export const mergeTutorials = (
  tutorials: UserTutorial[],
  currentTutorial: UserTutorial | undefined,
  tutorialName: string,
  status: UserTutorial['status'],
): UserTutorial[] => {
  let data = [...tutorials];
  if (currentTutorial) {
    data = data.map((t) => {
      return t.name === tutorialName
        ? {
            ...t,
            status,
            timestamp: new Date(),
          }
        : t;
    });
  } else {
    data.push({
      name: tutorialName,
      status,
      timestamp: new Date(),
    });
  }

  return data;
};
