import React, { ReactElement } from 'react';
import { CircularProgress, IconButton } from '@material-ui/core';
import { FileCopyRounded } from '@material-ui/icons';
import { useDuplicateIconButtonStyles } from './styles';
import { DuplicateIconButtonProps } from './types';

export const DuplicateIconButton = (props: DuplicateIconButtonProps): ReactElement => {
  const classes = useDuplicateIconButtonStyles();
  const { loading, ...other } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <IconButton className={classes.buttonRoot} {...other}>
      {loading ? <CircularProgress size="24px" /> : <FileCopyRounded />}
    </IconButton>
  );
};
