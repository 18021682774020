import { httpRequest, handleResponse } from '../../../httpClient';
import {
  CreateLocationDataType,
  FullLocationType,
  UpdateLocationDataType,
  FullDepartmentType,
  AreaType,
} from '../types';
import { isObject } from '../../../utils/helpers';

/**
 * Locations
 */
export const apiGetLocations = (): Promise<FullLocationType[]> =>
  httpRequest
    .get('/core/locations')
    .then((res) => handleResponse<FullLocationType[]>(res))
    .then((data) => (isObject(data) ? [] : data))
    .catch();

export const apiCreateLocation = (body: CreateLocationDataType): Promise<FullLocationType> =>
  httpRequest
    .post('/core/locations', { body })
    .then((res) => handleResponse<FullLocationType>(res))
    .then((data) => data)
    .catch();

export const apiUpdateLocation = (body: UpdateLocationDataType, locationId: string): Promise<FullLocationType> =>
  httpRequest
    .patch(`/core/locations/${locationId}`, { body })
    .then((res) => handleResponse<FullLocationType>(res))
    .then((data) => data);

export const apiDeleteLocation = (_id: string): Promise<unknown> =>
  httpRequest.delete(`/core/locations/${_id}`).then(handleResponse);

/**
 * Departments
 */
export const apiCreateDepartment = (
  body: { departmentName: string },
  locationId: string,
): Promise<FullDepartmentType> =>
  httpRequest
    .post(`/core/departments/${locationId}`, { body })
    .then((res) => handleResponse<FullDepartmentType>(res))
    .then((data) => data)
    .catch();

export const apiUpdateDepartment = (
  body: { departmentName: string; _id: string },
  departmentId: string,
): Promise<FullDepartmentType> =>
  httpRequest
    .patch(`/core/departments/${departmentId}`, { body })
    .then((res) => handleResponse<FullDepartmentType>(res))
    .then((data) => data);

export const apiDeleteDepartment = (_id: string, locationId: string): Promise<unknown> =>
  httpRequest.delete(`/core/departments/${_id}/${locationId}`).then(handleResponse);

/**
 * Areas
 */
export const apiCreateArea = (body: { areaName: string }, departmentId: string): Promise<AreaType> =>
  httpRequest
    .post(`/core/areas/${departmentId}`, { body })
    .then((res) => handleResponse<AreaType>(res))
    .then((data) => data)
    .catch();

export const apiUpdateArea = (body: { areaName: string; _id: string }, areaId: string): Promise<AreaType> =>
  httpRequest
    .patch(`/core/areas/${areaId}`, { body })
    .then((res) => handleResponse<AreaType>(res))
    .then((data) => data);

export const apiDeleteArea = (_id: string, departmentId: string): Promise<unknown> =>
  httpRequest.delete(`/core/areas/${_id}/${departmentId}`).then(handleResponse);
