import { ActionType, EmployeeTypeLoading, EmployeeType } from './types';
import { ProblemType } from '..';
import { employeeTypeConstants } from './constants';

/**
 * Actions
 * Beschreibt was wir tun wollen
 */

export const setAllEmployeeTypes = (payload: EmployeeType[] = []): ActionType => ({
  type: employeeTypeConstants.EMPLOYEE_TYPE_SET_ALL,
  payload,
});

export const setLoading = (payload: EmployeeTypeLoading = ''): ActionType => ({
  type: employeeTypeConstants.EMPLOYEE_TYPE_LOADING,
  payload,
});

export const erroredEmployeeType = (payload: ProblemType | null = null): ActionType => ({
  type: employeeTypeConstants.EMPLOYEE_TYPE_ERRORED,
  payload,
});

export const successEmployeeType = (payload: EmployeeTypeLoading = ''): ActionType => ({
  type: employeeTypeConstants.EMPLOYEE_TYPE_SUCCESS,
  payload,
});

export const addEmployeeType = (payload: EmployeeType): ActionType => ({
  type: employeeTypeConstants.EMPLOYEE_TYPE_ADD,
  payload,
});

export const replaceEmployeeType = (payload: EmployeeType): ActionType => ({
  type: employeeTypeConstants.EMPLOYEE_TYPE_REPLACE,
  payload,
});

export const deleteEmployeeType = (payload: string): ActionType => ({
  type: employeeTypeConstants.EMPLOYEE_TYPE_DELETE,
  payload,
});

export const initEmployeeTypes = (): ActionType => ({
  type: employeeTypeConstants.EMPLOYEE_TYPE_INIT,
});
