import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LanguageNameSpaces } from '../../lib/constants/languageNameSpaces';

import i18n from '../../lib/i18n';
import { RootState } from '../../lib/store';
import { attemptCheckAccount } from '../../lib/store/account';
import { attemptCheckOrganisation } from '../../lib/store/organisation/effects';
import { getSubdomain, isValidSubdomain } from '../../lib/utils';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useRoutes = () => {
  /**
   * Redux State
   */
  const { checked: profileChecked, isAuthenticated, current: account, reauthenticate } = useSelector(
    (state: RootState) => state.account,
  );

  const { checked: workspaceChecked } = useSelector((state: RootState) => state.organisation);

  /**
   * Get current subdomain
   */
  const subdomain = getSubdomain(window.location.hostname);

  /**
   * Effects
   */
  const reduxDispatch = useDispatch();
  // Check organisation + Account on app load
  useEffect(() => {
    if (!workspaceChecked) {
      reduxDispatch(attemptCheckOrganisation(!isValidSubdomain(subdomain) ? null : subdomain));
    } else {
      reduxDispatch(attemptCheckAccount(!isValidSubdomain(subdomain)));
    }
  }, [reduxDispatch, subdomain, workspaceChecked]);

  useEffect(() => {
    // If Authenticated, load language files
    if (isAuthenticated) {
      i18n.loadNamespaces(LanguageNameSpaces, (err) => {
        if (err) {
          // eslint-disable-next-line no-console
          console.error({
            code: 'PAGES_ROUTES_NAMESPACE',
            type: 'error',
            message: 'Error loading language namespaces',
            stack: JSON.stringify(err),
          });
        }
      });
    }
  }, [isAuthenticated]);

  return {
    profileChecked,
    workspaceChecked,
    isAuthenticated,
    account,
    reauthenticate,
    currentPath: window.location.pathname,
  };
};
