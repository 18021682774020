import { ActionType, UserManagementLoading, UserMetaResponse, UserType } from './types';
import { userConstants } from './constants';
import { ProblemType } from '..';

/**
 * Actions
 * Beschreibt was wir tun wollen
 */

export const setUserList = (payload: UserMetaResponse): ActionType => ({
  type: userConstants.USER_SET_ALL,
  payload,
});

export const setLoading = (payload: UserManagementLoading = ''): ActionType => ({
  type: userConstants.USER_LOADING,
  payload,
});

export const erroredUser = (payload: ProblemType | null = null): ActionType => ({
  type: userConstants.USER_ERRORED,
  payload,
});

export const successUser = (payload: UserManagementLoading = ''): ActionType => ({
  type: userConstants.USER_SUCCESS,
  payload,
});

export const addUser = (payload: UserType): ActionType => ({
  type: userConstants.USER_ADD,
  payload,
});

export const replaceUser = (payload: UserType): ActionType => ({
  type: userConstants.USER_REPLACE,
  payload,
});

export const deleteUser = (payload: string): ActionType => ({
  type: userConstants.USER_DELETE,
  payload,
});

export const initUsers = (): ActionType => ({
  type: userConstants.USER_INIT,
});
