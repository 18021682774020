import React, { ReactElement, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Box } from '@material-ui/core';

import { RootState } from '../../../../lib/store';
import { LoginForm } from '../../form/LoginForm';
import { LoginFormValueType, attemptLoginAccount } from '../../../../lib/store/account';
import { BaseDialog } from '../../../molecules/window/BaseDialog';
import { successAccount } from '../../../../lib/store/account/actions';

export const ReAuthenticateDialog = (): ReactElement => {
  const { t } = useTranslation(['common']);
  const { reauthenticate } = useSelector((state: RootState) => state.account);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(successAccount(''));
  }, []);

  const handleSubmit = (formState: LoginFormValueType): void => {
    dispatch(attemptLoginAccount(formState));
  };

  return (
    <BaseDialog open={reauthenticate} title={t('common:loginDialog.popupHeading')}>
      <Box mb={2}>
        <Typography>{t('common:loginDialog.subheading')}</Typography>
      </Box>
      <LoginForm handleSubmit={handleSubmit} showPasswordForgotLink={false} />
    </BaseDialog>
  );
};
