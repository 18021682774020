import React, { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Formik, Form, FormikErrors } from 'formik';
import { Grid, Box, Button, Tooltip, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { KeyboardArrowRight, InfoRounded } from '@material-ui/icons';
import ReactGA from 'react-ga4';
import posthog from 'posthog-js';
import { RootState } from '../../../../lib/store';
import { RegisterFormValueType } from './types';
import { RegisterFormPropType } from '../../../../pages/Register/types';
import { ErrorBox } from '../../../molecules/form/errors/ErrorBox';
import { getCurrentLanguage } from '../../../../lib/utils';
import { InputField } from '../../../molecules/form/elements';
import { ForbiddenSubdomains } from '../../../../lib/constants/forbiddenSubdomains';
import { useRegisterFormStyles } from './styles';

const ToolTipRegistration = (props: { currentSlug: string }) => {
  const { t } = useTranslation();
  const { currentSlug } = props;

  return (
    <Box mt={2} mb={1}>
      <Typography align="left" variant="subtitle1">
        {currentSlug ? t('init:pages.register.forms.currentWorkspace') : t('init:pages.register.forms.helpExample')}
      </Typography>
      <Box mt={1}>
        <Typography align="left" variant="subtitle2">
          {currentSlug ? `${currentSlug}.d-simple.com` : t('init:pages.register.forms.helpExampleMandant')}
        </Typography>
      </Box>
    </Box>
  );
};

export const RegisterForm: React.FC<RegisterFormPropType> = ({ handleSubmit }): ReactElement => {
  const { t } = useTranslation(['init']);
  const { problem, success, loading } = useSelector((state: RootState) => state.account);
  const [tooltipOpen, toggleTooltip] = React.useState(false);
  const classes = useRegisterFormStyles();

  useEffect(() => {
    if (success === 'register') {
      ReactGA.event({ category: 'progress', action: 'register' });
      posthog?.capture('user_progress', { action: 'register' });
    }
  }, [success]);

  return (
    <Formik
      initialValues={{
        organisationSlug: '',
        email: '',
        emailConfirm: '',
        password: '',
        chosenModule: 'umo',
      }}
      onSubmit={(values): void => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { emailConfirm, ...body } = values; // removes the confirmation fields from the values
        handleSubmit({ ...body, chosenLanguage: getCurrentLanguage() });
      }}
      validate={(values: RegisterFormValueType): FormikErrors<RegisterFormValueType> => {
        const errors: FormikErrors<RegisterFormValueType> = {};
        if (!values.email) {
          errors.email = t('init:shared.forms.errors.required');
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
          errors.email = t('init:shared.forms.errors.invalidFormat');
        }

        if (!values.emailConfirm) {
          errors.emailConfirm = t('init:shared.forms.errors.required');
        } else if (values.email !== values.emailConfirm) {
          errors.emailConfirm = t('init:shared.forms.errors.emailNoMatch');
        }

        if (!values.password) {
          errors.password = t('init:shared.forms.errors.required');
        } else if (!/^(?=.{6,50}$)(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*\W).*/.test(values.password)) {
          errors.password = t('init:shared.forms.errors.passwordFormat');
        }

        const regex = RegExp('^[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?$');
        if (!values.organisationSlug) {
          errors.organisationSlug = t('init:shared.forms.errors.required');
        } else if (!regex.test(values.organisationSlug)) {
          errors.organisationSlug = t('init:shared.forms.errors.invalidOrgFormat');
        } else if (ForbiddenSubdomains.includes(values.organisationSlug)) {
          errors.organisationSlug = t('init:shared.forms.errors.invalidOrg');
        }

        return errors;
      }}
    >
      {({ values }): ReactElement => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputField
                required
                name="organisationSlug"
                label={t('init:pages.register.forms.labels.organisationSlug')}
                normalize={(value): string => value.toLowerCase()}
                onBlur={() => toggleTooltip(false)}
                className={classes.inputRoot}
                InputProps={{
                  endAdornment: (
                    <Box className={classes.adornmentRoot}>
                      <Box className={classes.domainRoot}>
                        {values.organisationSlug ? (
                          <span className={classes.domainText}>{values.organisationSlug}</span>
                        ) : (
                          <span className={classes.domainPlaceholder}>
                            {t('init:pages.register.forms.labels.yourWorkspace')}
                          </span>
                        )}
                      </Box>
                      <Tooltip
                        open={tooltipOpen}
                        title={
                          <Typography variant="subtitle2" style={{ color: '#fff', lineHeight: '1.5' }}>
                            {t('init:pages.register.forms.helpWorkspace') || ''}
                            <ToolTipRegistration currentSlug={values.organisationSlug} />
                          </Typography>
                        }
                      >
                        <InfoRounded
                          color="secondary"
                          onMouseEnter={() => toggleTooltip(true)}
                          onMouseLeave={() => toggleTooltip(false)}
                        />
                      </Tooltip>
                    </Box>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputField required name="email" type="email" label={t('init:pages.register.forms.labels.email')} />
            </Grid>
            <Grid item xs={12}>
              <InputField
                required
                name="emailConfirm"
                type="email"
                label={t('init:pages.register.forms.labels.emailConfirm')}
              />
            </Grid>
            <Grid item xs={12}>
              <InputField
                required
                name="password"
                type="password"
                label={t('init:pages.register.forms.labels.password')}
              />
            </Grid>
            {problem && (problem.message || problem.details) && (
              <Grid item xs={12}>
                <ErrorBox message={problem.message} details={problem.details} />
              </Grid>
            )}
            {success === 'register' && (
              <Grid item xs={12}>
                <Alert severity="success">{t('init:pages.register.content.success')}</Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button type="submit" disabled={loading === 'register'} endIcon={<KeyboardArrowRight />}>
                  {t('init:pages.register.forms.buttons.submit')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

RegisterForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};
