/**
 * Takes an object (should be user.organisation) and confirms that the type is "Organisation" + organisationSlug exists on object.
 * This needs to happen since user.organisation is a union type
 * @param obj
 */

import { FileType } from '../store/file/types';
import { OrganisationType } from '../store/organisation/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isOrganisationType = (obj: OrganisationType | any): obj is OrganisationType => {
  return obj && obj.organisationSlug && typeof obj.organisationSlug === 'string';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isFileType = (val: FileType | any): val is FileType => {
  if (val && typeof val !== 'string') {
    return (
      val &&
      val.location &&
      typeof val.location === 'string' &&
      val._id &&
      typeof val._id === 'string' &&
      val.fileName &&
      typeof val.fileName === 'string'
    );
  }
  return false;
};
