import { templateConstants } from './constants';
import { TemplateState, ActionType } from './types';

// INITIAL STATE
const initialState: TemplateState = {
  templateList: [],
  problem: null,
  loading: '',
  success: '',
};

/**
 * Reducer
 * Updated den STORE je nach ACTION type - Mitarbeiter der Auftrag ausfuehrt
 */
export const templateReducer = (state = initialState, action: ActionType): TemplateState => {
  switch (action.type) {
    case templateConstants.TEMPLATE_LOADING:
      return {
        ...state,
        loading: action.payload,
        problem: action.payload ? null : state.problem,
        success: action.payload ? '' : state.success,
      };

    case templateConstants.TEMPLATE_ERRORED:
      return {
        ...state,
        problem: action.payload,
      };

    case templateConstants.TEMPLATE_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };

    case templateConstants.TEMPLATE_SET_ALL:
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        templateList: action.payload,
      };

    case templateConstants.TEMPLATE_INIT:
      return {
        ...initialState,
      };

    case 'dSimple/RESET_APP':
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
