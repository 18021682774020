import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useUpgradeTextStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      pointerEvents: 'all',
      marginLeft: '5px',
      fontSize: '10px',
      color: theme.palette.secondary.main,
      cursor: 'pointer',
      flex: '1',
      alignSelf: 'center',
      textAlign: 'end',
      fontWeight: 600,
      '&:hover': {
        color: theme.palette.secondary.light,
      },
    },
  }),
);
