import { RefObject } from 'react';

export const scrollToTarget = (ref: RefObject<HTMLDivElement>, offset = 0): void => {
  if (ref.current) {
    const elementPosition = ref.current.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }
};
