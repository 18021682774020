import React, { ReactElement, Ref } from 'react';
import { CircularProgress, IconButton } from '@material-ui/core';
import { DeleteRounded } from '@material-ui/icons';
import { useDeleteIconButtonStyles } from './styles';
import { DeleteIconButtonProps } from './types';

export const DeleteIconButton = React.forwardRef(
  (props: DeleteIconButtonProps, ref: Ref<HTMLButtonElement> | undefined): ReactElement => {
    const classes = useDeleteIconButtonStyles();
    const { loading, ...other } = props;

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <IconButton className={classes.buttonRoot} ref={ref} {...other}>
        {loading ? <CircularProgress size="24px" /> : <DeleteRounded />}
      </IconButton>
    );
  },
);

DeleteIconButton.displayName = 'DeleteIconButton';
