/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Field, useFormikContext } from 'formik';
import { useDropzone } from 'react-dropzone';
import { Box, Button, CircularProgress } from '@material-ui/core';

import { PublishRounded } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { SingleFileFieldProps } from './types';
import { useSingleFileFieldStyles } from './styles';
import { DeleteIconButton } from '../../../button';

import { processFile } from '../../../../../lib/utils/processFile';
import { FileLibraryDialog } from '../../../../../features/FileLibrary';
import { standardOptions } from '../../../../../lib/httpClient';

export const SingleFileField = (props: SingleFileFieldProps): ReactElement => {
  const { name, type, mode = 'file', initialPreview, scale } = props;
  const { t } = useTranslation(['module-core']);

  const { setFieldValue } = useFormikContext();
  const [previewData, setPreviewData] = React.useState(initialPreview || '');
  const [fileLoading, setFileLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const onDrop = React.useCallback(async (acceptedFiles: File[]) => {
    setFileLoading(true);

    const { file, dataUrl } = await processFile(acceptedFiles[0], type === 'image');

    setFileLoading(false);
    if (file && dataUrl) {
      setFieldValue(name, mode === 'dataUrl' ? dataUrl : file);
      setPreviewData(dataUrl);
    }
  }, []);

  const handleRemove = () => {
    setFieldValue(name, '');
    setPreviewData('');
  };

  const handleOpenLibrary = () => {
    setOpen(true);
  };

  const handleSelectInLibrary = async (url: string) => {
    const response = await fetch(url, {
      method: 'GET',
      ...standardOptions(),
    });
    const data = await response.blob();
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = (e) => {
      if (typeof e?.target?.result === 'string') {
        setFieldValue(name, e?.target?.result);
        setPreviewData(e?.target?.result);
      }
    };
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    disabled: fileLoading,
    accept: type === 'image' ? 'image/*' : 'application/pdf',
    maxFiles: 1,
    onDrop,
    noClick: true,
  });

  const classes = useSingleFileFieldStyles({ isDragActive, isDragAccept, isDragReject });

  return (
    <>
      <Field name={name}>
        {(): ReactElement => {
          return (
            <Box className={classes.root}>
              <div {...getRootProps({ className: 'dropzone' })}>
                {previewData && (
                  <img
                    className={`${classes.previewImage} ${scale === 'small' ? classes.scaleSmall : ''}`}
                    src={previewData}
                    alt="preview of uploaded file"
                  />
                )}
                <p>
                  {scale === 'small' && previewData
                    ? null
                    : t(`module-core:shared.common.${type === 'image' ? 'dragNDropImage' : 'dragNDropFile'}`)}
                </p>
                <Box display="flex" alignItems="center">
                  <Button
                    variant="outlined"
                    color="primary"
                    component="label"
                    onClick={mode === 'library' ? handleOpenLibrary : undefined}
                    disabled={fileLoading}
                    endIcon={fileLoading ? <CircularProgress size="24px" /> : <PublishRounded />}
                  >
                    <>
                      {t(`module-core:shared.common.${mode === 'library' ? 'selectFile' : 'uploadFile'}`)}
                      {mode !== 'library' && <input {...getInputProps()} />}
                    </>
                  </Button>
                  {previewData && (
                    <Box ml={1}>
                      <DeleteIconButton onClick={handleRemove} size="small" />
                    </Box>
                  )}
                </Box>
              </div>
            </Box>
          );
        }}
      </Field>
      <FileLibraryDialog
        onSelect={handleSelectInLibrary}
        open={open}
        onAssetChange={() => undefined}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

SingleFileField.propTypes = {
  name: PropTypes.string.isRequired,
};
