import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Typography, Link } from '@material-ui/core';
import { attemptCheckOrganisation } from '../../lib/store/organisation/effects';
import { OrganisationForm } from '../../components/organisms/form/OrganisationForm';
import { AccessTemplate } from '../../components/templates';
import { config } from '../../lib/config';
import { isString } from '../../lib/utils/helpers';
import { LogoHeadingBox } from '../../components/molecules/box/LogoHeadingBox';
import { InfoBar } from '../../components/molecules/box/InfoBar';
import { RouterLink } from '../../components/molecules/link/RouterLink';

const Organisation = (): ReactElement => {
  const { t } = useTranslation(['init']);
  const reduxDispatch = useDispatch();
  const location = useLocation();

  const { dest } = queryString.parse(location.search);
  const handleSubmit = (organisationSlug: string): void => {
    reduxDispatch(attemptCheckOrganisation(organisationSlug));
  };

  return (
    <AccessTemplate
      infoBar={<InfoBar infoLink={config.wikiUrl || ''} />}
      header={<LogoHeadingBox title={t('init:pages.organisation.header.title')} />}
      footer={(): ReactElement => {
        return (
          <Typography align="center">
            <Trans
              i18nKey="init:shared.pages.footer.registerText"
              components={{
                register: <Link component={RouterLink} color="secondary" to="/register" />,
              }}
            />
          </Typography>
        );
      }}
    >
      <OrganisationForm dest={isString(dest) ? dest.toLowerCase() : ''} handleSubmit={handleSubmit} />
    </AccessTemplate>
  );
};
export default Organisation;
