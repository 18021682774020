import { History, To } from 'history';
import React from 'react';
import { useHistory } from 'react-router';

export const useCheckHistory = (): History => {
  const { push: pushOriginal, ...historyOriginal } = useHistory();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const push: History['push'] = (to: To, state?: any) => {
    // eslint-disable-next-line no-return-assign
    if (window.swUpdate) window.location = to as Location;
    return pushOriginal(to, state);
  };

  const history = React.useMemo(() => Object.assign(historyOriginal, { push }), []);

  return history;
};
