import { GetStateType } from '../store';

import i18n from '../i18n';
import { ForbiddenSubdomains } from '../constants/forbiddenSubdomains';
import { PermissionType } from '../store/role/types';

export const getSubdomain = (hostname: string): string | null => {
  // Die RegEx findet und speichert den domain name OHNE subdomain in urlParts.
  // Im falle von umo.d-simple.com ist urlParts = d-simple.com
  const regexParse = new RegExp('[a-z-0-9]{2,63}.[a-z.]{2,5}$');
  const urlParts = regexParse.exec(hostname);

  // Mit replace wird der string d-simple.com mit einem leeren string '' ersetzt
  // so dass nur umo. uebrig bleibt. Das slice(0, -1) loescht das letzte zeichen vom string, also den punkt bei umo.
  if (urlParts && urlParts[0]) {
    return hostname.replace(urlParts[0], '').slice(0, -1);
  }
  return '';
};

export const isValidSubdomain = (subdomain: string | null): boolean => {
  // is not valid when no subdomain or 'app'
  if (!subdomain) return false;
  if (ForbiddenSubdomains.includes(subdomain)) return false;
  return true;
};

export const getRefreshToken = (): string | null => {
  return localStorage.getItem('refreshToken');
};

export const setRefreshToken = (token: string): void => {
  return localStorage.setItem('refreshToken', token);
};

export const getOrganisationSlug = (getState: GetStateType, errorLocation = '', ignoreReport = false): string => {
  const { slug } = getState().organisation;
  if (!slug && !ignoreReport) {
    // eslint-disable-next-line no-console
    console.error({
      code: `CORE_UTIL_GOS${errorLocation ? `_${errorLocation}` : ''}`,
      type: 'error',
      message: 'Could not find slug',
    });
  }
  return slug || '';
};

export const getCurrentLanguage = (): string => {
  const lang = i18n.language || 'en';
  // Only returns language code, eg. "en" for "en-GB" or if no dash found, returns the initial string
  return lang.substring(0, lang.indexOf('-') === -1 ? lang.length : lang.indexOf('-'));
};

/**
 * Checks if one or multiple permissions return true
 * @param permissions users permissions
 * @param requiredPermissions permissions to check
 * @param all if all permissions are required
 * @returns has permission or not
 */
export const hasAccountRequiredPermission = (
  permissions: PermissionType[],
  requiredPermissions: string[],
  all = false,
): boolean => {
  if (all) {
    return permissions
      ? requiredPermissions.every((requiredPermission) =>
          permissions.some((permission) => permission.slug === requiredPermission && permission.permitted),
        )
      : false;
  }

  return permissions
    ? requiredPermissions.some((requiredPermission) =>
        permissions.some((permission) => permission.slug === requiredPermission && permission.permitted),
      )
    : false;
};

export const getPermissionBySlug = (permissions: PermissionType[], slug: string): PermissionType | undefined => {
  return permissions.find((permission) => permission.slug === slug);
};

export const guidGenerator = (): string => {
  const S4 = (): string => {
    return ((1 + Math.random()) * 0x10000 || 0).toString(16).substring(1);
  };
  return `${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
};

export const daysToMilliSeconds = (days: number): number => {
  // 24 = 1 days
  // 60 = 1 hour
  // 60000 = 1m
  return days * 24 * 60 * 60000;
};
