import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Paper, Typography } from '@material-ui/core';
import { CenteredContentTemplate } from '../../components/templates';

// eslint-disable-next-line react/require-default-props
const ErrorReport = ({ showUpdate = false }: { showUpdate?: boolean }): ReactElement => {
  const { t } = useTranslation(['init']);
  return (
    <CenteredContentTemplate>
      <Box maxWidth="600px">
        <Paper>
          <Box p={2} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box mb={2}>
              <Typography variant="h3">{t(`init:pages.errorBoundry.title${showUpdate ? 'Update' : ''}`)}</Typography>
            </Box>
            <Box mb={2}>
              <Typography align="center" variant="body1">
                {t(`init:pages.errorBoundry.content${showUpdate ? 'Update' : ''}`)}
              </Typography>
            </Box>
            <Box>
              <Button component="a" href="">
                {t(`init:shared.forms.submit.${showUpdate ? 'update' : 'reload'}`)}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </CenteredContentTemplate>
  );
};

export default ErrorReport;
