import { ClickAwayListener, Popper } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useBasePopupStyles } from './styles';
import { BasePopupProps } from './types';

export const BasePopup = (props: BasePopupProps): ReactElement => {
  const { inputElement, placement = 'top', disablePortal = false, fullWidth, children } = props;
  const classes = useBasePopupStyles({ fullWidth });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'transitions-popper' : undefined;

  const handlePopup = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const closePopup = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      {React.cloneElement(inputElement, { onClick: handlePopup, 'aria-describedby': id })}
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        className={classes.popper}
        placement={placement}
        disablePortal={!!fullWidth || disablePortal}
      >
        {(): ReactElement => (
          <ClickAwayListener onClickAway={(): void => setAnchorEl(null)}>
            {typeof children === 'function' ? children({ closePopup }) : children}
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};
