import {
  PRIMARY_TEXT_COLOR,
  SECONDARY_TEXT_COLOR,
  TRANSPARENT,
  SEMI_GRAY_TRANSPARENT,
  ULTRA_GRAY_TRANSPARENT,
} from '../../../constants';

export const muiTextField = {
  root: {
    '& .MuiOutlinedInput-input': {
      padding: '13.5px 14px',
    },
    '& .MuiInputLabel-outlined': {
      textTransform: 'uppercase',
      fontSize: '11px',
      fontWeight: '600',
      transform: 'translate(14px, 15px) scale(1)',
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: '0px',
      marginRight: '0px',
    },
    '&:hover .MuiInputLabel-outlined:not(.Mui-disabled), & .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, 0px) scale(0.75)',
    },
    '& legend': {
      display: 'none',
    },
    '& .MuiInputLabel-formControl': {
      top: '3px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      backgroundColor: SEMI_GRAY_TRANSPARENT,
      top: '0',
    },
    '& .Mui-disabled': {
      color: ULTRA_GRAY_TRANSPARENT,
    },
    '&:hover .MuiOutlinedInput-root:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      backgroundColor: TRANSPARENT,
    },
    '& .MuiOutlinedInput-root.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
      borderColor: PRIMARY_TEXT_COLOR,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
    },
    '& .MuiInputLabel-root.Mui-focused:not(.Mui-error)': {
      color: SECONDARY_TEXT_COLOR,
    },
  },
};
