import { handleResponse } from '../handleResponse';
import { httpRequest } from '../httpRequest';

/**
 * When successful server responds with new cookie
 * @param refreshToken token to refresh accessToken
 */
export const exchangeToken = (refreshToken: string): Promise<void> =>
  httpRequest
    .get('/core/auth/refresh-token', { headers: { authorization: `Bearer ${refreshToken}` } })
    .then((res) => handleResponse<void>(res));
