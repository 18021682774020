import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useTextWithIconButtonStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonRoot: {
      color: theme.palette.text.primary,
      textTransform: 'capitalize',
      '& .MuiSvgIcon-root': {
        fontSize: '30px',
        color: ({ endIconColor }: { endIconColor?: string }) => endIconColor || theme.palette.primary.main,
      },
    },
    buttonSmall: {
      fontSize: '0.85rem',
      fontWeight: 600,
    },
  }),
);
