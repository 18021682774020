import lz from 'lzutf8';
import { useSelector } from 'react-redux';
import { InstructionViewState, UseInstructionViewerProps, ActionType, Reducer } from './types';
import { logReducer } from '../../../../lib/utils/logReducer';
import { instructionViewReducer } from './instructionViewReducer';
import { mapToInitialValues, renderInitialResults } from './helpers';
import { useEnhancedReducer } from '../../../../lib/utils/useEnhancedReducer';
import { RootState } from '../../../../lib/store';

// initiell leerer array
// wird gefuellt wenn folie wechsel, oder submit
// results wird gefuellt

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useInstructionViewer = (props: UseInstructionViewerProps) => {
  /**
   * destructure props
   */
  const { initialSlides } = props;

  /**
   * Check branding
   */
  const { current } = useSelector((state: RootState) => state.organisation);
  const { branding } = current || {};

  /**
   * instructionEditorReducer
   */
  const [state, dispatch, getState] = useEnhancedReducer<
    (state: InstructionViewState, action: ActionType) => InstructionViewState,
    InstructionViewState,
    ActionType
  >(
    process.env.NODE_ENV === 'production'
      ? instructionViewReducer
      : logReducer<Reducer, InstructionViewState, ActionType>(instructionViewReducer),
    {
      slides: initialSlides,
      activeSlideIndex: 0,
      activeSlideType: initialSlides[0].type,
      activeSlideJson: lz.decompress(lz.decodeBase64(initialSlides[0].data)),
      activeSlideQuestionnaire: initialSlides[0].type === 'questionnaire' ? initialSlides[0].questionnaire : null,
      questionnaireInitialValues:
        initialSlides[0].type === 'questionnaire' ? mapToInitialValues(initialSlides[0].questionnaire) : null,
      results: renderInitialResults(initialSlides),
    },
  );

  /**
   * generate return context
   */
  return {
    // reducer state
    ...state,
    // dispatch function
    dispatch,
    // get state function
    getState,
    header: branding?.isHeaderEnabled && branding?.header ? lz.decompress(lz.decodeBase64(branding.header)) : null,
  };
};
