import React, { ReactElement } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { CheckCircleRounded } from '@material-ui/icons';
import { Typography, Divider, Link } from '@material-ui/core';
import { useStepSuccessStyles } from '../styles';
import { StepSuccessProps } from '../types';
import { RouterLink } from '../../../../components/molecules/link/RouterLink';
import { UmoAuthRoutes } from '../../../lib/constants/pagePaths';
import { PrimaryButton } from '../../../../components/molecules/button';

export const StepSuccess = (props: StepSuccessProps): ReactElement => {
  const { currentInstructionId, handleClickPreview, handleClickExecute, isExecutingPossible } = props;
  const { t } = useTranslation(['module-umo']);
  const classes = useStepSuccessStyles();
  return (
    <div className={classes.successBoxWrap}>
      <div className={classes.successBox}>
        <CheckCircleRounded className={classes.checkIcon} />
        <Typography variant="body1" align="center" className={classes.successMessage}>
          {t('module-umo:shared.wizard.successMessage')}
        </Typography>
        <Divider className={classes.divider} />
        <div className={classes.whatNextBox}>
          <Typography variant="h4" align="center">
            {t('module-umo:shared.wizard.whatNext')}
          </Typography>
          <Typography variant="body1" className={classes.whatNextText}>
            <Trans
              i18nKey={`module-umo:shared.wizard.${isExecutingPossible ? 'whatNextText' : 'whatNextTextNoExecute'}`}
              components={{
                edit: <Link component={RouterLink} to={`${UmoAuthRoutes.instructionsEdit}${currentInstructionId}`} />,
              }}
            />
          </Typography>
          <div className={classes.whatNextButtons}>
            <PrimaryButton onClick={handleClickPreview}>
              {t('module-umo:shared.wizard.previewInstruction')}
            </PrimaryButton>
            {isExecutingPossible && (
              <PrimaryButton onClick={handleClickExecute}>
                {t('module-umo:shared.wizard.executeInstruction')}
              </PrimaryButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
