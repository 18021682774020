import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { TextField, Box } from '@material-ui/core';
import { Field, FieldProps } from 'formik';
import { TextareaFieldProps } from './types';
import { useTextareaFieldStyles } from './styles';
import { useInputFieldStyles } from '../InputField/styles';

export const TextareaField = ({
  name,
  label,
  rows,
  rowsMax,
  disabled,
  fullHeight,
  charLimit,
}: TextareaFieldProps): ReactElement => {
  const inputFieldClasses = useInputFieldStyles();
  const textareaFieldClasses = useTextareaFieldStyles();
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps): ReactElement => {
        return (
          <Box position="relative">
            <TextField
              id={name}
              name={name}
              className={`${inputFieldClasses.root} ${fullHeight ? inputFieldClasses.fullHeight : ''} `}
              error={!!(meta.touched && meta.error)}
              helperText={(meta.touched && meta.error) || ''}
              onBlur={field.onBlur}
              label={label}
              onChange={field.onChange}
              value={field.value}
              fullWidth
              disabled={disabled}
              multiline
              rows={rows}
              rowsMax={rowsMax}
              inputProps={typeof charLimit === 'number' ? { maxLength: charLimit } : {}}
            />
            {typeof charLimit === 'number' && (
              <Box className={textareaFieldClasses.limitWrap}>{`${field.value.length} / ${charLimit}`}</Box>
            )}
          </Box>
        );
      }}
    </Field>
  );
};

TextareaField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  disabled: PropTypes.bool,
};

TextareaField.defaultProps = {
  disabled: false,
  rows: 1,
  rowsMax: 10,
};
