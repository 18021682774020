import { makeStyles, createStyles } from '@material-ui/core/styles';
import { SECONDARY_TEXT_COLOR_DARK } from '../../../../lib/theme/constants';

export const useConfirmDialogStyles = makeStyles(() =>
  createStyles({
    title: {
      '& .MuiTypography-root': {
        marginBottom: '0px',
      },
    },
    dialogText: {
      color: SECONDARY_TEXT_COLOR_DARK,
    },
  }),
);
