import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Typography } from '@material-ui/core';
import { isArray } from '../../../../../lib/utils/helpers';
import { ProblemDetails, ErrorBoxProps } from './types';

export const ErrorBox = ({ message, details }: ErrorBoxProps): ReactElement => {
  const { t } = useTranslation(['init']);
  return isArray<ProblemDetails[]>(details) ? (
    <Alert severity="error">
      <AlertTitle>{t('init:shared.errors.errorBoxTitle')}</AlertTitle>
      {details.map((detail) => (
        <Typography key={detail.msg}>{detail.msg}</Typography>
      ))}
    </Alert>
  ) : (
    <Alert severity="error">{message}</Alert>
  );
};

ErrorBox.propTypes = {
  message: PropTypes.string,
  details: PropTypes.arrayOf(
    PropTypes.shape({
      msg: PropTypes.string.isRequired,
      param: PropTypes.string,
    }),
  ),
};
ErrorBox.defaultProps = {
  message: '',
  details: [],
};
