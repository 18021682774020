import React, { ReactElement, ReactNode } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import { SecondaryTabBarProps } from './types';
import { StyledTabPanel } from '../../../molecules/tab/StyledTabPanel';
import { StyledTabBar } from '../../../molecules/tab/StyledTabBar';

export const SecondaryTabBar = ({
  tabItems = [],
  orientation = 'horizontal',
  variant,
  children,
}: SecondaryTabBarProps): ReactElement => {
  const [value, setValue] = React.useState(tabItems.findIndex((tabItem) => !tabItem.disabled));

  const handleChange = (newValue: number): void => {
    // set new tab
    setValue(newValue);
  };

  const elements: React.ReactNode[] = [];

  React.Children.forEach(children, (child: ReactNode) => {
    if (child) {
      elements.push(
        // eslint-disable-next-line react/no-array-index-key
        <StyledTabPanel isSecondary value={value} key={elements.length} index={elements.length}>
          {child}
        </StyledTabPanel>,
      );
    }
  });

  return (
    <Box display={orientation === 'vertical' ? 'flex' : 'block'}>
      <StyledTabBar
        isSecondary
        orientation={orientation}
        variant={variant}
        handleChange={handleChange}
        currentValue={value}
        tabItems={tabItems}
      />
      {elements}
    </Box>
  );
};

SecondaryTabBar.propTypes = {
  tabItems: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string })).isRequired,
  variant: PropTypes.oneOf(['fullWidth', 'scrollable', 'standard']),
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
};

SecondaryTabBar.defaultProps = {
  variant: 'scrollable',
  orientation: 'horizontal',
};
