import React, { ReactElement } from 'react';
import { CircularProgress, IconButton } from '@material-ui/core';
import { ClearRounded } from '@material-ui/icons';
import { CancelIconButtonProps } from './types';

export const CancelIconButton = (props: CancelIconButtonProps): ReactElement => {
  const { loading, ...other } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <IconButton {...other}>{loading ? <CircularProgress size="24px" /> : <ClearRounded />}</IconButton>
  );
};
