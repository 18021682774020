import { ActionType, FileCategoryLoading, FileCategory } from './types';
import { ProblemType } from '..';
import { fileCategoryConstants } from './constants';

/**
 * Actions
 * Beschreibt was wir tun wollen
 */

export const setAllFileCategories = (payload: FileCategory[] = []): ActionType => ({
  type: fileCategoryConstants.FILE_CATEGORY_SET_ALL,
  payload,
});

export const setLoading = (payload: FileCategoryLoading = ''): ActionType => ({
  type: fileCategoryConstants.FILE_CATEGORY_LOADING,
  payload,
});

export const erroredFileCategory = (payload: ProblemType | null = null): ActionType => ({
  type: fileCategoryConstants.FILE_CATEGORY_ERRORED,
  payload,
});

export const successFileCategory = (payload: FileCategoryLoading = ''): ActionType => ({
  type: fileCategoryConstants.FILE_CATEGORY_SUCCESS,
  payload,
});

export const addFileCategory = (payload: FileCategory): ActionType => ({
  type: fileCategoryConstants.FILE_CATEGORY_ADD,
  payload,
});

export const replaceFileCategory = (payload: FileCategory): ActionType => ({
  type: fileCategoryConstants.FILE_CATEGORY_REPLACE,
  payload,
});

export const deleteFileCategory = (payload: string): ActionType => ({
  type: fileCategoryConstants.FILE_CATEGORY_DELETE,
  payload,
});

export const initFileCategories = (): ActionType => ({
  type: fileCategoryConstants.FILE_CATEGORY_INIT,
});
