/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useUser } from './useUser';
import { ActionType } from './types';

/**
 * state context
 */

export const UserStateContext = React.createContext<Omit<ReturnType<typeof useUser>, 'dispatch'>>(undefined as any);
export const UserStateProvider = UserStateContext.Provider;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useUserStateContext = () => {
  const userState = React.useContext(UserStateContext);
  // eslint-disable-next-line no-console
  if (!userState) console.error('Missuse of useUserContext. No Context Provider detected');
  return userState;
};

/**
 * dispatch context
 */
export const UserDispatchContext = React.createContext<React.Dispatch<ActionType>>(undefined as any);
export const UserDispatchProvider = UserDispatchContext.Provider;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useUserDispatchContext = () => {
  const userDispatch = React.useContext(UserDispatchContext);
  if (!userDispatch)
    // eslint-disable-next-line no-console
    console.error('Missuse of useUserDispatchContext. No Context Provider detected');
  return userDispatch;
};
