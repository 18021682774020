import React, { ReactElement, useState } from 'react';
import { IconButton, Drawer, ListSubheader, List, ListItem, ListItemIcon, ListItemText, Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { SettingsApplicationsRounded, AccountBoxRounded } from '@material-ui/icons';

import Menu from '@material-ui/icons/Menu';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { MobileMenuProps } from './types';
import { PRIMARY_TEXT_COLOR_DARK, PRIMARY_TEXT_COLOR } from '../../lib/theme/constants';
import { useCheckHistory } from '../../lib/hooks/useCheckHistory';
import {
  hasBillingPermissions,
  hasSystemSettingsPermissions,
  hasRoleManagementPermissions,
  hasUserManagementPermissions,
  hasCompanyStructurePermissions,
} from '../../lib/utils/permissionChecker';
import { AuthRoutes } from '../../lib/constants/pagePaths';
import { ProfileDialog } from '../../components/organisms/window/ProfileDialog';
import { attemptLogoutAccount } from '../../lib/store/account';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: '19px',
    },
    nestedText: {
      color: PRIMARY_TEXT_COLOR,
      fontSize: '14px',
    },
    listItemIcon: {
      minWidth: '32px',
    },
    topItem: {
      paddingBottom: '0',
    },
  }),
);

export const MobileMenu = ({ permissions }: MobileMenuProps): ReactElement => {
  const { t } = useTranslation(['menu']);
  const dispatch = useDispatch();
  const classes = useStyles();

  const history = useCheckHistory();

  const [openDrawer, setOpenDrawer] = useState(false);

  const [profilePopupOpen, setProfilePopupOpen] = useState(false);

  const toggleDrawer = () => {
    setOpenDrawer((value) => !value);
  };

  const navigate = (to: string) => {
    toggleDrawer();
    history.push(to);
  };

  const handleLogout = (): void => {
    toggleDrawer();
    dispatch(attemptLogoutAccount());
  };

  const handleProfilePopup = (): void => {
    toggleDrawer();
    setProfilePopupOpen(true);
  };

  const handleProfilePopupClose = (): void => {
    setProfilePopupOpen(false);
  };

  return (
    <>
      <IconButton edge="start" color="secondary" aria-label="open drawer" onClick={toggleDrawer}>
        <Menu />
      </IconButton>

      {/* The outside of the drawer */}
      <Drawer
        anchor="right" // from which side the drawer slides in
        variant="temporary" // if and how easily the drawer can be closed
        open={openDrawer} // if open is true, drawer is shown
        onClose={toggleDrawer} // function that is called when the drawer should close
      >
        <Box>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <div style={{ color: PRIMARY_TEXT_COLOR_DARK }}>
                <ListSubheader color="inherit" component="div" id="nested-list-subheader">
                  {t('menu:navbar.menu').toUpperCase()}
                </ListSubheader>
              </div>
            }
            className={classes.root}
          >
            <ListItem className={classes.topItem}>
              <ListItemIcon className={classes.listItemIcon}>
                <SettingsApplicationsRounded color="secondary" />
              </ListItemIcon>
              <ListItemText primary={t('menu:navbar.settings.text')} />
            </ListItem>
            {hasCompanyStructurePermissions(permissions) && (
              <List component="div" disablePadding>
                <ListItem button className={classes.nested} onClick={() => navigate(AuthRoutes.companyStructure)}>
                  <ListItemText
                    className={classes.nestedText}
                    primaryTypographyProps={{ variant: 'body2' }}
                    primary={t('menu:navbar.settings.dropdown.companystructure')}
                  />
                </ListItem>
              </List>
            )}

            {hasUserManagementPermissions(permissions) && (
              <List component="div" disablePadding>
                <ListItem button className={classes.nested} onClick={() => navigate(AuthRoutes.userManagement)}>
                  <ListItemText
                    className={classes.nestedText}
                    primaryTypographyProps={{ variant: 'body2' }}
                    primary={t('menu:navbar.settings.dropdown.usermanagement')}
                  />
                </ListItem>
              </List>
            )}

            {hasRoleManagementPermissions(permissions) && (
              <List component="div" disablePadding>
                <ListItem button className={classes.nested} onClick={() => navigate(AuthRoutes.roleManagement)}>
                  <ListItemText
                    className={classes.nestedText}
                    primaryTypographyProps={{ variant: 'body2' }}
                    primary={t('menu:navbar.settings.dropdown.rolemanagement')}
                  />
                </ListItem>
              </List>
            )}

            {hasSystemSettingsPermissions(permissions) && (
              <List component="div" disablePadding>
                <ListItem button className={classes.nested} onClick={() => navigate(AuthRoutes.systemSettings)}>
                  <ListItemText
                    className={classes.nestedText}
                    primaryTypographyProps={{ variant: 'body2' }}
                    primary={t('menu:navbar.settings.dropdown.systemsettings')}
                  />
                </ListItem>
              </List>
            )}

            {hasBillingPermissions(permissions) && (
              <List component="div" disablePadding>
                <ListItem button className={classes.nested} onClick={() => navigate(AuthRoutes.billing)}>
                  <ListItemText
                    className={classes.nestedText}
                    primaryTypographyProps={{ variant: 'body2' }}
                    primary={t('menu:navbar.settings.dropdown.billing')}
                  />
                </ListItem>
              </List>
            )}
            <ListItem className={classes.topItem} style={{ paddingTop: '16px' }}>
              <ListItemIcon className={classes.listItemIcon}>
                <AccountBoxRounded color="secondary" />
              </ListItemIcon>
              <ListItemText primary={t('menu:navbar.profile.text')} />
            </ListItem>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} onClick={handleProfilePopup}>
                <ListItemText
                  primaryTypographyProps={{ variant: 'body2' }}
                  className={classes.nestedText}
                  primary={t('menu:navbar.profile.dropdown.profilesettings')}
                />
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} onClick={handleLogout}>
                <ListItemText
                  className={classes.nestedText}
                  primaryTypographyProps={{ variant: 'body2' }}
                  primary={t('menu:navbar.profile.dropdown.logout')}
                />
              </ListItem>
            </List>
          </List>
        </Box>
      </Drawer>
      <ProfileDialog open={profilePopupOpen} onClose={handleProfilePopupClose} />
    </>
  );
};
