import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { LogoHeadingBoxProps } from './types';
import { MainLogo } from '../../../atoms/images/MainLogo';

export const LogoHeadingBox = ({ title, logo }: LogoHeadingBoxProps): ReactElement => {
  return (
    <Box display="flex" flexWrap="wrap" justifyContent="center">
      <Box>{logo || <MainLogo />}</Box>
      <Box p={1.5} flex="1 0 100%">
        {title && (
          <Typography align="center" variant="h5" color="textSecondary">
            {title}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

LogoHeadingBox.propTypes = {
  title: PropTypes.string.isRequired,
  logo: PropTypes.element,
};

LogoHeadingBox.defaultProps = {
  logo: undefined,
};
