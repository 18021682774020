import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Grid, Box, Button, Link } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { KeyboardArrowRight } from '@material-ui/icons';
import { Formik, Form, FormikErrors } from 'formik';
import { RootState } from '../../../../lib/store';
import { LoginFormPropType } from '../../../../pages/Login/types';
import { LoginFormValueType } from '../../../../lib/store/account';
import { NonAuthRoutes } from '../../../../lib/constants/pagePaths';
import { ErrorBox } from '../../../molecules/form/errors/ErrorBox';
import { InputField } from '../../../molecules/form/elements';
import { RouterLink } from '../../../molecules/link/RouterLink';

export const LoginForm: React.FC<LoginFormPropType> = ({
  handleSubmit,
  showPasswordForgotLink = true,
}): ReactElement => {
  const { t } = useTranslation(['init']);
  const { problem, loading, success } = useSelector((state: RootState) => state.account);

  return (
    <Formik
      initialValues={{
        identification: '',
        password: '',
      }}
      onSubmit={(values): void => {
        handleSubmit(values);
      }}
      validate={(values: LoginFormValueType): FormikErrors<LoginFormValueType> => {
        const errors: FormikErrors<LoginFormValueType> = {};

        if (!values.identification) {
          errors.identification = t('init:shared.forms.errors.required');
        }
        // else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        //   errors.email = t('init:shared.forms.errors.invalidFormat');
        // }
        if (!values.password) {
          errors.password = t('init:shared.forms.errors.required');
        }
        return errors;
      }}
    >
      {(): ReactElement => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InputField required name="identification" label={t('init:shared.forms.labels.identification')} />
            </Grid>
            <Grid item xs={12}>
              <InputField required name="password" type="password" label={t('init:shared.forms.labels.password')} />

              {showPasswordForgotLink && (
                <Box mt={2}>
                  <Link
                    rel="noopener"
                    component={RouterLink}
                    color="textSecondary"
                    variant="body2"
                    to={NonAuthRoutes.forgot}
                  >
                    {t('init:pages.login.content.forgotPassword')}
                  </Link>
                </Box>
              )}
            </Grid>

            {problem && (problem.message || problem.details) && (
              <Grid item xs={12}>
                <ErrorBox message={problem.message} details={problem.details} />
              </Grid>
            )}
            {success === 'verify' && (
              <Grid item xs={12}>
                <Alert severity="success">{t('init:pages.login.content.success')}</Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button type="submit" disabled={loading === 'login'} endIcon={<KeyboardArrowRight />}>
                  {t('init:shared.forms.submit.login')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  showPasswordForgotLink: PropTypes.bool,
};

LoginForm.defaultProps = {
  showPasswordForgotLink: true,
};
