import { archiveConstants } from './constants';
import { ArchiveState, ActionType } from './types';

// INITIAL STATE
const initialState: ArchiveState = {
  archiveList: [],
  problem: null,
  loading: '',
  success: '',
};

/**
 * Reducer
 * Updated den STORE je nach ACTION type - Mitarbeiter der Auftrag ausfuehrt
 */
export const archiveReducer = (state = initialState, action: ActionType): ArchiveState => {
  switch (action.type) {
    case archiveConstants.ARCHIVE_LOADING:
      return {
        ...state,
        loading: action.payload,
        problem: action.payload ? null : state.problem,
        success: action.payload ? '' : state.success,
      };

    case archiveConstants.ARCHIVE_ERRORED:
      return {
        ...state,
        problem: action.payload,
      };

    case archiveConstants.ARCHIVE_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };

    case archiveConstants.ARCHIVE_SET_ALL:
      return {
        ...state,
        archiveList: action.payload,
      };

    case archiveConstants.ARCHIVE_INIT:
      return {
        ...initialState,
      };

    case 'dSimple/RESET_APP':
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
