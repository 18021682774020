import React, { ReactElement, useState } from 'react';
import { Grid, Paper, Box, Typography, Link } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import { Formik, FormikErrors, Form, Field, FieldProps } from 'formik';
import dayjs from 'dayjs';
import { InfoRounded } from '@material-ui/icons';
import { useStepMetaStyles } from '../styles';
import { InputField, SelectField, DateField } from '../../../../components/molecules/form/elements';
import { InstructionTypeField } from '../../../components/organisms/form/fields/InstructionTypeField';
import { daysToMilliSeconds } from '../../../../lib/utils';
import { WizardMetaFormValues, StepMetaProps } from '../types';
import { DATE_FORMAT } from '../../../../lib/constants/dateFormat';
import { RouterLink } from '../../../../components/molecules/link/RouterLink';
import { UmoAuthRoutes } from '../../../lib/constants/pagePaths';

export const StepMeta = (props: StepMetaProps): ReactElement | null => {
  const { metaFormRef, initialValues } = props;

  const classes = useStepMetaStyles();
  const { t } = useTranslation(['init', 'module-umo']);

  const [focusedField, setFocusedField] = useState<string | null>(null);

  const handleFocus = (fieldName: string) => {
    setFocusedField(fieldName);
  };

  const handleBlur = () => undefined;

  return (
    <Grid container spacing={3} className={classes.gridContainer}>
      <Grid item xs={12} md={6}>
        <Paper variant="outlined" className={classes.paperRoot}>
          <Box mb={2} width="100%">
            <Typography variant="h4">{t('module-umo:shared.wizard.metaData')}</Typography>
          </Box>
          <Formik
            innerRef={metaFormRef}
            initialValues={initialValues}
            onSubmit={(): void => undefined}
            validate={(values): FormikErrors<WizardMetaFormValues> => {
              const errors: FormikErrors<WizardMetaFormValues> = {};

              if (!values.instructionName) {
                errors.instructionName = t('init:shared.forms.errors.required');
              }

              if (!values.instructionType) {
                errors.instructionType = t('init:shared.forms.errors.required');
              }

              if (!values.startDate) {
                errors.startDate = t('init:shared.forms.errors.required');
              } else if (!dayjs(values.startDate, DATE_FORMAT).isValid()) {
                errors.startDate = t('common:forms.elements.dateField.invalid');
              }

              if (!values.intervalInMilliSeconds) {
                errors.intervalInMilliSeconds = t('init:shared.forms.errors.required');
              }

              return errors;
            }}
          >
            {(): ReactElement => {
              return (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputField
                        onFocus={() => handleFocus('infoName')}
                        onBlur={handleBlur}
                        autoFocus
                        name="instructionName"
                        required
                        label={t('module-umo:shared.pageBuilder.forms.labels.instructionName')}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InstructionTypeField
                        hideExternal
                        required
                        name="instructionType"
                        onFocus={() => handleFocus('infoType')}
                        onBlur={handleBlur}
                      />
                      <Field name="instructionTypeCategory">
                        {({ field }: FieldProps): ReactElement => {
                          return (
                            <input
                              id="instructionTypeCategory"
                              name="instructionTypeCategory"
                              type="hidden"
                              value={field.value}
                            />
                          );
                        }}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <SelectField
                        required
                        onFocus={() => handleFocus('infoInterval')}
                        onBlur={handleBlur}
                        name="intervalInMilliSeconds"
                        label={t('module-umo:pages.instructionsAssign.forms.labels.interval')}
                        options={[
                          {
                            label: t('module-umo:pages.instructionsAssign.forms.labels.twoYear'),
                            value: daysToMilliSeconds(730),
                          },
                          {
                            label: t('module-umo:pages.instructionsAssign.forms.labels.yearly'),
                            value: daysToMilliSeconds(365),
                          },
                          {
                            label: t('module-umo:pages.instructionsAssign.forms.labels.halfYearly'),
                            value: daysToMilliSeconds(180),
                          },
                          {
                            label: t('module-umo:pages.instructionsAssign.forms.labels.quarterly'),
                            value: daysToMilliSeconds(90),
                          },
                          {
                            label: t('module-umo:pages.instructionsAssign.forms.labels.monthly'),
                            value: daysToMilliSeconds(30),
                          },
                          {
                            label: t('module-umo:pages.instructionsAssign.forms.labels.weekly'),
                            value: daysToMilliSeconds(7),
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <DateField
                        onFocus={() => handleFocus('infoStartDate')}
                        onBlur={handleBlur}
                        required
                        name="startDate"
                        label={t('module-umo:pages.instructionsAssign.forms.labels.startDate')}
                      />
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Paper>
      </Grid>
      {focusedField && (
        <Grid item xs={false} md={6} className={classes.infoGrid}>
          <Box className={classes.infoWrap}>
            <Box display="flex" mb={2}>
              <Box mr={2}>
                <InfoRounded />
              </Box>
              <Typography variant="h4">{t(`module-umo:shared.wizard.meta.${focusedField}Heading`)}</Typography>
            </Box>

            <Typography paragraph variant="body1">
              <Trans
                i18nKey={`module-umo:shared.wizard.meta.${focusedField}Text`}
                components={{
                  instructionType: <Link component={RouterLink} target="_blank" to={UmoAuthRoutes.settings} />,
                }}
              />
            </Typography>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
