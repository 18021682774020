import React, { ReactElement } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import { useTextWithIconButtonStyles } from './styles';
import { TextWithIconButtonProps } from './types';

export const TextWithIconButton = (props: TextWithIconButtonProps): ReactElement => {
  const { children, small, endIconColor, endIcon, loading, ...other } = props;
  const classes = useTextWithIconButtonStyles({ endIconColor });

  return (
    <Button
      variant="text"
      size={small ? 'medium' : 'large'}
      disabled={loading}
      className={clsx(classes.buttonRoot, small && classes.buttonSmall)}
      endIcon={endIcon ? loading ? <CircularProgress size="24px" /> : endIcon : undefined}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {children || ''}
    </Button>
  );
};
