export enum fileCategoryConstants {
  FILE_CATEGORY_SET_ALL = 'dSimple/fileCategory/SET_ALL',
  FILE_CATEGORY_LOADING = 'dSimple/fileCategory/LOADING',
  FILE_CATEGORY_ERRORED = 'dSimple/fileCategory/ERRORED',
  FILE_CATEGORY_SUCCESS = 'dSimple/fileCategory/SUCCESS',
  FILE_CATEGORY_ADD = 'dSimple/fileCategory/ADD',
  FILE_CATEGORY_REPLACE = 'dSimple/fileCategory/REPLACE',
  FILE_CATEGORY_DELETE = 'dSimple/fileCategory/DELETE',
  FILE_CATEGORY_INIT = 'dSimple/fileCategory/INIT',
}
