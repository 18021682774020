import { getCurrentLanguage } from '../../utils';

export const standardOptions = (
  otherHeaders = {},
  isFormData = false,
): { headers: HeadersInit; credentials?: 'include' } => {
  const language = getCurrentLanguage();
  return {
    headers: {
      ...(!isFormData && { 'Content-Type': 'application/json' }),
      Accept: 'application/json',
      'Accept-Language': language,
      ...otherHeaders,
    },
    credentials: 'include',
  };
};
