/* eslint-disable @typescript-eslint/no-explicit-any */
// Checks if an item is an Object
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const isObject = (item: any, notEmpty = false): boolean => {
  return notEmpty
    ? item != null && item.constructor.name === 'Object' && Object.keys(item).length !== 0
    : item != null && item.constructor.name === 'Object';
};

// Checks if an item is a string

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const isString = (item: any, notEmpty = true): item is string => {
  return notEmpty ? item && typeof item === 'string' : typeof item === 'string';
};

// Checks if an item is a string
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const isArray = <T extends unknown>(item: any, notEmpty = true): item is T => {
  return notEmpty ? item && item.length && item.length > 0 : item && item.length;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const arraysEqual = (_arr1: any, _arr2: any): boolean => {
  if (!Array.isArray(_arr1) || !Array.isArray(_arr2) || _arr1.length !== _arr2.length) return false;

  const arr1 = _arr1.concat().sort();
  const arr2 = _arr2.concat().sort();

  for (let i = 0; i < arr1.length; i += 1) {
    if (arr1[i] !== arr2[i]) return false;
  }

  return true;
};

export const scrollIntoViewWithOffset = (element: HTMLElement, offset: number): void => {
  window.scrollTo({
    behavior: 'smooth',
    top: element.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset,
  });
};

export const escapeRegExp = (input: string): string => {
  return input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};
