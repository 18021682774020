import { useState, useRef, useEffect } from 'react';

import { TutorialStep } from './types';

export const useCheckMounted = (steps: TutorialStep[]): boolean => {
  // used to determine if all targets are rendered
  const [mounted, setMounted] = useState(false);
  const timesRunRef = useRef(0);

  useEffect(() => {
    if (!mounted) {
      const interval = setInterval(() => {
        timesRunRef.current += 1;
        let exit = false;

        if (timesRunRef.current === 10) {
          // eslint-disable-next-line no-console
          console.error("Couldn't find all targets", {
            type: 'error',
            code: 'TUTORIAL_TARGET_NOT_FOUND',
            message: "Couldn't find all targets",
            steps,
          });
          clearInterval(interval);
        }

        for (let index = 0; index < steps.length; index += 1) {
          const step = steps[index];
          const el = typeof step.target === 'string' ? document.querySelector(step.target) : step.target;
          // if any el not mounted, abort

          if (!el && !step.optional) {
            exit = true;
            break;
          }
        }

        if (!exit) {
          setMounted(true);
          clearInterval(interval);
        }
      }, 500);
    }
  }, [steps, mounted]);

  return mounted;
};
