import lz from 'lzutf8';
import { actionTypes } from './constants';
import { InstructionViewState, ActionType } from './types';
import { replaceInResult, mapToInitialValuesFromResult, renderInitialResults } from './helpers';

export const instructionViewReducer = (state: InstructionViewState, action: ActionType): InstructionViewState => {
  switch (action.type) {
    case actionTypes.CHANGE_ACTIVE_SLIDE_INDEX:
      if (action.payload < state.slides.length && action.payload >= 0) {
        const slide = state.slides[action.payload];

        if (!slide) return state;

        return {
          ...state,
          activeSlideIndex: action.payload,
          activeSlideType: slide.type,
          activeSlideJson: lz.decompress(lz.decodeBase64(slide.data)),
          activeSlideQuestionnaire: slide.type === 'questionnaire' ? slide.questionnaire : null,
          questionnaireInitialValues:
            slide.type === 'questionnaire' ? mapToInitialValuesFromResult(slide._id, state.results) : null,
        };
      }
      return state;

    case actionTypes.REPLACE_RESULT:
      return {
        ...state,
        results: replaceInResult(state.results, action.payload),
      };

    case actionTypes.RESET_RESULT:
      return {
        ...state,
        results: renderInitialResults(state.slides),
      };

    default:
      return state;
  }
};
