import React, { useEffect, ReactElement, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, I18nextProvider } from 'react-i18next';

import { List, ListItemText, Grid, Typography, Paper } from '@material-ui/core';

import { StepTemplatesProps } from '../types';
import { RootState } from '../../../../lib/store';
import { attemptGetTemplates } from '../../../lib/store/template/effects';

import { ListItemEditable } from '../../../../components/molecules/list/ListItemEditable';

import { PlaceholderBox } from '../../../../components/molecules/box/PlaceholderBox';
import { ListSkeleton } from '../../../../components/molecules/skeleton/ListSkeleton';
import { useStepTemplatesStyles } from '../styles';
import { InstructionViewer } from '../../InstructionViewer';
import { instructionInstance } from '../../../../lib/i18n/instructions-i18n';

// const CATEGORIES = [
//   {
//     title: 'module-umo:shared.templates.categories',
//     name: 'module-umo:shared.templates.multiStepInstructions',
//     value: 'multi-step-instruction',
//   },
// ];

const StepTemplates = (props: StepTemplatesProps): ReactElement => {
  const { selectedCategory, initialLanguage, selectedTemplateId, handleSelectTemplate } = props;
  const classes = useStepTemplatesStyles();

  const reduxDispatch = useDispatch();
  const { t } = useTranslation();
  const { templateList, loading } = useSelector((state: RootState) => state.template);

  const isLoading = loading === 'templateGet';

  const activeTemplate = useMemo(() => {
    return templateList.find((template) => template._id === selectedTemplateId);
  }, [selectedTemplateId, templateList]);

  useEffect(() => {
    reduxDispatch(
      attemptGetTemplates({
        sort: 'sortNumber',
        type: 'static',
        category: selectedCategory || undefined,
        language: initialLanguage,
      }),
    );
  }, [selectedCategory, initialLanguage]);

  React.useLayoutEffect(() => {
    if (initialLanguage) {
      instructionInstance.changeLanguage(initialLanguage);
    }
  }, [initialLanguage]);

  // const handleSelectCategory = (value: string) => {
  //   handleSelectTemplate('', [], 'de-DE');
  //   setSelectedCategory(value);
  // };

  return (
    <Grid className={classes.gridContainer} container spacing={4}>
      <Grid item xs={12} sm={12} md={4} lg={3} className={classes.gridItem1}>
        <Paper variant="outlined" className={classes.paper}>
          {/* <Typography variant="h6">{t('module-umo:shared.wizard.categories')}</Typography>

          <div>
            {CATEGORIES.map((category) => {
              return (
                <Chip
                  key={category.name}
                  className={classes.chip}
                  color={category.value === selectedCategory ? 'primary' : 'default'}
                  label={t(category.name)}
                  onClick={() => handleSelectCategory(category.value)}
                />
              );
            })}
          </div>

          <Box my={2}>
            <Divider />
          </Box> */}

          <Typography variant="h6">{t('module-umo:shared.wizard.templates')}</Typography>

          <List className={classes.templateListWrap}>
            {isLoading ? (
              <ListSkeleton amountItems={4} />
            ) : templateList.length === 0 ? (
              <PlaceholderBox helpText={t('module-umo:shared.wizard.noVisibleTemplate')} />
            ) : (
              templateList.map((template) => {
                return (
                  <ListItemEditable
                    onClick={() => handleSelectTemplate(template._id, template.slides, template.language)}
                    button
                    key={template._id}
                    selected={template._id === selectedTemplateId}
                  >
                    <ListItemText primary={template.title || ''} />
                  </ListItemEditable>
                );
              })
            )}
          </List>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={9} className={classes.gridChild}>
        <Paper variant="outlined" className={classes.paper}>
          <Typography variant="h6">{t('module-umo:shared.wizard.preview')}</Typography>

          {activeTemplate ? (
            <I18nextProvider i18n={instructionInstance}>
              <InstructionViewer
                key={activeTemplate._id}
                initialSlides={activeTemplate.slides}
                instructionName={activeTemplate.title || ''}
                instructionType=""
                createdBy=""
                previewMode
                handleReset={() => undefined}
                isWizardMode
                hideHeader
              />
            </I18nextProvider>
          ) : (
            <PlaceholderBox helpText={t('module-umo:shared.wizard.howToSelectTemplate')} />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default StepTemplates;
