import { actionTypes } from './constants';
import { RoleStateType, ActionType } from './types';

/**
 * Reducer
 * Updated den STORE je nach ACTION type
 */
export const roleReducer = (state: RoleStateType, action: ActionType): RoleStateType => {
  switch (action.type) {
    case actionTypes.SET_ALL:
      return {
        ...state,
        roles: action.payload,
      };

    case actionTypes.ADD:
      return {
        ...state,
        roles: [...state.roles, action.payload],
      };

    case actionTypes.REMOVE:
      return {
        ...state,
        roles: state.roles.filter((item) => item.tempId !== action.payload),
      };

    default:
      return state;
  }
};
