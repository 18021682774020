import { EmployeeTypeState, ActionType } from './types';
import { employeeTypeConstants } from './constants';

// INITIAL STATE
const initialState: EmployeeTypeState = {
  employeeTypes: [],
  problem: null,
  loading: '',
  success: '',
};

/**
 * Reducer
 * Updated den STORE je nach ACTION type - Mitarbeiter der Auftrag ausfuehrt
 */
export const employeeTypeReducer = (state = initialState, action: ActionType): EmployeeTypeState => {
  switch (action.type) {
    case employeeTypeConstants.EMPLOYEE_TYPE_SET_ALL:
      return {
        ...state,
        employeeTypes: action.payload,
      };

    case employeeTypeConstants.EMPLOYEE_TYPE_LOADING:
      return {
        ...state,
        loading: action.payload,
        problem: action.payload ? null : state.problem,
        success: action.payload ? '' : state.success,
      };

    case employeeTypeConstants.EMPLOYEE_TYPE_ERRORED:
      return {
        ...state,
        problem: action.payload,
      };

    case employeeTypeConstants.EMPLOYEE_TYPE_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };

    case employeeTypeConstants.EMPLOYEE_TYPE_ADD:
      return {
        ...state,
        employeeTypes: [...state.employeeTypes, action.payload],
      };

    case employeeTypeConstants.EMPLOYEE_TYPE_REPLACE:
      return {
        ...state,
        employeeTypes: state.employeeTypes.map((employeeType) =>
          employeeType._id === action.payload._id ? action.payload : employeeType,
        ),
      };

    case employeeTypeConstants.EMPLOYEE_TYPE_DELETE:
      return {
        ...state,
        employeeTypes: state.employeeTypes.filter((employeeType) => employeeType._id !== action.payload),
      };

    case employeeTypeConstants.EMPLOYEE_TYPE_INIT:
      return {
        ...initialState,
      };

    case 'dSimple/RESET_APP':
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
