export enum accountConstants {
  ACCOUNT_LOADING = 'dSimple/account/LOADING',
  ACCOUNT_ERRORED = 'dSimple/account/ERRORED',
  ACCOUNT_CURRENT = 'dSimple/account/CURRENT',
  ACCOUNT_SUCCESS = 'dSimple/account/SUCCESS',
  ACCOUNT_CHECK = 'dSimple/account/CHECK',
  ACCOUNT_SET_CSRF = 'dSimple/account/SET_CSRF',
  ACCOUNT_SET_HOME_URL = 'dSimple/account/SET_HOME_URL',
  SET_SHOULD_CHANGE_PASSWORD = 'dSimple/account/SET_SHOULD_CHANGE_PASSWORD',
  ACCOUNT_PROFILE_AUTH = 'dSimple/account/PROFILE_AUTH',
  ACCOUNT_CURRENT_ROLE_REPLACE = 'dSimple/account/ACCOUNT_CURRENT_ROLE_REPLACE',
  ACCOUNT_REAUTHENTICATE = 'dSimple/account/REAUTHENTICATE',
  ACCOUNT_INIT = 'dSimple/account/INIT',
}

export enum accountUmoConstants {
  ACCOUNT_UMO_ABSOLVED_REPLACE = 'dSimple/account/umo/ABSOLVED_REPLACE',
}
