import React, { ReactElement, useCallback, useState, useEffect } from 'react';
import { throttle } from 'throttle-debounce';
import { Box, IconButton, List, ListItem, ListItemText, Paper, Typography } from '@material-ui/core';
import { NavigateNextRounded, NavigateBeforeRounded, KeyboardArrowUpRounded } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { PageNavigationProps } from './types';

import { PrimaryButton } from '../../../../../components/molecules/button';
import { BasePopup } from '../../../../../components/molecules/window/BasePopup';
import { RootState } from '../../../../../lib/store';
import { RouterLink } from '../../../../../components/molecules/link/RouterLink';
import { useCheckHistory } from '../../../../../lib/hooks/useCheckHistory';
import { UmoAuthRoutes } from '../../../../lib/constants/pagePaths';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrap: {
      flexWrap: 'wrap',
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
        '& button, & a': {
          fontSize: '10px',
        },
      },
    },
    evaluate: {
      background: theme.palette.secondary.main,
      '&:hover': {
        background: theme.palette.secondary.light,
      },
    },
    helperText: {
      position: 'absolute',
      right: 0,
      top: '-20px',
      fontSize: '10px',
    },
  }),
);

export const PageNavigation = (props: PageNavigationProps): ReactElement => {
  const {
    previewMode,
    evaluated,
    activeSlideIndex,
    totalSlides,
    handlePrevClick,
    handleNextClick,
    handleSelectPage,
    handleEvaluate,
    isEvaluating,
    handleReset,
    canEvaluate,
    helperText,
    disabledNext,
    isCapturing,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useCheckHistory();
  const { homeUrl } = useSelector((state: RootState) => state.account);
  const [showHelperText, setShowHelperText] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let timer: any = null;

  // when the slideIndex changes, init value to false, and set timeout
  useEffect(() => {
    setShowHelperText(false);

    if (timer) {
      timer = null;
    }

    timer = setTimeout(() => {
      setShowHelperText(true);
    }, 3000);

    return () => {
      timer = null;
    };
  }, [activeSlideIndex, timer]);

  // homeUrl is empty when openInstruction
  const myUmoListUrl = homeUrl ? UmoAuthRoutes.myInstructions : '';

  const handleBack = () => {
    history.push(myUmoListUrl);
  };

  const handleEvaluateThrottle = useCallback(throttle(2000, handleEvaluate, { noTrailing: true }), []);

  return (
    <>
      <Box display="flex" alignItems="center" className={classes.wrap}>
        {evaluated ? (
          <>
            {evaluated === 'passed' && myUmoListUrl ? (
              <PrimaryButton component={RouterLink} to={myUmoListUrl}>
                {t('shared.instructionViewer.backHome')}
              </PrimaryButton>
            ) : evaluated === 'failed' && myUmoListUrl ? (
              <>
                <Box mr={1}>
                  <PrimaryButton component={RouterLink} to={myUmoListUrl}>
                    {t('shared.instructionViewer.backHome')}
                  </PrimaryButton>
                </Box>
                <PrimaryButton
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  {t('shared.instructionViewer.repeat')}
                </PrimaryButton>
              </>
            ) : evaluated === 'failed' ? (
              <PrimaryButton
                onClick={() => {
                  handleReset();
                }}
              >
                {t('shared.instructionViewer.repeat')}
              </PrimaryButton>
            ) : (
              <PrimaryButton
                onClick={() => {
                  window.location.reload();
                }}
              >
                {t('shared.instructionViewer.backHome')}
              </PrimaryButton>
            )}
          </>
        ) : (
          <>
            {showHelperText && helperText && (
              <Typography variant="subtitle2" className={classes.helperText}>
                {helperText}
              </Typography>
            )}
            <Box mr={1}>
              <PrimaryButton
                disabled={activeSlideIndex === 0}
                startIcon={<NavigateBeforeRounded />}
                endIcon={null}
                loading={isCapturing === 'prev'}
                onClick={handlePrevClick}
              >
                {t('shared.instructionViewer.infoBox.prev')}
              </PrimaryButton>
            </Box>

            <Box display="flex" justifyContent="flex-end" flex="1">
              <PrimaryButton
                endIcon={<NavigateNextRounded />}
                disabled={disabledNext}
                className={clsx(activeSlideIndex === totalSlides - 1 && classes.evaluate)}
                loading={isEvaluating || isCapturing === 'next'}
                onClick={
                  activeSlideIndex === totalSlides - 1
                    ? previewMode || !canEvaluate
                      ? handleBack
                      : handleEvaluateThrottle
                    : handleNextClick
                }
              >
                {activeSlideIndex === totalSlides - 1
                  ? previewMode || !canEvaluate
                    ? t('shared.instructionViewer.backHome')
                    : t('shared.instructionViewer.infoBox.evaluate')
                  : t('shared.instructionViewer.infoBox.next')}
              </PrimaryButton>
            </Box>
            {previewMode && (
              <Box ml={1}>
                <BasePopup
                  placement="top"
                  inputElement={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <IconButton edge="end" color="secondary">
                      <KeyboardArrowUpRounded />
                    </IconButton>
                  }
                >
                  {({ closePopup }): ReactElement => {
                    return (
                      <Paper>
                        <List dense>
                          {[...Array(totalSlides)].map((_undefined, index) => {
                            return (
                              <ListItem
                                selected={activeSlideIndex === index}
                                button
                                onClick={(): void => {
                                  handleSelectPage(index);
                                  closePopup();
                                }}
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                              >
                                <ListItemText>
                                  {`${t('shared.instructionViewer.infoBox.page')} ${index + 1}`}
                                </ListItemText>
                              </ListItem>
                            );
                          })}
                        </List>
                      </Paper>
                    );
                  }}
                </BasePopup>
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
};
