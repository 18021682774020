import { useEffect, useLayoutEffect, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { UserStateType, ActionType, Reducer } from './types';
import { userReducer } from './userReducer';

import { actionTypes } from './constants';
import { attemptGetUsers } from '../../store/user/effects';
import { RootState } from '../../store';
import { logReducer } from '../../utils/logReducer';
import { setLoading } from '../../store/user/actions';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useUser = (viewMode: 'new' | 'edit' | 'view' = 'view') => {
  /**
   * Redux state
   */
  const {
    user: { success: successUser, userList: initialUsers, loading: loadingUser, totalUsers },
  } = useSelector((state: RootState) => state);

  /**
   * Fetch user Data initially and setLoading
   */

  const reduxDispatch = useDispatch();
  useLayoutEffect(() => {
    reduxDispatch(setLoading('userListGet'));
  }, [reduxDispatch]);

  useEffect(() => {
    reduxDispatch(attemptGetUsers());
  }, [reduxDispatch]);

  /**
   * userReducer
   */
  const [state, dispatch] = useReducer(
    process.env.NODE_ENV === 'production' ? userReducer : logReducer<Reducer, UserStateType, ActionType>(userReducer),
    {
      users: initialUsers,
      currentUser: undefined,
      initialUserFormValues: undefined,
      viewMode,
      isMultiSelectMode: false,
    },
  );

  // update state if props change
  useEffect(() => {
    dispatch({
      type: actionTypes.SET_ALL,
      payload: initialUsers,
    });
  }, [initialUsers, dispatch]);

  // update state if props change
  useEffect(() => {
    if (successUser === 'userDelete') {
      dispatch({
        type: actionTypes.SET_CURRENT,
        payload: { data: false, _id: '' },
      });
    }
  }, [successUser, dispatch]);

  /**
   * generate return context
   */
  return {
    // user reducer state
    ...state,

    // user dispatch function
    dispatch,

    // redux state
    totalUsers,
    loadingUser,
    successUser,
  };
};
