import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useRichInputFieldStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexFlow: 'column',
      height: '100%',
      '& .ql-container': {
        flex: '1',
        maxHeight: '450px',
        overflowY: 'scroll',
      },
    },
  }),
);
