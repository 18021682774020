/* eslint-disable @typescript-eslint/no-explicit-any */
import { useReducer, useCallback, useRef, Dispatch } from 'react';

export const useEnhancedReducer = <
  // eslint-disable-next-line @typescript-eslint/ban-types
  R extends Function,
  S extends Record<string, unknown>,
  A extends { type: string; payload?: any }
>(
  reducer: R,
  initialState: S,
): [S, Dispatch<A>, () => S] => {
  const lastState = useRef<S>(initialState);
  const getState = useCallback(() => lastState.current, []);
  return [
    ...useReducer(
      // eslint-disable-next-line no-return-assign
      (state: S, action: A) => (lastState.current = reducer(state, action)),
      initialState,
    ),
    getState,
  ];
};
