import React, { ReactElement } from 'react';
import { Box, Typography, IconButton, useMediaQuery } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { CloseRounded } from '@material-ui/icons';
import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core/styles';
import { BaseDialogProps } from './types';

import { PixelImage } from '../../../atoms/images/PixelImage';
import { WaveImage } from '../../../atoms/images/WaveImage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
        marginBottom: 0,
      },
    },
  }),
);

export const BaseDialog = (props: BaseDialogProps): ReactElement => {
  const {
    open,
    onClose,
    children,
    title,
    disableBackdropClick,
    DialogProps = {},
    withPixel = true,
    withWave = true,
  } = props;

  const classes = useStyles();

  const theme = useTheme();
  const isLowViewPort = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDialogClose = (): void => {
    if (onClose) {
      onClose();
    } else {
      // eslint-disable-next-line no-console
      console.error({
        type: 'warning',
        code: 'SHAREDCOM_TEM_MAINDIALOG_NO_ONCLOSE',
        message: 'handleDialogClose called without onClose prop set as function',
      });
    }
  };

  return (
    <Dialog
      scroll="body"
      open={open}
      onClose={handleDialogClose}
      disableBackdropClick={!!disableBackdropClick}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...DialogProps}
    >
      <Box p={4} zIndex="20" position="relative">
        <Box display="flex" alignItems={isLowViewPort ? 'center' : 'stretch'} mb={isLowViewPort ? 3 : 0}>
          <Box>
            {' '}
            {title && (
              <Typography className={classes.heading} variant="h1">
                {title}
              </Typography>
            )}
          </Box>
          {onClose && (
            <Box flex="1" textAlign="right" position="relative" zIndex="500">
              <IconButton onClick={handleDialogClose} size={isLowViewPort ? 'small' : undefined}>
                <CloseRounded />
              </IconButton>
            </Box>
          )}
        </Box>

        {children}
      </Box>
      {withPixel && (
        <Box zIndex="2" position="relative">
          <PixelImage small flip />
        </Box>
      )}
      {withWave && (
        <Box zIndex="1" position="relative">
          <WaveImage maxWidth="50%" orientation="bottom" />
        </Box>
      )}
    </Dialog>
  );
};
