import { ActionType, LocationsLoadingType, FullLocationType, AreaType, FullDepartmentType } from './types';
import { ProblemType } from '../types';
import { locationConstants } from './constants';

/**
 * Actions
 * Beschreibt was wir tun wollen
 */

export const setAllLocations = (payload: FullLocationType[] = []): ActionType => ({
  type: locationConstants.LOCATION_SET_ALL,
  payload,
});

export const setLoading = (payload: LocationsLoadingType = ''): ActionType => ({
  type: locationConstants.LOCATION_LOADING,
  payload,
});

export const erroredLocation = (payload: ProblemType | null = null): ActionType => ({
  type: locationConstants.LOCATION_ERRORED,
  payload,
});

export const successLocation = (payload: LocationsLoadingType = ''): ActionType => ({
  type: locationConstants.LOCATION_SUCCESS,
  payload,
});

export const addLocation = (payload: FullLocationType): ActionType => ({
  type: locationConstants.LOCATION_ADD,
  payload,
});

export const replaceLocation = (payload: FullLocationType): ActionType => ({
  type: locationConstants.LOCATION_REPLACE,
  payload,
});

export const deleteLocation = (payload: string): ActionType => ({
  type: locationConstants.LOCATION_DELETE,
  payload,
});

export const addDepartment = (payload: { data: FullDepartmentType; locationId: string }): ActionType => ({
  type: locationConstants.LOCATION_ADD_DEPARTMENT,
  payload,
});

export const replaceDepartment = (payload: { data: FullDepartmentType; locationId: string }): ActionType => ({
  type: locationConstants.LOCATION_REPLACE_DEPARTMENT,
  payload,
});

export const deleteDepartment = (payload: string): ActionType => ({
  type: locationConstants.LOCATION_DELETE_DEPARTMENT,
  payload,
});

export const addArea = (payload: { data: AreaType; departmentId: string }): ActionType => ({
  type: locationConstants.LOCATION_ADD_AREA,
  payload,
});

export const replaceArea = (payload: { data: AreaType; departmentId: string }): ActionType => ({
  type: locationConstants.LOCATION_REPLACE_AREA,
  payload,
});

export const deleteArea = (payload: string): ActionType => ({
  type: locationConstants.LOCATION_DELETE_AREA,
  payload,
});

export const initLocations = (): ActionType => ({
  type: locationConstants.LOCATION_INIT,
});
