import { Dispatch } from 'redux';
import { thunkify } from '../../httpClient';
import { enqueueSnackbar } from '../notifier/actions';
import { erroredOrganisation, loadingOrganisation } from './actions';
import { ProblemType } from '../types';

/**
 * Thunks
 *
 * The return value of the inner function should be a promise. The dispatch function
 * returns the value of the function from within it. This allows us to chain dispatch functions.
 */
export const thunk = thunkify({
  error: (e, dispatch: Dispatch) => {
    return dispatch(
      enqueueSnackbar({
        message: e.message,
        details: e.details,
        errors: e.errors,
        options: {
          variant: 'error',
        },
      }),
    );
  },
});

export const thunkInline = thunkify({
  error: (e: ProblemType, dispatch: Dispatch) => {
    dispatch(loadingOrganisation());
    return dispatch(erroredOrganisation(e));
  },
});
