import { handleResponse } from '../handleResponse';
import { httpRequest } from '../httpRequest';

export const getCsrfToken = (): Promise<{ csrfToken: string }> =>
  httpRequest
    .get('/core/auth/csrf-token')
    .then((res) => handleResponse<{ csrfToken: string }>(res))
    .catch(() => {
      return { csrfToken: '' };
    });
