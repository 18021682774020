import dayjs from 'dayjs';
import { InstructionStat, InstructionStatRaw } from './types';

export const enrichInstructionStats = (instructionStats: InstructionStatRaw[]): InstructionStat => {
  // Initialize counters
  let redCount = 0;
  let yellowCount = 0;
  let greenCount = 0;

  // Create a new array with updated user counts
  const updatedInstructionStats = instructionStats.map((instructionStat) => {
    // Current date
    const now = dayjs().startOf('day');

    let redInnerCount = 0;
    let yellowInnerCount = 0;
    let greenInnerCount = 0;

    // Create a new array for updated user counts
    instructionStat.assignedUserCounts.forEach((userCount) => {
      // Parse nextDue date
      const nextDueDate = dayjs(userCount.nextDue).startOf('day');

      // Calculate difference in days
      const remainingDays = nextDueDate.diff(now, 'day');

      if (remainingDays > 30) {
        greenInnerCount += 1;
        greenCount += 1;
      } else if (remainingDays >= 1) {
        yellowInnerCount += 1;
        yellowCount += 1;
      } else {
        redInnerCount += 1;
        redCount += 1;
      }
    });

    // Return the updated instructionStat with updatedUserCounts
    return {
      ...instructionStat,
      redCount: redInnerCount,
      yellowCount: yellowInnerCount,
      greenCount: greenInnerCount,
    };
  });

  // Return the updated array and counts
  return {
    instructionStats: updatedInstructionStats,
    redCount,
    yellowCount,
    greenCount,
  };
};
