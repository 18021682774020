import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { ListSkeletonProps } from './types';

export const ListSkeleton = ({ amountItems, p = 5 }: ListSkeletonProps): ReactElement => {
  return (
    <Box p={p} display="flex" flexDirection="column">
      <div role="status" aria-label="loading items">
        {[...Array(amountItems)].map((_item, index) => {
          // eslint-disable-next-line react/no-array-index-key
          return <Skeleton key={index} height="45px" />;
        })}
      </div>
    </Box>
  );
};

ListSkeleton.propTypes = {
  amountItems: PropTypes.number,
  p: PropTypes.number,
};

ListSkeleton.defaultProps = {
  amountItems: 5,
  p: 5,
};
