/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { Box, Typography, Link } from '@material-ui/core';

import { History } from 'history';
import { TFunction } from 'i18next';
import { TutorialStep, NextSteps } from '../../components/organisms/window/Tutorial/types';
import { AvailablePermissions } from '../../lib/constants/availablePermissions';
import { queryParams } from '../../lib/utils/queryParams';
import { RouterLink } from '../../components/molecules/link/RouterLink';
import { UmoAuthRoutes } from '../lib/constants/pagePaths';
import { trigger } from '../../lib/events';

/* const StartedTutorialEmployee = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography align="left" variant="body1">
        {t('This is the Getting Started tutorial It will guide you through the basics of using the UMO')}
      </Typography>

      <Box mt={2}>
        <Typography align="left" variant="body2">
          {t('Reading time')}
          {t('Reading time with 1 minuten')}
        </Typography>
      </Box>
    </Box>
  );
}; */

const StartedTutorial = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography align="left" variant="body1">
        {t('This is the Getting Started tutorial It will guide you through the basics of using the UMO')}
      </Typography>

      <Box mt={2}>
        <Typography align="left" variant="body2">
          {t('Reading time')}
          {t('Reading time with 3 minuten')}
        </Typography>
      </Box>
    </Box>
  );
};

const TutorialsNavbar = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography align="left" variant="body1">
        <b> {t('Side menu')}</b>
      </Typography>
      <Box mt={1}>
        <Typography align="left" variant="body1">
          {t('In the side bar you can find module relevant features')}
        </Typography>
      </Box>
      <Box mt={1}>
        <Typography align="left" variant="body1">
          {t('Depending on the authorization, the view may vary')}
        </Typography>
      </Box>
    </Box>
  );
};

const TutorialsDashboard = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography align="left" variant="body1">
        <b> {t('Step Dashboard')}</b>
      </Typography>
      <Box mt={1}>
        <Typography align="left" variant="body1">
          {t('See your dashboard')}
        </Typography>
      </Box>
    </Box>
  );
};

const TutorialsMyInstructions = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography align="left" variant="body1">
        <b> {t('Own instructions')}</b>
      </Typography>
      <Box mt={1}>
        <Typography align="left" variant="body1">
          {t('Here you will find all the teachings assigned to you and tutorials relevant to the use of UMO')}
        </Typography>
      </Box>
    </Box>
  );
};

const TutorialsInstructions = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography align="left" variant="body1">
        <b> {t('Overview of instructions')}</b>
      </Typography>
      <Box mt={1}>
        <Typography align="left" variant="body1">
          {t('Here you will find a list of all instructions that are currently created in the system')}
        </Typography>
      </Box>
      <Box mt={1}>
        <Typography align="left" variant="body1">
          {t('The adjustment modification or deletion of an instruction is done via this menu item')}
        </Typography>
      </Box>
    </Box>
  );
};

const TutorialsTermControl = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography align="left" variant="body1">
        <b> {t('Date Monitoring')}</b>
      </Typography>
      <Box mt={1}>
        <Typography align="left" variant="body1">
          {t('The schedule control enables the monitoring of the instruction dates')}
        </Typography>
      </Box>
      <Box mt={1}>
        <Typography align="left" variant="body1">
          {t('The view is supported by a traffic light function')}
        </Typography>
      </Box>
    </Box>
  );
};
const TutorialsArchive = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography align="left" variant="body1">
        <b> {t('Instruction archive')}</b>
      </Typography>
      <Box mt={1}>
        <Typography align="left" variant="body1">
          {t('The instruction archive documents the proper execution of an instruction')}
        </Typography>
      </Box>
      <Box mt={1}>
        <Typography align="left" variant="body1">
          {t('You can download every instruction that has been carried out as a PDF file')}
        </Typography>
      </Box>
    </Box>
  );
};

const TutorialsUmoSettings = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography align="left" variant="body1">
        <b> {t('UMO settings')}</b>
      </Typography>
      <Box mt={1}>
        <Typography align="left" variant="body1">
          {t(
            'This menu item allows you to create modify or delete the instruction types edit a header or access the media gallery',
          )}
        </Typography>
      </Box>
    </Box>
  );
};

const TutorialsUMOHelpButton = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography align="left" variant="body1">
        <b> {t('In-app help function')}</b>
      </Typography>
      <Box mt={1}>
        <Typography align="left" variant="body1">
          {t('Each page contains detailed help which you can access via this button')}
        </Typography>
        <Box mt={1}>
          <Typography align="left" variant="body1">
            {t('The inapp help always starts with a short introduction and a menu')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const TutorialsOwnInstrctionOpen = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography align="left" variant="body1">
        {t('common:umoGetStartedTutorial.lastStep.nextSteps')}
      </Typography>
      <Box mt={2}>
        <Typography align="left" variant="body1">
          <Trans
            i18nKey="common:umoGetStartedTutorial.lastStep.alternative"
            components={{
              linkTutorial: (
                <Link
                  onClick={() => {
                    trigger('ds_close:tutorial', { tutorialName: 'core:basic' });
                  }}
                  component={RouterLink}
                  to={`${UmoAuthRoutes.myInstructions}/tutorials`}
                />
              ),
            }}
          />
        </Typography>
      </Box>
    </Box>
  );
};

export const getUmoBasicNextSteps = (params: { t: TFunction; history: History }): NextSteps => ({
  renderIf: ['skipped', 'closed'],
  title: params.t('common:umoGetStartedTutorial.nextSteps.title'),
  textReason: params.t('common:umoGetStartedTutorial.nextSteps.text'),
  textCancel: params.t('common:umoGetStartedTutorial.nextSteps.textCancel'),
  textConfirm: params.t('common:umoGetStartedTutorial.nextSteps.textConfirm'),
  onClickCancel: () => undefined,
  onClickConfirm: () => {
    queryParams.add(params.history, 'drawer', 'wizard');
  },
});

export const coreBasicTutorialSteps: TutorialStep[] = [
  /* {
    target: 'body',
    title: 'Getting Started',
    content: <StartedTutorialEmployee />,
    disableBeacon: true,
    placement: 'center',
    styles: {
      options: {
        width: 700,
      },
    },
    requiredPermissions: [
      AvailablePermissions.CoreCompanystructureFind,
      AvailablePermissions.CoreEmployeetypesFind,
      AvailablePermissions.CoreGroupadministrationFind,
      AvailablePermissions.CoreCrosssystemSettingsBillingFind,
      AvailablePermissions.CoreCrosssystemSettingsBillingUpdate,
      AvailablePermissions.CoreUseradministrationFind,
      AvailablePermissions.CoreUseradministrationCreate,
      AvailablePermissions.CoreUseradministrationUpdate,
      AvailablePermissions.CoreUseradministrationDelete,
      AvailablePermissions.CoreRoleFind,
      AvailablePermissions.CoreRoleCreate,
      AvailablePermissions.CoreRoleUpdate,
      AvailablePermissions.CoreRoleDelete,
      AvailablePermissions.CoreCrosssystemSettingsFind,
      AvailablePermissions.CoreCrosssystemSettingsUpdate,
    ],

    locale: { next: 'Start Tutorial' },
  }, */
  // ----------------------------------------------------------------
  {
    target: 'body',
    title: 'Getting Started',
    content: <StartedTutorial />,
    disableBeacon: true,
    placement: 'center',
    styles: {
      options: {
        width: 700,
      },
    },
    requiredPermissions: [
      AvailablePermissions.CoreCompanystructureFind,
      AvailablePermissions.CoreEmployeetypesFind,
      AvailablePermissions.CoreGroupadministrationFind,
      AvailablePermissions.CoreCrosssystemSettingsBillingFind,
      AvailablePermissions.CoreCrosssystemSettingsBillingUpdate,
      AvailablePermissions.CoreUseradministrationFind,
      AvailablePermissions.CoreUseradministrationCreate,
      AvailablePermissions.CoreUseradministrationUpdate,
      AvailablePermissions.CoreUseradministrationDelete,
      AvailablePermissions.CoreRoleFind,
      AvailablePermissions.CoreRoleCreate,
      AvailablePermissions.CoreRoleUpdate,
      AvailablePermissions.CoreRoleDelete,
      AvailablePermissions.CoreCrosssystemSettingsFind,
      AvailablePermissions.CoreCrosssystemSettingsUpdate,
    ],
    locale: { next: 'Start Tutorial' },
  },
  // ----------------------------------------------------------------
  {
    target: '#navbar-profile-button',
    title: 'Getting Started',
    spotlightPadding: 0,
    content: 'In the top bar you can change your profile settings',
    requiredPermissions: [
      AvailablePermissions.CoreCompanystructureFind,
      AvailablePermissions.CoreEmployeetypesFind,
      AvailablePermissions.CoreGroupadministrationFind,
      AvailablePermissions.CoreCrosssystemSettingsBillingFind,
      AvailablePermissions.CoreCrosssystemSettingsBillingUpdate,
      AvailablePermissions.CoreUseradministrationFind,
      AvailablePermissions.CoreUseradministrationCreate,
      AvailablePermissions.CoreUseradministrationUpdate,
      AvailablePermissions.CoreUseradministrationDelete,
      AvailablePermissions.CoreRoleFind,
      AvailablePermissions.CoreRoleCreate,
      AvailablePermissions.CoreRoleUpdate,
      AvailablePermissions.CoreRoleDelete,
      AvailablePermissions.CoreCrosssystemSettingsFind,
      AvailablePermissions.CoreCrosssystemSettingsUpdate,
    ],
  },
  // ----------------------------------------------------------------
  {
    target: '#navbar-settings-button',
    title: 'Getting Started',
    spotlightPadding: 0,
    content: 'Or module independent settings / base data',
    requiredPermissions: [
      AvailablePermissions.CoreCompanystructureFind,
      AvailablePermissions.CoreEmployeetypesFind,
      AvailablePermissions.CoreGroupadministrationFind,
      AvailablePermissions.CoreCrosssystemSettingsBillingFind,
      AvailablePermissions.CoreCrosssystemSettingsBillingUpdate,
      AvailablePermissions.CoreUseradministrationFind,
      AvailablePermissions.CoreUseradministrationCreate,
      AvailablePermissions.CoreUseradministrationUpdate,
      AvailablePermissions.CoreUseradministrationDelete,
      AvailablePermissions.CoreRoleFind,
      AvailablePermissions.CoreRoleCreate,
      AvailablePermissions.CoreRoleUpdate,
      AvailablePermissions.CoreRoleDelete,
      AvailablePermissions.CoreCrosssystemSettingsFind,
      AvailablePermissions.CoreCrosssystemSettingsUpdate,
    ],
  },
  // ----------------------------------------------------------------

  {
    target: '#side-navbar',
    title: 'Getting Started',
    spotlightPadding: 0,
    placement: 'right',
    content: <TutorialsNavbar />,
    styles: {
      options: {
        width: 700,
      },
    },
    requiredPermissions: [
      AvailablePermissions.UmoInstructionadministrationListFind,
      AvailablePermissions.UmoInstructionarchiveListFind,
      AvailablePermissions.UmoInstructionarchiveExport,
      AvailablePermissions.UmoInstructionarchiveDelete,
      AvailablePermissions.UmoInstructioncontrolInstructionviewFind,
      AvailablePermissions.UmoInstructioncontrolEmployeeviewFind,
      AvailablePermissions.UmoInstructiontypesFind,
      AvailablePermissions.UmoInstructiontypesCreate,
      AvailablePermissions.UmoInstructiontypesUpdate,
      AvailablePermissions.UmoInstructiontypesDelete,
    ],
  },
  // ----------------------------------------------------------------
  {
    target: '#sidebar-item-umo-dashboard',
    title: 'Getting Started',
    placement: 'right',
    spotlightPadding: 0,
    content: <TutorialsDashboard />,
    styles: {
      options: {
        width: 700,
      },
    },
    requiredPermissions: [
      AvailablePermissions.UmoInstructionadministrationListFind,
      AvailablePermissions.UmoInstructionarchiveListFind,
      AvailablePermissions.UmoInstructionarchiveExport,
      AvailablePermissions.UmoInstructionarchiveDelete,
      AvailablePermissions.UmoInstructioncontrolInstructionviewFind,
      AvailablePermissions.UmoInstructioncontrolEmployeeviewFind,
      AvailablePermissions.UmoInstructiontypesFind,
      AvailablePermissions.UmoInstructiontypesCreate,
      AvailablePermissions.UmoInstructiontypesUpdate,
      AvailablePermissions.UmoInstructiontypesDelete,
    ],
  },
  // ----------------------------------------------------------------
  {
    target: '#sidebar-item-umo-my-instructions',
    title: 'Getting Started',
    spotlightPadding: 0,
    placement: 'right',
    content: <TutorialsMyInstructions />,
    styles: {
      options: {
        width: 700,
      },
    },
    requiredPermissions: [
      AvailablePermissions.UmoInstructionadministrationListFind,
      AvailablePermissions.UmoInstructionarchiveListFind,
      AvailablePermissions.UmoInstructionarchiveExport,
      AvailablePermissions.UmoInstructionarchiveDelete,
      AvailablePermissions.UmoInstructioncontrolInstructionviewFind,
      AvailablePermissions.UmoInstructioncontrolEmployeeviewFind,
      AvailablePermissions.UmoInstructiontypesFind,
      AvailablePermissions.UmoInstructiontypesCreate,
      AvailablePermissions.UmoInstructiontypesUpdate,
      AvailablePermissions.UmoInstructiontypesDelete,
    ],
  },
  // ----------------------------------------------------------------
  {
    target: '#sidebar-item-umo-instructions',
    title: 'Getting Started',
    spotlightPadding: 0,
    placement: 'right',
    content: <TutorialsInstructions />,
    styles: {
      options: {
        width: 700,
      },
    },
    requiredPermissions: [
      AvailablePermissions.UmoInstructionadministrationListFind,
      AvailablePermissions.UmoInstructionarchiveListFind,
      AvailablePermissions.UmoInstructionarchiveExport,
      AvailablePermissions.UmoInstructionarchiveDelete,
      AvailablePermissions.UmoInstructioncontrolInstructionviewFind,
      AvailablePermissions.UmoInstructioncontrolEmployeeviewFind,
      AvailablePermissions.UmoInstructiontypesFind,
      AvailablePermissions.UmoInstructiontypesCreate,
      AvailablePermissions.UmoInstructiontypesUpdate,
      AvailablePermissions.UmoInstructiontypesDelete,
    ],
  },
  // ----------------------------------------------------------------
  {
    target: '#sidebar-item-umo-term-control',
    title: 'Getting Started',
    spotlightPadding: 0,
    placement: 'right',
    content: <TutorialsTermControl />,
    styles: {
      options: {
        width: 700,
      },
    },
    requiredPermissions: [
      AvailablePermissions.UmoInstructionadministrationListFind,
      AvailablePermissions.UmoInstructionarchiveListFind,
      AvailablePermissions.UmoInstructionarchiveExport,
      AvailablePermissions.UmoInstructionarchiveDelete,
      AvailablePermissions.UmoInstructioncontrolInstructionviewFind,
      AvailablePermissions.UmoInstructioncontrolEmployeeviewFind,
      AvailablePermissions.UmoInstructiontypesFind,
      AvailablePermissions.UmoInstructiontypesCreate,
      AvailablePermissions.UmoInstructiontypesUpdate,
      AvailablePermissions.UmoInstructiontypesDelete,
    ],
  },
  // ----------------------------------------------------------------
  {
    target: '#sidebar-item-umo-archive',
    title: 'Getting Started',
    spotlightPadding: 0,
    placement: 'right',
    content: <TutorialsArchive />,
    styles: {
      options: {
        width: 700,
      },
    },
    requiredPermissions: [
      AvailablePermissions.UmoInstructionadministrationListFind,
      AvailablePermissions.UmoInstructionarchiveListFind,
      AvailablePermissions.UmoInstructionarchiveExport,
      AvailablePermissions.UmoInstructionarchiveDelete,
      AvailablePermissions.UmoInstructioncontrolInstructionviewFind,
      AvailablePermissions.UmoInstructioncontrolEmployeeviewFind,
      AvailablePermissions.UmoInstructiontypesFind,
      AvailablePermissions.UmoInstructiontypesCreate,
      AvailablePermissions.UmoInstructiontypesUpdate,
      AvailablePermissions.UmoInstructiontypesDelete,
    ],
  },
  // ----------------------------------------------------------------
  {
    target: '#sidebar-item-umo-settings',
    title: 'Getting Started',
    spotlightPadding: 0,
    placement: 'right',
    content: <TutorialsUmoSettings />,
    styles: {
      options: {
        width: 700,
      },
    },
    requiredPermissions: [
      AvailablePermissions.UmoInstructionadministrationListFind,
      AvailablePermissions.UmoInstructionarchiveListFind,
      AvailablePermissions.UmoInstructionarchiveExport,
      AvailablePermissions.UmoInstructionarchiveDelete,
      AvailablePermissions.UmoInstructioncontrolInstructionviewFind,
      AvailablePermissions.UmoInstructioncontrolEmployeeviewFind,
      AvailablePermissions.UmoInstructiontypesFind,
      AvailablePermissions.UmoInstructiontypesCreate,
      AvailablePermissions.UmoInstructiontypesUpdate,
      AvailablePermissions.UmoInstructiontypesDelete,
    ],
  },
  // ----------------------------------------------------------------
  {
    target: '#open-navbar',
    title: 'Getting Started',
    spotlightPadding: 0,
    placement: 'top',
    content: 'Navbar open or close',
    requiredPermissions: [
      AvailablePermissions.UmoInstructionadministrationListFind,
      AvailablePermissions.UmoInstructionarchiveListFind,
      AvailablePermissions.UmoInstructionarchiveExport,
      AvailablePermissions.UmoInstructionarchiveDelete,
      AvailablePermissions.UmoInstructioncontrolInstructionviewFind,
      AvailablePermissions.UmoInstructioncontrolEmployeeviewFind,
      AvailablePermissions.UmoInstructiontypesFind,
      AvailablePermissions.UmoInstructiontypesCreate,
      AvailablePermissions.UmoInstructiontypesUpdate,
      AvailablePermissions.UmoInstructiontypesDelete,
    ],
  },
  // ----------------------------------------------------------------
  {
    target: '#help-button',
    title: 'Getting Started',
    spotlightPadding: 0,
    placement: 'left',
    styles: {
      options: {
        width: 700,
      },
    },
    requiredPermissions: [
      AvailablePermissions.UmoInstructionadministrationListFind,
      AvailablePermissions.UmoInstructionarchiveListFind,
      AvailablePermissions.UmoInstructionarchiveExport,
      AvailablePermissions.UmoInstructionarchiveDelete,
      AvailablePermissions.UmoInstructioncontrolInstructionviewFind,
      AvailablePermissions.UmoInstructioncontrolEmployeeviewFind,
      AvailablePermissions.UmoInstructiontypesFind,
      AvailablePermissions.UmoInstructiontypesCreate,
      AvailablePermissions.UmoInstructiontypesUpdate,
      AvailablePermissions.UmoInstructiontypesDelete,
    ],
    content: <TutorialsUMOHelpButton />,
  },
  // ----------------------------------------------------------------
  {
    target: 'body',
    title: 'Getting Started',
    content: <TutorialsOwnInstrctionOpen />,
    disableBeacon: true,
    locale: { last: 'common:umoGetStartedTutorial.lastStep.startWizard' },
    placement: 'center',
    styles: {
      options: {
        width: 700,
      },
    },
    requiredPermissions: [
      AvailablePermissions.UmoInstructionadministrationListFind,
      AvailablePermissions.UmoInstructionarchiveListFind,
      AvailablePermissions.UmoInstructionarchiveExport,
      AvailablePermissions.UmoInstructionarchiveDelete,
      AvailablePermissions.UmoInstructioncontrolInstructionviewFind,
      AvailablePermissions.UmoInstructioncontrolEmployeeviewFind,
      AvailablePermissions.UmoInstructiontypesFind,
      AvailablePermissions.UmoInstructiontypesCreate,
      AvailablePermissions.UmoInstructiontypesUpdate,
      AvailablePermissions.UmoInstructiontypesDelete,
    ],
  },
];
