import { FullLocationType, AreaType, FullDepartmentType } from './types';

/**
 * Integrates new Item into store locations
 * addDepartmentToLocationHelper -> integrates new department into locations.departments
 * addAreaToLocationHelper -> integrates new area into locations.departments.areas
 */

export const addDepartmentToLocationHelper = (
  locations: FullLocationType[],
  locationSearchId: string,
  department: FullDepartmentType,
): FullLocationType[] => {
  return locations.map((location) => {
    if (location._id === locationSearchId) {
      return { ...location, departments: [...location.departments, department] };
    }
    return location;
  });
};

export const addAreaToLocationHelper = (
  locations: FullLocationType[],
  departmentSearchId: string,
  area: AreaType,
): FullLocationType[] => {
  return locations.map((location) => {
    return {
      ...location,
      departments: location.departments.map((department) => {
        if (department._id === departmentSearchId) {
          return { ...department, areas: [...department.areas, area] };
        }
        return department;
      }),
    };
  });
};

/**
 * Replaces item in store locations
 * replaceDepartmentInLocationHelper -> replaces department in locations.departments
 * replaceAreaInLocationHelper -> replaces area in locations.departments.areas
 */
export const replaceDepartmentInLocationHelper = (
  locations: FullLocationType[],
  locationSearchId: string,
  department: FullDepartmentType,
): FullLocationType[] => {
  return locations.map((location) => {
    if (location._id === locationSearchId) {
      return {
        ...location,
        departments: location.departments.map((item) => (item._id === department._id ? department : item)),
      };
    }
    return location;
  });
};

export const replaceAreaInLocationHelper = (
  locations: FullLocationType[],
  departmentSearchId: string,
  area: AreaType,
): FullLocationType[] => {
  return locations.map((location) => {
    return {
      ...location,
      departments: location.departments.map((department) => {
        if (department._id === departmentSearchId) {
          return {
            ...department,
            areas: department.areas.map((item) => (item._id === area._id ? area : item)),
          };
        }
        return department;
      }),
    };
  });
};
