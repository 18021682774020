import { notifierConstants } from './constants';
import { NotificationType, ActionType } from './types';

export const enqueueSnackbar = (notification: NotificationType): ActionType => {
  return {
    type: notifierConstants.ENQUEUE_SNACKBAR,
    payload: {
      ...notification,
      ...{ options: { ...notification.options, key: new Date().getTime() + Math.random() } },
    },
  };
};

export const closeSnackbar = (key?: number): ActionType => ({
  type: notifierConstants.CLOSE_SNACKBAR,
  payload: { key, dismissAll: !key }, // dismiss all if no key has been defined
});

export const removeSnackbar = (key: number): ActionType => ({
  type: notifierConstants.REMOVE_SNACKBAR,
  payload: key,
});
