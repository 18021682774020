import { Dispatch } from 'redux';
import { thunk, thunkNoLoading } from './thunks';
import {
  apiLogoutAccount,
  apiLoginAccount,
  apiCheckAccountAuth,
  apiForgotPassword,
  apiVerifyAccount,
  apiRegisterAccount,
  apiResetPassword,
  apiUpdateAccount,
} from './services';
import {
  currentAccount,
  successAccount,
  checkAccount,
  authAccount,
  csrfAccount,
  loadingAccount,
  setShouldChangePassword,
  reauthenticateAccount,
} from './actions';
import { LoginFormValueType, RegisterDataType, UserAccountUpdateBody } from './types';
import { GetStateType } from '..';
import { getCsrfToken, httpRequest } from '../../httpClient';
import { getOrganisationSlug } from '../../utils';
import { currentOrganisation } from '../organisation/actions';
import { enqueueSnackbar } from '../notifier/actions';
import { APIError } from '../../types';

export const attemptLoginAccount = (
  data: LoginFormValueType,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch, getState: GetStateType) => {
    dispatch(loadingAccount('login'));
    const organisationSlug = getOrganisationSlug(getState, 'EFFECT_ACCOUNT_ALA');

    const { user, organisation } = await apiLoginAccount({ ...data, organisationSlug });
    dispatch(currentAccount(user));
    dispatch(authAccount(true));
    dispatch(currentOrganisation(organisation));
    dispatch(successAccount('login'));

    if (user && user.isStartPassword) {
      dispatch(setShouldChangePassword(true));
    }
  });

export const attemptCheckAccount = (
  ignoreReport = false,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch, getState: GetStateType) => {
    try {
      dispatch(loadingAccount('check'));
      const organisationSlug = getOrganisationSlug(getState, 'EFFECT_ACCOUNT_ACA', ignoreReport);

      const { csrfToken } = await getCsrfToken();
      // Set token on our requestHandler
      httpRequest.setCsrfToken(csrfToken);
      dispatch(csrfAccount(csrfToken));

      if (!organisationSlug) {
        dispatch(authAccount(false));
        localStorage.removeItem('refreshToken');
      } else {
        const user = await apiCheckAccountAuth(organisationSlug); // check token still good
        dispatch(currentAccount(user));
        dispatch(authAccount(true));
        if (user && user.isStartPassword) {
          dispatch(setShouldChangePassword(true));
        }
      }
    } catch (e) {
      dispatch(authAccount(false));
      localStorage.removeItem('refreshToken');
      // TODO: Error needed?
    }

    dispatch(checkAccount(true));
  });

export const attemptCheckAccountToken = (): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch, getState: GetStateType) => {
    try {
      const organisationSlug = getOrganisationSlug(getState, 'EFFECT_ACCOUNT_ACAT');

      // TODO: check if csrfToken needs to be set too
      if (!organisationSlug) {
        dispatch(authAccount(false));
        localStorage.removeItem('refreshToken');
      } else {
        await apiCheckAccountAuth(organisationSlug); // check token still good
      }
    } catch (e) {
      if (e instanceof APIError && e.tokenExpired) {
        dispatch(reauthenticateAccount());
      }
    }
  });

export const attemptUpdateAccount = (
  body: UserAccountUpdateBody,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(loadingAccount('profileEdit'));
    const user = await apiUpdateAccount(body); // check token still good
    dispatch(successAccount('profileEdit'));
    dispatch(currentAccount(user));
  });

export const attemptChangeStartPassword = (body: {
  password: string;
  currentPassword: string;
}): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(loadingAccount('changePassword'));
    const user = await apiUpdateAccount({ ...body, isStartPassword: false }); // check token still good
    dispatch(successAccount('changePassword'));
    dispatch(currentAccount(user));
    dispatch(setShouldChangePassword(false));

    dispatch(
      enqueueSnackbar({
        translationString: 'module-core:shared.account.changePasswordSuccess',
        messageTranslationKeys: {},
        options: {
          variant: 'success',
        },
      }),
    );
  });

export const attemptUpdateTutorial = (body: {
  tutorials: UserAccountUpdateBody['tutorials'];
}): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunkNoLoading(async (dispatch: Dispatch) => {
    const user = await apiUpdateAccount(body); // check token still good
    dispatch(currentAccount(user));
  });

export const attemptLogoutAccount = (): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    apiLogoutAccount();
    localStorage.removeItem('refreshToken');
    dispatch({ type: 'dSimple/RESET_APP' });
    // TODO: Log error to server
  });

export const attemptForgotPassword = (email: string): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch, getState: GetStateType) => {
    dispatch(loadingAccount('forgot'));
    const organisationSlug = getOrganisationSlug(getState, 'EFFECT_FORGOT_AFP');
    await apiForgotPassword({ email, organisationSlug });
    dispatch(successAccount('forgot'));
  });

export const attemptVerifyAccount = (token: string): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(loadingAccount('verify'));
    await apiVerifyAccount(token);
    dispatch(successAccount('verify'));
  });

export const attemptRegisterAccount = (
  data: RegisterDataType,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk<void>(async (dispatch: Dispatch) => {
    dispatch(loadingAccount('register'));
    await apiRegisterAccount(data);
    // TODO: Check if error thrown is fetched in thunk error and below action is not executed
    dispatch(successAccount('register'));
  });

export const attemptResetPassword = (
  token: string,
  password: string,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    const body = { token, password };
    dispatch(loadingAccount('reset'));
    await apiResetPassword(body);
    dispatch(successAccount('reset'));
  });
