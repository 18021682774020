import { GroupState, ActionType } from './types';
import { groupConstants } from './constants';

// INITIAL STATE
const initialState: GroupState = {
  groups: [],
  problem: null,
  loading: '',
  success: '',
};

/**
 * Reducer
 * Updated den STORE je nach ACTION type - Mitarbeiter der Auftrag ausfuehrt
 */
export const groupReducer = (state = initialState, action: ActionType): GroupState => {
  switch (action.type) {
    case groupConstants.GROUP_SET_ALL:
      return {
        ...state,
        groups: action.payload,
      };

    case groupConstants.GROUP_LOADING:
      return {
        ...state,
        loading: action.payload,
        problem: action.payload ? null : state.problem,
        success: action.payload ? '' : state.success,
      };

    case groupConstants.GROUP_ERRORED:
      return {
        ...state,
        problem: action.payload,
      };

    case groupConstants.GROUP_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };

    case groupConstants.GROUP_ADD:
      return {
        ...state,
        groups: [...state.groups, action.payload],
      };

    case groupConstants.GROUP_REPLACE:
      return {
        ...state,
        groups: state.groups.map((group) => (group._id === action.payload._id ? action.payload : group)),
      };

    case groupConstants.GROUP_DELETE:
      return {
        ...state,
        groups: state.groups.filter((group) => group._id !== action.payload),
      };

    case 'dSimple/RESET_APP':
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
