import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const drawerWidth = 270;

export const useBaseSideBarStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    gap: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      minHeight: ({ toolbarHeight }: { toolbarHeight: string }) => toolbarHeight,
      [theme.breakpoints.down('xs')]: {
        minHeight: '56px !important',
      },
      // necessary for content to be below app bar
      // ...theme.mixins.toolbar,
    },
    moduleLink: {
      textDecoration: 'none',
    },
    openerBox: {
      flex: '1',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      '& .MuiIconButton-root': {
        flex: '1',
        background: theme.palette.action.selected,
        color: theme.palette.secondary.main,
        borderRadius: '0',
        '&:hover': {
          background: theme.palette.action.hover,
        },
      },
    },
    module: {
      fontSize: '16px',
      margin: '0',
      padding: `${theme.spacing(2.5)}px ${theme.spacing(1.5)}px`,
      [theme.breakpoints.down('xs')]: {
        padding: `${theme.spacing(2.5)}px ${theme.spacing(0.5)}px`,
      },
      '&:before': {
        width: '2px',
        marginRight: '5px',
      },
    },
    button: {
      padding: '0',
      '& a': {
        padding: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(1.5),
        },
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      },
      '&:hover': {
        background: 'transparent',
      },
      '& a.sideBarItemActive': {
        background: theme.palette.action.selected,
      },
      '&:hover a': {
        background: theme.palette.action.hover,
      },
    },
    text: {
      '& .MuiTypography-root': {
        fontSize: '12px',
      },
    },
    list: {
      paddingTop: '0px',
    },
  }),
);
