import React, { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { Typography, Link } from '@material-ui/core';

import { ForgotForm } from '../../components/organisms/form/ForgotForm';

import { config } from '../../lib/config';
import { NonAuthRoutes } from '../../lib/constants/pagePaths';
import { attemptForgotPassword } from '../../lib/store/account/effects';
import { LogoHeadingBox } from '../../components/molecules/box/LogoHeadingBox';
import { InfoBar } from '../../components/molecules/box/InfoBar';
import { AccessTemplate } from '../../components/templates';
import { RouterLink } from '../../components/molecules/link/RouterLink';
import { successAccount } from '../../lib/store/account/actions';

const Forgot = (): ReactElement => {
  const { t } = useTranslation(['init']);
  const reduxDispatch = useDispatch();

  useEffect(() => {
    reduxDispatch(successAccount(''));
  }, []);

  const handleSubmit = (email: string): void => {
    reduxDispatch(attemptForgotPassword(email));
  };

  return (
    <AccessTemplate
      infoBar={<InfoBar infoLink={config.wikiUrl || ''} />}
      header={<LogoHeadingBox title={t('init:pages.forgot.header.title')} />}
      footer={(): ReactElement => {
        return (
          <Typography align="center">
            <Trans
              i18nKey="init:shared.pages.footer.loginText"
              components={{
                login: <Link component={RouterLink} color="secondary" to={NonAuthRoutes.login} />,
              }}
            />
          </Typography>
        );
      }}
    >
      <ForgotForm handleSubmit={handleSubmit} />
    </AccessTemplate>
  );
};
export default Forgot;
