import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box } from '@material-ui/core';
import { InputField, SelectField, DateField } from '../../../../molecules/form/elements';
import { HelperText } from '../../../../molecules/form/texts/HelperText';
import { ErrorBoxOnInvalidField } from '../../actions/ErrorBoxOnInvalidField';
import { useUserStateContext } from '../../../../../lib/context/user';

export const PersonalFields = (props: { disableAllFields: boolean; showRequiredOnly?: boolean }): ReactElement => {
  const { disableAllFields, showRequiredOnly = false } = props;
  const { t } = useTranslation(['module-core']);
  const { currentUser, viewMode } = useUserStateContext();

  const genderOptions = [
    {
      value: '',
      label: '-',
    },
    {
      value: 'm',
      label: t('module-core:pages.userManagement.forms.labels.genderOptions.m'),
    },
    {
      value: 'f',
      label: t('module-core:pages.userManagement.forms.labels.genderOptions.f'),
    },
    {
      value: 'd',
      label: t('module-core:pages.userManagement.forms.labels.genderOptions.d'),
    },
  ];
  return (
    <Box>
      <Box mb={2}>
        <Grid container spacing={2} item>
          <Grid item xs={12}>
            <Typography variant="h6">{t('module-core:pages.userManagement.forms.headings.personalDetails')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <InputField
              disabled={disableAllFields}
              required
              name="firstName"
              label={t('module-core:pages.userManagement.forms.labels.firstName')}
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              disabled={disableAllFields}
              name="lastName"
              required
              label={t('module-core:pages.userManagement.forms.labels.lastName')}
            />
          </Grid>
          {!showRequiredOnly && (
            <Grid item xs={12}>
              <SelectField
                disabled={disableAllFields}
                name="gender"
                label={t('module-core:pages.userManagement.forms.labels.gender')}
                options={genderOptions}
              />
            </Grid>
          )}
          {!showRequiredOnly && (
            <Grid item xs={12}>
              <DateField
                disabled={disableAllFields}
                name="dateOfBirth"
                label={t('module-core:pages.userManagement.forms.labels.dateOfBirth')}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={2} item>
          <Grid item xs={12}>
            <Typography variant="h6">{t('module-core:pages.userManagement.forms.headings.identification')}</Typography>
            <HelperText>{t('module-core:pages.userManagement.forms.headings.identificationHelper')}</HelperText>
          </Grid>
          <Grid item xs={12}>
            <InputField
              disabled={disableAllFields}
              name="email"
              type="email"
              label={t('module-core:pages.userManagement.forms.labels.email')}
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              disabled={disableAllFields}
              name="personnelNumber"
              label={t('module-core:pages.userManagement.forms.labels.personnelNumber')}
            />
          </Grid>
          {!(currentUser && currentUser.isRootUser) && (
            <Grid item xs={12}>
              <InputField
                disabled={disableAllFields}
                name="password"
                required={viewMode !== 'edit'}
                autoComplete="off"
                label={t(
                  `module-core:pages.userManagement.forms.labels.${
                    viewMode === 'edit' ? 'passwordEditForm' : 'password'
                  }`,
                )}
              />
            </Grid>
          )}
          {!showRequiredOnly && (
            <Grid item xs={12}>
              <InputField
                disabled={disableAllFields}
                name="rfidNumber"
                label={t('module-core:pages.userManagement.forms.labels.rfidNumber')}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      <ErrorBoxOnInvalidField
        message={t('module-core:pages.userManagement.companyTabHasErrors')}
        fieldNames={['role']}
      />
    </Box>
  );
};
