import './lib/wdyr';
import React from 'react';
import ReactDOM from 'react-dom';
import './lib/i18n';

import * as OfflinePluginRuntime from 'offline-plugin/runtime';
import * as Sentry from '@sentry/react';
import { CaptureConsole } from '@sentry/integrations';
import { BrowserTracing } from '@sentry/tracing';
import posthog from 'posthog-js';

import { App } from './App';
import { config } from './lib/config';

declare global {
  interface Window {
    swUpdate?: true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Cypress?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    store?: any;
  }
}

OfflinePluginRuntime.install({
  onUpdateReady: () => OfflinePluginRuntime.applyUpdate(),
  // eslint-disable-next-line no-return-assign
  onUpdated: () => (window.swUpdate = true),
});

const env = process.env.REACT_APP_NODE_ENV;
const nodeEnv = process.env.NODE_ENV;

const query = new URLSearchParams(window.location.search);
const mode = query.get('mode');

if (!(nodeEnv === 'development' || nodeEnv === 'test')) {
  Sentry.init({
    dsn: config.sentry,
    integrations: [
      new BrowserTracing(),
      new CaptureConsole({
        levels: ['error'],
      }),
    ],
    tracesSampleRate: env === 'sandbox' ? 1 : 0.6,
    environment: env === 'sandbox' ? 'sandbox' : 'production',
  });

  if (config.posthog) {
    posthog?.init(config.posthog, { api_host: 'https://eu.posthog.com' });
  } else {
    // opt out of capturing for non-production environments
    posthog.init('fake token', {
      autocapture: false,
      loaded(ph) {
        ph.opt_out_capturing();
      },
    });
  }
}

ReactDOM.render(
  <App mode={mode} environment={env === 'sandbox' ? 'sandbox' : nodeEnv} />,
  document.getElementById('app'),
);
