import { httpRequest, handleResponse } from '../../../httpClient';
import { getRefreshToken, setRefreshToken } from '../../../utils';
import { OrganisationType } from '../../organisation/types';
import {
  LoginDataType,
  ForgotDataType,
  UserAccountType,
  UserAccountUpdateBody,
  RegisterDataType,
  ResetDataType,
} from '../types';

export const apiLoginAccount = (
  body: LoginDataType,
): Promise<{ user: UserAccountType; organisation: OrganisationType; refreshToken: string }> => {
  return httpRequest
    .post('/core/users/login', { body })
    .then((res) => handleResponse<{ user: UserAccountType; organisation: OrganisationType; refreshToken: string }>(res))
    .then((res) => {
      setRefreshToken(res.refreshToken);
      return res;
    });
};

export const apiCheckAccountAuth = (organisationSlug: string): Promise<UserAccountType> =>
  httpRequest
    .get('/core/users/check', { query: { organisationSlug } })
    .then((res) => handleResponse<UserAccountType>(res))
    .then((res) => res);

export const apiLogoutAccount = (): Promise<unknown> =>
  httpRequest
    .get('/core/users/logout', { headers: { authorization: `Bearer ${getRefreshToken()}` } })
    .then(handleResponse);

export const apiForgotPassword = (body: ForgotDataType): Promise<unknown> =>
  httpRequest.post('/core/users/forgot-password', { body }).then(handleResponse);

export const apiUpdateAccount = (body: UserAccountUpdateBody): Promise<UserAccountType> =>
  httpRequest
    .patch('/core/users/account', { body })
    .then((res) => handleResponse<UserAccountType>(res))
    .then((res) => res);

export const apiVerifyAccount = (verificationToken: string): Promise<unknown> =>
  httpRequest.get('/core/users/verify', { query: { verificationToken } }).then(handleResponse);

export const apiRegisterAccount = (body: RegisterDataType): Promise<unknown> =>
  httpRequest.post('/core/organisations', { body }).then(handleResponse);

export const apiResetPassword = (body: ResetDataType): Promise<unknown> =>
  httpRequest.post('/core/users/reset-password', { body }).then(handleResponse);
