import React, { ReactElement } from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { TabPanelProps } from './types';

export const StyledTabPanel = (props: TabPanelProps): ReactElement => {
  const { children, value, index, isSecondary, stretch, py = 3 } = props;

  return (
    <div
      role="tabpanel"
      style={{ ...(stretch && { flex: 1 }) }}
      hidden={value !== index}
      id={`${isSecondary ? 'secondary' : 'nav'}-tabpanel-${index}`}
      aria-labelledby={`${isSecondary ? 'secondary' : 'nav'}-tab-${index}`}
    >
      {value === index && <Box py={py}>{children}</Box>}
    </div>
  );
};

StyledTabPanel.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  isSecondary: PropTypes.bool,
  stretch: PropTypes.bool,
  py: PropTypes.number,
};

StyledTabPanel.defaultProps = {
  isSecondary: false,
  py: 3,
  stretch: false,
};
