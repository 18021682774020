import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useWaveImageStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'absolute',
      maxWidth: ({ maxWidth }: { maxWidth?: string }) => maxWidth || 'initial',
    },
    orientationRight: {
      top: '0',
      right: '0',
      height: '100%',
    },
    orientationBottom: {
      bottom: '0',
      right: '0',
      width: '100%',
    },
    orientationTop: {
      bottom: '0',
      right: '0',
      width: '100%',
    },
  }),
);
