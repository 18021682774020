/* eslint-disable react/no-array-index-key */
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Box } from '@material-ui/core';

import { Skeleton } from '@material-ui/lab';
import { useWindowSize } from 'react-use';
import { useUser } from '../../../../lib/context/user/useUser';

import { actionTypes } from '../../../../lib/context/user/constants';
import { UserManagementMode } from '../../../../lib/context/user/types';

import { UserDispatchProvider, UserStateProvider } from '../../../../lib/context/user';

import { useCheckPlan } from '../../../../lib/hooks/useCheckPlan';
import { availablePlans } from '../../../../lib/constants/availablePlans';
import { UpgradeDialog } from '../../../../components/organisms/window/UpgradeDialog';
import { UserCard } from './UserCard';
import { UserForm } from './UserForm';
import { useStepAssignmentsStyles } from '../styles';
import { AddUserCard } from './AddUserCard';
import { useRole } from '../../../../lib/context/role/useRole';
import { UserType } from '../../../../lib/store/user/types';
import { StepAssignmentsProps } from '../types';
import { BaseDialog } from '../../../../components/molecules/window/BaseDialog';

export const StepAssignments = (props: StepAssignmentsProps): ReactElement => {
  const { handleUpdateAssignment, currentAssignment } = props;
  const { t } = useTranslation(['module-core']);
  const classes = useStepAssignmentsStyles();

  const isXs = useWindowSize().width < 600;

  const [open, setOpen] = React.useState<'upgradeDialog' | ''>('');
  const userBag = useUser('view');
  useRole();

  const { hasPlan, _inclusiveUsers, isBetaPlan } = useCheckPlan(availablePlans);

  const { dispatch, ...state } = userBag;
  const { loadingUser, viewMode, currentUser, users } = state;

  const isFormActive = viewMode === 'edit' || viewMode === 'new';

  const useFormTitle = t(
    `module-core:pages.userManagement.${
      viewMode === 'edit' ? 'editUserHeading' : viewMode === 'new' ? 'addUserHeading' : 'userDetails'
    }`,
  );

  const handleToggleMode = (mode: UserManagementMode): void => {
    dispatch({
      type: actionTypes.CHANGE_MODE,
      payload: mode,
    });
  };

  const handleOpenForm = (_id?: string): void => {
    const isEditForm = !!_id;

    const isUserLimitReached =
      (!hasPlan || isBetaPlan) && typeof _inclusiveUsers === 'number' && userBag.totalUsers >= _inclusiveUsers;

    if (!isEditForm && isUserLimitReached) {
      setOpen('upgradeDialog');
      return;
    }

    dispatch({
      type: actionTypes.SET_CURRENT,
      payload: { data: isEditForm, _id: _id || '' },
    });

    handleToggleMode(isEditForm ? 'edit' : 'new');
  };

  const handleCloseForm = (): void => {
    dispatch({
      type: actionTypes.SET_CURRENT,
      payload: { data: false, _id: '' },
    });

    handleToggleMode('view');
  };

  const handleSelectUser = (user: UserType) => {
    const shouldDeselect = !!currentAssignment?.users?.includes(user._id);
    const currentUsers = currentAssignment?.users || [];

    if (shouldDeselect) {
      handleUpdateAssignment({ users: currentUsers.filter((userId) => userId !== user._id) });
    } else {
      handleUpdateAssignment({ users: [...currentUsers, user._id] });
    }
  };

  return (
    <UserStateProvider value={state}>
      <UserDispatchProvider value={dispatch}>
        <Grid container spacing={3} className={classes.gridRoot}>
          <Grid
            item
            xs={12}
            sm={isFormActive && !isXs ? 6 : 12}
            md={isFormActive && !isXs ? 8 : 12}
            className={classes.gridItem}
          >
            <div className={classes.userCards}>
              {loadingUser === 'userListGet' ? (
                [...Array(4)].map((_, index) => (
                  <Box key={index} className={classes.userCardSkeleton}>
                    <Skeleton variant="rect" width="100%" height={118} />
                  </Box>
                ))
              ) : (
                <>
                  {users.map((user) => {
                    return (
                      <UserCard
                        key={user._id}
                        user={user}
                        handleSelectUser={() => handleSelectUser(user)}
                        handleEditUser={
                          user._id === currentUser?._id ? () => handleCloseForm() : () => handleOpenForm(user._id)
                        }
                        isEditing={user._id === currentUser?._id}
                        isSelected={!!currentAssignment?.users?.includes(user._id)}
                      />
                    );
                  })}

                  <AddUserCard
                    open={viewMode === 'new'}
                    toggleForm={viewMode === 'new' ? () => handleCloseForm() : () => handleOpenForm()}
                  />
                </>
              )}
            </div>
          </Grid>
          {isFormActive && !isXs && (
            <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
              <UserForm
                title={useFormTitle}
                key={`${viewMode}-${currentUser?._id}`}
                handleClose={() => handleCloseForm()}
              />
            </Grid>
          )}

          <BaseDialog title={useFormTitle} open={isFormActive && isXs} onClose={() => handleCloseForm()}>
            <UserForm handleClose={() => handleCloseForm()} key={`${viewMode}-${currentUser?._id}`} isDialogMode />
          </BaseDialog>
        </Grid>
        <UpgradeDialog handleClose={() => setOpen('')} open={open === 'upgradeDialog'} />
      </UserDispatchProvider>
    </UserStateProvider>
  );
};
