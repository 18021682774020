import React, { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AutoCompleteField } from '../../../../molecules/form/elements';
import { RootState } from '../../../../../lib/store';
import { attemptGetRoleNames } from '../../../../../lib/store/role/effects';
import { setLoading } from '../../../../../lib/store/role/actions';

import { RoleNameType } from '../../../../../lib/store/role/types';
import { useUserStateContext } from '../../../../../lib/context/user';
import { RoleFieldProps } from './types';

export const RoleField = (props: RoleFieldProps): ReactElement => {
  const { name, preventFetching, showLoadingOn, disabled, required } = props;
  const { t } = useTranslation(['module-core']);
  const reduxDispatch = useDispatch();
  const { currentUser } = useUserStateContext();
  const { loading, roleNameList } = useSelector((state: RootState) => state.role);

  const handleFetchRoleNames = useCallback(() => {
    reduxDispatch(setLoading(showLoadingOn || 'roleNameListGet'));
    reduxDispatch(attemptGetRoleNames());
  }, [reduxDispatch, showLoadingOn]);

  const handleResetRoleNames = useCallback(() => {
    reduxDispatch(setLoading());
  }, [reduxDispatch]);

  return (
    <AutoCompleteField<RoleNameType>
      name={name || 'role'}
      required={required}
      label={t('module-core:pages.userManagement.forms.labels.role')}
      loading={loading === (showLoadingOn || 'roleNameListGet')}
      options={roleNameList}
      disabled={disabled || !!(currentUser && currentUser.isRootUser)}
      selectValueBy="roleName"
      handleFetchData={preventFetching ? undefined : handleFetchRoleNames}
      handleResetData={preventFetching ? undefined : handleResetRoleNames}
    />
  );
};
