import React, { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, MenuItem, Box } from '@material-ui/core';
import { AccountBoxRounded } from '@material-ui/icons';

import { TopMenuItem } from '../../molecules/menu/TopMenuItem';
import { attemptLogoutAccount } from '../../../lib/store/account';
import { ProfileDialog } from '../window/ProfileDialog';

export const ProfileMenuItem: React.FC = (): ReactElement => {
  const [profilePopupOpen, setProfilePopupOpen] = useState(false);
  const handleProfilePopupClose = (): void => {
    setProfilePopupOpen(false);
  };
  const dispatch = useDispatch();
  const { t } = useTranslation(['menu']);
  return (
    <>
      <TopMenuItem
        mainComponent={(anchorRef, open): ReactElement => {
          return (
            <Button
              id="navbar-profile-button"
              ref={anchorRef}
              startIcon={<AccountBoxRounded color="secondary" />}
              variant="text"
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
            >
              {t('menu:navbar.profile.text')}
            </Button>
          );
        }}
        dropdownComponent={(handleClose): ReactElement[] => {
          const handleLogout = (event: React.MouseEvent<EventTarget>): void => {
            handleClose(event);
            dispatch(attemptLogoutAccount());
          };
          const handleProfilePopup = (event: React.MouseEvent<EventTarget>): void => {
            handleClose(event);
            setProfilePopupOpen(true);
          };

          return [
            <MenuItem key="profilesettings" onClick={handleProfilePopup}>
              <Box>{t('menu:navbar.profile.dropdown.profilesettings')}</Box>
            </MenuItem>,
            // <MenuItem key="notifications" onClick={handleClose}>
            //   <Box>{t('menu:navbar.profile.dropdown.notifications')}</Box>
            // </MenuItem>,
            <MenuItem key="logout" onClick={handleLogout}>
              <Box>{t('menu:navbar.profile.dropdown.logout')}</Box>
            </MenuItem>,
          ];
        }}
      />
      <ProfileDialog open={profilePopupOpen} onClose={handleProfilePopupClose} />
    </>
  );
};
