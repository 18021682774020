import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

export const useRegisterFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputRoot: {
      '& .MuiFormLabel-root': {
        display: 'none',
      },
    },
    adornmentRoot: {
      display: 'flex',
      width: '250px',
      justifyContent: 'flex-end',
      alignItems: 'center',
      height: '46px',
      paddingLeft: '10px',
      [theme.breakpoints.down('xs')]: {
        width: '200px',
      },
    },
    domainRoot: {
      position: 'absolute',
      right: '0',
      left: '0',
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      pointerEvents: 'none',
      '&:after': {
        content: '".d-simple.com"',
        [theme.breakpoints.down('xs')]: {
          fontSize: '11px',
        },
      },
    },
    domainText: {
      marginLeft: '15px',
      fontWeight: 'normal',
      color: 'transparent',
    },
    domainPlaceholder: {
      marginLeft: '15px',
      color: '#989898',
      fontWeight: 'normal',
      [theme.breakpoints.down('xs')]: {
        fontSize: '11px',
      },
    },
  }),
);
