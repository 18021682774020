import { Box } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useToolbarStyles } from './styles';

export const Toolbar = (): React.ReactElement => {
  const { t } = useTranslation();
  const classes = useToolbarStyles();

  return (
    <Box className={classes.root}>
      <div id="toolbar">
        <select className="ql-header" defaultValue="4" onChange={(e) => e.persist()}>
          <option value="1">{t('module-core:shared.common.heading1')}</option>
          <option value="2">{t('module-core:shared.common.heading2')}</option>
          <option value="3">{t('module-core:shared.common.heading3')}</option>
          <option value="4" aria-label="default">
            {t('module-core:shared.common.normal')}
          </option>
        </select>
        <button type="button" aria-label="bold" className="ql-bold" />
        <button type="button" aria-label="italic" className="ql-italic" />
        <select className="ql-color" defaultValue="#1d1d1d" onChange={(e) => e.persist()}>
          <option aria-label="red" value="red" />
          <option aria-label="green" value="green" />
          <option aria-label="blue" value="blue" />
          <option aria-label="orange" value="orange" />
          <option aria-label="violet" value="violet" />
          <option aria-label="light grey" value="#d0d1d2" />
          <option aria-label="default" value="#1d1d1d" />
        </select>
      </div>
    </Box>
  );
};
