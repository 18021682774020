import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useDateFieldStyles = makeStyles(() =>
  createStyles({
    buttonAlignment: {
      '& .MuiInputBase-adornedEnd': {
        paddingRight: '0px',
      },
    },
  }),
);
