export enum UmoAuthRoutes {
  myInstructions = '/umo/my-instructions',
  myInstructionsView = '/umo/my-instructions/view/', // trailing slash indicates it accepts params
  instructionsOverview = '/umo/instructions',
  instructionsCreate = '/umo/instructions/create',
  instructionsPreview = '/umo/instructions/view/',
  instructionsEdit = '/umo/instructions/edit/',
  instructionsAssign = '/umo/instructions/assign/',

  termControl = '/umo/term-control',
  archive = '/umo/archive',
  settings = '/umo/settings',
}

export enum UmoNoneAuthRoutes {
  viewOpenInstruction = '/umo/view/instruction',
  viewInstructionTemplates = '/umo/view/templates',
}
