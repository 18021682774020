import { FileStatic } from '../../lib/store/file/types';
import { config } from '../../lib/config';

/**
 * Location format: static/<parentGroup>/<group>/<fileName>
 * Example: static/signs/warning-signs/file.txt
 */
export const getStaticFileUrl = (file: FileStatic): string => {
  const parts = file.location.split('/');
  const groups = parts.slice(1, parts.length - 1).join('/');

  return `${config.endpoint}/core/file/static/${file.fileName}?group=${groups}&location=${encodeURIComponent(
    file.location,
  )}`;
};
