import React, { ReactElement } from 'react';
import { CircularProgress, IconButton } from '@material-ui/core';
import { EditRounded } from '@material-ui/icons';
import clsx from 'clsx';
import { useEditIconButtonStyles } from './styles';
import { EditIconButtonProps } from './types';

export const EditIconButton = (props: EditIconButtonProps): ReactElement => {
  const classes = useEditIconButtonStyles();
  const { loading, className, ...other } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <IconButton className={clsx(classes.buttonRoot, className)} {...other}>
      {loading ? <CircularProgress size="24px" /> : <EditRounded />}
    </IconButton>
  );
};
