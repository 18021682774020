import { Dispatch } from 'redux';
import { thunk } from './thunks';
import { setTemplateList, setLoading } from './actions';

import { apiFindTemplates } from './services';
import { QueryObject } from '../../../../lib/httpClient';
import { GetStateType } from '../../../../lib/store';

export const attemptGetTemplates = (
  query?: QueryObject,
  viewToken?: string,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('templateGet'));

    const data = await apiFindTemplates(query, viewToken);
    dispatch(setTemplateList(data));
    dispatch(setLoading(''));
  });
